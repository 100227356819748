import React, { useEffect, useState } from "react";
import CommonModal from "../../../Common/Component/CommonModal";
import { Container, Row, Col, Form, Label, Input } from "reactstrap";
import { useForm } from "react-hook-form";
import { Btn, H5 } from "../../../../AbstractElements";
import axios from "axios";
import { toast } from "react-toastify";
import { adminBackendURL, backendURL } from "../../../../Config/Config";
import { useContext } from "react";
import UserContext from "../../../../_helper/User";
const SaveInterfaceModel = ({
  is_open,
  trigger_model,
  model_data,
  form_data,
  reload_table,
}) => {
  const userContext = useContext(UserContext);
  const loggedUser = userContext.userData;
  const toggle = () => trigger_model(!is_open);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });
  const onSubmit = (data) => {
    const form = new FormData(document.querySelector(".form-1"));
    if (data !== "") {
      const id = toast.loading("Please wait...");
      const moduleInterface = {
        interfaceId: model_data === "" ? 0 : model_data.moduleLineId,
        clientId: loggedUser.clientId,
        orgId: loggedUser.orgId,
        userId: loggedUser.userId,
        loggedModuleId: loggedUser.loggedModuleId,
        moduleId: parseInt(form.get("fm_1_sa_module_id")),
        moduleCategoryId: parseInt(form.get("fm_1_sa_module_category_id")),
        isActive: form.get("fm_1_active") ? true : false,
        description: form.get("fm_1_description"),
        name: form.get("fm_1_name"),
        searchKey: form.get("fm_1_search_key"),
        remark: form.get("fm_1_remark"),
      };
      const reqInstance = axios.create({
        baseURL: adminBackendURL,
      });
      reqInstance
        .post("/saveInterface", moduleInterface)
        .then((response_data) => {
          const result = response_data.data;
          if (result.hasError) {
            toast.update(id, {
              render: "Failed",
              type: "error",
              isLoading: false,
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          } else {
            let successMessage = "";
            if (moduleInterface.interfaceId > 0) {
              successMessage = "Interface Successfully Updated.";
            } else {
              successMessage = "Interface Successfully Added.";
            }
            toast.update(id, {
              render: successMessage,
              type: "success",
              isLoading: false,
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            reload_table(true);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      errors.showMessages();
    }
  };
  const [moduleList, setModuleList] = useState([]);
  const [moduleCategoryList, setModuleCategoryList] = useState([]);

  useEffect(() => {
    if (form_data !== undefined) {
      setModuleList([
        { moduleId: 0, name: "- Select -" },
        ...form_data.moduleList,
      ]);
      setModuleCategoryList([
        { moduleCategoryId: 0, name: "- Select -" },
        ...form_data.categoryList,
      ]);
    }
    if (model_data !== "") {
      setValue("fm_1_name", model_data?.name);
      setValue("fm_1_search_key", model_data?.searchKey);
      setValue("fm_1_description", model_data?.description);
      setValue("fm_1_remark", model_data?.remark);
      setValue("fm_1_sa_module_id", model_data?.module?.moduleId);
      setValue(
        "fm_1_sa_module_category_id",
        model_data?.moduleCategory?.moduleCategoryId
      );
    } else {
      setValue("fm_1_name", "");
      setValue("fm_1_search_key", "");
      setValue("fm_1_description", "");
      setValue("fm_1_remark", "");
      setValue("fm_1_sa_module_id", 0);
      setValue("fm_1_sa_module_category_id", 0);
    }
  }, [model_data]);

  return (
    <CommonModal
      isOpen={is_open}
      toggler={toggle}
      bodyClass="grid-showcase"
      size="xl"
    >
      <Container fluid={true}>
        <Row>
          <Col sm="12" className="p-b-15">
            <Form onSubmit={handleSubmit(onSubmit)} className="form-1">
              <H5 attrH5={{ className: "m-b-30" }}>
                {model_data === "" ? "Add " : "Update "} Interface
              </H5>
              <Row>
                <Col lg="2">
                  <Label
                    htmlFor="id_input_fm_1_search_key"
                    className="required-input-mark"
                  >
                    Search Key
                  </Label>
                </Col>
                <Col lg="4" className="m-b-10">
                  <input
                    id="id_input_fm_1_search_key"
                    name="fm_1_search_key"
                    type="text"
                    className="form-control"
                    placeholder="Enter Search Key"
                    {...register("fm_1_search_key", {
                      required: "Please enter Search Key",
                      maxLength: {
                        value: 250,
                        message: "Please enter less than characters 250.",
                      },
                    })}
                  />
                  <span className="txt-danger f-12">
                    {errors?.fm_1_search_key &&
                      errors?.fm_1_search_key?.message}
                  </span>
                </Col>
                <Col lg="2">
                  <Label
                    htmlFor="id_input_fm_1_name"
                    className="required-input-mark"
                  >
                    Interface
                  </Label>
                </Col>
                <Col lg="4" className="m-b-10">
                  <input
                    id="id_input_fm_1_name"
                    name="fm_1_name"
                    type="text"
                    className="form-control"
                    placeholder="Enter Interface Name"
                    {...register("fm_1_name", {
                      required: "Please enter Interface Name",
                      maxLength: {
                        value: 250,
                        message: "Please enter less than characters 250.",
                      },
                    })}
                  />
                  <span className="txt-danger f-12">
                    {errors?.fm_1_name && errors?.fm_1_name?.message}
                  </span>
                </Col>
              </Row>
              <Row>
                <Col lg="2">
                  <Label
                    htmlFor="id_input_fm_1_sa_module_id"
                    className="required-input-mark"
                  >
                    Module
                  </Label>
                </Col>
                <Col lg="4" className="m-b-10">
                  <select
                    id="id_input_fm_1_sa_module_id"
                    name="fm_1_sa_module_id"
                    className="form-select"
                    {...register("fm_1_sa_module_id", {
                      required: "Please select Module",
                    })}
                  >
                    {moduleList.length
                      ? moduleList.map((list_item, index) => (
                          <option value={list_item?.moduleId} key={index}>
                            {list_item?.name}
                          </option>
                        ))
                      : null}
                  </select>
                  <span className="txt-danger f-12">
                    {errors?.fm_1_sa_module_id &&
                      errors?.fm_1_sa_module_id?.message}
                  </span>
                </Col>
                <Col lg="2">
                  <Label
                    htmlFor="id_input_fm_1_sa_module_category_id"
                    className="required-input-mark"
                  >
                    Category
                  </Label>
                </Col>
                <Col lg="4" className="m-b-10">
                  <select
                    id="id_input_fm_1_sa_module_category_id"
                    name="fm_1_sa_module_category_id"
                    className="form-select"
                    {...register("fm_1_sa_module_category_id", {
                      required: "Please select Module Category",
                    })}
                  >
                    {moduleCategoryList.length
                      ? moduleCategoryList.map((list_item, index) => (
                          <option
                            value={list_item?.moduleCategoryId}
                            key={index}
                          >
                            {list_item?.name}
                          </option>
                        ))
                      : null}
                  </select>
                  <span className="txt-danger f-12">
                    {errors?.fm_1_sa_module_id &&
                      errors?.fm_1_sa_module_id?.message}
                  </span>
                </Col>
              </Row>
              <Row>
                <Col lg="2">
                  <Label htmlFor="id_input_fm_1_description">Description</Label>
                </Col>
                <Col lg="10" className="m-b-10">
                  <textarea
                    id="id_input_fm_1_description"
                    name="fm_1_description"
                    rows="2"
                    className="form-control"
                    placeholder="Enter Description"
                    {...register("fm_1_description", {
                      value: model_data !== "" ? model_data.description : "",
                      maxLength: {
                        value: 100,
                        message: "Maximum allowed character length is 100.",
                      },
                    })}
                  ></textarea>
                  <span className="txt-danger f-12">
                    {errors?.fm_1_description &&
                      errors?.fm_1_description?.message}
                  </span>
                </Col>
              </Row>
              <Row>
                <Col lg="2">
                  <Label htmlFor="id_input_fm_1_remark">Remark</Label>
                </Col>
                <Col lg="10" className="m-b-10">
                  <textarea
                    id="id_input_fm_1_remark"
                    name="fm_1_remark"
                    rows="3"
                    className="form-control"
                    placeholder="Enter Remarks"
                    {...register("fm_1_remark", {
                      value: model_data !== "" ? model_data.remark : "",
                      maxLength: {
                        value: 250,
                        message: "Maximum allowed character length is 250.",
                      },
                    })}
                  ></textarea>
                  <span className="txt-danger f-12">
                    {errors?.fm_1_remark &&
                      errors?.fm_1_remark?.message}
                  </span>
                </Col>
              </Row>
              <Row>
                <Col lg="6" className="offset-lg-2 m-b-10">
                  <Label className="d-block" htmlFor="input_fm_1_active">
                    <Input
                      className="checkbox_animated"
                      id="input_fm_1_active"
                      name="fm_1_active"
                      type="checkbox"
                      defaultChecked={
                        model_data !== "" ? model_data.isActive : true
                      }
                      {...register("fm_1_active")}
                    />
                    Active
                  </Label>
                </Col>
              </Row>
              <div className="d-flex justify-content-end">
                <Btn
                  attrBtn={{
                    color: "primary",
                    size: "sm",
                    disabled: false,
                    outline: true,
                    type: "submit",
                    className: "m-r-10 d-flex align-items-center",
                  }}
                >
                  {model_data === "" ? "Submit" : "Update"}
                </Btn>
                {/* {model_data !== "" ? (
                  <Btn
                    attrBtn={{
                      color: "danger",
                      size: "sm",
                      disabled: false,
                      outline: true,
                      className: "d-flex align-items-center",
                    }}
                  >
                    Delete
                  </Btn>
                ) : null} */}
              </div>
            </Form>
          </Col>
        </Row>
      </Container>
    </CommonModal>
  );
};

export default SaveInterfaceModel;
