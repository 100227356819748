import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Input, Label, Row } from "reactstrap";
import CommonModal from "../../../../Common/Component/CommonModal";
import { useForm } from "react-hook-form";
import DatePicker from "react-datepicker";

const AssetViewManageDepreciation = ({
  is_open,
  trigger_model,
  model_data,
}) => {
  const toggle = () => trigger_model(!is_open);
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    // data.prevetDefault();
    console.log(data);
    if (data !== "") {
      alert("You submitted the form and stuff!");
    } else {
      errors.showMessages();
    }
  };
  const [startDate, setstartDate] = useState(new Date());

  const handleChange = (date) => {
    setstartDate(date);
  };

  // useEffect(() => {}, [setError]);

  return (
    <CommonModal
      isOpen={is_open}
      toggler={toggle}
      bodyClass="grid-showcase"
      size="lg"
    >
      <Container fluid={true}>
        <Row>
          <Col sm="12" className="p-b-15">
            <Form
              className="needs-validation"
              noValidate=""
              onSubmit={handleSubmit(onSubmit)}
            >
              <Row className="justify-content-center">
                <Col lg="4">
                  <Label htmlFor="input_fm_1_depreciable_asset">
                    {"Depreciable Asset"}
                  </Label>
                </Col>
                <Col lg="6" className="d-flex m-b-10">
                  <Col xs="6" sm="6">
                    <Label
                      className="d-flex align-items-center mb-0"
                      htmlFor="input_fm_1_depreciable_asset_yes"
                    >
                      <input
                        className="radio_animated"
                        id="input_fm_1_depreciable_asset_yes"
                        type="radio"
                        name="fm_1_depreciable_asset"
                        value={1}
                        {...register("depreciableAsset")}
                        />
                      Yes
                    </Label>
                  </Col>
                  <Col xs="6" sm="6">
                    <Label
                      className="d-flex align-items-center mb-0"
                      htmlFor="input_fm_1_depreciable_asset_yes"
                      >
                      <input
                        className="radio_animated m-l-5"
                        id="input_fm_1_depreciable_asset_no"
                        type="radio"
                        name="fm_1_depreciable_asset"
                        value={0}
                        {...register("depreciableAsset")}
                      />
                      No
                    </Label>
                  </Col>
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col lg="4">
                  <Label htmlFor="input_fm_1_depreciable_cost">
                    {"Depreciable Cost"}
                  </Label>
                </Col>
                <Col lg="6" className="m-b-10">
                  <div className="input-group mb-0">
                    <span className="input-group-text" id="basic-addon1">
                      $
                    </span>
                    <input
                      id="input_fm_1_depreciable_cost"
                      name="fm_1_depreciable_cost"
                      type="text"
                      className="form-control"
                      placeholder="00.00"
                      aria-label="Salvage Value"
                      aria-describedby="basic-addon1"
                      {...register("depreciableCost", {
                        pattern: /^\d+(\.\d+)?$/,
                      })}
                    />
                  </div>
                  <span className="txt-danger f-12">
                    {errors.depreciableCost && (
                      <span>Please Enter an Integer or Decimal Value</span>
                    )}
                  </span>
                  <div className="valid-feedback">{"Looks good!"}</div>
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col lg="4">
                  <Label htmlFor="input_fm_1_salvage_value">
                    {"Salvage Value"}
                  </Label>
                </Col>
                <Col lg="6" className="m-b-10">
                  <div className="input-group mb-0">
                    <span className="input-group-text" id="basic-addon1">
                      $
                    </span>
                    <input
                      id="input_fm_1_salvage_value"
                      name="fm_1_salvage_value"
                      type="text"
                      className="form-control"
                      placeholder="00.00"
                      aria-label="Salvage Value"
                      aria-describedby="basic-addon1"
                      {...register("salvageValue", {
                        pattern: /^\d+(\.\d+)?$/,
                      })}
                      // onKeyDown={() => {
                      //   setError("salvageValue", {
                      //     types: {
                      //       pattern: "Please Enter an Integer or Decimal Value",
                      //     },
                      //   });
                      // }}
                    />
                  </div>
                  <span className="txt-danger f-12">
                    {errors.salvageValue && (
                      <span>Please Enter an Integer or Decimal Value</span>
                    )}
                    {/* {errors.salvageValue && errors.salvageValue.types.pattern} */}
                  </span>
                  <div className="valid-feedback">{"Looks good!"}</div>
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col lg="4">
                  <Label htmlFor="input_fm_1_asset_list">{"Asset Life"}</Label>
                </Col>
                <Col lg="6" className="m-b-10">
                  <input
                    id="input_fm_1_asset_list"
                    className="form-control"
                    name="fm_1_asset_list"
                    type="text"
                    placeholder="Asset Life"
                    {...register("assetLife")}
                  />
                  <div className="valid-feedback">{"Looks good!"}</div>
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col lg="4">
                  <Label htmlFor="input_fm_1_depreciation_method">
                    {"Depreciation Method"}
                  </Label>
                </Col>
                <Col lg="6" className="m-b-10">
                  <select
                    id="input_fm_1_depreciation_method"
                    name="fm_1_depreciation_method"
                    className="form-select"
                    {...register("depreciationMethod")}
                  ></select>
                  <div className="valid-feedback">{"Looks good!"}</div>
                </Col>
              </Row>
              <Row className="justify-content-center m-b-30">
                <Col lg="4">
                  <Label htmlFor="input_fm_1_date_acquired">
                    {"Date Acquired"}
                  </Label>
                </Col>
                <Col lg="6">
                  <DatePicker
                    id="input_fm_1_date_acquired"
                    name="fm_1_date_acquired"
                    className="form-control digits"
                    selected={startDate}
                    onChange={handleChange}
                  />
                </Col>
              </Row>
              <div className="d-flex justify-content-end">
                <Button type="submit" color="primary">
                  Submit
                </Button>
              </div>
            </Form>
          </Col>
        </Row>
      </Container>
    </CommonModal>
  );
};

export default AssetViewManageDepreciation;
