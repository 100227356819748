import React, { Fragment, useEffect, useRef, useState } from "react";
import { Container, Row, Col, Card, CardBody, Media } from "reactstrap";
import { Breadcrumbs, Btn, Spinner } from "../../../AbstractElements";
import HeaderCard from "../../Common/Component/HeaderCard";
import ReactTable from "react-data-table-component";
import * as feather from "feather-icons";
import { toast } from "react-toastify";
import axios from "axios";
import SaveEmployeeLocationModel from "./Models/SaveEmployeeLocationModel";
import { adminBackendURL } from "../../../Config/Config";
import UserContext from "../../../_helper/User";
import { useContext } from "react";

const EmployeeLocation = () => {
  const userContext = useContext(UserContext)
  const loggedUser = userContext.userData;

  /**Datatable Functions */
  const statusSort = (rowA, rowB) => {
    const a = rowA.status.props.children;
    const b = rowB.status.props.children;

    if (a > b) {
      return 1;
    }

    if (b > a) {
      return -1;
    }

    return 0;
  };
  /**Datatable */
  const tableColumns = useRef([
    {
      name: "Employee",
      selector: (row) => `${row.employee}`,
      sortable: true,
      center: false,
    },
    {
      name: "Location",
      selector: (row) => `${row.location}`,
      sortable: true,
      center: false,
    },
  ]);
  const [tableData, setTableData] = useState();
  const [fetchingTableData, setFetchingTableData] = useState(true);
  const assetData = useRef([
    {
      building: "Wing A",
      site: "Headquarters",
      status: <span className="badge badge-light-info">Available</span>,
      action: (
        <Media>
          <Btn
            attrBtn={{
              color: "info",
              size: "sm",
              disabled: false,
              outline: true,
              className: "m-r-10",
              onClick: () => handleEditBtnClick(assetData[0]),
            }}
          >
            Edit
          </Btn>
        </Media>
      ),
    },
    {
      building: "Wing B",
      site: "Headquarters",
      status: <span className="badge badge-light-danger">Not Available</span>,
      action: (
        <Media>
          <Btn
            attrBtn={{
              color: "info",
              size: "sm",
              disabled: false,
              outline: true,
              className: "m-r-10",
              onClick: () => handleEditBtnClick(assetData[0]),
            }}
          >
            Edit
          </Btn>
        </Media>
      ),
    },
    {
      building: "Annex Building",
      site: "Headquarters",
      status: <span className="badge badge-light-info">Available</span>,
      action: (
        <Media>
          <Btn
            attrBtn={{
              color: "info",
              size: "sm",
              disabled: false,
              outline: true,
              className: "m-r-10",
              onClick: () => handleEditBtnClick(assetData[0]),
            }}
          >
            Edit
          </Btn>
        </Media>
      ),
    },
  ]);
  // Load Table Data
  useEffect(() => {
    if (fetchingTableData) {
      const reqBody = {
        clientId: loggedUser.clientId,
        orgId: loggedUser.orgId,
        userId: loggedUser.userId,
        moduleId: loggedUser.loggedModuleId,
      };
      const reqInstance = axios.create({
        baseURL: adminBackendURL,
      });
      reqInstance
        .post("/viewBpartnerLocation", reqBody)
        .then((response_data) => {
          if (response_data) {
            const result = response_data.data;
            if (!result.hasError) {
              let roleData = [];
              setModelFormData({
                clientList: result.activeClientList,
                orgList: result.activeOrgList,
                employeeList: result.activeEmployeeList,
                locationList: result.activeLocationList,
              });
              result.allBpartnerLocation.forEach((bl_item) => {
                roleData.push({
                  employee: result.activeEmployeeList.find(
                    (item) => item.bpartnerId === bl_item.bpartnerId
                  ).name,
                  location: result.activeLocationList.find(
                    (item) => item.locationId === bl_item.locationId
                  ).name,
                });
              });
              setTableData(roleData);
              setFetchingTableData(false);
            } else {
              setTableData([]);
              setFetchingTableData(false);
              toast.error("Request Failed", {
                isLoading: false,
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [fetchingTableData]);
  /**Model */
  const [triggerSaveModel, setTriggerSaveModel] = useState(false);
  const [modelFormData, setModelFormData] = useState();
  const [modelData, setModelData] = useState([]);
  /**Common */
  const handleEditBtnClick = (row_data) => {
    setTriggerSaveModel(true);
    setModelData(row_data);
  };
  const comingSoonNotification = () => {
    toast.warning("This feature is not yet available.", {
      isLoading: false,
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  return (
    <Fragment>
      <Breadcrumbs
        mainTitle="Settings"
        parent="Settings"
        title="Employee location"
      />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <HeaderCard
                title="Employee Location"
                span1="User can set multiple locations for each Employee. There can be locations like Mini Bar, Store, Main Kitchen...etc. So Employees can set to one or more locations."
              />
              <CardBody>
                <div className="d-flex justify-content-end m-b-15">
                  <Btn
                    attrBtn={{
                      color: "primary",
                      size: "sm",
                      disabled: false,
                      outline: false,
                      className: "m-r-10 d-flex align-items-center",
                      onClick: () => handleEditBtnClick(""),
                    }}
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html: feather.icons["plus-circle"].toSvg({
                          height: "12px",
                          width: "12px",
                        }),
                      }}
                      className="p-r-5 d-flex align-items-center"
                    />
                    New Employee Location
                  </Btn>
                  <Btn
                    attrBtn={{
                      color: "primary",
                      size: "sm",
                      disabled: false,
                      outline: true,
                      className: "d-flex align-items-center",
                      onClick: () => comingSoonNotification(),
                    }}
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html: feather.icons["upload-cloud"].toSvg({
                          height: "12px",
                          width: "12px",
                        }),
                      }}
                      className="p-r-5 d-flex align-items-center"
                    />
                    Import Employee Locations
                  </Btn>
                </div>
                {/* <Row className="m-b-5">
                  <Col md="4 p-l-10">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Search..."
                      onKeyDown={(evt) => handleBasicSearch(evt)}
                    />
                  </Col>
                </Row> */}
                <ReactTable
                  columns={tableColumns.current}
                  data={tableData}
                  progressComponent={
                    <div className="">
                      <div className="loader-box">
                        <Spinner attrSpinner={{ className: "loader-26" }} />
                      </div>
                      <small>Fetching...</small>
                    </div>
                  }
                  progressPending={fetchingTableData}
                  pointerOnHover
                  selectableRows
                  pagination
                />
                <SaveEmployeeLocationModel
                  is_open={triggerSaveModel}
                  trigger_model={setTriggerSaveModel}
                  model_data={modelData}
                  form_data={modelFormData}
                  reload_table={setFetchingTableData}
                />{" "}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default EmployeeLocation;
