import React, { useState } from "react";
import { Button, Col, Container, Row } from "reactstrap";
import CommonModal from "../../../../Common/Component/CommonModal";
import ImageUploader from "../../../../Common/Component/ImageUploader";
import { backendURL, imageFileURL } from "../../../../../Config/Config";
import axios from "axios";
import { toast } from "react-toastify";
import { useContext } from "react";
import AssetItemContext from "../../../../../_helper/AssetItem";
import UserContext from "../../../../../_helper/User";

const AssetViewAddImage = ({ is_open, trigger_model, refresh_item }) => {
  const userContext = useContext(UserContext);
  const loggedUser = userContext.userData;
  //
  const [previewFileList, setPreviewFileList] = useState([]);
  const [attachmentList, setAttachmentList] = useState([]);

  const assetContext = useContext(AssetItemContext);
  const assetItemData = assetContext.assetItem;

  const toggle = () => trigger_model(!is_open);

  const reqInstance = axios.create({
    baseURL: backendURL,
  });

  const handleImageUpload = () => {
    const reqInstance = axios.create({
      baseURL: backendURL,
    });
    console.log("Image List Size =>", attachmentList.length);
    if (attachmentList.length > 0) {
      const statusToast = toast.loading(
        `Uploading ${attachmentList.length} Attachment(s).`
      );
      attachmentList.forEach((attachment_item) => {
        if (attachment_item.isModified) {
          let imageData = new FormData();
          imageData.append(
            "assetImageId",
            parseInt(attachment_item.documentId)
          );
          imageData.append("clientId", loggedUser.clientId);
          imageData.append("orgId", loggedUser.orgId);
          imageData.append("userId", loggedUser.userId);
          imageData.append(
            "assetItemId",
            assetItemData.assetItemId
              ? assetItemData.assetItemId
              : assetItemData.assetItemId
          );
          imageData.append("isActive", "true");
          imageData.append("isAttachmentModified", attachment_item.isModified);
          imageData.append(
            "attachmentExtension",
            attachment_item.attachmentExtension
          );
          imageData.append("attachment", attachment_item.attachment);
          imageData.append("name", "");
          imageData.append("remark", "");
          reqInstance
            .post("/saveAssetImage", imageData, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
            .then((image_response_data) => {
              const uploadResult = image_response_data.data;
              //
              if (!uploadResult.hasError) {
                //==
                refresh_item.refreshAssetItem();
                toast.update(statusToast, {
                  render: "Attachments Uploaded.",
                  type: "success",
                  isLoading: false,
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                });
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
    }
  };

  return (
    <CommonModal
      isOpen={is_open}
      toggler={toggle}
      bodyClass="grid-showcase"
      size="lg"
    >
      <Container fluid={true}>
        <Row>
          <Col sm="12" className="p-b-15">
            {/* Start of Image Details */}
            <ImageUploader
              attachment_list={attachmentList}
              preview_list={previewFileList}
              set_attachment_list={setAttachmentList}
              set_preview_list={setPreviewFileList}
              max_file_limit={1}
              max_file_size={5242880}

              // onFileSelect={handleImageSelection}
            />
            {/* End of Image Details */}
            <div className="d-flex justify-content-end">
              <Button
                type="button"
                color="primary"
                onClick={() => handleImageUpload()}
              >
                {"Upload"}
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    </CommonModal>
  );
};

export default AssetViewAddImage;
