import React, { Fragment, useEffect, useRef, useState } from "react";
import { Row, Col } from "reactstrap";
import { Spinner, Btn } from "../../../../../AbstractElements";
import LightCardBox from "../../../../Common/Component/LightCardBox";
import ReactTable from "react-data-table-component";
import AssetViewManageDepreciation from "./AssetViewManageDepreciation";

const AssetViewDepreciation = () => {
  const INPROGRESS = 10;
  const COMPLETED = 11;
  const LightCardData2 = [
    // {
    //   title: "Date Acquired",
    //   icon: <i className="icon-calendar"></i>,
    //   price: "2nd August, 2023",
    //   color: "success",
    // },
    // {
    //   title: "Depreciation Method",
    //   icon: <i className="icon-stats-down"></i>,
    //   price: "Straight Line",
    //   color: "danger",
    // },
    // {
    //   title: "Depreciable Cost",
    //   icon: "expense",
    //   price: "$20.00",
    // },
    // {
    //   title: "Salvage Value",
    //   icon: "doller-return",
    //   price: "$2.00",
    // },
    // {
    //   title: "Asset Life (months)",
    //   icon: <i className="icon-heart-broken"></i>,
    //   price: "6 Months",
    //   color: "danger",
    // },
  ];
  const tableColumns = useRef([
    {
      name: "Year",
      selector: (row) => `${row.year}`,
      sortable: true,
      center: false,
    },
    {
      name: "Depreciation Expense",
      selector: (row) => row["depreciationExpense"],
      sortable: true,
      center: true,
    },
    {
      name: "Accumulated depreciation at year-end",
      selector: (row) => row["depreciationAtYearEnd"],
      center: true,
      sortable: true,
    },
    {
      name: "Book value at year-end",
      selector: (row) => row["bookValueAtYearEnd"],
      sortable: true,
      center: true,
    },
  ]);
  const [tableData, setTableData] = useState();
  const [modelData, setModelData] = useState([]);
  const [triggerModel, setTriggerModel] = useState(false);
  const [searchingProgress, setSearchingProgress] = useState(INPROGRESS);

  /**Page Onload Section */
  useEffect(() => {
    setTableData(assetData.current);
    setSearchingProgress(COMPLETED);
  }, []);
  const assetData = useRef([
    // {
    //   id: 1,
    //   year: 2023,
    //   date: "2023/04/27",
    //   assetId: "#PX0101",
    //   designation: "System Architect",
    //   depreciationExpense: (
    //     <span>
    //       <i className="font-info icofont icofont-cur-dollar"></i> 3.60
    //     </span>
    //   ),
    //   depreciationAtYearEnd: (
    //     <span>
    //       <i className="font-info icofont icofont-cur-dollar"></i> 3.60
    //     </span>
    //   ),
    //   bookValueAtYearEnd: (
    //     <span>
    //       <i className="font-info icofont icofont-cur-dollar"></i> 16.40
    //     </span>
    //   ),
    //   status: <span className="badge badge-light-info">Available</span>,
    // },
    // {
    //   id: 2,
    //   year: 2024,
    //   date: "2023/04/22",
    //   assetId: "#RF304f",
    //   designation: "Maintenace service",
    //   depreciationExpense: (
    //     <span>
    //       <i className="font-info icofont icofont-cur-dollar"></i> 3.60
    //     </span>
    //   ),
    //   depreciationAtYearEnd: (
    //     <span>
    //       <i className="font-info icofont icofont-cur-dollar"></i> 7.20
    //     </span>
    //   ),
    //   bookValueAtYearEnd: (
    //     <span>
    //       <i className="font-info icofont icofont-cur-dollar"></i> 12.80
    //     </span>
    //   ),
    //   status: <span className="badge badge-light-danger">Not Available</span>,
    // },
    // {
    //   id: 3,
    //   year: 2025,
    //   date: "2023/05/21",
    //   assetId: "#DNJ480",
    //   designation: "Junior Technical Author	",
    //   depreciationExpense: (
    //     <span>
    //       <i className="font-info icofont icofont-cur-dollar"></i> 3.60
    //     </span>
    //   ),
    //   depreciationAtYearEnd: (
    //     <span>
    //       <i className="font-info icofont icofont-cur-dollar"></i> 10.80
    //     </span>
    //   ),
    //   bookValueAtYearEnd: (
    //     <span>
    //       <i className="font-info icofont icofont-cur-dollar"></i> 9.20
    //     </span>
    //   ),
    //   status: <span className="badge badge-light-info">Available</span>,
    // },
    // {
    //   id: 4,
    //   year: 2026,
    //   date: "2023/03/09",
    //   assetId: "#G189d0",
    //   designation: "Senior Javascript Developer",
    //   depreciationExpense: (
    //     <span>
    //       <i className="font-info icofont icofont-cur-dollar"></i> 3.60
    //     </span>
    //   ),
    //   depreciationAtYearEnd: (
    //     <span>
    //       <i className="font-info icofont icofont-cur-dollar"></i> 14.40
    //     </span>
    //   ),
    //   bookValueAtYearEnd: (
    //     <span>
    //       <i className="font-info icofont icofont-cur-dollar"></i> 5.60
    //     </span>
    //   ),
    //   status: <span className="badge badge-light-info">Available</span>,
    // },
    // {
    //   id: 5,
    //   year: 2027,
    //   date: "2023/03/09",
    //   assetId: "#G189d0",
    //   designation: "Senior Javascript Developer",
    //   depreciationExpense: (
    //     <span>
    //       <i className="font-info icofont icofont-cur-dollar"></i> 3.60
    //     </span>
    //   ),
    //   depreciationAtYearEnd: (
    //     <span>
    //       <i className="font-info icofont icofont-cur-dollar"></i> 18.80
    //     </span>
    //   ),
    //   bookValueAtYearEnd: (
    //     <span>
    //       <i className="font-info icofont icofont-cur-dollar"></i> 2.00
    //     </span>
    //   ),
    //   status: <span className="badge badge-light-info">Available</span>,
    // },
  ]);

  return (
    <Fragment>
      <div className="d-flex justify-content-end">
        <Btn
          attrBtn={{
            color: "info",
            size: "sm",
            disabled: false,
            outline: true,
            className: "m-b-15",
            onClick: () => setTriggerModel(true),
          }}
        >
          Manage
        </Btn>
      </div>
      {/* <Row className="g-sm-3 g-2 justify-content-md-center m-b-10">
        <Col md="4">
          <LightCardBox data={LightCardData2[0]} />
        </Col>
        <Col md="4">
          <LightCardBox data={LightCardData2[1]} />
        </Col>
      </Row>
      <Row className="g-sm-3 g-2 m-b-20">
        <Col md="4">
          <LightCardBox data={LightCardData2[2]} />
        </Col>
        <Col md="4">
          <LightCardBox data={LightCardData2[3]} />
        </Col>
        <Col md="4">
          <LightCardBox data={LightCardData2[4]} />
        </Col>
      </Row> */}
      <ReactTable
        columns={tableColumns.current}
        data={tableData}
        progressPending={searchingProgress === INPROGRESS}
        progressComponent={
          <div className="">
            <div className="loader-box">
              <Spinner attrSpinner={{ className: "loader-26" }} />
            </div>
            <small>Searching...</small>
          </div>
        }
        pointerOnHover
        pagination
      />
      <AssetViewManageDepreciation
        is_open={triggerModel}
        trigger_model={setTriggerModel}
        model_data={modelData}
      />
    </Fragment>
  );
};

export default AssetViewDepreciation;
