export const CurrencyFormatter = (value) => {
  /**
   * Format a string in currency format
   * eg:- 390700800000 => 390,700,800,000.00
   *
   * @params {string} value - currency value
   * @return {string} returns currency value in 000,000.00 format
   */
  if (!value) {
    return "Not Specified";
  } else {
    let splittedValue = value.toString().split(".");
    let wholeValue = splittedValue[0].toString();
    let centValue = splittedValue[1];
    let formattedValue = [];
    console.log(splittedValue);
    console.log(centValue);
    //
    let noOfSplitters = Math.floor(wholeValue.length / 3);
    console.log(noOfSplitters);
    let startPoint = wholeValue.length % 3;
    console.log(startPoint);
    if (noOfSplitters > 0) {
      for (let index = 0; index < noOfSplitters; index++) {
        if (index === 0 && startPoint > 0) {
          formattedValue.push(wholeValue.slice(0, startPoint));
          formattedValue.push(",");
          let secondSlice = wholeValue.slice(startPoint, startPoint + 3)
          formattedValue.push(secondSlice);
          if (noOfSplitters > 1) formattedValue.push(",");
        } else {
          let sliceStart = index * 3 + startPoint;
          let sliceEnd = sliceStart + 3;
          formattedValue.push(wholeValue.slice(sliceStart, sliceEnd));
          if (index + 1 < noOfSplitters) formattedValue.push(",");
        }
      }
      console.log(formattedValue);
      formattedValue.push(splittedValue.length === 1 ? ".00" : `.${centValue}`);
      console.log(formattedValue);
    } else {
      formattedValue.push(wholeValue)
      formattedValue.push(splittedValue.length === 1 ? ".00" : `.${centValue}`)
    }
    return formattedValue.join("");
  }
};

export const ThousandSplitter = (value) => {
    /**
     * Format a string in 1000 places
     * eg:- 390700800000 => 390,700,800,000
     *
     * @params {string} value - currency value
     * @return {string} returns currency value in 000,000 format
     */
    console.log(value);
    if (!value) {
      return "Not Specified";
    } else {
      let splittedValue = value.toString().split(".");
      let wholeValue = splittedValue[0].toString();
      let formattedValue = [];
      console.log(splittedValue);
      //
      let noOfSplitters = Math.floor(wholeValue.length / 3);
      let startPoint = wholeValue.length % 3;
      if (noOfSplitters > 0) {
        for (let index = 0; index < noOfSplitters; index++) {
          if (index === 0 && startPoint > 0) {
            formattedValue.push(wholeValue.slice(0, startPoint));
            formattedValue.push(",");
            let secondSlice = wholeValue.slice(startPoint, startPoint + 3)
            formattedValue.push(secondSlice);
            if (noOfSplitters > 1) formattedValue.push(",");
          } else {
            let sliceStart = index * 3 + startPoint;
            let sliceEnd = sliceStart + 3;
            formattedValue.push(wholeValue.slice(sliceStart, sliceEnd));
            if (index + 1 < noOfSplitters) formattedValue.push(",");
          }
        }
      } else {

        formattedValue.push(wholeValue);
      }
      return formattedValue.join("");
    }
  };