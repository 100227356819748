import axios from "axios";
import React, { useEffect, useState } from "react";
import { backendURL } from "../../Config/Config";
import Context from "./index";

const AssetItemProvider = (props) => {
  const [assetItem, setAssetItem] = useState({ assetItemId: 0 });

  const addAssetItem = (asset_item) => {
    setAssetItem(asset_item);
  };

  const removeAssetItem = () => {
    setAssetItem({ assetItemId: 0 });
    localStorage.removeItem("currentAssetData");
  };

  const refreshAssetItem = () => {
    const reqBody = {
      assetItemId: assetItem.assetItemId,
      clientId: 38,
      orgId: 41,
      userId: 213,
      moduleId: 250,
    };
    const reqInstance = axios.create({
      baseURL: backendURL,
    });
    reqInstance
      .post("/getAssetItem", reqBody)
      .then((response_data) => {
        if (response_data) {
          const result = response_data.data;
          if (!result.hasError) {
            setAssetItem(result.assetItem);
          } else {
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Load data from localStorage when the component mounts
  useEffect(() => {
    const storedData = localStorage.getItem("currentAssetData");
    if (storedData) {
      console.log(JSON.parse(storedData));
      setAssetItem(JSON.parse(storedData));
    }
  }, []);

  // Save data to localStorage whenever it changes
  useEffect(() => {
    console.log(assetItem);
    localStorage.setItem("currentAssetData", JSON.stringify(assetItem));
  }, [assetItem]);

  return (
    <Context.Provider
      value={{
        ...props,
        assetItem,
        addAssetItem: addAssetItem,
        removeAssetItem: removeAssetItem,
        refreshAssetItem: refreshAssetItem,
      }}
    >
      {props.children}
    </Context.Provider>
  );
};

export default AssetItemProvider;
