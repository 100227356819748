import AssetList from "../Components/Pages/Asset/AssetList";
import AssetView from "../Components/Pages/Asset/AssetList/AssetView";
import Dispose from "../Components/Pages/Asset/Dispose";
import Maintenance from "../Components/Pages/Asset/Maintenance";
import NewAsset from "../Components/Pages/Asset/NewAsset";
import Reserve from "../Components/Pages/Asset/Reserve";
import Transfer from "../Components/Pages/Asset/Transfer";
import Help from "../Components/Pages/Help/Help";
import AssetCategory from "../Components/Pages/MasterFile/AssetCategory";
import Building from "../Components/Pages/MasterFile/Building";
import Category from "../Components/Pages/MasterFile/Category";
import Condition from "../Components/Pages/MasterFile/Condition";
import DisposalType from "../Components/Pages/MasterFile/DisposalType";
import Floor from "../Components/Pages/MasterFile/Floor";
import Location from "../Components/Pages/MasterFile/Location";
import Site from "../Components/Pages/MasterFile/Site";
import Verification from "../Components/Pages/MasterFile/Verification";
import SamplePage from "../Components/Pages/PageLayout/SimplePage";
import AssetItems from "../Components/Pages/Reports/AssetItems";
import AssetVerification from "../Components/Pages/Reports/AssetVerification";
import Department from "../Components/Pages/Settings/Department";
import Designation from "../Components/Pages/Settings/Designation";
import EmployeeLocation from "../Components/Pages/Settings/EmployeeLocation";
import Function from "../Components/Pages/Settings/Function";
import Interface from "../Components/Pages/Settings/Interface";
import Module from "../Components/Pages/Settings/Module";
import Role from "../Components/Pages/Settings/Role";
import RoleLine from "../Components/Pages/Settings/RoleLine";
import DocumentGalery from "../Components/Pages/Tools/DocumentGalery";
import Export from "../Components/Pages/Tools/Export";
import Employee from "../Components/Pages/UserManagement/Employee";
import User from "../Components/Pages/UserManagement/User";

export const routes = [
  //asset
  {
    path: `/pages/dashboard`,
    Component: <SamplePage />,
  },
  {
    path: `/asset/list-of-assets`,
    Component: <AssetList />,
  },
  {
    path: `/asset/asset-list/asset-view`,
    Component: <AssetView />,
  },
  {
    path: `/asset/add-asset`,
    Component: <NewAsset />,
  },
  {
    path: `/asset/edit-asset`,
    Component: <NewAsset />,
  },
  {
    path: `/asset/list-of-disposed-assets`,
    Component: <Dispose />,
  },
  {
    path: `/transactionfiles/disposed-assets`,
    Component: <Dispose />,
  },
  {
    path: `/asset/maintenance`,
    Component: <Maintenance />,
  },
  {
    path: `/asset/list-of-transferred-asset`,
    Component: <Transfer />,
  },
  {
    path: `/transactionfiles/transferred-asset`,
    Component: <Transfer />,
  },
  {
    path: `/asset/list-of-warranties`,
    Component: <Reserve />,
  },
  // tools
  {
    path: `/tools/export-data`,
    Component: <Export />,
  },
  {
    path: `/tools/documentGalery`,
    Component: <DocumentGalery />,
  },
  // master files
  {
    path: `/masterfiles/location`,
    Component: <Location />,
  },
  {
    path: `/masterfiles/verification`,
    Component: <Verification />,
  },
  {
    path: `/transactionfiles/verification`,
    Component: <Verification />,
  },
  {
    path: `/masterfiles/floor`,
    Component: <Floor />,
  },
  {
    path: `/masterfiles/site`,
    Component: <Site />,
  },
  {
    path: `/masterfiles/condition`,
    Component: <Condition />,
  },
  {
    path: `/masterfiles/asset-category`,
    Component: <AssetCategory />,
  },
  {
    path: `/masterfiles/common-category`,
    Component: <Category />,
  },
  {
    path: `/masterfiles/building`,
    Component: <Building />,
  },
  {
    path: `/masterfiles/disposal-type`,
    Component: <DisposalType />,
  },
  {
    path: `/List/maintenance`,
    Component: <Maintenance />,
  },
  // reports
  {
    path: `/reports/asset-items`,
    Component: <AssetItems />,
  },
  {
    path: `/reports/asset-verification`,
    Component: <AssetVerification />,
  },
  // user management
  {
    path: `/pages/employees`,
    Component: <Employee />,
  },
  {
    path: `/pages/users`,
    Component: <User />,
  },
  // settings
  {
    path: `/pages/roles`,
    Component: <Role />,
  },
  {
    path: `/pages/role-lines`,
    Component: <RoleLine />,
  },
  {
    path: `/pages/departments`,
    Component: <Department />,
  },
  {
    path: `/pages/designations`,
    Component: <Designation />,
  },
  {
    path: `/pages/functions`,
    Component: <Function />,
  },
  {
    path: `/pages/interfaces`,
    Component: <Interface />,
  },
  {
    path: `/pages/modules`,
    Component: <Module />,
  },
  {
    path: `/pages/employee-location`,
    Component: <EmployeeLocation />,
  },
  {
    path: `/help-support/user-manual`,
    Component: <Help />,
  },
];
