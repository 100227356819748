import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Container, Row, Col, Card, CardBody, Media } from "reactstrap";
import { Breadcrumbs, Btn, Spinner, P } from "../../../AbstractElements";
import HeaderCard from "../../Common/Component/HeaderCard";
import * as feather from "feather-icons";
import ReactTable from "react-data-table-component";
import { toast } from "react-toastify";
import axios from "axios";
import { backendURL } from "../../../Config/Config";
import SaveAssetCategoryModel from "./Models/SaveAssetCategoryModel";
import UserContext from "../../../_helper/User";

const AssetCategory = () => {
  const userContext = useContext(UserContext);
  const loggedUser = userContext.userData;
  /**Datatable */
  // Table Functions
  const handleEditBtnClick = (row_data) => {
    setModelData(row_data);
    setTriggerSaveModel(true);
  };
  const statusSort = (rowA, rowB) => {
    console.log(rowA);
    const a = rowA.status.props.children;
    const b = rowB.status.props.children;

    if (a > b) {
      return 1;
    }

    if (b > a) {
      return -1;
    }

    return 0;
  };
  const [tableColumns, setTableColumns] = useState([
    {
      name: "Name",
      selector: (row) => `${row.name}`,
      sortable: true,
      center: false,
      omit: false,
      download: true,
    },
    {
      name: "Code",
      selector: (row) => `${row.searchKey}`,
      sortable: true,
      center: false,
      omit: false,
      download: true,
    },
    {
      name: "Depreciation Method",
      selector: (row) =>
        `${
          row.assetDepreciation !== null
            ? row.assetDepreciation.name
            : "Not Selected"
        }`,
      sortable: true,
      center: false,
      omit: false,
      download: true,
    },
    {
      name: "AssetCategoryId",
      selector: (row) => `${row.assetCategoryId}`,
      sortable: true,
      center: false,
      omit: true,
      download: false,
    },
    {
      name: "ClientId",
      selector: (row) => `${row.clientId}`,
      sortable: true,
      center: false,
      omit: true,
      download: false,
    },
    {
      name: "OrgId",
      selector: (row) => `${row.orgId}`,
      sortable: true,
      center: false,
      omit: true,
      download: false,
    },
    {
      name: "Created",
      selector: (row) => `${row.created}`,
      sortable: true,
      center: false,
      omit: true,
      download: false,
    },
    {
      name: "CreatedBy",
      selector: (row) => `${row.createdBy}`,
      sortable: true,
      center: false,
      omit: true,
      download: false,
    },
    {
      name: "Updated",
      selector: (row) => `${row.updated}`,
      sortable: true,
      center: false,
      omit: true,
      download: false,
    },
    {
      name: "UpdatedBy",
      selector: (row) => `${row.updatedBy}`,
      sortable: true,
      center: false,
      omit: true,
      download: false,
    },
    {
      name: "Remark",
      selector: (row) => `${row.remark}`,
      sortable: true,
      center: false,
      omit: true,
      download: false,
    },
    {
      name: "Status",
      selector: (row) => row["status"],
      sortable: true,
      center: false,
      sortFunction: statusSort,
      omit: false,
      download: true,
    },
    {
      name: "Action",
      selector: (row) => row["action"],
      sortable: true,
      center: false,
      ignoreRowClick: true,
      omit: false,
      download: false,
    },
  ]);
  const [tableData, setTableData] = useState([]);
  const [fetchingTableData, setFetchingTableData] = useState(true);
  const assetData = useRef([]);
  const handleCheckboxChange = (columnName, event) => {
    console.log(event.target.checked);
    if (event.target.checked === true) {
      setTableColumns((prev) =>
        prev.map((item) => {
          // console.log(item);
          if (item.name === columnName) {
            item.omit = false;
          }
          return item;
        })
      );
    } else {
      setTableColumns((prev) =>
        prev.map((item) => {
          if (item.name === columnName) {
            item.omit = true;
          }
          return item;
        })
      );
    }
  };
  // Load Table Data
  useEffect(() => {
    if (fetchingTableData && Object.keys(loggedUser).length > 0) {
      const reqBody = {
        clientId: loggedUser.clientId,
        orgId: loggedUser.orgId,
        userId: loggedUser.userId,
        moduleId: loggedUser.loggedModuleId,
      };
      const reqInstance = axios.create({
        baseURL: backendURL,
      });
      reqInstance
        .post("/viewAssetCategory", reqBody)
        .then((response_data) => {
          if (response_data) {
            const result = response_data.data;
            if (!result.hasError) {
              let assetCategoryData = [];
              setModelFormData({
                clientList: result.activeClientList,
                orgList: result.activeOrgList,
                depreciationMethodList: result.activeAssetDepreciationTypeList,
              });
              result.allAssetCategoryList.forEach((asset_category_item) => {
                assetCategoryData.push({
                  ...asset_category_item,
                  status: asset_category_item?.isActive ? (
                    <span className="badge badge-light-info">Active</span>
                  ) : (
                    <span className="badge badge-light-danger">Not Active</span>
                  ),
                  action: (
                    <Media>
                      <Btn
                        attrBtn={{
                          color: "info",
                          size: "sm",
                          disabled: false,
                          outline: true,
                          className: "m-r-10",
                          onClick: () =>
                            handleEditBtnClick(asset_category_item),
                        }}
                      >
                        Edit
                      </Btn>
                    </Media>
                  ),
                });
              });
              setTableData(assetCategoryData);
              assetData.current = assetCategoryData;
              setFetchingTableData(false);
            } else {
              setTableData([]);
              setFetchingTableData(false);
              toast.error("Request Failed", {
                isLoading: false,
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [loggedUser, fetchingTableData]);
  /**Model */
  const [triggerSaveModel, setTriggerSaveModel] = useState(false);
  const [modelFormData, setModelFormData] = useState();
  const [modelData, setModelData] = useState([]);
  const [isListVisible, setIsListVisible] = useState(false);
  const [isFilterListVisible, setIsFilterListVisible] = useState(false);
  const [dpAssetCategoryName, setDpAssetCategoryName] = useState();
  const [dpAssetCategoryNameList, setDpAssetCategoryNameList] = useState([]);
  const [dpStatus, setDpStatus] = useState();
  const [dpStatusList, setdpStatusList] = useState([]);
  const [dpCode, setDpCode] = useState();
  const [dpCodeList, setDpCodeList] = useState([]);
  const [dpDepreciationMethod, setDpDepreciationMethod] = useState();
  const [dpDepreciationMethodList, setDpDepreciationMethodList] = useState([]);
  /**Page  */
  const comingSoonNotification = () => {
    toast.warning("This feature is not yet available.", {
      isLoading: false,
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };
  const handleBasicSearch = (evt) => {
    let inputValue = evt.target.value.toLocaleLowerCase();
    if (inputValue.length > 0) {
      let searchResults = assetData.current;
      searchResults = searchResults.filter((search_row) => {
        let possibleValues = Object.values(search_row).filter(
          (search_prop) =>
            typeof search_prop === "string" || typeof search_prop === "number"
        );
        // console.log(possibleValues);
        return (
          possibleValues.filter(
            (target_value) =>
              target_value
                .toString()
                .toLocaleLowerCase()
                .indexOf(inputValue) !== -1
          )?.length > 0
        );
      });
      setTableData(searchResults);
    } else {
      setTableData(assetData.current);
    }
  };
  const convertArrayOfObjectsToCSV = (header_list, table_data) => {
    let result;

    const columnHeaders = [];
    console.log(table_data);
    header_list.forEach((header_item) => {
      if (header_item.download) columnHeaders.push(header_item.name);
    });
    const columnDelimiter = ",";
    const lineDelimiter = "\n";
    const keys = columnHeaders;

    result = "";
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    table_data.forEach((item) => {
      let ctr = 0;

      //extracting required data
      let rowData = [
        item.name,
        item.searchKey,
        item.assetDepreciation !== null
          ? item.assetDepreciation.name
          : "Not Selected",
        item.status.props.children,
      ];

      rowData.forEach((row_item) => {
        if (ctr > 0) result += columnDelimiter;

        result += row_item;

        ctr++;
      });
      result += lineDelimiter;
    });

    return result;
  };
  const downloadCSV = (header_list, table_data) => {
    const link = document.createElement("a");
    let csv = convertArrayOfObjectsToCSV(header_list, table_data);
    if (csv == null) return;

    const currentTimestamp = new Date();
    const filename = `Asset Category ${currentTimestamp}.csv`;

    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }

    link.setAttribute("href", encodeURI(csv));
    link.setAttribute("download", filename);
    link.click();
  };

  const handleIconClick = () => {
    setIsListVisible(!isListVisible);
  };

  const handlefilterIconClick = () => {
    setIsFilterListVisible(!isFilterListVisible);
  };

  useEffect(() => {
    if (tableData.length > 0) {
      if (
        dpAssetCategoryNameList.length === 0 ||
        dpStatusList.length === 0 ||
        dpCodeList.length === 0 ||
        dpDepreciationMethodList === 0
      ) {
        const uniqueAssetCategoryNames = [
          ...new Set(tableData?.map((item) => item.name)),
        ];
        setDpAssetCategoryNameList(uniqueAssetCategoryNames);
        const uniqueStatus = [
          ...new Set(
            tableData.map((item) => (item.isActive ? "Active" : "Not Active"))
          ),
        ];
        setdpStatusList(uniqueStatus);
        const uniqueCodes = [
          ...new Set(tableData?.map((item) => item.searchKey)),
        ];
        setDpCodeList(uniqueCodes);
        const uniqueDepreciationMethods = [
          ...new Set(tableData?.map((item) => item.assetDepreciation.name)),
        ];
        setDpDepreciationMethodList(uniqueDepreciationMethods);
      }
    }
  }, [tableData]);

  const handleAssetCategoryNameSelectChange = (event) => {
    const selectedName = event.target.value;
    setDpAssetCategoryName(selectedName);
    if (selectedName === "All") {
      setTableData(assetData.current);
    } else {
      setTableData((prev) =>
        assetData.current.filter((item) => item.name === selectedName)
      );
    }
  };

  const handleStatusSelectChange = (event) => {
    const selectedStatus = event.target.value;
    setDpStatus(selectedStatus);
    console.log(event.target.value);

    if (selectedStatus === "All") {
      setTableData(assetData.current);
    } else {
      if (selectedStatus === "Active") {
        setTableData((prev) =>
          assetData.current.filter((item) => item.isActive === true)
        );
      } else {
        setTableData((prev) =>
          assetData.current.filter((item) => item.isActive === false)
        );
      }
    }
  };

  const handleCodeSelectChange = (event) => {
    const selectedCode = event.target.value;
    setDpCode(selectedCode);
    if (selectedCode === "All") {
      setTableData(assetData.current);
    } else {
      setTableData((prev) =>
        assetData.current.filter((item) => item.searchKey === selectedCode)
      );
    }
  };

  const handleDepreciationMethodSelectChange = (event) => {
    const selectedDepreciationMethod = event.target.value;
    setDpDepreciationMethod(selectedDepreciationMethod);
    if (selectedDepreciationMethod === "All") {
      setTableData(assetData.current);
    } else {
      setTableData((prev) =>
        assetData.current.filter(
          (item) => item.assetDepreciation.name === selectedDepreciationMethod
        )
      );
    }
  };

  return (
    <Fragment>
      <Breadcrumbs
        mainTitle="Asset Category"
        parent="Master Files"
        title="Asset Category"
      />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <HeaderCard
                title="List Of Asset Category"
                span1="Common Fixed Asset Categories have already been defined for you. Make them as general or specific as you desire. Fixed Asset Categories may include 'Land and Buildings','Machinery and Equipment','Furniture and Fixtures','Vehicles','Computer Systems and IT Assets',...etc. Adjust to your unique needs."
              />
              <CardBody>
                <div className="d-flex justify-content-end m-b-15">
                  <Btn
                    attrBtn={{
                      color: "primary",
                      size: "sm",
                      disabled: false,
                      outline: false,
                      className: "m-r-10 d-flex align-items-center",
                      onClick: () => handleEditBtnClick(""),
                    }}
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html: feather.icons["plus-circle"].toSvg({
                          height: "12px",
                          width: "12px",
                        }),
                      }}
                      className="p-r-5 d-flex align-items-center"
                    />
                    Add New Asset Category
                  </Btn>
                  <Btn
                    attrBtn={{
                      color: "primary",
                      size: "sm",
                      disabled: false,
                      outline: true,
                      className: "d-flex align-items-center",
                      onClick: () => comingSoonNotification(),
                    }}
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html: feather.icons["upload-cloud"].toSvg({
                          height: "12px",
                          width: "12px",
                        }),
                      }}
                      className="p-r-5 d-flex align-items-center"
                    />
                    Import Categories
                  </Btn>
                </div>
                <Row className="m-b-5 justify-content-between align-items-center">
                  <Col md="4 p-l-10">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Search..."
                      onKeyDown={(evt) => handleBasicSearch(evt)}
                    />
                  </Col>
                  <Col md="3" className="d-flex justify-content-end">
                    <div className="d-flex align-items-center">
                      {/* show hide columns */}
                      {/* <div
                        className="position-relative"
                        style={{
                          float: "right",
                          marginRight: 12,
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <div
                          dangerouslySetInnerHTML={{
                            __html: feather.icons["columns"].toSvg({
                              height: "16px",
                              width: "16px",
                            }),
                          }}
                          className="d-flex align-items-center"
                          onClick={handleIconClick}
                        />

                        {isListVisible && (
                          <Card
                            className="position-absolute m-t-20 top-0 end-0"
                            style={{ zIndex: 2 }}
                          >
                            <CardBody>
                              <h5 style={{ fontSize: 15 }}>Show Columns</h5>
                              {tableColumns
                                .slice()
                                .sort((a, b) => a.name.localeCompare(b.name))
                                .map((column_item, index) => (
                                  <div className="d-flex align-items-start m-b-10">
                                    <input
                                      className="checkbox_animated"
                                      id="input_fm_1_active"
                                      name="fm_1_active"
                                      type="checkbox"
                                      defaultChecked={
                                        column_item.omit === false
                                      }
                                      onChange={(event) =>
                                        handleCheckboxChange(
                                          column_item.name,
                                          event
                                        )
                                      }
                                    />
                                    <span key={index}>{column_item.name}</span>
                                  </div>
                                ))}
                            </CardBody>
                          </Card>
                        )}
                      </div> */}

                      {/* filter column data */}
                      {/* <div
                        className="position-relative"
                        style={{
                          position: "flex-end",
                          bottom: 0,
                          right: 0,
                          marginRight: 25,
                        }}
                      >
                        <div
                          style={{
                            float: "right",
                          }}
                          dangerouslySetInnerHTML={{
                            __html: feather.icons["filter"].toSvg({
                              height: "16px",
                              width: "16px",
                            }),
                          }}
                          className="d-flex align-items-center"
                          onClick={handlefilterIconClick}
                        />

                        {isFilterListVisible && (
                          <Card
                            className="position-absolute m-t-20 top-0 end-0"
                            style={{ zIndex: 2 }}
                          >
                            <CardBody>
                              <div value={"DisposalType"}>Name</div>
                              <select
                                lableId="demo-simple-select-lable"
                                id="demo-simple-select"
                                className="form-select m-b-10"
                                style={{
                                  width: 150,
                                  height: 30,
                                  display: "block",
                                }}
                                onChange={handleAssetCategoryNameSelectChange}
                                value={dpAssetCategoryName}
                              >
                                <option value={"All"}>All</option>
                                {dpAssetCategoryNameList.map((name, index) => (
                                  <option key={index} value={name}>
                                    {name}
                                  </option>
                                ))}
                              </select>

                              <div value={"Code"}>Code</div>
                              <select
                                lableId="demo-simple-select-lable"
                                id="demo-simple-select"
                                className="form-select m-b-10"
                                style={{
                                  width: 150,
                                  height: 30,
                                  display: "block",
                                }}
                                onChange={handleCodeSelectChange}
                                value={dpCode}
                              >
                                <option value={"All"}>All</option>
                                {dpCodeList.map((name, index) => (
                                  <option key={index} value={name}>
                                    {name}
                                  </option>
                                ))}
                              </select>
                              <div
                                value={"DepreciationMethod"}
                                style={{ minWidth: "max-content" }}
                              >
                                Depreciation Method
                              </div>
                              <select
                                lableId="demo-simple-select-lable"
                                id="demo-simple-select"
                                className="form-select  m-b-10"
                                style={{
                                  width: 150,
                                  height: 30,
                                  display: "block",
                                }}
                                onChange={handleDepreciationMethodSelectChange}
                                value={dpDepreciationMethod}
                              >
                                <option value={"All"}>All</option>
                                {dpDepreciationMethodList.map((name, index) => (
                                  <option key={index} value={name}>
                                    {name}
                                  </option>
                                ))}
                              </select>

                              <div value={"Status"}>Status</div>
                              <select
                                lableId="demo-simple-select-lable"
                                id="demo-simple-select"
                                className="form-select m-b-10"
                                style={{
                                  width: 150,
                                  height: 30,
                                  display: "block",
                                }}
                                onChange={handleStatusSelectChange}
                                value={dpStatus}
                              >
                                <option value={"All"}>All</option>

                                {dpStatusList.map((status, index) => (
                                  <option key={index} value={status}>
                                    {status}
                                  </option>
                                ))}
                              </select>
                            </CardBody>
                          </Card>
                        )}
                      </div> */}
                    </div>
                    <div
                      className="d-flex"
                      onClick={() => downloadCSV(tableColumns, tableData)}
                    >
                      <div
                        dangerouslySetInnerHTML={{
                          __html: feather.icons["download"].toSvg({
                            height: "16px",
                            width: "16px",
                          }),
                        }}
                        className={`txt-info m-r-10 d-flex align-items-center`}
                      />
                      <P>Export CSV</P>
                    </div>
                  </Col>
                </Row>
                <ReactTable
                  columns={tableColumns}
                  data={tableData}
                  progressComponent={
                    <div className="">
                      <div className="loader-box">
                        <Spinner attrSpinner={{ className: "loader-26" }} />
                      </div>
                      <small>Fetching...</small>
                    </div>
                  }
                  progressPending={fetchingTableData}
                  pointerOnHover
                  pagination
                />
                <SaveAssetCategoryModel
                  is_open={triggerSaveModel}
                  trigger_model={setTriggerSaveModel}
                  model_data={modelData}
                  form_data={modelFormData}
                  reload_table={setFetchingTableData}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default AssetCategory;
