import React, { useContext, useEffect, useState } from "react";
import { Col, Container, Row } from "reactstrap";
import { Image, H4, P, Btn } from "../../../../AbstractElements";
import CommonModal from "../../../Common/Component/CommonModal";
import user from "../../../../assets/images/email-template/image_3.png";
import { MoreDetails } from "../../../../Constant";
import * as feather from "feather-icons";
import AssetItemContext from "../../../../_helper/AssetItem";
import UserContext from "../../../../_helper/User";
import axios from "axios";
import { backendURL, imageFileURL } from "../../../../Config/Config";
import { ConvertMillisToDate } from "../../../../Data/DateUtil";
import asset_default from "../../../../assets/images/asset-item/asset_default.png";

const AssetMinView = ({ is_open, trigger_model, model_data }) => {
  const toggle = () => trigger_model(!is_open);
  const assetContext = useContext(AssetItemContext);
  const assetItemData = assetContext.assetItem;
  const userContext = useContext(UserContext);
  const loggedUser = userContext.userData;
  const [defaultImage, setDefaultImage] = useState("");

  useEffect(() => {
    const reqBody = {
      clientId: loggedUser.clientId,
      orgId: loggedUser.orgId,
      userId: loggedUser.userId,
      moduleId: loggedUser.loggedModuleId,
      assetItemId: model_data?.assetItemId,
    };
    const reqInstance = axios.create({
      baseURL: backendURL,
    });
    reqInstance
      .post("/getAssetItem", reqBody)
      .then((response_data) => {
        if (response_data) {
          const result = response_data.data;
          if (!result.hasError) {
            assetContext.addAssetItem(result.assetItem);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [loggedUser, model_data?.assetItemId]);

  const mainData = [
    ["Serial No", assetItemData?.serialNo],
    ["Purchase Date", ConvertMillisToDate(assetItemData?.purchaseDate)],
    ["Purchase From", assetItemData?.purchaseFrom],
    ["Brand", assetItemData?.brand],
    ["Model", assetItemData?.model],
    ["Assigned To", ""],
  ];

  useEffect(() => {
    let imageList = assetItemData.assetImageList;
    if (imageList?.length > 0) {
      let selectedImage = imageList.filter(
        (image_item) => image_item.isPrimaryImage === true
      )[0];
      if (selectedImage) {
        setDefaultImage(selectedImage);
      }
    }
  }, [assetItemData]);

  return (
    <CommonModal
      isOpen={is_open}
      toggler={toggle}
      bodyClass="grid-showcase"
      size="lg"
    >
      <Container fluid={true} className="bd-example-row">
        <Row className="align-items-stretch m-b-10">
          <Col md="6">
            <div className="b-b-primary b-l-primary border-3 rounded h-100">
              <div className="b-t-success b-r-success rounded h-100 d-flex align-items-center justify-content-center">
                <Image
                  attrImage={{
                    className: "rounded w-100 m-b-10",
                    src: `${
                      defaultImage
                        ? imageFileURL + defaultImage.imagePath
                        : asset_default
                    }`,
                    alt: "Generic placeholder image",
                  }}
                />
              </div>
            </div>
          </Col>
          <Col md="6">
            <div className="b-b-light border-3 m-b-10 d-flex justify-content-between align-items-end">
              <div>
                <P attrPara={{ className: "f-24 m-b-0" }}>
                  {assetItemData?.searchKey}
                </P>
                <H4 attrH4={{ className: "text-secondary" }}>
                  {assetItemData?.name}
                </H4>
              </div>
              {/* <P
                attrPara={{
                  className: `badge ${
                    assetItemData?.isActive
                      ? "badge-light-info"
                      : "badge-light-danger"
                  }`,
                }}
              >
                {assetItemData?.isActive ? "Active" : "Not Active"}
              </P> */}
            </div>
            <div className="p-t-15 p-b-15 p-l-15 p-r-15 badge-light-primary rounded">
              {mainData.map((data_object, index) => (
                <Row key={index}>
                  <Col md="5">
                    <P
                      attrPara={{
                        className: `${index === 5 ? "m-b-0" : ""}`,
                      }}
                    >
                      <strong>{data_object[0]}</strong>
                    </P>
                  </Col>
                  <Col md="7" className={`${index === 5 ? "m-b-0" : ""}`}>
                    <P
                      attrPara={{
                        className: `${index === 5 ? "m-b-0" : ""}`,
                      }}
                    >
                      {data_object[1]}
                    </P>
                  </Col>
                </Row>
              ))}
            </div>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <div className="p-t-15 p-b-15 p-l-15 p-r-15 badge-light-primary rounded">
              <Row>
                <Col md="4">
                  <div className="d-flex">
                    <div className="font-primary m-r-10">
                      <i className="fa fa-building-o"></i>
                    </div>
                    <div>
                      <P attrPara={{ className: "m-b-0" }}>
                        <strong>Department</strong>
                      </P>
                      <P>{assetItemData?.existDepartment?.name}</P>
                    </div>
                  </div>
                </Col>
                <Col md="4">
                  <div className="d-flex">
                    <div className="font-primary m-r-10">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: feather.icons["map-pin"].toSvg({
                            height: "14px",
                            width: "14px",
                          }),
                        }}
                      />{" "}
                    </div>
                    <div>
                      <P attrPara={{ className: "m-b-0" }}>
                        <strong>Site</strong>
                      </P>
                      <P>{assetItemData?.assetSite?.name}</P>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md="4">
                  <div className="d-flex">
                    <div className="font-primary m-r-10">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: feather.icons["map-pin"].toSvg({
                            height: "14px",
                            width: "14px",
                          }),
                        }}
                      />
                    </div>
                    <div>
                      <P attrPara={{ className: "m-b-0" }}>
                        <strong>Building</strong>
                      </P>
                      <P>{assetItemData?.building?.name}</P>
                    </div>
                  </div>
                </Col>
                <Col md="4">
                  <div className="d-flex">
                    <div className="font-primary m-r-10">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: feather.icons["map-pin"].toSvg({
                            height: "14px",
                            width: "14px",
                          }),
                        }}
                      />{" "}
                    </div>
                    <div>
                      <P attrPara={{ className: "m-b-0" }}>
                        <strong>Floor</strong>
                      </P>
                      <P>{assetItemData?.floor?.name}</P>
                    </div>
                  </div>
                </Col>
                <Col md="4">
                  <div className="d-flex">
                    <div className="font-primary m-r-10">
                      <i className="fa fa-building-o"></i>
                    </div>
                    <div>
                      <P attrPara={{ className: "m-b-0" }}>
                        <strong>Location</strong>
                      </P>
                      <P>{assetItemData?.location?.name}</P>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        <div className="d-flex justify-content-end m-t-10 m-b-40">
          {/* <Btn attrBtn={{ className: "", color: "primary" }}>{MoreDetails}</Btn> */}
        </div>
      </Container>
    </CommonModal>
  );
};

export default AssetMinView;
