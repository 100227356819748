import React from "react";
import { Fragment } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { Breadcrumbs, H4, H6, Image, P } from "../../../AbstractElements";
import HeaderCard from "../../Common/Component/HeaderCard";

const Help = () => {

    return (
        <Fragment>
            <Breadcrumbs
                mainTitle="Help Support"
                parent="Help Support"
                title="User Manual"
            />
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card>
                            <HeaderCard
                                title="User Manual"
                                span1="This interface guides the user to use Asset Management System correctly."
                            />
                            <CardBody>
                                {/* <div>
                                    <div>Texonic Information Systems (Pvt) Ltd.</div>

                                    <div> No. 113/12, Dutugamunu Street, Colombo 06. Sri Lanka</div>

                                    <div>Tel (+94) 773099352</div>

                                    <div>www.saprosolutions.com</div>
                                </div> */}

                                {/* <div className="m-t-20 p-t-50">
                                    <H1>USER MANUAL</H1>
                                    <H6>ATTENDANCE APPLICATION </H6>
                                    <H6>VERSION 1.0.0 </H6>
                                    <span>Spring Boot and React based web application for marking attendance using a QR Code</span>
                                </div> */}

                                {/* <div>
                                    <H4 className="p-t-50">Contents </H4><br />

                                    <ul class="list-group m-l-40">
                                        <li class="list-group-item">Log In Interface.........................................................................................................................................................................1</li>
                                        <li class="list-group-item">Dashboard..................................................................................................................................................................................3</li>
                                        <li class="list-group-item">User management.................................................................................................................................................................4</li>
                                        <li class="list-group-item">Master Interfaces....................................................................................................................................................................9</li>
                                    </ul>
                                </div> */}

                                <div className="">

                                    <H4 className="p-t-50">Log In Interface</H4><br />
                                    <span><b>Link for the Application </b>-<a href="https://pms.saprosolutions.com/AssetManagement/#/login">https://pms.saprosolutions.com/AssetManagement/#/login</a></span>
                                    <p className="m-t-20">Enter your username, password and client code correctly. For now, to login to the system, use login details below.</p>
                                    <div className="m-t-20 m-l-40" >Username - vishan</div>
                                    <div className="m-t-20 m-l-40" >Password – vishan@123</div>
                                    <div className="m-t-20 m-l-40" >Client Code - CG</div>

                                    <div className="m-t-40 px-4">
                                        <Image attrImage={{ className: 'rounded img-fluid d-inline', src: `${require('../../../assets/images/help/sap logIn.jpeg')}`, alt: '' }} />

                                    </div>


                                </div>

                                <div className="m-t-20 p-t-50">

                                    <H4 className="p-t-50">Dashboard</H4><br />
                                    <p className="m-t-20">First row of the dashboard contains, 3 cards. Left Column contains 2 cards and right column contains the other card. 2 cards of the Left column are as follows.</p>
                                    <ul class="list-group m-l-40">
                                        <li class="list-group-item">•	Top Card - Monetory value of Total Assets and Diposed Assets</li>
                                        <li class="list-group-item">•	Bottom Card - No.of of Total Assets and Diposed Assets</li>
                                    </ul>
                                    <p className="m-t-20">Right Column Card shows a bar graph which displays values by catagorizing assets Building Wise.</p>
                                    <p className="m-t-20">Bottom row contains 2 cards and those are as follows.</p>
                                    <ul class="list-group m-l-40">
                                        <li class="list-group-item">•	Left Card - Shows a bar graph which depicts values by catagorizing assets Asset Category Wise.</li>
                                        <li class="list-group-item">•	Right Card - Shows a pie graph which displays values by catagorizing assets according to it's condition.</li>
                                    </ul>
                                    <div className="m-t-40 px-4">
                                        <Image attrImage={{ className: 'rounded img-fluid d-inline rounded', src: `${require('../../../assets/images/help/dashboard.jpeg')}`, alt: '' }} />
                                    </div>

                                    <div className="m-t-40 px-4">
                                        <Image attrImage={{ className: 'rounded img-fluid d-inline', src: `${require('../../../assets/images/help/dashboard 2.jpeg')}`, alt: '' }} />
                                    </div>

                                </div>

                                <div className="m-t-20 p-t-50">
                                    <H4 className="p-t-50">User management</H4><br />

                                    <ul class="list-group m-l-40">
                                        <li class="list-group-item">•	Here you can see the User Management section</li>
                                        <li class="list-group-item">•	User Management contains two Sub Sections in the sidebar</li>
                                        <div className="m-l-40">o	Employees</div>
                                        <div className="m-l-40">o	Users</div>
                                        <li class="list-group-item">•	If you click Employees, You will see the Employee interface</li>
                                        <li class="list-group-item">•	Now you can click the New Employee Button to create a new Employee</li>
                                    </ul>

                                    <div className="m-t-40 px-4">
                                        <Image attrImage={{ className: 'rounded img-fluid d-inline', src: `${require('../../../assets/images/help/user management.jpg')}`, alt: '' }} />
                                    </div>

                                    <div className="m-t-40">Here you want to fill all the details and click the submit button then you can create a new Employee.</div>
                                    <div className="m-t-40 px-4">
                                        <Image attrImage={{ className: 'rounded img-fluid d-inline', src: `${require('../../../assets/images/help/employee.jpg')}`, alt: '' }} />
                                    </div>

                                    <div className="m-t-40">If you need to change any employee details,</div>

                                    <ol class="list-group list-group-numbered m-l-40">
                                        <li class="list-group-item"> You want to go to the employee page that contain all the employee details.</li>
                                        <li class="list-group-item"> Click the edit button of that employee details then you can get the edit employee popup.</li>
                                        <li class="list-group-item"> Then you can make the change in that popup and click the update button.</li>
                                        <li class="list-group-item"> Now your employee details are change.</li>
                                    </ol>

                                    <div className="m-t-40 px-4">
                                        <Image attrImage={{ className: 'rounded img-fluid d-inline rounded', src: `${require('../../../assets/images/help/update employee.jpg')}`, alt: '' }} />
                                    </div>

                                </div>


                                <div className="m-t-20 p-t-50">
                                    <H4 className="p-t-50">Master Files</H4><br />

                                    <div className="m-t-15 m-l-20">This application contains 8 master interfaces as,</div>

                                    <ul class="list-group m-t-10 m-l-40">


                                        <li class="list-group-item m-t-33">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="5 -4 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check"><polyline points="20 6 9 17 4 12"></polyline>
                                            </svg>     Asset Category</li>
                                        <li class="list-group-item m-t-33">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="5 -4 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check"><polyline points="20 6 9 17 4 12"></polyline>
                                            </svg>     Building</li>
                                        <li class="list-group-item m-t-33">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="5 -4 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check"><polyline points="20 6 9 17 4 12"></polyline>
                                            </svg>     Disposal Type</li>
                                        <li class="list-group-item m-t-33">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="5 -4 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check"><polyline points="20 6 9 17 4 12"></polyline>
                                            </svg>     Common Category</li>
                                        <li class="list-group-item m-t-33">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="5 -4 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check"><polyline points="20 6 9 17 4 12"></polyline>
                                            </svg>     Condition</li>
                                        <li class="list-group-item m-t-33">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="5 -4 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check"><polyline points="20 6 9 17 4 12"></polyline>
                                            </svg>     Floor</li>
                                        <li class="list-group-item m-t-33">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="5 -4 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check"><polyline points="20 6 9 17 4 12"></polyline>
                                            </svg>     Location</li>
                                        <li class="list-group-item m-t-33">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="5 -4 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check"><polyline points="20 6 9 17 4 12"></polyline>
                                            </svg>     Site</li>

                                    </ul>

                                </div>

                                <div className="m-t-10 p-t-20">

                                    <div className="m-l-25">
                                        <H6>Asset Category</H6>
                                    </div>

                                    <div className="m-t-10 m-l-25">
                                        <P>This master interface will be used to input a list of asset categories related to assets. This could include categories like Land and Buildings,
                                            Machinery and Equipment, Furniture and Fixtures, Vehicles, Computer Systems and IT Assets,
                                            Infrastructure ,...etc.</P>
                                    </div>

                                    <div className="m-t-20 m-l-25">Here user can see a table of Asset Category list.</div>

                                    <div className="m-t-40 px-4">
                                        <Image attrImage={{ className: 'rounded img-fluid d-inline', src: `${require('../../../assets/images/help/listOfAssCato.png')}`, alt: '' }} />
                                    </div>

                                    <div className="m-t-20 m-l-25">•	If you click Export CSV, You can download the List of Asset Category details</div>

                                    <div className="m-t-15 m-l-25"><P>•	To add  Asset Category, User can click Add New Asset Category
                                        button on top of the table . Then user will be prompted with an interface as below.</P></div>

                                    <div className="m-t-15 m-l-25">By submitting below form, user will be able to create new Asset Category.</div>

                                    <div className="m-t-20 px-4">
                                        <Image attrImage={{ className: 'rounded img-fluid d-inline', src: `${require('../../../assets/images/help/addAssetCato.png')}`, alt: '' }} />
                                    </div>

                                    <div className="m-t-15 m-l-25">•	Import Categories feature not yet available</div>

                                    <div className="m-t-20 px-4">
                                        <Image attrImage={{ className: 'rounded img-fluid d-inline', src: `${require('../../../assets/images/help/importCato.png')}`, alt: '' }} />
                                    </div>

                                    <div className="m-t-45 m-l-25">•	User can click  Edit Button in the table. Then user will be prompted with an interface as below.</div>

                                    <div className="m-t-15 m-l-25">By fill the below form, user will be able to Delete or Update an existing Asset Category.</div>

                                    <div className="m-t-20 px-4">
                                        <Image attrImage={{ className: 'rounded img-fluid d-inline', src: `${require('../../../assets/images/help/updateAssetCato.png')}`, alt: '' }} />
                                    </div>

                                </div>


                                <div className="m-t-20 p-t-50">
                                    <div className="m-t-30 m-l-25">
                                        <H6>Building</H6>
                                    </div>

                                    <div className="m-t-10 m-l-25"><P>You may also add Buildings. Buildings are subsets of sites.
                                        Each site can have multiple buildings within it. For instance, within the 'Headquarters'
                                        site, you might have buildings like 'Wing A', 'Wing B', or 'Annex Building'.</P>
                                    </div>

                                    <div className="m-t-20 m-l-25">Here user can see a table of List Of Buildings.</div>

                                    <div className="m-t-40 px-4">
                                        <Image attrImage={{ className: 'rounded img-fluid d-inline', src: `${require('../../../assets/images/help/listOfBuildings.jpg')}`, alt: '' }} />
                                    </div>

                                    <div className="m-t-40 m-l-25">•	If you click Export CSV, You can download the List of Buildings details.</div>

                                    <div className="m-t-10 m-l-25">•	To add  Building, User can click New Building button on top of the
                                        table. Then user will be prompted with an interface as below.</div>

                                    <div className="m-t-10 m-l-25">By submitting below form, user will be able to create new Building.</div>

                                    <div className="m-t-40 px-4">
                                        <Image attrImage={{ className: 'rounded img-fluid d-inline', src: `${require('../../../assets/images/help/addBuilding.jpg')}`, alt: '' }} />
                                    </div>

                                    <div className="m-t-40 m-l-25">•	Import Buildings feature not yet available</div>

                                    <div className="m-t-40 px-4">
                                        <Image attrImage={{ className: 'rounded img-fluid d-inline', src: `${require('../../../assets/images/help/importBuilding.jpg')}`, alt: '' }} />
                                    </div>

                                    <div className="m-t-45 m-l-25">•	User can click  Edit Button in the table. Then user will be prompted with an interface as below.</div>

                                    <div className="m-t-15 m-l-25">By fill the below form, user will be able to Delete or Update an existing Building.</div>

                                    <div className="m-t-20 px-4">
                                        <Image attrImage={{ className: 'rounded img-fluid d-inline', src: `${require('../../../assets/images/help/updateBuilding.jpg')}`, alt: '' }} />
                                    </div>

                                </div>


                                <div className="m-t-20 p-t-50">
                                    <div className="m-t-30 m-l-25">
                                        <H6>Disposal Type</H6>
                                    </div>

                                    <div className="m-t-10 m-l-25">
                                        <P>User can use this interface to define different kinds of Disposal Type. These data will be used in the Asset Dispose interface when disposing an Asset Item. For eg.: Trade-Out, Trade-In...etc.</P>
                                    </div>

                                    <div className="m-t-20 m-l-25">Here user can see a table of List Of Disposal Type.</div>

                                    <div className="m-t-40 px-4">
                                        <Image attrImage={{ className: 'rounded img-fluid d-inline', src: `${require('../../../assets/images/help/listDisposalType.jpg')}`, alt: '' }} />
                                    </div>

                                    <div className="m-t-40 m-l-25">•	If you click Export CSV, You can download the List of Disposal Type details.</div>

                                    <div className="m-t-10 m-l-25">•	To add  Disposal Type, User can click Add New Disposal Type button
                                        on top of the table . Then user will be prompted with an interface as below.</div>

                                    <div className="m-t-10 m-l-25">By submitting below form, user will be able to create new Disposal Type.</div>

                                    <div className="m-t-40 px-4">
                                        <Image attrImage={{ className: 'rounded img-fluid d-inline', src: `${require('../../../assets/images/help/addDisposalType.jpg')}`, alt: '' }} />
                                    </div>

                                    <div className="m-t-40 m-l-25">•	     Import Disposal Type feature not yet available</div>

                                    <div className="m-t-40 px-4">
                                        <Image attrImage={{ className: 'rounded img-fluid d-inline', src: `${require('../../../assets/images/help/importDisposalType.jpg')}`, alt: '' }} />
                                    </div>

                                    <div className="m-t-45 m-l-25">•	User can click  Edit Button in the table. Then user will be prompted with an interface as below.</div>

                                    <div className="m-t-15 m-l-25">By fill the below form, user will be able to Delete or Update an existing Disposal Type.</div>

                                    <div className="m-t-20 px-4">
                                        <Image attrImage={{ className: 'rounded img-fluid d-inline', src: `${require('../../../assets/images/help/updateDisposalType.jpg')}`, alt: '' }} />
                                    </div>


                                </div>


                                <div className="m-t-20 p-t-50">

                                    <div className="m-t-30 m-l-25">
                                        <H6>Common Category</H6>
                                    </div>

                                    <div className="m-t-10 m-l-25">
                                        <P>Common categories have already been defined for you. Make them as general or specific
                                            as you desire. Categories may include 'Furniture and fixtures', 'Buildings', and
                                            'Computer hardware'. Adjust to your unique needs. Parent Category - helps to organize
                                            and classify assets into logical groups based on their shared characteristics or purpose.</P>
                                    </div>

                                    <div className="m-t-20 m-l-25">Here user can see a table of List Of Categories.</div>

                                    <div className="m-t-40 px-4">
                                        <Image attrImage={{ className: 'rounded img-fluid d-inline', src: `${require('../../../assets/images/help/listCato.jpg')}`, alt: '' }} />
                                    </div>

                                    <div className="m-t-40 m-l-25">•	If you click Export CSV, You can download the List of Categories details.</div>

                                    <div className="m-t-10 m-l-25">•	To add  Common Category, User can click  New Category button on top of the table. Then user will be prompted with an interface as below.</div>

                                    <div className="m-t-10 m-l-25">By submitting below form, user will be able to create new Category.</div>

                                    <div className="m-t-40 px-4">
                                        <Image attrImage={{ className: 'rounded img-fluid d-inline', src: `${require('../../../assets/images/help/addCato.jpg')}`, alt: '' }} />
                                    </div>

                                    <div className="m-t-40 m-l-25">•	Import Categories  feature not yet available</div>

                                    <div className="m-t-40 px-4">
                                        <Image attrImage={{ className: 'rounded img-fluid d-inline', src: `${require('../../../assets/images/help/importCato.jpg')}`, alt: '' }} />
                                    </div>

                                    <div className="m-t-45 m-l-25">•	User can click  Edit Button in the table.Then user will be prompted with an interface as below.</div>

                                    <div className="m-t-15 m-l-25">By fill the below form, user will be able to Delete or Update an existing Category.</div>

                                    <div className="m-t-20 px-4">
                                        <Image attrImage={{ className: 'rounded img-fluid d-inline', src: `${require('../../../assets/images/help/updateCato.jpg')}`, alt: '' }} />
                                    </div>

                                </div>


                                <div className="m-t-20 p-t-50">

                                    <div className="m-t-30 m-l-25">
                                        <H6>Condition</H6>
                                    </div>

                                    <div className="m-t-10 m-l-25">
                                        <P>Scale to assess the condition of assets, Ex New Assets are in excellent or
                                            optimal condition, with no significant issues affecting their functionality,
                                            performance, or value.Moderate Condition: Assets are in a reasonably good condition
                                            but may have some minor wear and tear, performance limitations, or slight functional
                                            impairments that do not significantly affect their overall usability or value.</P>
                                    </div>

                                    <div className="m-t-20 m-l-25">Here user can see a table of List Of Asset Conditions</div>

                                    <div className="m-t-40 px-4">
                                        <Image attrImage={{ className: 'rounded img-fluid d-inline', src: `${require('../../../assets/images/help/listOfCondition.png')}`, alt: '' }} />
                                    </div>

                                    <div className="m-t-40 m-l-25">•	If you click Export CSV, You can download the List of Asset Condition details.</div>

                                    <div className="m-t-10 m-l-25">•	To add  Asset Condition, User can click  Add New Condition button
                                        on top of the table . Then user will be prompted with an interface as below.</div>

                                    <div className="m-t-10 m-l-25">By submitting below form, user will be able to create new Asset Condition.</div>

                                    <div className="m-t-40 px-4">
                                        <Image attrImage={{ className: 'rounded img-fluid d-inline', src: `${require('../../../assets/images/help/addCondition.png')}`, alt: '' }} />
                                    </div>

                                    <div className="m-t-40 m-l-25">•	Import Conditions feature not yet available</div>

                                    <div className="m-t-40 px-4">
                                        <Image attrImage={{ className: 'rounded img-fluid d-inline', src: `${require('../../../assets/images/help/importCondition.png')}`, alt: '' }} />
                                    </div>

                                    <div className="m-t-45 m-l-25">•	User can click  Edit Button in the table. Then user will be
                                        prompted with an interface as below.</div>

                                    <div className="m-t-15 m-l-25">By fill the below form, user will be able to Delete or Update an
                                        existing Asset Condition.</div>

                                    <div className="m-t-20 px-4">
                                        <Image attrImage={{ className: 'rounded img-fluid d-inline', src: `${require('../../../assets/images/help/updateCondition.png')}`, alt: '' }} />
                                    </div>

                                </div>


                                <div className="m-t-20 p-t-50">

                                    <div className="m-t-30 m-l-25">
                                        <H6>Floor</H6>
                                    </div>

                                    <div className="m-t-10 m-l-25">
                                        <P>You may also add Floor. Floors are subsets of buildings. Each buildings can have multiple
                                            floors within it. For instance, within the 'Wing A' Building, you might have floors like
                                            '1st Floor', '2nd Floor', or 'Ground Floor'.</P>
                                    </div>

                                    <div className="m-t-20 m-l-25">Here user can see a table of List Of Floors.</div>

                                    <div className="m-t-40 px-4">
                                        <Image attrImage={{ className: 'rounded img-fluid d-inline', src: `${require('../../../assets/images/help/listOfFloors.png')}`, alt: '' }} />
                                    </div>

                                    <div className="m-t-40 m-l-25">•	If you click Export CSV, You can download the List of Floors details.</div>

                                    <div className="m-t-10 m-l-25">•	To add  Floor, User can click  New Floor button on top of the
                                        table . Then user will be prompted with an interface as below.</div>

                                    <div className="m-t-10 m-l-25">By submitting below form, user will be able to create new Floor.</div>

                                    <div className="m-t-40 px-4">
                                        <Image attrImage={{ className: 'rounded img-fluid d-inline', src: `${require('../../../assets/images/help/addFloor.png')}`, alt: '' }} />
                                    </div>

                                    <div className="m-t-40 m-l-25">•	Import Floors feature not yet available</div>

                                    <div className="m-t-40 px-4">
                                        <Image attrImage={{ className: 'rounded img-fluid d-inline', src: `${require('../../../assets/images/help/importFloors.png')}`, alt: '' }} />
                                    </div>

                                    <div className="m-t-45 m-l-25">•	User can click  Edit Button in the table. Then user will be
                                        prompted with an interface as below.</div>

                                    <div className="m-t-15 m-l-25">By fill the below form, user will be able to Delete or Update an existing Floor.</div>

                                    <div className="m-t-20 px-4">
                                        <Image attrImage={{ className: 'rounded img-fluid d-inline', src: `${require('../../../assets/images/help/updateFloor.png')}`, alt: '' }} />
                                    </div>

                                </div>


                                <div className="m-t-20 p-t-50">

                                    <div className="m-t-30 m-l-25">
                                        <H6>Location</H6>
                                    </div>

                                    <div className="m-t-10 m-l-25">
                                        <P>The Location may be a specific room, office or lobby within the Floor.
                                            Select a Floor and add your list of Locations here.</P>
                                    </div>

                                    <div className="m-t-20 m-l-25">Here user can see a table of List Of Locations.</div>

                                    <div className="m-t-40 px-4">
                                        <Image attrImage={{ className: 'rounded img-fluid d-inline', src: `${require('../../../assets/images/help/listOfLocations.png')}`, alt: '' }} />
                                    </div>

                                    <div className="m-t-40 m-l-25">•	If you click Export CSV, You can download the List of Location details.</div>

                                    <div className="m-t-10 m-l-25">•	To add  Location, User can click  New Floor button on top of
                                        the table . Then user will be prompted with an interface as below.</div>

                                    <div className="m-t-10 m-l-25">By submitting below form, user will be able to create new Location.</div>

                                    <div className="m-t-40 px-4">
                                        <Image attrImage={{ className: 'rounded img-fluid d-inline', src: `${require('../../../assets/images/help/addLocation.png')}`, alt: '' }} />
                                    </div>

                                    <div className="m-t-40 m-l-25">•	Import Locations feature not yet available</div>

                                    <div className="m-t-40 px-4">
                                        <Image attrImage={{ className: 'rounded img-fluid d-inline', src: `${require('../../../assets/images/help/importLocation.png')}`, alt: '' }} />
                                    </div>

                                    <div className="m-t-45 m-l-25">•	User can click  Edit Button in the table. Then user will be prompted with an interface as below.</div>

                                    <div className="m-t-15 m-l-25">By fill the below form, user will be able to Delete or Update an existing Location.</div>

                                    <div className="m-t-20 px-4">
                                        <Image attrImage={{ className: 'rounded img-fluid d-inline', src: `${require('../../../assets/images/help/updateLocation.png')}`, alt: '' }} />
                                    </div>

                                </div>

                                <div className="m-t-20 p-t-50">

                                    <div className="m-t-30 m-l-25">
                                        <H6>Site</H6>
                                    </div>

                                    <div className="m-t-10 m-l-25">
                                        <P>This master interface will be used to input a list of sites where Assets have been located
                                            in an organization.</P>
                                    </div>

                                    <div className="m-t-20 m-l-25">Here user can see a table of List Of Sites.</div>

                                    <div className="m-t-40 px-4">
                                        <Image attrImage={{ className: 'rounded img-fluid d-inline', src: `${require('../../../assets/images/help/listOfSites.png')}`, alt: '' }} />
                                    </div>

                                    <div className="m-t-40 m-l-25">•	If you click Export CSV, You can download the List of Asset Site details.</div>

                                    <div className="m-t-10 m-l-25">•	To add  Asset Site, User can click  Add New Site button on top of
                                        the table . Then user will be prompted with an interface as below.</div>

                                    <div className="m-t-10 m-l-25">By submitting below form, user will be able to create new Site.</div>

                                    <div className="m-t-40 px-4">
                                        <Image attrImage={{ className: 'rounded img-fluid d-inline', src: `${require('../../../assets/images/help/addSite.png')}`, alt: '' }} />
                                    </div>

                                    <div className="m-t-40 m-l-25">•	Import Sites feature not yet available</div>

                                    <div className="m-t-40 px-4">
                                        <Image attrImage={{ className: 'rounded img-fluid d-inline', src: `${require('../../../assets/images/help/importSite.png')}`, alt: '' }} />
                                    </div>

                                    <div className="m-t-45 m-l-25">•	User can click  Edit Button in the table. Then user will be prompted with an interface as below.</div>

                                    <div className="m-t-15 m-l-25">By filling the form below, user will be able to Delete or Update an existing Site.</div>

                                    <div className="m-t-20 px-4">
                                        <Image attrImage={{ className: 'rounded img-fluid d-inline', src: `${require('../../../assets/images/help/updateSite.png')}`, alt: '' }} />
                                    </div>

                                </div>


                            </CardBody>

                        </Card>


                    </Col>
                </Row>
            </Container>

        </Fragment>
    );
}
export default Help;