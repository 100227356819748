import React, { table, Fragment, useState, useEffect } from "react";
import { Breadcrumbs, H5, P, Alerts } from "../../../AbstractElements";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Media,
  CardHeader,
  Label,
  Input,
} from "reactstrap";
import { Btn, Spinner } from "../../../AbstractElements";
import * as feather from "feather-icons";
import { useForm } from "react-hook-form";
import axios from "axios";
import { backendURL } from "../../../Config/Config";
import * as XLSX from "xlsx";

const Export = () => {
  const handleExport = () => {
    const currentTimestamp = new Date().toISOString().replace(/:/g, "-");
    const fileName = `${selectedTable}_${currentTimestamp}`;
    const selectedColumns = Object.keys(columnCheckboxes).filter(
      (column) => columnCheckboxes[column]
    );
    const selectedData = tableData.map((row) =>
      selectedColumns.reduce((acc, column) => {
        acc[column] = row[column];
        return acc;
      }, {})
    );

    console.log("worked");

    // Create worksheet

    if (selectedFileType === ".xlsx") {
      const ws = XLSX.utils.json_to_sheet(selectedData);

      // Create workbook

      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Exported Data");

      // Export to .xlsx format
      //  XLSX.writeFile(wb, 'exported_data.xlsx');
      XLSX.writeFile(wb, `${fileName}.xlsx`);
    } else if (selectedFileType === ".csv") {
      // Generate CSV content
      // const csvContent = selectedData.map((row) => Object.values(row).join(',')).join('\n');
      let csvContent = selectedColumns.join(",") + "\n"; // Include column names as the first row
      csvContent += selectedData
        .map((row) => selectedColumns.map((col) => row[col] || "").join(","))
        .join("\n");
      const csvBlob = new Blob([csvContent], {
        type: "text/csv;charset=utf-8;",
      });
      const csvURL = URL.createObjectURL(csvBlob);
      const link = document.createElement("a");
      link.href = csvURL;
      // link.setAttribute('download', 'exported_data.csv');
      link.setAttribute("download", `${fileName}.csv`);
      link.click();
    }
  };

  const [Open, setOpen] = useState(true);
  const Toggle = () => setOpen(!Open);
  const [tableData, setTableData] = useState([]); // to hold the fetched table data
  const [fetchingTableData, setFetchingTableData] = useState(true); // to manage fetching state
  const [selectedTable, setSelectedTable] = useState("AssetCategory"); // Default value for the dropdown
  const [columnCheckboxes, setColumnCheckboxes] = useState([]);
  const [selectAllColumns, setSelectAllColumns] = useState(false);
  const [selectedFileType, setSelectedFileType] = useState(".xlsx");
  const [isTableSelected, setIsTableSelected] = useState(false);

  const handleCheckboxChange = (column) => {
    setColumnCheckboxes((prevState) => ({
      ...prevState,
      [column]: !prevState[column],
    }));
  };

  const handleSelectAllColumns = () => {
    setSelectAllColumns(!selectAllColumns);
    setColumnCheckboxes(
      Object.keys(columnCheckboxes).reduce(
        (acc, column) => ({ ...acc, [column]: !selectAllColumns }),
        {}
      )
    );
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  // /----------------------=====================================================
  const handleTable = (e) => {
    console.log(e.target.value);

    setIsTableSelected(e.target.value !== "option1");
    if (e.target.value === "Building") {
      //console.log("my name is khan");
      // if (fetchingTableData) {
      const reqBody = {
        clientId: 38,
        orgId: 41,
        userId: 213,
        moduleId: 250,
      };
      const reqInstance = axios.create({
        baseURL: backendURL,
      }); // Replace with your backend URL

      // const endpoint = tableEndpoints[selectedTable];

      reqInstance
        .post("/viewBuilding", reqBody) // Make sure the URL matches your API endpoint
        .then((response_data) => {
          if (response_data) {
            const result = response_data.data;
            if (!result.hasError) {
              // Process the data and set the tableData state
              setTableData(result.allBuildingList);
              setFetchingTableData(false);

              if (result?.allBuildingList?.length > 0) {
                const columns = Object.keys(result.allBuildingList[0]);
                setColumnCheckboxes(
                  columns.reduce(
                    (acc, column) => ({ ...acc, [column]: false }),
                    {}
                  )
                );
              }
            } else {
              // Handle error case
              console.error("Request Failed");
              setFetchingTableData(false);
              // Add your error handling logic here (e.g., show a toast message)
            }
            console.log(result);
          }
        })
        .catch((error) => {
          console.error(error);
          setFetchingTableData(false);
          // Add your error handling logic here (e.g., show a toast message)
        });
    } else if (e.target.value === "AssetCategory") {
      console.log("1234");
      // if (fetchingTableData) {
      const reqBody = {
        clientId: 38,
        orgId: 41,
        userId: 213,
        moduleId: 250,
      };
      const reqInstance = axios.create({
        baseURL: backendURL,
      }); // Replace with your backend URL

      // const endpoint = tableEndpoints[selectedTable];

      reqInstance
        .post("/viewAssetCategory", reqBody) // Make sure the URL matches your API endpoint
        .then((response_data) => {
          if (response_data) {
            const result = response_data.data;
            if (!result.hasError) {
              // Process the data and set the tableData state
              setTableData(result.allAssetCategoryList);
              setFetchingTableData(false);

              if (result?.allAssetCategoryList?.length > 0) {
                const columns = Object.keys(result.allAssetCategoryList[0]);
                setColumnCheckboxes(
                  columns.reduce(
                    (acc, column) => ({ ...acc, [column]: false }),
                    {}
                  )
                );
              }
            } else {
              // Handle error case
              console.error("Request Failed");
              setFetchingTableData(false);
              // Add your error handling logic here (e.g., show a toast message)
            }
            console.log(result);
          }
        })
        .catch((error) => {
          console.error(error);
          setFetchingTableData(false);
          // Add your error handling logic here (e.g., show a toast message)
        });
    } else if (e.target.value === "Floor") {
      console.log("3");
      // if (fetchingTableData) {
      const reqBody = {
        clientId: 38,
        orgId: 41,
        userId: 213,
        moduleId: 250,
      };
      const reqInstance = axios.create({
        baseURL: backendURL,
      }); // Replace with your backend URL

      // const endpoint = tableEndpoints[selectedTable];

      reqInstance
        .post("/viewFloor", reqBody) // Make sure the URL matches your API endpoint
        .then((response_data) => {
          if (response_data) {
            const result = response_data.data;
            if (!result.hasError) {
              // Process the data and set the tableData state
              setTableData(result.allFloorList);
              setFetchingTableData(false);

              if (result?.allFloorList?.length > 0) {
                const columns = Object.keys(result.allFloorList[0]);
                setColumnCheckboxes(
                  columns.reduce(
                    (acc, column) => ({ ...acc, [column]: false }),
                    {}
                  )
                );
              }
            } else {
              // Handle error case
              console.error("Request Failed");
              setFetchingTableData(false);
              // Add your error handling logic here (e.g., show a toast message)
            }
            console.log(result);
          }
        })
        .catch((error) => {
          console.error(error);
          setFetchingTableData(false);
          // Add your error handling logic here (e.g., show a toast message)
        });
    } else if (e.target.value === "AssetCondition") {
      console.log("3");
      // if (fetchingTableData) {
      const reqBody = {
        clientId: 38,
        orgId: 41,
        userId: 213,
        moduleId: 250,
      };
      const reqInstance = axios.create({
        baseURL: backendURL,
      }); // Replace with your backend URL

      // const endpoint = tableEndpoints[selectedTable];

      reqInstance
        .post("/viewAssetCondition", reqBody) // Make sure the URL matches your API endpoint
        .then((response_data) => {
          if (response_data) {
            const result = response_data.data;
            if (!result.hasError) {
              // Process the data and set the tableData state
              setTableData(result.assetConditionList);
              setFetchingTableData(false);

              if (result?.assetConditionList?.length > 0) {
                const columns = Object.keys(result.assetConditionList[0]);
                setColumnCheckboxes(
                  columns.reduce(
                    (acc, column) => ({ ...acc, [column]: false }),
                    {}
                  )
                );
              }
            } else {
              // Handle error case
              console.error("Request Failed");
              setFetchingTableData(false);
              // Add your error handling logic here (e.g., show a toast message)
            }
            console.log(result);
          }
        })
        .catch((error) => {
          console.error(error);
          setFetchingTableData(false);
          // Add your error handling logic here (e.g., show a toast message)
        });
    } else if (e.target.value === "Category") {
      console.log("3");
      // if (fetchingTableData) {
      const reqBody = {
        clientId: 38,
        orgId: 41,
        userId: 213,
        moduleId: 250,
      };
      const reqInstance = axios.create({
        baseURL: backendURL,
      }); // Replace with your backend URL

      // const endpoint = tableEndpoints[selectedTable];

      reqInstance
        .post("/viewCategory", reqBody) // Make sure the URL matches your API endpoint
        .then((response_data) => {
          if (response_data) {
            const result = response_data.data;
            if (!result.hasError) {
              // Process the data and set the tableData state
              setTableData(result.allCategoryList);
              setFetchingTableData(false);

              if (result?.allCategoryList?.length > 0) {
                const columns = Object.keys(result.allCategoryList[0]);
                setColumnCheckboxes(
                  columns.reduce(
                    (acc, column) => ({ ...acc, [column]: false }),
                    {}
                  )
                );
              }
            } else {
              // Handle error case
              console.error("Request Failed");
              setFetchingTableData(false);
              // Add your error handling logic here (e.g., show a toast message)
            }
            console.log(result);
          }
        })
        .catch((error) => {
          console.error(error);
          setFetchingTableData(false);
          // Add your error handling logic here (e.g., show a toast message)
        });
    } else if (e.target.value === "Location") {
      console.log("3");
      // if (fetchingTableData) {
      const reqBody = {
        clientId: 38,
        orgId: 41,
        userId: 213,
        moduleId: 250,
      };
      const reqInstance = axios.create({
        baseURL: backendURL,
      });

      reqInstance
        .post("/viewLocation", reqBody)
        .then((response_data) => {
          if (response_data) {
            const result = response_data.data;
            if (!result.hasError) {
              setTableData(result.allLocationList);
              setFetchingTableData(false);

              if (result?.allLocationList?.length > 0) {
                const columns = Object.keys(result.allLocationList[0]);
                setColumnCheckboxes(
                  columns.reduce(
                    (acc, column) => ({ ...acc, [column]: false }),
                    {}
                  )
                );
              }
            } else {
              console.error("Request Failed");
              setFetchingTableData(false);
            }
            console.log(result);
          }
        })
        .catch((error) => {
          console.error(error);
          setFetchingTableData(false);
        });
    } else if (e.target.value === "AssetSite") {
      console.log("3");
      // if (fetchingTableData) {
      const reqBody = {
        clientId: 38,
        orgId: 41,
        userId: 213,
        moduleId: 250,
      };
      const reqInstance = axios.create({
        baseURL: backendURL,
      });

      reqInstance
        .post("/viewAssetSite", reqBody)
        .then((response_data) => {
          if (response_data) {
            const result = response_data.data;
            if (!result.hasError) {
              // Process the data and set the tableData state
              setTableData(result.allAssetSiteList);
              setFetchingTableData(false);

              if (result?.allAssetSiteList?.length > 0) {
                const columns = Object.keys(result.allAssetSiteList[0]);
                setColumnCheckboxes(
                  columns.reduce(
                    (acc, column) => ({ ...acc, [column]: false }),
                    {}
                  )
                );
              }
            } else {
              console.error("Request Failed");
              setFetchingTableData(false);
            }
            console.log(result);
          }
        })
        .catch((error) => {
          console.error(error);
          setFetchingTableData(false);
        });
    }
  };

  return (
    <div>
      <Fragment>
        <Breadcrumbs
          mainTitle="Export Table"
          parent="Pages"
          title="Sample Card"
        />
        <Container fluid={true}>
          <Row>
            <Col sm="12">
              <Card>
                <CardHeader>
                  <H5>Step 1 : Select Table</H5>
                  <span>Choose required table needed to be exported</span>
                </CardHeader>
                <CardBody>
                  <Col md="5" className="container text-center d-flex m-b-15">
                    <Label
                      htmlFor="input_fm_1_site_id m-r-15"
                      style={{
                        width: "120px",
                        marginRight: "7px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {"Export table"}
                    </Label>
                    <select
                      id="input_fm_1_site_id"
                      name="fm_1_site_id"
                      className="form-select"
                      {...register("Export table")}
                      onChange={(e) => {
                        handleTable(e);
                        setSelectedTable(e.target.value);
                        setFetchingTableData(true); // Trigger data fetching when an option is selected
                      }}
                    >
                      <option value="option1">-Select-</option>
                      <option value="AssetCategory">Asset Category</option>
                      <option value="Building">Building</option>
                      <option value="Category">Category</option>
                      <option value="AssetCondition">Condition</option>
                      <option value="Floor">Floor</option>
                      <option value="Location">location</option>
                      <option value="AssetSite">site</option>
                    </select>
                  </Col>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </Fragment>
      <Fragment>
        {/* <Breadcrumbs mainTitle='Sample Card' parent='Pages' title='Sample Card' /> */}
        <Container fluid={true}>
          <Row>
            <Col sm="12">
              <Card>
                <CardHeader>
                  <H5>Step 2 : Select Table column</H5>
                  <span>
                    Choose required columns required to be appeared in the
                    export file by selecting the appropriate checkboxes.
                  </span>
                </CardHeader>
                <CardBody>
                  {isTableSelected ? null : (
                    <Alerts
                      attrAlert={{
                        className: "alert-dismissible",
                        color: "info inverse",
                        isOpen: Open,
                        target: "Alert-1",
                      }}
                      closeBtn={false}
                      attrBtn={{
                        className: "btn-close",
                        color: "default",
                        id: "Alert-1",
                        onClick: Toggle,
                      }}
                    >
                      <i className="icon-info-alt"></i>
                      <P>Please select a table before exporting data</P>
                    </Alerts>
                  )}
                  {isTableSelected && fetchingTableData ? (
                    <div className="d-flex flex-column align-items-center">
                      <div className="loader-box">
                        <Spinner attrSpinner={{ className: "loader-26" }} />
                      </div>
                      <small>Fetching...</small>
                    </div>
                  ) : null}

                  {isTableSelected && !fetchingTableData && (
                    <Col>
                      <input
                        type="checkbox"
                        className="checkbox_animated"
                        id="selectAllColumns"
                        checked={selectAllColumns}
                        onChange={handleSelectAllColumns}
                        style={{ marginLeft: "4px" }}
                      />
                      <label
                        for="selectAllColumns"
                        style={{ marginLeft: "4px" }}
                      >
                        All Column
                      </label>
                      <br />
                      <h6>Category Fields</h6>
                      {Object.keys(columnCheckboxes).map((column) => (
                        <div key={column}>
                          <input
                            type="checkbox"
                            id={column}
                            className="checkbox_animated"
                            checked={columnCheckboxes[column]}
                            onChange={() => handleCheckboxChange(column)}
                          />
                          <label htmlFor={column} style={{ marginLeft: "4px" }}>
                            {column}
                          </label>
                          <br />
                        </div>
                      ))}
                    </Col>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </Fragment>
      <Fragment>
        <Container fluid={true}>
          <Row>
            <Col sm="12">
              <Card>
                <CardHeader>
                  <H5>Step 3 : Export File Format</H5>
                  <span>Choose required file type</span>
                </CardHeader>
                <CardBody>
                  <Col sm="4" className=" d-flex m-b-20">
                    <Label
                      htmlFor="input_fm_1_name"
                      className="required-input-mark  m-r-15"
                      style={{
                        width: "120px",
                        marginRight: "7px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      File type
                    </Label>

                    <select
                      id="input_fm_1_site_id"
                      name="fm_1_site_id"
                      className="form-select  d-flex"
                      value={selectedFileType}
                      onChange={(e) => setSelectedFileType(e.target.value)}
                    >
                      <option value=".xlsx">Excel (.xlsx)</option>
                      <option value=".csv">CSV (.csv)</option>
                    </select>
                  </Col>

                  <Col className="d-flex">
                    <Btn
                      attrBtn={{
                        color: "primary",
                        size: "sm",
                        disabled: false,
                        outline: false,
                        className: "m-r-10 d-flex align-items-center",
                        onClick: handleExport,
                      }}
                    >
                      Export
                    </Btn>
                  </Col>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </Fragment>
    </div>
  );
};
export default Export;
