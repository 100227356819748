import React, { useState } from "react";
import { useEffect } from "react";
import Context from "./index";

const UserProvider = (props) => {
  const [userData, setUserData] = useState({});

  const addUserData = (user_data) => {
    console.log("Data Set");
    setUserData(user_data);
  };

  // Load data from localStorage when the component mounts
  useEffect(() => {
    const storedData = localStorage.getItem("loggedUserData");
    if (storedData) {
      setUserData(JSON.parse(storedData));
    }
  }, []);

  // Save data to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem("loggedUserData", JSON.stringify(userData));
  }, [userData]);

  return (
    <Context.Provider
      value={{
        ...props,
        userData,
        addUserData: addUserData,
      }}
    >
      {props.children}
    </Context.Provider>
  );
};

export default UserProvider;
