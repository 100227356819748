import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Container, Row, Col, Card, CardBody, Media } from "reactstrap";
import { Breadcrumbs, Btn, Spinner, P } from "../../../AbstractElements";
import HeaderCard from "../../Common/Component/HeaderCard";
import * as feather from "feather-icons";
import ReactTable from "react-data-table-component";
import { toast } from "react-toastify";
import axios from "axios";
import { backendURL } from "../../../Config/Config";
import UserContext from "../../../_helper/User";
import MoreDocumentGaleryModel from "./Models/MoreDocumentGaleryModel";
import NewSaveDocumentGaleryModel from "./Models/NewSaveDocumentGaleryModel";
import { ConvertMillisToDate } from "../../../Data/DateUtil.jsx";

const DocumentGalery = () => {
  const userContext = useContext(UserContext);
  const loggedUser = userContext.userData;
  /**Datatable */
  // Table Functions
  const handleEditBtnClick = (row_data) => {
    setModelData(row_data);
    setTriggerSaveModel(true);
  };

  const handleMoreBtnClick = (row_data)=>{
    // console.log("Row Data:", row_data);
    setMoreDModelData([row_data]);
    setTriggerMoreModel(true);
  };

  const statusSort = (rowA, rowB) => {
    console.log(rowA);
    const a = rowA.status.props.children;
    const b = rowB.status.props.children;

    if (a > b) {
      return 1;
    }

    if (b > a) {
      return -1;
    }

    return 0;
  };
  const moretableColumns = useRef([
    {
      name: "Document Id",
      selector: (row) => `${row.assetDocumentId}`,
      sortable: true,
      center: false,
      download: false,
      omit:true
    },
    {
      name: "File Name",
      selector: (row) => `${row.documentPath.replace(/^.*[\\\/]/, '')}`,
      sortable: true,
      center: false,
      download: true,
    },
    {
      name: "Title",
      selector: (row) => `${row.name}`,
      sortable: true,
      center: false,
      download: true,
    },
    {
      name: "File Type",
      selector: (row) => `${row.assetDocumentType.name}`,
      sortable: true,
      center: false,
      download: true,
    },
    {
      name: "Uploaded Date",
      selector: (row) =>  ConvertMillisToDate(row.created),
      sortable: true,
      center: false,
      download: true,
    },
    {
      name: "Uploaded By",
      selector: (row) =>
        `${row.createdBy}`,
      sortable: true,
      center: false,
      download: true,
    },
    // {
    //   name: "#Asset Attached",
    //   selector: (row) => `${row.name}`,
    //   sortable: true,
    //   center: false,
    //   sortFunction: statusSort,
    //   download: true,
    // },
    {
      name: "Action",
      selector: (row) => row["action"],
      sortable: true,
      center: false,
      ignoreRowClick: true,
      download: false,
    },
  ]);
  const [tableData, setTableData] = useState();
  const [fetchingTableData, setFetchingTableData] = useState(true);
  const documentData = useRef([]);
  // Load Table Data
  useEffect(() => {
    if (fetchingTableData && Object.keys(loggedUser).length > 0) {
      const reqBody = {
        clientId: loggedUser.clientId,
        orgId: loggedUser.orgId,
        userId: loggedUser.userId,
        moduleId: loggedUser.loggedModuleId,
      };
      const reqInstance = axios.create({
        baseURL: backendURL,
      });
      reqInstance
        .post("/viewAllAssetDocument", reqBody)
        .then((response_data) => {
          if (response_data) {
            const result = response_data.data;
            if (!result.hasError) {
              let assetCategoryData = [];
              setModelFormData({
                clientList: result.activeClientList,
                orgList: result.activeOrgList,
                documentTypeList: result.activedocumentTypeList,
              });
              // const documentName= result.allDocumentList.documentPath.replace(/^.*[\\\/]/, '')
              result.allDocumentList.forEach((asset_category_item) => {
                assetCategoryData.push({
                  ...asset_category_item,
                  action: 
                    <Media>
                      <Btn
                        attrBtn={{
                          color: "info",
                          size: "sm",
                          disabled: false,
                          outline: true,
                          className: "m-r-10",
                          onClick: () =>
                            handleMoreBtnClick(asset_category_item),
                        }}
                      >
                        More
                      </Btn>
                    </Media>
                  ,
                });
              });
              setTableData(assetCategoryData);
              documentData.current = assetCategoryData;
              setFetchingTableData(false);
            } else {
              setTableData([]);
              setFetchingTableData(false);
              toast.error("Request Failed", {
                isLoading: false,
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [loggedUser, fetchingTableData]);
  /**Model */
  const [triggerSaveModel, setTriggerSaveModel] = useState(false);
  const [modelFormData, setModelFormData] = useState();
  const [modelData, setModelData] = useState([]);
  /**Model */
  const [triggerMoreModel, setTriggerMoreModel] = useState(false);
  const [moreModelFormData, setMoreModelFormData] = useState();
  const [moreModelData, setMoreModelData] = useState([]);
  const [moreDModelData, setMoreDModelData] = useState([]);
  /**Page  */
  const comingSoonNotification = () => {
    toast.warning("This feature is not yet available.", {
      isLoading: false,
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };
  const handleBasicSearch = (evt) => {
    let inputValue = evt.target.value.toLocaleLowerCase();
    if (inputValue.length > 0) {
      let searchResults = documentData.current;
      searchResults = searchResults.filter((search_row) => {
        let possibleValues = Object.values(search_row).filter(
          (search_prop) =>
            typeof search_prop === "string" || typeof search_prop === "number"
        );
        // console.log(possibleValues);
        return (
          possibleValues.filter(
            (target_value) =>
              target_value
                .toString()
                .toLocaleLowerCase()
                .indexOf(inputValue) !== -1
          )?.length > 0
        );
      });
      setTableData(searchResults);
    } else {
      setTableData(documentData.current);
    }
  };
  const convertArrayOfObjectsToCSV = (header_list, table_data) => {
    let result;

    const columnHeaders = [];
    header_list.current.forEach((header_item) => {
      if (header_item.download) columnHeaders.push(header_item.name);
    });
    const columnDelimiter = ",";
    const lineDelimiter = "\n";
    const keys = columnHeaders;

    result = "";
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    table_data.forEach((item) => {
      let ctr = 0;

      //extracting required data
      let rowData = [
        item.name,
        item.searchKey,
        item.assetDepreciation !== null
          ? item.assetDepreciation.name
          : "Not Selected",
        item.status.props.children,
      ];

      rowData.forEach((row_item) => {
        if (ctr > 0) result += columnDelimiter;

        result += row_item;

        ctr++;
      });
      result += lineDelimiter;
    });

    return result;
  };
  const downloadDocument = (url) => {
    const link = document.createElement("a");
    // link.setAttribute("href", encodeURI(csv));
    // link.setAttribute("download", filename);
    link.click();
  };

  return (
    <Fragment>
      <Breadcrumbs
        mainTitle="Documents Gallery"
        parent="Tools"
        title="Documents Gallery"
      />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <HeaderCard
                title="List Of Manage Your Documents"
                span1="Keeping track of your Documents is easy with the Document Gallery. You can easly link documents to specific assets by reviewing the information on documents and linking them. "
              />
              <CardBody>
                <div className="d-flex justify-content-end m-b-15">
                  <Btn
                    attrBtn={{
                      color: "primary",
                      size: "sm",
                      disabled: false,
                      outline: false,
                      className: "m-r-10 d-flex align-items-center",
                      onClick: () => handleEditBtnClick(""),
                    }}
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html: feather.icons["upload"].toSvg({
                          height: "12px",
                          width: "12px",
                        }),
                      }}
                      className="p-r-5 d-flex align-items-center"
                    />
                    Upload Document
                  </Btn>
                </div>
                <Row className="m-b-5 justify-content-between align-items-center">
                  <Col md="4 p-l-10">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Search..."
                      onKeyDown={(evt) => handleBasicSearch(evt)}
                    />
                  </Col>
                </Row>
                <ReactTable
                  columns={moretableColumns.current}
                  data={tableData}
                  progressComponent={
                    <div className="">
                      <div className="loader-box">
                        <Spinner attrSpinner={{ className: "loader-26" }} />
                      </div>
                      <small>Fetching...</small>
                    </div>
                  }
                  progressPending={fetchingTableData}
                  pointerOnHover
                  pagination
                />
                {/* <SaveDocumentGaleryModel
                  is_open={triggerSaveModel}
                  trigger_model={setTriggerSaveModel}
                  model_data={modelData}
                  form_data={modelFormData}
                  reload_table={setFetchingTableData}
                /> */}
                 <NewSaveDocumentGaleryModel
                  is_open={triggerSaveModel}
                  trigger_model={setTriggerSaveModel}
                  model_data={modelData}
                  form_data={modelFormData}
                  reload_table={setFetchingTableData}
                />
                 <MoreDocumentGaleryModel
                  is_open={triggerMoreModel}
                  trigger_model={setTriggerMoreModel}
                  model_data={moreModelData}
                  form_data={moreModelFormData}
                  reload_table={setFetchingTableData}
                  moreDModelData={moreDModelData}
                 
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default DocumentGalery;
