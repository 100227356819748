import React, { Fragment, useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Form,
  Label,
  Media,
} from "reactstrap";
import { Breadcrumbs } from "../../../AbstractElements";
import HeaderCard from "../../Common/Component/HeaderCard";
import ImageUploader from "../../Common/Component/ImageUploader";
import { backendURL } from "../../../Config/Config";
import { toast } from "react-toastify";
import axios from "axios";
import { ConvertDateToDate, ConvertMillisToDate } from "../../../Data/DateUtil";
import AssetItemContext from "../../../_helper/AssetItem";
import UserContext from "../../../_helper/User";
import { useLocation } from "react-router";

const NewAsset = () => {
  const userContext = useContext(UserContext);
  const loggedUser = userContext.userData;
  const {
    watch,
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const assetContext = useContext(AssetItemContext);
  const assetItemData = assetContext.assetItem;
  const location = useLocation();
  // Set Form Data
  const [assetCondition, setAssetCondition] = useState([]);
  const [assetSite, setAssetSite] = useState([]);
  const [assetBuilding, setAssetBuilding] = useState([]);
  const [assetFloor, setAssetFloor] = useState([]);
  const [assetLocation, setAssetLocation] = useState([]);
  const [assetCategory, setAssetCategory] = useState([]);
  const [assetSubCategory, setAssetSubCategory] = useState([]);
  const [assetMainCategory, setAssetMainCategory] = useState([]);
  const [assetDepartment, setAssetDepartment] = useState([]);
  const [currency, setCurrency] = useState("");
  const [dpSiteValue, setDpSiteValue] = useState(0);
  const [dpBuildingValue, setDpBuildingValue] = useState(0);
  const [dpFloorValue, setDpFloorValue] = useState(0);
  const [dpAssetCategoryValue, setDpAssetCategoryValue] = useState(0);
  const [dpMainCategoryValue, setDpMainCategoryValue] = useState(0);
  const [isDisposedItem, setIsDisposedItem] = useState(0);
  // Load Form Data
  const [formDataLoaded, setFormDataLoaded] = useState(false);
  const [previewFileList, setPreviewFileList] = useState([]);
  const [attachmentList, setAttachmentList] = useState([]);

  useEffect(() => {
    if (location.pathname === "/asset/add-asset/") {
      assetContext.removeAssetItem();
      if (assetItemData.assetItemId > 0) {
        window.location.reload();
      }
    }
    console.log(location.pathname);
    console.log(assetItemData.assetItemId);
  }, []);

  useEffect(() => {
    const reqBody = {
      clientId: loggedUser.clientId,
      orgId: loggedUser.orgId,
      userId: loggedUser.userId,
      moduleId: loggedUser.loggedModuleId,
    };
    const reqInstance = axios.create({
      baseURL: backendURL,
    });
    reqInstance
      .post("/viewAssetItem", reqBody)
      .then((response_data) => {
        if (response_data) {
          const result = response_data.data;
          setAssetCondition([
            { assetConditionId: 0, name: "- Select -" },
            ...result.activeAssetConditionList,
          ]);
          setAssetSite([
            { assetSiteId: 0, name: "- Select -" },
            ...result.activeAssetSiteList,
          ]);
          setAssetCategory([
            { assetConditionId: 0, name: "- Select -" },
            ...result.activeAssetCategoryList,
          ]);
          setAssetDepartment([
            { departmentId: 0, name: "- Select -" },
            ...result.activeDepartmentList,
          ]);
          setCurrency(result.defaultCurrency);
          //
          if (assetItemData.assetItemId > 0) {
            setValue("fm_1_is_bulk", !assetItemData.isBulk);
            setValue("fm_1_name", assetItemData.name);
            setValue("fm_1_asset_id", assetItemData.searchKey);
            setValue("fm_1_purchased_from", assetItemData.purchaseFrom);
            setValue("fm_1_reference_no", assetItemData.referenceNo);
            setValue("fm_1_brand", assetItemData.brand);
            setValue(
              "fm_1_purchase_date",
              ConvertMillisToDate(assetItemData.purchaseDate)
            );
            setValue("fm_1_model", assetItemData.model);
            setValue("fm_1_cost", assetItemData.costPerItem);
            setValue("fm_1_serial_no", assetItemData.serialNo);
            setValue(
              "fm_1_capitalization_date",
              ConvertMillisToDate(assetItemData.capitalizationDate)
            );
            setValue(
              "fm_1_a_asset_condition_id",
              assetItemData.assetCondition !== null
                ? assetItemData.assetCondition?.assetConditionId
                : 0
            );

            setDpAssetCategoryValue(
              assetItemData.assetCategory.assetCategoryId
            );
            setDpMainCategoryValue(assetItemData.category.parentId);
            setDpSiteValue(assetItemData.assetSite.assetSiteId);
            setDpBuildingValue(assetItemData.building.buildingId);
            setDpFloorValue(assetItemData.floor.floorId);
            setValue(
              "fm_1_a_asset_site_id",
              assetItemData.assetSite?.assetSiteId
            );
            setValue(
              "fm_1_sa_fo_building_id",
              assetItemData.building?.buildingId
            );
            setValue("fm_1_sa_fo_floor_id", assetItemData.floor?.floorId);
            setValue(
              "fm_1_a_asset_category_id",
              assetItemData.assetCategory?.assetCategoryId
            );
            setValue(
              "fm_1_sa_main_category_id",
              assetItemData.category?.parentId
            );
            setValue(
              "fm_1_sa_ownership_department_id",
              assetItemData.ownershipDepartment.departmentId
            );
            setValue(
              "fm_1_sa_exist_department_id",
              assetItemData.existDepartment.departmentId
            );
            setValue("fm_1_print_name", assetItemData.printName);
            setValue("fm_1_is_activate", assetItemData.isActivate);
            setValue("fm_1_active", assetItemData.isActive);
            setValue(
              "fm_1_depreciable_asset",
              assetItemData.isDepreciable ? "1" : "0"
            );
            //
            if (assetItemData.assetImageList.length > 0) {
              let previewList = [];
              let imageList = [];
              assetItemData.assetImageList.forEach((image_item) => {
                previewList.push({
                  id: image_item.assetImageId,
                  source: image_item.imagePath,
                });
                imageList.push({
                  documentId: image_item.assetImageId,
                  isModified: false,
                  attachmentExtension: "",
                  attachment: "",
                });
              });
              setPreviewFileList(previewList);
              setAttachmentList(imageList);
            }
            //
            let disposedItem = {
              clientId: loggedUser.clientId,
              orgId: loggedUser.orgId,
              assetItemId: assetItemData.assetItemId,
            };
            reqInstance
              .post("/checkAssetItemDisposed", disposedItem)
              .then((dispose_response_data) => {
                setIsDisposedItem(parseInt(dispose_response_data.existType));
              });
          }
          //
          setFormDataLoaded(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [assetItemData]);

  useEffect(() => {
    if (dpSiteValue > 0) {
      const reqBody = {
        clientId: loggedUser.clientId,
        orgId: loggedUser.orgId,
        userId: loggedUser.userId,
        siteId: dpSiteValue,
      };
      const reqInstance = axios.create({
        baseURL: backendURL,
      });
      reqInstance
        .post("/getAllSiteBuilding", reqBody)
        .then((response_data) => {
          if (response_data) {
            const result = response_data.data;
            setAssetBuilding([
              { buildingId: 0, name: "- Select -" },
              ...result,
            ]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [dpSiteValue]);
  useEffect(() => {
    if (dpBuildingValue > 0) {
      const reqBody = {
        clientId: loggedUser.clientId,
        orgId: loggedUser.orgId,
        userId: loggedUser.userId,
        moduleId: loggedUser.loggedModuleId,
        buildingId: dpBuildingValue,
      };
      const reqInstance = axios.create({
        baseURL: backendURL,
      });
      reqInstance
        .post("/getAllBuildingFloor", reqBody)
        .then((response_data) => {
          if (response_data) {
            const result = response_data.data;
            setAssetFloor([{ floorId: 0, name: "- Select -" }, ...result]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [dpBuildingValue]);
  useEffect(() => {
    if (dpFloorValue > 0) {
      const reqBody = {
        clientId: loggedUser.clientId,
        orgId: loggedUser.orgId,
        userId: loggedUser.userId,
        moduleId: loggedUser.loggedModuleId,
        siteId: dpSiteValue,
        buildingId: dpBuildingValue,
        floorId: dpFloorValue,
      };
      const reqInstance = axios.create({
        baseURL: backendURL,
      });
      reqInstance
        .post("/getAllSiteBuildingFloorLocation", reqBody)
        .then((response_data) => {
          if (response_data) {
            const result = response_data.data;
            setAssetLocation([
              { locationId: 0, name: "- Select -" },
              ...result,
            ]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [dpFloorValue]);
  useEffect(() => {
    if (assetLocation.length > 1 && assetItemData.assetItemId > 0) {
      setValue("fm_1_sa_location_id", assetItemData.location.locationId);
    }
  }, [assetLocation]);
  useEffect(() => {
    if (assetSubCategory.length > 1 && assetItemData.assetItemId > 0) {
      setValue("fm_1_sa_category_id", assetItemData.category.categoryId);
    }
  }, [assetSubCategory]);

  useEffect(() => {
    if (dpAssetCategoryValue > 0) {
      const reqBody = {
        clientId: loggedUser.clientId,
        orgId: loggedUser.orgId,
        userId: loggedUser.userId,
        moduleId: loggedUser.loggedModuleId,
        assetCategoryId: dpAssetCategoryValue,
      };
      const reqInstance = axios.create({
        baseURL: backendURL,
      });
      reqInstance
        .post("/getAllAssetCategoryMainCategory", reqBody)
        .then((response_data) => {
          if (response_data) {
            const result = response_data.data;
            setAssetMainCategory([
              { categoryId: 0, name: "- Select -" },
              ...result,
            ]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [dpAssetCategoryValue]);
  useEffect(() => {
    if (dpMainCategoryValue > 0) {
      const reqBody = {
        clientId: loggedUser.clientId,
        orgId: loggedUser.orgId,
        userId: loggedUser.userId,
        moduleId: loggedUser.loggedModuleId,
        mainCategoryId: dpMainCategoryValue,
      };
      const reqInstance = axios.create({
        baseURL: backendURL,
      });
      reqInstance
        .post("/getAllAssetCategorySubCategory", reqBody)
        .then((response_data) => {
          if (response_data) {
            const result = response_data.data;
            setAssetSubCategory([
              { categoryId: 0, name: "- Select -" },
              ...result,
            ]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [dpMainCategoryValue]);

  const onSubmit = (data) => {
    const form = new FormData(document.querySelector(".form-1"));
    if (data !== "") {
      const id = toast.loading("Please wait...");
      const reqInstance = axios.create({
        baseURL: backendURL,
      });
      const checkDuplicateReq = {
        assetItemId: assetItemData.assetItemId ? assetItemData.assetItemId : 0,
        clientId: loggedUser.clientId,
        orgId: loggedUser.orgId,
        userId: loggedUser.userId,
        moduleId: loggedUser.loggedModuleId,
        name: form.get("fm_1_name"),
        searchKey: form.get("fm_1_asset_id"),
      };
      reqInstance
        .post("/checkAssetItemExist", checkDuplicateReq)
        .then((check_response) => {
          const checkResponseData = check_response.data;
          if (!checkResponseData.hasError) {
            if (
              checkResponseData.existType === 0 ||
              checkResponseData.existType === -1 ||
              checkResponseData.existType === 2
            ) {
              const assetItem = {
                assetItemId: assetItemData.assetItemId
                  ? assetItemData.assetItemId
                  : 0,
                clientId: loggedUser.clientId,
                orgId: loggedUser.orgId,
                userId: loggedUser.userId,
                moduleId: loggedUser.loggedModuleId,
                categoryId: 12,
                searchKey: form.get("fm_1_asset_id"),
                name: form.get("fm_1_name"),
                purchasedFrom: form.get("fm_1_purchased_from"),
                referenceNo: form.get("fm_1_reference_no"),
                brand: form.get("fm_1_brand"),
                purchaseDate: ConvertDateToDate(form.get("fm_1_purchase_date")),
                model: form.get("fm_1_model"),
                costPerItem: form.get("fm_1_cost"),
                currencyId: parseFloat(form.get("fm_1_cost"))
                  ? currency.currencyId
                  : 0,
                serialNo: form.get("fm_1_serial_no"),
                capitalizationDate: ConvertDateToDate(
                  form.get("fm_1_purchase_date")
                ),
                conditionId: form.get("fm_1_a_asset_condition_id"),
                siteId: form.get("fm_1_a_asset_site_id"),
                buildingId: form.get("fm_1_sa_fo_building_id"),
                floorId: form.get("fm_1_sa_fo_floor_id"),
                locationId: form.get("fm_1_sa_location_id"),
                assetCategoryId: form.get("fm_1_a_asset_category_id"),
                subCategoryId: form.get("fm_1_sa_category_id"),
                ownershipDepartmentId: form.get(
                  "fm_1_sa_ownership_department_id"
                ),
                existDepartmentId: form.get("fm_1_sa_exist_department_id"),
                printName: form.get("fm_1_print_name"),
                isActivate: form.get("fm_1_is_activate") ? true : false,
                isActive: form.get("fm_1_active") ? true : false,
                isBulk: form.get("fm_1_is_bulk") ? false : true,
                isDepreciable: parseInt(form.get("fm_1_depreciable_asset"))
                  ? true
                  : false,
                remark: "",
              };
              reqInstance
                .post("/saveAssetItem", assetItem)
                .then((response_data) => {
                  const result = response_data.data;
                  if (result.hasError) {
                    toast.update(id, {
                      render: "Failed",
                      type: "error",
                      isLoading: false,
                      position: "top-right",
                      autoClose: 5000,
                      hideProgressBar: true,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                      theme: "light",
                    });
                  } else {
                    let successMessage = "";
                    if (assetItemData.assetItemId > 0) {
                      successMessage = "Asset Item Successfully Updated.";
                    } else {
                      successMessage = "Asset Item Successfully Added.";
                    }
                    toast.update(id, {
                      render: successMessage,
                      type: "success",
                      isLoading: false,
                      position: "top-right",
                      autoClose: 5000,
                      hideProgressBar: true,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                      theme: "light",
                    });
                    // delete images
                    if (assetItemData.assetItemId > 0) {
                      let existImages = assetItemData.assetImageList;
                      console.log(existImages);
                      if (existImages.length > 0) {
                        let deletedImages = [];
                        existImages.forEach((deleted_image) => {
                          let deletedImage = attachmentList.filter(
                            (image_item) =>
                              image_item.documentId ===
                              deleted_image.assetImageId
                          );
                          console.log(deletedImage);
                          if (deletedImage.length === 0) {
                            deletedImages.push(deleted_image);
                          }
                        });
                        if (deletedImages.length > 0) {
                          deletedImages.forEach((image_to_delete) => {
                            const assetImage = {
                              assetImageId: image_to_delete.assetImageId,
                              clientId: loggedUser.clientId,
                              orgId: loggedUser.orgId,
                              userId: loggedUser.userId,
                            };
                            reqInstance
                              .post("/deleteAssetImage", assetImage)
                              .then((image_response_data) => {
                                const uploadResult = response_data.data;
                                //
                              });
                          });
                        }
                      }
                    }
                    // add images
                    let modifiedAttachmentListSize = [];
                    if (attachmentList.length > 0) {
                      modifiedAttachmentListSize = attachmentList.filter(
                        (image_item) => image_item.isModified
                      );
                      if (modifiedAttachmentListSize.length > 0) {
                        const statusToast = toast.loading(
                          `Uploading ${attachmentList.length} Attachment(s).`
                        );
                        attachmentList.forEach((attachment_item) => {
                          if (attachment_item.isModified) {
                            let imageData = new FormData();
                            imageData.append(
                              "assetImageId",
                              parseInt(attachment_item.documentId)
                            );
                            imageData.append("clientId", loggedUser.clientId);
                            imageData.append("orgId", loggedUser.orgId);
                            imageData.append("userId", loggedUser.userId);
                            imageData.append(
                              "assetItemId",
                              assetItemData.assetItemId
                                ? assetItemData.assetItemId
                                : result.assetItemId
                            );
                            imageData.append("isActive", "true");
                            imageData.append(
                              "isAttachmentModified",
                              attachment_item.isModified
                            );
                            imageData.append(
                              "attachmentExtension",
                              attachment_item.attachmentExtension
                            );
                            imageData.append(
                              "attachment",
                              attachment_item.attachment
                            );
                            imageData.append("name", "");
                            imageData.append("remark", "");
                            reqInstance
                              .post("/saveAssetImage", imageData, {
                                headers: {
                                  "Content-Type": "multipart/form-data",
                                },
                              })
                              .then((image_response_data) => {
                                const uploadResult = response_data.data;
                                //
                                if (!uploadResult.hasError) {
                                  toast.update(statusToast, {
                                    render: "Attachments Uploaded.",
                                    type: "success",
                                    isLoading: false,
                                    position: "top-right",
                                    autoClose: 5000,
                                    hideProgressBar: true,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                                  });
                                }
                              });
                          }
                        });
                        toast.update(statusToast, {
                          render: "Attachments Uploaded.",
                          type: "success",
                          isLoading: false,
                          position: "top-right",
                          autoClose: 5000,
                          hideProgressBar: true,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                          theme: "light",
                        });
                      }
                    }
                    // refresh item
                    assetContext.refreshAssetItem();
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
            } else {
              console.log(check_response);
              if (checkResponseData.existType === 1) {
                toast.update(id, {
                  render: "Asset Id Exists",
                  type: "warning",
                  isLoading: false,
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                });
              } else if (checkResponseData.existType === 2) {
                toast.update(id, {
                  render: "Asset Name Exists",
                  type: "warning",
                  isLoading: false,
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                });
              } else if (checkResponseData.existType === 3) {
                toast.update(id, {
                  render: "Site Search Key and Name Exists",
                  type: "warning",
                  isLoading: false,
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                });
              }
            }
          }
        });
    } else {
      errors.showMessages();
    }
  };

  const popuplatPrintName = (value) => {
    const nameValue = watch("fm_1_name");
    if (assetItemData.assetItemId === 0) {
      setValue("fm_1_print_name", nameValue);
    }
  };

  return (
    <Fragment>
      <Breadcrumbs mainTitle="Asset" parent="Asset" title="Add Asset" />
      <Container fluid={true} className="p-relative">
        <Row>
          <Col sm="12">
            <Card>
              <HeaderCard
                title={`${assetItemData.assetItemId ? "Update" : "Add"} Asset`}
                span1="User has facilitated to add / update Asset Item related details in this interface."
              />
              <CardBody>
                <Form className="form-1" onSubmit={handleSubmit(onSubmit)}>
                  {/* Start of Details Section */}
                  <Row className="justify-content-end">
                    <Col lg="6" className="d-flex">
                      <Col lg="4">
                        <Label
                          className="col-form-label m-r-10"
                          htmlFor="input_fm_1_is_bulk"
                        >
                          Fixed Asset
                        </Label>
                      </Col>
                      <Col lg="8">
                        <Label className="switch px-2">
                          <input
                            type="checkbox"
                            id="input_fm_1_is_bulk"
                            name="fm_1_is_bulk"
                            defaultChecked
                            {...register("fm_1_is_bulk")}
                          />
                          <span className="switch-state"></span>
                        </Label>
                      </Col>
                    </Col>
                  </Row>
                  <Row className="m-b-10">
                    <Col md="2">
                      <Label
                        htmlFor="input_fm_1_name"
                        className="required-input-mark"
                      >
                        Item Name
                      </Label>
                    </Col>
                    <Col md="10">
                      <input
                        id="input_fm_1_name"
                        className="form-control"
                        name="fm_1_name"
                        type="text"
                        placeholder="Enter Item Name"
                        onKeyDownCapture={() => popuplatPrintName()}
                        {...register("fm_1_name", {
                          required: "Please Enter Item Name",
                        })}
                      />
                      <span className="txt-danger f-12">
                        {errors?.fm_1_name && errors?.fm_1_name?.message}
                      </span>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="2">
                      <Label
                        htmlFor="input_fm_1_asset_id"
                        className="required-input-mark"
                      >
                        {"Asset Id"}
                      </Label>
                    </Col>
                    <Col md="4" className="m-b-10">
                      <input
                        id="input_fm_1_asset_id"
                        name="fm_1_asset_id"
                        className="form-control"
                        type="text"
                        placeholder="Enter Asset Id"
                        {...register("fm_1_asset_id", {
                          required: "Please Enter Asset Id",
                        })}
                      />
                      <span className="txt-danger f-12">
                        {errors?.fm_1_asset_id &&
                          errors?.fm_1_asset_id?.message}
                      </span>
                    </Col>
                    <Col md="2">
                      <Label htmlFor="input_fm_1_purchased_from">
                        {"Purchased From"}
                      </Label>
                    </Col>
                    <Col md="4" className="m-b-10">
                      <input
                        id="input_fm_1_purchased_from"
                        className="form-control"
                        name="fm_1_purchased_from"
                        type="text"
                        placeholder="Purchased From"
                        {...register("fm_1_purchased_from")}
                      />
                      <span className="txt-danger f-12">
                        {errors?.fm_1_purchased_from &&
                          errors?.fm_1_purchased_from?.message}
                      </span>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="2">
                      <Label htmlFor="input_fm_1_reference_no">
                        {"Reference No."}
                      </Label>
                    </Col>
                    <Col md="4" className="m-b-10">
                      <input
                        id="input_fm_1_reference_no"
                        className="form-control"
                        name="fm_1_reference_no"
                        type="text"
                        placeholder="Reference No."
                        {...register("fm_1_reference_no")}
                      />
                      <span className="txt-danger f-12">
                        {errors?.fm_1_reference_no &&
                          errors?.fm_1_reference_no?.message}
                      </span>
                    </Col>
                    <Col md="2">
                      <Label htmlFor="input_fm_1_brand">{"Brand"}</Label>
                    </Col>
                    <Col md="4" className="m-b-10">
                      <input
                        id="input_fm_1_brand"
                        className="form-control"
                        name="fm_1_brand"
                        type="text"
                        placeholder="Brand"
                        {...register("fm_1_brand")}
                      />
                      <span className="txt-danger f-12">
                        {errors?.fm_1_brand && errors?.fm_1_brand?.message}
                      </span>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="2">
                      <Label htmlFor="input_fm_1_purchase_date">
                        {"Purchase Date"}
                      </Label>
                    </Col>
                    <Col md="4" className="m-b-10">
                      {/* <DatePicker
                        id="input_fm_1_purchase_date"
                        name="fm_1_purchase_date"
                        className="form-control digits"
                        // selected={startDate}
                        onChange={(evt) => handleChange(evt)}
                        {...register("fm_1_purchase_date")}
                      /> */}
                      <input
                        id="input_fm_1_purchase_date"
                        name="fm_1_purchase_date"
                        type="date"
                        className="form-control"
                        {...register("fm_1_purchase_date")}
                      />
                    </Col>
                    <Col md="2">
                      <Label htmlFor="input_fm_1_model">{"Model"}</Label>
                    </Col>
                    <Col md="4" className="m-b-10">
                      <input
                        id="input_fm_1_model"
                        className="form-control"
                        name="fm_1_model"
                        type="text"
                        placeholder="Model"
                        {...register("fm_1_model")}
                      />
                      <span className="txt-danger f-12">
                        {errors?.fm_1_model && errors?.fm_1_model?.message}
                      </span>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="2">
                      <Label htmlFor="input_fm_1_cost">{"Cost"}</Label>
                    </Col>{" "}
                    <Col md="4" className="m-b-10">
                      <div className="input-group mb-0">
                        <span className="input-group-text" id="basic-addon1">
                          {currency?.searchKey}
                        </span>
                        <input
                          id="input_fm_1_cost"
                          type="text"
                          className="form-control"
                          name="fm_1_cost"
                          placeholder="00.00"
                          {...register("fm_1_cost", {
                            pattern: /^\d+(\.\d+)?$/,
                          })}
                        />
                      </div>
                      <span className="txt-danger f-12">
                        {errors?.fm_1_cost && errors?.fm_1_cost?.message}
                      </span>
                    </Col>{" "}
                    <Col md="2">
                      <Label htmlFor="input_fm_1_serial_no">
                        {"Serial No."}
                      </Label>
                    </Col>
                    <Col md="4">
                      <input
                        id="input_fm_1_serial_no"
                        className="form-control"
                        name="fm_1_serial_no"
                        type="text"
                        placeholder="Serial No."
                        {...register("fm_1_serial_no")}
                      />
                      <span className="txt-danger f-12">
                        {errors?.fm_1_serial_no &&
                          errors?.fm_1_serial_no?.message}
                      </span>{" "}
                    </Col>
                  </Row>
                  <Row className="m-b-30">
                    <Col md="2">
                      <Label htmlFor="fm_1_capitalization_date">
                        {"Capitalization Date"}
                      </Label>
                    </Col>
                    <Col md="4" className="m-b-10">
                      <input
                        id="input_fm_1_capitalization_date"
                        name="fm_1_capitalization_date"
                        type="date"
                        className="form-control"
                        {...register("fm_1_capitalization_date")}
                      />
                      {/* <DatePicker
                        id="input_fm_1_capitalization_date"
                        name="fm_1_capitalization_date"
                        className="form-control digits"
                        // selected={startDate}
                        onChange={handleChange}
                        {...register("fm_1_capitalization_date")}
                      /> */}
                    </Col>
                    <Col md="2">
                      <Label htmlFor="id_input_fm_1_a_asset_condition_id">
                        Condition
                      </Label>
                    </Col>
                    <Col md="4" className="m-b-10">
                      <select
                        id="id_input_fm_1_a_asset_condition_id"
                        name="fm_1_a_asset_condition_id"
                        className={`form-select ${
                          formDataLoaded ? "" : "disabled"
                        }`}
                        {...register("fm_1_a_asset_condition_id")}
                      >
                        {assetCondition.length
                          ? assetCondition.map((list_item, index) => (
                              <option
                                value={list_item?.assetConditionId}
                                key={index}
                              >
                                {list_item?.name}
                              </option>
                            ))
                          : null}
                      </select>
                      <span className="txt-danger f-12">
                        {errors?.fm_1_sa_asset_condition_id &&
                          errors?.fm_1_sa_asset_condition_id?.message}
                      </span>
                    </Col>
                  </Row>
                  {/* End of Details Section */}
                  {/* Start of Location Details */}
                  <Row>
                    <Col md="2">
                      <Label
                        htmlFor="input_fm_1_a_asset_site_id"
                        className="required-input-mark"
                      >
                        {"Site"}
                      </Label>
                    </Col>
                    <Col md="4" className="m-b-10">
                      <select
                        id="input_fm_1_a_asset_site_id"
                        name="fm_1_a_asset_site_id"
                        className={`form-select ${
                          formDataLoaded ? "" : "disabled"
                        }`}
                        {...register("fm_1_a_asset_site_id", {
                          required: "Please select Site",
                        })}
                        onChange={(evt) => setDpSiteValue(evt.target.value)}
                        value={dpSiteValue}
                      >
                        {assetSite.length
                          ? assetSite.map((list_item, index) => (
                              <option
                                value={list_item?.assetSiteId}
                                key={index}
                              >
                                {list_item?.name}
                              </option>
                            ))
                          : null}
                      </select>
                      <span className="txt-danger f-12">
                        {errors?.fm_1_a_asset_site_id &&
                          errors?.fm_1_a_asset_site_id?.message}
                      </span>
                    </Col>
                    <Col md="2">
                      <Label
                        htmlFor="input_fm_1_a_asset_category_id"
                        className="required-input-mark"
                      >
                        {"Asset Category"}
                      </Label>
                    </Col>
                    <Col md="4" className="m-b-10">
                      <select
                        id="input_fm_1_a_asset_category_id"
                        name="fm_1_a_asset_category_id"
                        className={`form-select ${
                          formDataLoaded ? "" : "disabled"
                        }`}
                        {...register("fm_1_a_asset_category_id", {
                          required: "Please select Asset Category",
                        })}
                        onChange={(evt) =>
                          setDpAssetCategoryValue(evt.target.value)
                        }
                        value={dpAssetCategoryValue}
                      >
                        {assetCategory.length
                          ? assetCategory.map((list_item, index) => (
                              <option
                                value={list_item?.assetCategoryId}
                                key={index}
                              >
                                {list_item?.name}
                              </option>
                            ))
                          : null}
                      </select>
                      <span className="txt-danger f-12">
                        {errors?.fm_1_a_asset_category_id &&
                          errors?.fm_1_a_asset_category_id?.message}
                      </span>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="2">
                      <Label
                        htmlFor="input_fm_1_building_id"
                        className="required-input-mark"
                      >
                        {"Building"}
                      </Label>
                    </Col>
                    <Col md="4" className="m-b-10">
                      <select
                        id="id_input_fm_1_sa_fo_building_id"
                        name="fm_1_sa_fo_building_id"
                        className={`form-select ${
                          assetBuilding.length ? "" : "disabled"
                        }`}
                        {...register("fm_1_sa_fo_building_id", {
                          required: "Please select Building",
                        })}
                        onChange={(evt) => setDpBuildingValue(evt.target.value)}
                        value={dpBuildingValue}
                      >
                        {assetBuilding.length
                          ? assetBuilding.map((list_item, index) => (
                              <option value={list_item?.buildingId} key={index}>
                                {list_item?.name}
                              </option>
                            ))
                          : null}
                      </select>
                      <span className="txt-danger f-12">
                        {errors?.fm_1_sa_fo_building_id &&
                          errors?.fm_1_sa_fo_building_id?.message}
                      </span>
                    </Col>
                    <Col md="2">
                      <Label
                        htmlFor="id_input_fm_1_sa_main_category_id"
                        className="required-input-mark"
                      >
                        {"Category"}
                      </Label>
                    </Col>
                    <Col md="4" className="m-b-10">
                      <select
                        id="id_input_fm_1_sa_main_category_id"
                        name="fm_1_sa_main_category_id"
                        className={`form-select ${
                          assetMainCategory.length ? "" : "disabled"
                        }`}
                        {...register("fm_1_sa_main_category_id", {
                          required: "Please select Category",
                        })}
                        onChange={(evt) =>
                          setDpMainCategoryValue(evt.target.value)
                        }
                        value={dpMainCategoryValue}
                      >
                        {assetMainCategory.length
                          ? assetMainCategory.map((list_item, index) => (
                              <option value={list_item?.categoryId} key={index}>
                                {list_item?.name}
                              </option>
                            ))
                          : null}
                      </select>
                      <span className="txt-danger f-12">
                        {errors?.fm_1_sa_main_category_id &&
                          errors?.fm_1_sa_main_category_id?.message}
                      </span>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="2">
                      <Label
                        htmlFor="input_fm_1_sa_fo_floor_id"
                        className="required-input-mark"
                      >
                        {"Floor"}
                      </Label>
                    </Col>
                    <Col md="4" className="m-b-10">
                      <select
                        id="input_fm_1_sa_fo_floor_id"
                        name="fm_1_sa_fo_floor_id"
                        className={`form-select ${
                          assetFloor.length ? "" : "disabled"
                        }`}
                        {...register("fm_1_sa_fo_floor_id", {
                          required: "Please select Floor",
                        })}
                        onChange={(evt) => setDpFloorValue(evt.target.value)}
                        value={dpFloorValue}
                      >
                        {assetFloor.length
                          ? assetFloor.map((list_item, index) => (
                              <option value={list_item?.floorId} key={index}>
                                {list_item?.name}
                              </option>
                            ))
                          : null}
                      </select>
                      <span className="txt-danger f-12">
                        {errors?.fm_1_sa_fo_floor_id &&
                          errors?.fm_1_sa_fo_floor_id?.message}
                      </span>
                    </Col>
                    <Col md="2">
                      <Label
                        htmlFor="id_input_fm_1_sa_category_id"
                        className="required-input-mark"
                      >
                        {"Sub Category"}
                      </Label>
                    </Col>
                    <Col md="4" className="m-b-10">
                      <select
                        id="id_input_fm_1_sa_category_id"
                        name="fm_1_sa_category_id"
                        className={`form-select ${
                          assetSubCategory.length ? "" : "disabled"
                        }`}
                        {...register("fm_1_sa_category_id", {
                          required: "Please select Category",
                        })}
                      >
                        {assetSubCategory.length
                          ? assetSubCategory.map((list_item, index) => (
                              <option value={list_item?.categoryId} key={index}>
                                {list_item?.name}
                              </option>
                            ))
                          : null}
                      </select>
                      <span className="txt-danger f-12">
                        {errors?.fm_1_sa_category_id &&
                          errors?.fm_1_sa_category_id?.message}
                      </span>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="2">
                      <Label
                        htmlFor="input_fm_1_sa_location_id"
                        className="required-input-mark"
                      >
                        {"Location"}
                      </Label>
                    </Col>
                    <Col md="4" className="m-b-10">
                      <select
                        id="input_fm_1_sa_location_id"
                        name="fm_1_sa_location_id"
                        className={`form-select ${
                          assetLocation.length ? "" : "disabled"
                        }`}
                        {...register("fm_1_sa_location_id", {
                          required: "Please select Location",
                        })}
                      >
                        {assetLocation.length
                          ? assetLocation.map((list_item, index) => (
                              <option value={list_item?.locationId} key={index}>
                                {list_item?.name}
                              </option>
                            ))
                          : null}
                      </select>
                      <span className="txt-danger f-12">
                        {errors?.fm_1_sa_location_id &&
                          errors?.fm_1_sa_location_id?.message}
                      </span>
                    </Col>
                    <Col md="2">
                      <Label
                        htmlFor="id_input_fm_1_sa_ownership_department_id"
                        className="required-input-mark"
                      >
                        Ownership Department
                      </Label>
                    </Col>
                    <Col md="4" className="m-b-10">
                      <select
                        id="id_input_fm_1_sa_ownership_department_id"
                        name="fm_1_sa_ownership_department_id"
                        className={`form-select ${
                          formDataLoaded ? "" : "disabled"
                        }`}
                        {...register("fm_1_sa_ownership_department_id", {
                          required: "Please select Ownership Department",
                        })}
                      >
                        {assetDepartment.length
                          ? assetDepartment.map((list_item, index) => (
                              <option
                                value={list_item?.departmentId}
                                key={index}
                              >
                                {list_item?.name}
                              </option>
                            ))
                          : null}
                      </select>
                      <span className="txt-danger f-12">
                        {errors?.fm_1_sa_ownership_department_id &&
                          errors?.fm_1_sa_ownership_department_id?.message}
                      </span>
                    </Col>
                  </Row>
                  <Row className="m-b-30 justify-content-end">
                    <Col md="2">
                      <Label
                        htmlFor="id_input_fm_1_sa_exist_department_id"
                        className="required-input-mark"
                      >
                        Exist Department
                      </Label>
                    </Col>
                    <Col md="4" className="m-b-10">
                      <select
                        id="id_input_fm_1_sa_exist_department_id"
                        name="fm_1_sa_exist_department_id"
                        className={`form-select ${
                          formDataLoaded ? "" : "disabled"
                        }`}
                        {...register("fm_1_sa_exist_department_id", {
                          required: "Please select Exist Department",
                        })}
                      >
                        {assetDepartment.length
                          ? assetDepartment.map((list_item, index) => (
                              <option
                                value={list_item?.departmentId}
                                key={index}
                              >
                                {list_item?.name}
                              </option>
                            ))
                          : null}
                      </select>
                      <span className="txt-danger f-12">
                        {errors?.fm_1_sa_exist_department_id &&
                          errors?.fm_1_sa_exist_department_id?.message}
                      </span>
                    </Col>
                  </Row>
                  {/* End of Location Details */}
                  {/* Start of Print Details */}
                  <Row>
                    <Col md="2">
                      <Label htmlFor="input_fm_1_print_name">Print Name</Label>
                    </Col>
                    <Col md="4" className="m-b-10">
                      <input
                        id="input_fm_1_print_name"
                        name="fm_1_print_name"
                        className="form-control"
                        type="text"
                        placeholder="Print Name"
                        {...register("fm_1_print_name")}
                      />
                    </Col>
                    <Col md="6">
                      <Media>
                        <Col lg="4">
                          <Label
                            className="col-form-label m-r-10"
                            htmlFor="input_fm_1_is_activate"
                          >
                            Activate
                          </Label>
                        </Col>
                        <Col lg="8">
                          <Media body className="text-start">
                            <Label className="switch mx-2">
                              <input
                                id="input_fm_1_is_activate"
                                name="fm_1_is_activate"
                                type="checkbox"
                                {...register("fm_1_is_activate")}
                                defaultChecked
                              />
                              <span className="switch-state"></span>
                            </Label>
                          </Media>
                        </Col>
                      </Media>
                    </Col>
                  </Row>
                  <Row className="m-b-30 m-t-10">
                    <Col lg="2" className="offset-lg-2">
                      <Label className="d-block" htmlFor="input_fm_1_active">
                        <input
                          className="checkbox_animated"
                          id="input_fm_1_active"
                          name="fm_1_active"
                          type="checkbox"
                          defaultChecked
                          disabled={isDisposedItem}
                          {...register("fm_1_active")}
                        />
                        Active
                      </Label>
                    </Col>
                  </Row>
                  {/* End of Print Details */}
                  {/* Start of Image Details */}
                  <ImageUploader
                    attachment_list={attachmentList}
                    preview_list={previewFileList}
                    set_attachment_list={setAttachmentList}
                    set_preview_list={setPreviewFileList}
                    max_file_limit={1}
                    max_file_size={5242880}
                  />
                  {/* End of Image Details */}
                  {/* Start of Depreciation Details */}
                  <Row className="m-t-30">
                    <Col sm="12" md="2">
                      <Label htmlFor="input_fm_1_depreciable_asset">
                        {"Depreciable Asset"}
                      </Label>
                    </Col>
                    <Col xs="12" sm="12" md="4" className="d-flex m-b-10">
                      <Col xs="6" sm="6">
                        <Label
                          className="d-flex align-items-center mb-0"
                          htmlFor="input_fm_1_depreciable_asset_yes"
                        >
                          <input
                            className="radio_animated"
                            id="input_fm_1_depreciable_asset_yes"
                            type="radio"
                            name="fm_1_depreciable_asset"
                            value={1}
                            {...register("fm_1_depreciable_asset")}
                          />
                          Yes
                        </Label>
                      </Col>
                      <Col xs="6" sm="6">
                        <Label
                          className="d-flex align-items-center mb-0"
                          htmlFor="input_fm_1_depreciable_asset_yes"
                        >
                          <input
                            className="radio_animated m-l-5"
                            id="input_fm_1_depreciable_asset_no"
                            type="radio"
                            name="fm_1_depreciable_asset"
                            value={0}
                            {...register("fm_1_depreciable_asset")}
                          />
                          No
                        </Label>
                      </Col>
                    </Col>
                  </Row>
                  {/* <Row>
                    <Col md="2">
                      <Label htmlFor="input_fm_1_depreciable_cost">
                        {"Depreciable Cost"}
                      </Label>
                    </Col>
                    <Col lg="4" className="m-b-10">
                      <div className="input-group mb-0">
                        <span className="input-group-text" id="basic-addon1">
                          $
                        </span>
                        <input
                          id="input_fm_1_depreciable_cost"
                          name="fm_1_depreciable_cost"
                          type="text"
                          className="form-control"
                          placeholder="00.00"
                          aria-label="Salvage Value"
                          aria-describedby="basic-addon1"
                          {...register("depreciableCost", {
                            pattern: /^\d+(\.\d+)?$/,
                          })}
                        />
                      </div>
                      <span className="txt-danger f-12">
                        {errors.depreciableCost && (
                          <span>Please Enter an Integer or Decimal Value</span>
                        )}
                      </span>
                      <div className="valid-feedback">{"Looks good!"}</div>
                    </Col>
                    <Col md="2">
                      <Label htmlFor="input_fm_1_asset_list">
                        {"Asset Life"}
                      </Label>
                    </Col>
                    <Col md="4" className="m-b-10">
                      <input
                        id="input_fm_1_asset_list"
                        className="form-control"
                        name="fm_1_asset_list"
                        type="text"
                        placeholder="Asset Life"
                        {...register("assetLife")}
                      />
                      <div className="valid-feedback">{"Looks good!"}</div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="2">
                      <Label htmlFor="input_fm_1_salvage_value">
                        {"Salvage Value"}
                      </Label>
                    </Col>
                    <Col md="4" className="m-b-10">
                      <div className="input-group mb-0">
                        <span className="input-group-text" id="basic-addon1">
                          $
                        </span>
                        <input
                          id="input_fm_1_salvage_value"
                          name="fm_1_salvage_value"
                          type="text"
                          className="form-control"
                          placeholder="00.00"
                          aria-label="Salvage Value"
                          aria-describedby="basic-addon1"
                          {...register("salvageValue", {
                            pattern: /^\d+(\.\d+)?$/,
                          })}
                        />
                      </div>
                      <span className="txt-danger f-12">
                        {errors.salvageValue && (
                          <span>Please Enter an Integer or Decimal Value</span>
                        )}
                      </span>
                      <div className="valid-feedback">{"Looks good!"}</div>
                    </Col>
                    <Col md="2">
                      <Label htmlFor="input_fm_1_depreciation_method">
                        {"Depreciation Method"}
                      </Label>
                    </Col>
                    <Col md="4" className="m-b-10">
                      <select
                        id="input_fm_1_depreciation_method"
                        name="fm_1_depreciation_method"
                        className="form-select"
                        {...register("depreciationMethod")}
                      ></select>
                      <div className="valid-feedback">{"Looks good!"}</div>
                    </Col>
                  </Row>
                  <Row className="justify-content-end m-b-30">
                    <Col md="2">
                      <Label htmlFor="input_fm_1_date_acquired">
                        {"Date Acquired"}
                      </Label>
                    </Col>
                    <Col md="4">
                       <input
                        id="input_fm_1_date_acquired"
                        name="fm_1_date_acquired"
                        type="date"
                        className="form-control"
                        {...register("fm_1_date_acquired")}
                      />
                    </Col>
                  </Row> */}
                  {/* End of Depreciation Details */}
                  <div className="d-flex justify-content-end">
                    <Button type="submit" color="primary">
                      {"Submit form"}
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default NewAsset;
