
import React, { useRef, useState } from "react";
import Swal from "sweetalert2";
import { imageFileURL, MAX_ASSET_FILE_SIZE } from "../../../../Config/Config.jsx";
import * as feather from "feather-icons";
import { Container, Row, Col, Form, Label, Input } from "reactstrap";


const FileUploader = ({
  attachment_list,
  preview_list,
  set_attachment_list,
  set_preview_list,
  max_file_limit,
}) => {
  const fileValidationMessage = useRef("");
  const fileInputRef = useRef(null);
  const [selectedFileName, setSelectedFileName] = useState("");

  const handleRemove = (preview_item, index) => {
    let removingItem = undefined;
    if (fileInputRef.current.classList.contains("is-invalid")) {
      fileInputRef.current.classList.remove("is-invalid");
    }

    if (preview_item.id === 0) {
      removingItem = attachment_list.find(
        (item) => item.attachment === preview_item.source
      );
    } else {
      Swal.fire({
        icon: "warning",
        title: "Are you sure?",
        text: "This will permanently delete this file from the server.",
        showDenyButton: true,
        confirmButtonText: "Delete",
        denyButtonText: "Cancel",
        denyButtonColor: "#2a3142",
      }).then((result) => {
        if (result.isConfirmed) {
          removingItem = attachment_list.find(
            (item) => item.documentId === preview_item.id
          );
        }
      });
    }

    if (removingItem !== undefined) {
      let tempAttachments = attachment_list;
      tempAttachments.splice(attachment_list.indexOf(removingItem), 1);
      let tempPreviews = preview_list;
      tempPreviews.splice(index, 1);
      set_attachment_list([...tempAttachments]);
      set_preview_list([...tempPreviews]);
    }
  };

  const handleFileUpload = (event) => {
    const allowedFileTypes = [
      "application/pdf",
      "application/vnd.ms-excel",
      "text/csv",
    ];
    const maxFileSize = MAX_ASSET_FILE_SIZE;

    const selectedFile = event.target.files[0];

    if (
      selectedFile &&
      allowedFileTypes.includes(selectedFile.type) &&
      selectedFile.size <= maxFileSize
    ) {
      setSelectedFileName(selectedFile.name);
      set_preview_list((prevList) => [
        ...prevList,
        {
          id: 0,
          type: selectedFile.type,
          source: selectedFile,
        },
      ]);
      set_attachment_list((prev_list_items) => [
        ...prev_list_items,
        {
          documentId: 0,
          isModified: true,
          attachmentExtension: selectedFile.name.split(".").pop(),
          attachment: selectedFile, // multipart form data
        },
      ]);
    } else {
      setSelectedFileName("No File Chosen");
      fileValidationMessage.current.textContent =
        "Invalid file type or size. Allowed types: PDF, Excel, CSV; Max size: 50MB";
      fileInputRef.current.classList.add("is-invalid");
    }
  };

  return (
    // <div className="form-group mb-4">
    <Row>
    <Col lg="2">
      <label className="required-input-mark" >Files{max_file_limit > 1 ? "s" : ""}</label>
    </Col> 
    {/* <div className="border rounded p-4 form-control"> */}
        {/* previewer */}
        <Col lg="10" className="m-b-10 border rounded p-4 ">
        <div className="form-row">
          {preview_list.map((list_item, index) => (
            <div
              className={`col-12 col-sm-5 m-2 py-2 px-2 d-flex align-items-center justify-content-between bg-light rounded`}
              key={index}
            >
              {/* Display file preview */}
              {list_item.type === "application/pdf" ? (
                <embed
                  src={URL.createObjectURL(list_item.source)}
                  width="200"
                  height="200"
                />
              ) : (
                <span>Preview not available</span>
              )}

              <div
                dangerouslySetInnerHTML={{
                  __html: feather.icons["trash-2"].toSvg({
                    height: "14px",
                    width: "14px",
                  }),
                }}
                className="txt-danger bg-light-danger px-2 py-2 b-r-4 d-flex align-items-center m-r-10"
                onClick={() => handleRemove(list_item, index)}
              />
            </div>
          ))}
        </div>
        {/* input handler */}
        <div className="input-group">
          <div className="custom-file">
            <input
              ref={fileInputRef}
              type="file"
              accept=".pdf, .xls, .xlsx, .csv"
              className="custom-file-input"
              onChange={(e) => handleFileUpload(e)}
              required // Make the input mandatory
            />
            <label className="custom-file-label">{selectedFileName}</label>
          </div>
          <div className="input-group-append">
            <button
              className="btn btn-outline-primary"
              type="button"
              onClick={() => fileInputRef.current.click()}
            >
              Choose File
            </button>
          </div>
        </div>
        </Col>
        <Col lg="2"></Col>
        <Col lg="10">
      <div ref={fileValidationMessage} className="invalid-feedback"></div>
      <small className="txt-warning">
        <ul className="d-sm-flex">
          <li>1. Allowed file types: PDF, Excel, CSV</li>
          <li className="mx-sm-3">2. Max file size: 50MB</li>
          <li>3. You can upload up to {max_file_limit} files.</li>
        </ul>
      </small>
      </Col>
   </Row>
   //</div> 
  );
};

export default FileUploader;
