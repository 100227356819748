import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { Container } from "reactstrap";
import { Image, Btn } from "../../../../../AbstractElements";
import ImageViewer from "../../../../Common/Component/ImageViewer";
import * as feather from "feather-icons";
import AssetViewAddImage from "./AssetViewAddImage";
import { backendURL, imageFileURL } from "../../../../../Config/Config";
import axios from "axios";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import UserContext from "../../../../../_helper/User";

const AssetViewImages = ({ asset_item_data_context, item_context }) => {
  const userContext = useContext(UserContext);
  const loggedUser = userContext.userData;
  //
  const [defaultAssetImage, setDefaultAssetImage] = useState(undefined);
  const initilindex = { index: 0, isOpen: false };
  const [photoIndex, setPhotoIndex] = useState(initilindex);
  const [triggerModel, setTriggerModel] = useState(false);
  const [modelData, setModelData] = useState([]);
  console.log(asset_item_data_context.assetImageList);
  const [smallImages, setSmallImages] = useState(
    asset_item_data_context.assetImageList
  );
  const callback = useCallback((photo) => {
    setPhotoIndex(photo);
  });

  const handleDefaultImage = (index, image_id) => {
    const reqBody = {
      assetItemId: asset_item_data_context.assetItemId,
      assetItemImageId: image_id,
      userId: loggedUser.userId,
    };
    const reqInstance = axios.create({
      baseURL: backendURL,
    });
    reqInstance
      .post("/setAssetImageAsDefault", reqBody)
      .then((response_data) => {
        if (response_data) {
          const result = response_data.data;
          setDefaultAssetImage(index);
          item_context.refreshAssetItem();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleDeleteImage = (index, image_id) => {
    Swal.fire({
      icon: "warning",
      title: "Are you sure?",
      text: "This will permanently deletes this Asset Image.",
      showDenyButton: true,
      confirmButtonText: "Delete",
      denyButtonText: "Cancel",
      denyButtonColor: "#2a3142",
    }).then((confirmation) => {
      if (confirmation.isConfirmed) {
        const statusToast = toast.loading(`Deleting image...`);
        const reqBody = {
          assetImageId: image_id,
          clientId: loggedUser.clientId,
          orgId: loggedUser.orgId,
          userId: loggedUser.userId,
        };
        const reqInstance = axios.create({
          baseURL: backendURL,
        });
        reqInstance
          .post("/deleteAssetImage", reqBody)
          .then((response_data) => {
            if (response_data) {
              const result = response_data.data;
              if (result.isSuccess) {
                toast.update(statusToast, {
                  render: "Image Deleted Successfully.",
                  type: "success",
                  isLoading: false,
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                });
              } else {
                toast.update(statusToast, {
                  render: "Delete Failed." + result.errMsg,
                  type: "error",
                  isLoading: false,
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                });
              }
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    });
  };

  useEffect(() => {
    let imageList = smallImages;
    if (imageList?.length > 0) {
      for (let index = 0; index < imageList.length; index++) {
        if (imageList[index].isPrimaryImage === true) {
          setDefaultAssetImage(index);
        }
      }
    }
  }, []);

  return (
    <Fragment>
      <Container>
        <div className="d-flex justify-content-end">
          <Btn
            attrBtn={{
              color: "info",
              size: "sm",
              disabled: false,
              outline: true,
              className: "m-b-15",
              onClick: () => setTriggerModel(true),
            }}
          >
            <span>
              <i className="icofont icofont-plus m-r-10"></i>
            </span>
            Add Image
          </Btn>
        </div>
        <div className="my-gallery row gallery">
          {asset_item_data_context.assetImageList?.length > 0
            ? asset_item_data_context.assetImageList?.map(
                (image_item, index) => (
                  <figure
                    key={index}
                    className="col-xl-3 col-sm-6 p-relative img-hover hover-1"
                  >
                    <Image
                      attrImage={{
                        src: `${imageFileURL}${image_item?.imagePath}`,
                        alt: "Gallery",
                        className: "img-thumbnail",
                      }}
                    />
                    <div
                      className={`p-absolute top-0 start-0 d-flex flex-column ${
                        index === defaultAssetImage
                          ? "justify-content-between"
                          : "justify-content-end"
                      } h-100 w-100 py-2 px-4`}
                      onClick={() =>
                        handleDefaultImage(index, image_item?.assetImageId)
                      }
                    >
                      {index === defaultAssetImage ? (
                        <div className="txt-warning">
                          <i className="fa fa-circle"></i>
                        </div>
                      ) : null}
                      <div className="d-flex justify-content-end">
                        <div
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            handleDeleteImage(index, image_item?.assetImageId)
                          }
                          dangerouslySetInnerHTML={{
                            __html: feather.icons["trash-2"].toSvg({
                              height: "14px",
                              width: "14px",
                            }),
                          }}
                          className="txt-danger bg-light-danger px-2 py-2 b-r-4 d-flex align-items-center m-r-10"
                        />
                        <div
                          dangerouslySetInnerHTML={{
                            __html: feather.icons["maximize"].toSvg({
                              height: "14px",
                              width: "14px",
                            }),
                          }}
                          className="txt-info bg-light-info px-2 py-2 b-r-4 d-flex align-items-center"
                          onClick={() =>
                            setPhotoIndex({
                              ...photoIndex,
                              index: index,
                              isOpen: true,
                            })
                          }
                        />
                      </div>
                    </div>
                  </figure>
                )
              )
            : null}
        </div>
      </Container>
      <ImageViewer
        photoIndex={photoIndex}
        setPhotoIndex={callback}
        images={smallImages}
      />
      <AssetViewAddImage
        is_open={triggerModel}
        trigger_model={setTriggerModel}
        refresh_item={item_context}
      />
    </Fragment>
  );
};

export default AssetViewImages;
