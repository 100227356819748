import React, { useContext, useEffect, useState } from "react";
import CommonModal from "../../../Common/Component/CommonModal";
import { Container, Row, Col, Form, Label, Input } from "reactstrap";
import { useForm } from "react-hook-form";
import { Btn, Image, H5 } from "../../../../AbstractElements";
import axios from "axios";
import { toast } from "react-toastify";
import { adminBackendURL } from "../../../../Config/Config";
import UserContext from "../../../../_helper/User";

const SaveEmployeeModel = ({
  is_open,
  trigger_model,
  model_data,
  form_data,
  reload_table,
}) => {
  const toggle = () => trigger_model(!is_open);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });
  const userContext = useContext(UserContext);
  const loggedUser = userContext.userData;
  const onSubmit = (data) => {
    const form = new FormData(document.querySelector(".form-1"));
    const employee = new FormData();
    if (data !== "") {
      const id = toast.loading("Please wait...");
      employee.append(
        "bpartnerId",
        model_data === "" ? 0 : model_data.bpartnerId
      );
      employee.append("clientId", loggedUser.clientId);
      employee.append("orgId", loggedUser.orgId);
      employee.append("userId", loggedUser.userId);
      employee.append("moduleId", loggedUser.loggedModuleId);
      employee.append("isActive", "true");
      employee.append("firstName", form.get("fm_1_first_name"));
      employee.append("lastName", form.get("fm_1_last_name"));
      employee.append("searchKey", form.get("fm_1_searchkey"));
      employee.append("titleId", parseInt(form.get("fm_1_sa_title_id")));
      employee.append("genderId", 0);
      employee.append("nicNo", form.get("fm_1_nic_no"));
      // employee.append("dateOfBirth", form.get("fm_1_date_of_birth"));
      employee.append("contactNumber", form.get("fm_1_contact_number"));
      employee.append("email", form.get("fm_1_email"));
      employee.append(
        "designationId",
        parseInt(form.get("fm_1_sa_designation_id"))
      );
      employee.append(
        "nationalityId",
        parseInt(form.get("fm_1_sa_nationality_id"))
      );
      employee.append("countryId", parseInt(form.get("fm_1_sa_country_id")));
      employee.append("cityId", 0);
      employee.append("description", "");
      employee.append("remark", form.get("fm_1_remark"));
      employee.append("remark", form.get("fm_1_remark"));
      if (isProfilePictureChange) {
        employee.append("isProfilePictureChange", isProfilePictureChange);
        employee.append("profilePicture", profilePicture);
        employee.append("profilePictureNameExtension", pictureExtension);
      }
      employee.append("remark", form.get("fm_1_remark"));

      const reqInstance = axios.create({
        baseURL: adminBackendURL,
      });
      reqInstance
        .post("/saveEmployee", employee, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response_data) => {
          const result = response_data.data;
          if (result.hasError) {
            toast.update(id, {
              render: "Failed",
              type: "error",
              isLoading: false,
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          } else {
            let successMessage = "";
            if (employee.bpartnerId > 0) {
              successMessage = "Employee Successfully Updated.";
            } else {
              successMessage = "Employee Successfully Added.";
            }
            toast.update(id, {
              render: successMessage,
              type: "success",
              isLoading: false,
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            reload_table(true);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      errors.showMessages();
    }
  };
  const [genderList, setGenderList] = useState([]);
  const [titleList, setTitleList] = useState([]);
  const [departmentList, setDepartmentList] = useState([]);
  const [designationList, setDesignationList] = useState([]);
  const [nationalityList, setNationalityList] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [profilePicture, setProfilePicture] = useState();
  const [isProfilePictureChange, setIsProfilePictureChange] = useState(false);
  const [pictureExtension, setPictureExtension] = useState("");
  const [employeeData, setEmployeeData] = useState(model_data);

  const [url, setUrl] = useState("");

  const readUrl = (event) => {
    if (event.target.files.length === 0) return;
    var mimeType = event.target.files[0].type;

    if (mimeType.match(/image\/*/) == null) {
      return;
    }
    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (_event) => {
      setUrl(reader.result);
      setIsProfilePictureChange(true);
      setProfilePicture(event.target.files[0]);
      setPictureExtension(event.target.files[0].type.split("/")[1]);
    };
  };

  useEffect(() => {
    if (form_data !== undefined) {
      setCountryList([
        { countryId: 0, name: "- Select -" },
        ...form_data.countryList,
      ]);
      setDesignationList([
        { designationId: 0, name: "- Select -" },
        ...form_data.designationList,
      ]);
      setNationalityList([
        { nationalityId: 0, name: "- Select -" },
        ...form_data.nationalityList,
      ]);
      setTitleList([
        { titleId: 0, name: "- Select -" },
        ...form_data.titleList,
      ]);
    }
    if (model_data !== "") {
      if (
        model_data.profilePicturePath === "" ||
        model_data.profilePicturePath === null
      ) {
        setUrl("");
      }
    }
  }, [model_data]);

  const ConvertMillisToDate = (date_in_millis) => {
    /**
     * Convert a millisecond value to date
     * eg:- 390700800000 => 1982-05-20
     *
     * @params {int} date_in_millis - date value in miliseconds
     * @return {string}               date value in yyyy-mm-dd format
     */
    if (!date_in_millis) {
      return "Not Specified";
    } else {
      let date = new Date(parseInt(date_in_millis));
      return `${date.getFullYear()}-${
        date.getMonth() + 1 >= 10
          ? (date.getMonth() + 1).toString()
          : "0" + (date.getMonth() + 1).toString()
      }-${
        date.getDate() > 9 ? date.getDate() : "0" + date.getDate().toString()
      }`;
    }
  };

  return (
    <CommonModal
      isOpen={is_open}
      toggler={toggle}
      bodyClass="grid-showcase"
      size="xl"
    >
      <Container fluid={true}>
        <Row>
          <Col sm="12" className="p-b-15">
            <Form className="form-1" onSubmit={handleSubmit(onSubmit)}>
              <H5 attrH5={{ className: "m-b-30" }}>
                {model_data === "" ? "Add " : "Update "} Employee
              </H5>
              <Row className="m-b-30">
                <div className="user-profile">
                  <div className="hovercard d-flex justify-content-center">
                    <div className="user-image">
                      <div className="avatar m-0">
                        <Image
                          attrImage={{
                            className: "step1",
                            alt: "",
                            src: `${
                              url
                                ? url
                                : require("../../../../assets/images/user/user-dp.png")
                            }`,
                          }}
                        />
                      </div>
                      <div
                        className="icon-wrapper step2"
                        data-intro="Change Profile image here"
                      >
                        <i
                          className="icofont icofont-pencil-alt-5"
                          onChange={(e) => readUrl(e)}
                        >
                          <input
                            className="upload"
                            type="file"
                            onChange={(e) => readUrl(e)}
                          />
                        </i>
                      </div>
                    </div>
                  </div>
                </div>
              </Row>
              <Row>
                <Col lg="2">
                  <Label
                    htmlFor="id_input_fm_1_first_name"
                    className="required-input-mark"
                  >
                    First Name
                  </Label>
                </Col>
                <Col lg="4" className="m-b-10">
                  <input
                    id="id_input_fm_1_first_name"
                    name="fm_1_first_name"
                    type="text"
                    className="form-control"
                    placeholder="Enter First Name"
                    // defaultValue={model_data.firstName}
                    {...register("fm_1_first_name", {
                      // required: "Please enter First Name",
                      value: model_data !== "" ? model_data.firstName : "",
                      maxLength: {
                        value: 250,
                        message: "Please enter less than characters 250.",
                      },
                    })}
                    required
                  />
                  <span className="txt-danger f-12">
                    {errors?.fm_1_first_name &&
                      errors?.fm_1_first_name?.message}
                  </span>
                </Col>
                <Col lg="2">
                  <Label
                    htmlFor="id_input_fm_1_last_name"
                    className="required-input-mark"
                  >
                    Last Name
                  </Label>
                </Col>
                <Col lg="4" className="m-b-10">
                  <input
                    id="id_input_fm_1_last_name"
                    name="fm_1_last_name"
                    type="text"
                    className="form-control"
                    placeholder="Enter Last Name"
                    {...register("fm_1_last_name", {
                      // required: "Please enter Last Name",
                      value: model_data !== "" ? model_data.lastName : "",
                      maxLength: {
                        value: 250,
                        message: "Please enter less than characters 250.",
                      },
                    })}
                    required
                  />
                  <span className="txt-danger f-12">
                    {errors?.fm_1_last_name && errors?.fm_1_last_name?.message}
                  </span>
                </Col>
              </Row>
              <Row>
                <Col lg="2">
                  <Label
                    htmlFor="id_input_fm_1_searchkey"
                    className="required-input-mark"
                  >
                    Employee Id
                  </Label>
                </Col>
                <Col lg="4" className="m-b-10">
                  <input
                    id="id_input_fm_1_searchkey"
                    name="fm_1_searchkey"
                    type="text"
                    className="form-control"
                    placeholder="Enter Employee Id"
                    {...register("fm_1_searchkey", {
                      // required: "Please enter Last Name",
                      value: model_data !== "" ? model_data.searchKey : "",
                      maxLength: {
                        value: 250,
                        message: "Please enter less than characters 250.",
                      },
                    })}
                    required
                  />
                  <span className="txt-danger f-12">
                    {errors?.fm_1_searchkey && errors?.fm_1_searchkey?.message}
                  </span>
                </Col>
                <Col lg="2">
                  <Label
                    htmlFor="id_input_fm_1_date_of_birth"
                    // className="required-input-mark"
                  >
                    Date of Birth
                  </Label>
                </Col>
                <Col lg="4" className="m-b-10">
                  <input
                    type="date"
                    className="form-control"
                    id="id_input_fm_1_date_of_birth"
                    name="fm_1_date_of_birth"
                    {...register("fm_1_date_of_birth", {
                      // required: "Please select Date of Birth",
                      value: ConvertMillisToDate(model_data.dateOfBirth),
                    })}
                  />
                  <span className="txt-danger f-12">
                    {errors?.fm_1_date_of_birth &&
                      errors?.fm_1_date_of_birth?.message}
                  </span>
                </Col>
                {/* <Col lg="2">
                  <Label
                    htmlFor="id_input_fm_1_sa_gender_id"
                    // className="required-input-mark"
                  >
                    Gender
                  </Label>
                </Col>
                <Col lg="4" className="m-b-10">
                  <select
                    id="id_input_fm_1_sa_gender_id"
                    name="fm_1_sa_gender_id"
                    className="form-select"
                    {...register("fm_1_sa_gender_id", {
                      // required: "Please select Gender",
                    })}
                  >
                    {genderList.length
                      ? genderList.map((list_item, index) => (
                          <option value={list_item?.genderId} key={index}>
                            {list_item?.name}
                          </option>
                        ))
                      : null}
                  </select>
                  <span className="txt-danger f-12">
                    {errors?.fm_1_sa_gender_id &&
                      errors?.fm_1_sa_gender_id?.message}
                  </span>
                </Col> */}
              </Row>
              <Row>
                <Col lg="2">
                  <Label
                    htmlFor="id_input_fm_1_nic_no"
                    className="required-input-mark"
                  >
                    NIC
                  </Label>
                </Col>
                <Col lg="4" className="m-b-10">
                  <input
                    id="id_input_fm_1_nic_no"
                    name="fm_1_nic_no"
                    type="text"
                    className="form-control"
                    placeholder="Enter NIC Number"
                    {...register("fm_1_nic_no", {
                      // required: "Please enter NIC Number",
                      value: model_data !== "" ? model_data.nicNo : "",
                      maxLength: {
                        value: 20,
                        message: "Please enter less than characters 20.",
                      },
                      pattern: {
                        value:
                          /\b[0-9]{12}\b|\b[0-9]{9}[vV]\b|\b[0-9]{9}[xX]\b/,
                        message: "Invalid NIC Number",
                      },
                    })}
                    required
                  />
                  <span className="txt-danger f-12">
                    {errors?.fm_1_nic_no && errors?.fm_1_nic_no?.message}
                  </span>
                </Col>
                <Col lg="2">
                  <Label
                    htmlFor="id_input_fm_1_sa_title_id"
                    className="required-input-mark"
                  >
                    Title
                  </Label>
                </Col>
                <Col lg="4" className="m-b-10">
                  <select
                    id="id_input_fm_1_sa_title_id"
                    name="fm_1_sa_title_id"
                    className="form-select"
                    {...register("fm_1_sa_title_id", {
                      // required: "Please select Title",
                      value: model_data !== "" ? model_data.titleId : 0,
                    })}
                    required
                  >
                    {titleList.length
                      ? titleList.map((listItem, index) => (
                          <option value={listItem?.titleId} key={index}>
                            {listItem?.name}
                          </option>
                        ))
                      : null}
                  </select>
                  <span className="txt-danger f-12">
                    {errors?.fm_1_sa_title_id &&
                      errors?.fm_1_sa_title_id?.message}
                  </span>
                </Col>
              </Row>
              <Row>
                <Col lg="2">
                  <Label
                    htmlFor="id_input_fm_1_contact_number"
                    // className="required-input-mark"
                  >
                    Contact Number
                  </Label>
                </Col>
                <Col lg="4" className="m-b-10">
                  <input
                    id="id_input_fm_1_contact_number"
                    name="fm_1_contact_number"
                    type="text"
                    className="form-control"
                    placeholder="Enter Contact Number"
                    {...register("fm_1_contact_number", {
                      // required: "Please enter Contact Number",
                      value: model_data !== "" ? model_data.contactNumber : "",
                      maxLength: {
                        value: 15,
                        message: "Maximum allowed character length is 15.",
                      },
                      minLength: {
                        value: 9,
                        message: "Minimum allowed charcter length is 9.",
                      },
                      pattern: {
                        value: /\b[0-9]{9,15}\b/,
                        message: "Invalid Phone Number",
                      },
                    })}
                  />
                  <span className="txt-danger f-12">
                    {errors?.fm_1_contact_number &&
                      errors?.fm_1_contact_number?.message}
                  </span>
                </Col>
                <Col lg="2">
                  <Label
                    htmlFor="id_input_fm_1_email"
                    // className="required-input-mark"
                  >
                    Email
                  </Label>
                </Col>
                <Col lg="4" className="m-b-10">
                  <input
                    id="id_input_fm_1_email"
                    name="fm_1_email"
                    type="email"
                    maxLength={100}
                    className="form-control"
                    placeholder="Enter Email Address"
                    {...register("fm_1_email", {
                      value: model_data !== "" ? model_data.email : "",
                      // required: "Please enter Email Address",
                      maxLength: {
                        value: 100,
                        message: "Maximum allowed character length is 100.",
                      },
                      pattern: {
                        value:
                          /^[a-zA-z][0-9a-zA-Z-._]+@[a-zA-Z]+[.][a-zA-z]{1,3}$/,
                        message: "Invalid Email Address.",
                      },
                    })}
                  />
                  <span className="txt-danger f-12">
                    {errors?.fm_1_email && errors?.fm_1_email?.message}
                  </span>
                </Col>
              </Row>
              <Row>
                {/* <Col lg="2">
                  <Label
                    htmlFor="id_input_fm_1_sa_department_id"
                    // className="required-input-mark"
                  >
                    Department
                  </Label>
                </Col>
                <Col lg="4" className="m-b-10">
                  <select
                    id="id_input_fm_1_sa_department_id"
                    name="fm_1_sa_department_id"
                    className="form-select"
                    {...register("fm_1_sa_department_id", {
                      // required: "Please select Department",
                    })}
                  >
                    {departmentList.length
                      ? departmentList.map((listItem, index) => (
                          <option value={listItem?.departmentId} key={index}>
                            {listItem?.name}
                          </option>
                        ))
                      : null}
                  </select>
                  <span className="txt-danger f-12">
                    {errors?.fm_1_sa_department_id &&
                      errors?.fm_1_sa_department_id?.message}
                  </span>
                </Col> */}
                <Col lg="2">
                  <Label
                    htmlFor="id_input_fm_1_sa_designation_id"
                    className="required-input-mark"
                  >
                    Designation
                  </Label>
                </Col>
                <Col lg="4" className="m-b-10">
                  <select
                    id="id_input_fm_1_sa_designation_id"
                    name="fm_1_sa_designation_id"
                    className="form-select"
                    {...register("fm_1_sa_designation_id", {
                      // required: "Please select Designation",
                      value: model_data !== "" ? model_data.designationId : 0,
                    })}
                    required
                  >
                    {designationList.length
                      ? designationList.map((listItem, index) => (
                          <option value={listItem?.designationId} key={index}>
                            {listItem?.name}
                          </option>
                        ))
                      : null}
                  </select>
                  <span className="txt-danger f-12">
                    {errors?.fm_1_sa_designation_id &&
                      errors?.fm_1_sa_designation_id?.message}
                  </span>
                </Col>
              </Row>
              <Row>
                <Col lg="2">
                  <label
                    htmlFor="id_input_fm_1_sa_country_id"
                    className="col-form-label pt-0 required-input-mark"
                  >
                    Country
                  </label>
                </Col>
                <Col lg="4" className="m-b-10">
                  <select
                    id="id_input_fm_1_sa_country_id"
                    name="fm_1_sa_country_id"
                    className="form-select"
                    {...register("fm_1_sa_country_id", {
                      value: model_data !== "" ? model_data.countryId : 0,
                    })}
                    required
                  >
                    {countryList.length
                      ? countryList.map((listItem, index) => (
                          <option value={listItem?.countryId} key={index}>
                            {listItem?.name}
                          </option>
                        ))
                      : null}
                  </select>
                </Col>
                <Col lg="2">
                  <label
                    htmlFor="id_input_fm_1_sa_nationality_id"
                    className="col-form-label pt-0 required-input-mark"
                  >
                    Nationality
                  </label>
                </Col>
                <Col lg="4" className="m-b-10">
                  <select
                    id="id_input_fm_1_sa_nationality_id"
                    name="fm_1_sa_nationality_id"
                    className="form-select"
                    {...register("fm_1_sa_nationality_id", {
                      value: model_data !== "" ? model_data.nationalityId : 0,
                    })}
                    required
                  >
                    {nationalityList.length
                      ? nationalityList.map((listItem, index) => (
                          <option value={listItem?.nationalityId} key={index}>
                            {listItem?.name}
                          </option>
                        ))
                      : null}
                  </select>
                  <span className="txt-danger f-12">
                    {errors?.fm_1_sa_nationality_id &&
                      errors?.fm_1_sa_nationality_id?.message}
                  </span>
                </Col>
              </Row>
              {/* <Row>
                <Col lg="2">
                  <Label htmlFor="id_input_fm_1_address">Address</Label>
                </Col>
                <Col lg="10" className="m-b-10">
                  <input
                    id="id_input_fm_1_address"
                    name="fm_1_address"
                    type="text"
                    className="form-control"
                    placeholder="Enter Address Line"
                    {...register("fm_1_address", {
                      value: model_data !== "" ? model_data.address : "",
                      maxLength: {
                        value: 250,
                        message: "Maximum allowed character length is 250.",
                      },
                    })}
                  />
                  <span className="txt-danger f-12">
                    {errors?.fm_1_address && errors?.fm_1_address?.message}
                  </span>
                </Col>
              </Row> */}
              <Row>
                <Col lg="2">
                  <Label htmlFor="id_input_fm_1_description">Description</Label>
                </Col>
                <Col lg="10" className="m-b-10">
                  <textarea
                    id="id_input_fm_1_description"
                    name="fm_1_description"
                    className="form-control"
                    rows="3"
                    placeholder="Enter Description"
                    {...register("fm_1_description", {
                      value:
                        model_data !== ""
                          ? model_data.description
                            ? ""
                            : model_data.description
                          : "",
                      maxLength: {
                        value: 250,
                        message: "Maximum allowed character length is 250.",
                      },
                    })}
                  ></textarea>
                </Col>
              </Row>
              <Row>
                <Col lg="2">
                  <Label htmlFor="id_input_fm_1_remark">Remark</Label>
                </Col>
                <Col lg="10" className="m-b-10">
                  <textarea
                    id="id_input_fm_1_remark"
                    name="fm_1_remark"
                    rows="3"
                    className="form-control"
                    placeholder="Enter Remarks"
                    {...register("fm_1_remark", {
                      value: model_data !== "" ? model_data.remark : "",
                      maxLength: {
                        value: 250,
                        message: "Maximum allowed character length is 250.",
                      },
                    })}
                  ></textarea>
                </Col>
              </Row>
              <Row>
                <Col lg="6" className="offset-lg-2 m-b-10">
                  <Label className="d-block" htmlFor="input_fm_1_active">
                    <Input
                      className="checkbox_animated"
                      id="input_fm_1_active"
                      name="fm_1_active"
                      type="checkbox"
                      defaultChecked={
                        model_data !== "" ? model_data.isActive : true
                      }
                      {...register("fm_1_active")}
                    />
                    Active
                  </Label>
                </Col>
              </Row>
              <div className="d-flex justify-content-end">
                <Btn
                  attrBtn={{
                    color: "primary",
                    size: "sm",
                    disabled: false,
                    outline: true,
                    type: "submit",
                    className: "d-flex align-items-center",
                  }}
                >
                  {model_data === "" ? "Submit" : "Update"}
                </Btn>
                {/* {model_data !== "" ? (
                  <Btn
                    attrBtn={{
                      color: "danger",
                      size: "sm",
                      disabled: false,
                      outline: true,
                      className: "m-l-10 d-flex align-items-center",
                    }}
                  >
                    Delete
                  </Btn>
                ) : null} */}
              </div>
            </Form>
          </Col>
        </Row>
      </Container>
    </CommonModal>
  );
};

export default SaveEmployeeModel;
