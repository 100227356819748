import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Container, Row, Col, Card, CardBody, Media } from "reactstrap";
import { Breadcrumbs, Btn, Spinner } from "../../../AbstractElements";
import HeaderCard from "../../Common/Component/HeaderCard";
import * as feather from "feather-icons";
import ReactTable from "react-data-table-component";
import { toast } from "react-toastify";
import axios from "axios";
import { backendURL } from "../../../Config/Config";
import SaveConditionModel from "./Models/SaveConditionModel";
import UserContext from "../../../_helper/User";

const Condition = () => {
  const userContext = useContext(UserContext);
  const loggedUser = userContext.userData;
  /**Datatable */
  // Table Functions
  const handleEditBtnClick = (row_data) => {
    setModelData(row_data);
    setTriggerSaveModel(true);
  };
  const statusSort = (rowA, rowB) => {
    console.log(rowA);
    const a = rowA.status.props.children;
    const b = rowB.status.props.children;

    if (a > b) {
      return 1;
    }

    if (b > a) {
      return -1;
    }

    return 0;
  };
  const tableColumns = useRef([
    {
      name: "Condition",
      selector: (row) => `${row.name}`,
      sortable: true,
      center: false,
      download: true,
    },
    {
      name: "Weight",
      selector: (row) => `${row.weight}`,
      sortable: true,
      center: false,
      download: true,
    },
    {
      name: "Status",
      selector: (row) => row["status"],
      sortable: true,
      center: false,
      sortFunction: statusSort,
      download: true,
    },
    {
      name: "Action",
      selector: (row) => row["action"],
      sortable: true,
      center: false,
      ignoreRowClick: true,
      download: false,
    },
  ]);
  const [tableData, setTableData] = useState();
  const [fetchingTableData, setFetchingTableData] = useState(true);
  const assetData = useRef([]);
  // Load Table Data
  useEffect(() => {
    if (fetchingTableData && Object.keys(loggedUser).length > 0) {
      const reqBody = {
        clientId: loggedUser.clientId,
        orgId: loggedUser.orgId,
        userId: loggedUser.userId,
        moduleId: loggedUser.loggedModuleId,
      };
      const reqInstance = axios.create({
        baseURL: backendURL,
      });
      reqInstance
        .post("/viewAssetCondition", reqBody)
        .then((response_data) => {
          if (response_data) {
            const result = response_data.data;
            if (!result.hasError) {
              let conditionData = [];
              setModelFormData({
                clientList: result.activeClientList,
                orgList: result.activeOrgList,
              });
              result.assetConditionList.forEach((condition_item) => {
                conditionData.push({
                  ...condition_item,
                  status: condition_item?.isActive ? (
                    <span className="badge badge-light-info">Active</span>
                  ) : (
                    <span className="badge badge-light-danger">Not Active</span>
                  ),
                  action: (
                    <Media>
                      <Btn
                        attrBtn={{
                          color: "info",
                          size: "sm",
                          disabled: false,
                          outline: true,
                          className: "m-r-10",
                          onClick: () => handleEditBtnClick(condition_item),
                        }}
                      >
                        Edit
                      </Btn>
                    </Media>
                  ),
                });
              });
              setTableData(conditionData);
              assetData.current = conditionData;
              setFetchingTableData(false);
            } else {
              setTableData([]);
              setFetchingTableData(false);
              toast.error("Request Failed", {
                isLoading: false,
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [loggedUser, fetchingTableData]);
  /**Model */
  const [triggerSaveModel, setTriggerSaveModel] = useState(false);
  const [modelFormData, setModelFormData] = useState();
  const [modelData, setModelData] = useState([]);
  /**Page  */
  const comingSoonNotification = () => {
    toast.warning("This feature is not yet available.", {
      isLoading: false,
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };
  const handleBasicSearch = (evt) => {
    let inputValue = evt.target.value.toLocaleLowerCase();
    if (inputValue.length > 0) {
      let searchResults = assetData.current;
      searchResults = searchResults.filter((search_row) => {
        let possibleValues = Object.values(search_row).filter(
          (search_prop) =>
            typeof search_prop === "string" || typeof search_prop === "number"
        );
        // console.log(possibleValues);
        return (
          possibleValues.filter(
            (target_value) =>
              target_value
                .toString()
                .toLocaleLowerCase()
                .indexOf(inputValue) !== -1
          )?.length > 0
        );
      });
      setTableData(searchResults);
    } else {
      setTableData(assetData.current);
    }
  };
  const convertArrayOfObjectsToCSV = (header_list, table_data) => {
    let result;

    const columnHeaders = [];
    header_list.current.forEach((header_item) => {
      if (header_item.download) columnHeaders.push(header_item.name);
    });
    const columnDelimiter = ",";
    const lineDelimiter = "\n";
    const keys = columnHeaders;

    result = "";
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    table_data.forEach((item) => {
      let ctr = 0;

      //extracting required data
      let rowData = [item.name, item.weight, item.status.props.children];

      rowData.forEach((row_item) => {
        if (ctr > 0) result += columnDelimiter;

        result += row_item;

        ctr++;
      });
      result += lineDelimiter;
    });

    return result;
  };
  const downloadCSV = (header_list, table_data) => {
    const link = document.createElement("a");
    let csv = convertArrayOfObjectsToCSV(header_list, table_data);
    if (csv == null) return;

    const currentTimestamp = new Date();
    const filename = `Condition ${currentTimestamp}.csv`;

    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }

    link.setAttribute("href", encodeURI(csv));
    link.setAttribute("download", filename);
    link.click();
  };

  return (
    <Fragment>
      <Breadcrumbs
        mainTitle="Asset Condition"
        parent="Master Files"
        title="Asset Condition"
      />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <HeaderCard
                title="List Of Asset Condition"
                span1="Scale to assess the condition of assets, Ex New Assets are in excellent or optimal condition, with no significant issues affecting their functionality, performance, or value.Moderate Condition: Assets are in a reasonably good condition but may have some minor wear and tear, performance limitations, or slight functional impairments that do not significantly affect their overall usability or value."
              />
              <CardBody>
                <div className="d-flex justify-content-end m-b-15">
                  <Btn
                    attrBtn={{
                      color: "primary",
                      size: "sm",
                      disabled: false,
                      outline: false,
                      className: "m-r-10 d-flex align-items-center",
                      onClick: () => handleEditBtnClick(""),
                    }}
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html: feather.icons["plus-circle"].toSvg({
                          height: "12px",
                          width: "12px",
                        }),
                      }}
                      className="p-r-5 d-flex align-items-center"
                    />
                    Add New Condition
                  </Btn>
                  <Btn
                    attrBtn={{
                      color: "primary",
                      size: "sm",
                      disabled: false,
                      outline: true,
                      className: "d-flex align-items-center",
                      onClick: () => comingSoonNotification(),
                    }}
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html: feather.icons["upload-cloud"].toSvg({
                          height: "12px",
                          width: "12px",
                        }),
                      }}
                      className="p-r-5 d-flex align-items-center"
                    />
                    Import Categories
                  </Btn>
                </div>
                <Row className="m-b-5 justify-content-between align-items-center">
                  <Col md="4 p-l-10">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Search..."
                      onKeyDown={(evt) => handleBasicSearch(evt)}
                    />
                  </Col>
                  <Col md="3" className="d-flex justify-content-end">
                    <div
                      className="d-flex"
                      onClick={() => downloadCSV(tableColumns, tableData)}
                    >
                      <div
                        dangerouslySetInnerHTML={{
                          __html: feather.icons["download"].toSvg({
                            height: "16px",
                            width: "16px",
                          }),
                        }}
                        className={`txt-info m-r-10 d-flex align-items-center`}
                      />
                      Export CSV
                    </div>
                  </Col>
                </Row>
                <ReactTable
                  columns={tableColumns.current}
                  data={tableData}
                  progressComponent={
                    <div className="">
                      <div className="loader-box">
                        <Spinner attrSpinner={{ className: "loader-26" }} />
                      </div>
                      <small>Fetching...</small>
                    </div>
                  }
                  progressPending={fetchingTableData}
                  pointerOnHover
                  // selectableRows
                  pagination
                />
                <SaveConditionModel
                  is_open={triggerSaveModel}
                  trigger_model={setTriggerSaveModel}
                  model_data={modelData}
                  form_data={modelFormData}
                  reload_table={setFetchingTableData}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Condition;
