import React, { Fragment, useEffect, useRef, useState } from "react";
import ReactTable from "react-data-table-component";
import { Container, Media } from "reactstrap";
import { Btn, Spinner } from "../../../../../AbstractElements";
import AssetViewManageMaintenance from "./AssetViewManageMaintenance";

const AssetViewMaintenance = () => {
  /**Page Status Section */
  const INPROGRESS = 10;
  const COMPLETED = 11;
  const [searchingProgress, setSearchingProgress] = useState(INPROGRESS);

  /**Datatable Section */
  const tableColumns = useRef([
    {
      name: "Name",
      selector: (row) => `${row.name}`,
      sortable: true,
      center: false,
    },
    {
      name: "Scheduled Date",
      selector: (row) => `${row.scheduledDate}`,
      sortable: true,
      center: true,
    },
    {
      name: "Maintenance By",
      selector: (row) => `${row.maintenanceBy}`,
      sortable: true,
      center: true,
    },
    {
      name: "Completion Date",
      selector: (row) => `${row.completionDate}`,
      sortable: true,
      center: true,
    },
    {
      name: "Cost",
      selector: (row) => row["cost"],
      sortable: true,
      center: true,
    },
    {
      name: "Note",
      selector: (row) => `${row.notes}`,
      sortable: true,
      center: true,
    },
    {
      name: "Status",
      selector: (row) => row["status"],
      sortable: true,
      center: true,
    },
    {
      name: "Action",
      selector: (row) => row["action"],
      sortable: true,
      center: true,
      ignoreRowClick: true,
    },
  ]);
  const [tableData, setTableData] = useState();
  const [triggerSaveModel, setTriggerSaveModel] = useState(false);
  const [modelData, setModelData] = useState([]);
  const assetData = useRef([
    // {
    //   id: 1,
    //   name: "Service",
    //   scheduledDate: "2023/04/27",
    //   maintenanceBy: "CP Computer",
    //   completionDate: "2023/04/27",
    //   cost: (
    //     <span>
    //       <i className="font-info icofont icofont-cur-dollar"></i> 3.60
    //     </span>
    //   ),
    //   notes:
    //     "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    //   status: <span className="badge badge-light-success">Completed</span>,
    //   action: (
    //     <Media>
    //       <Btn
    //         attrBtn={{
    //           color: "info",
    //           size: "sm",
    //           disabled: false,
    //           outline: true,
    //           onClick: () => setTriggerSaveModel(true),
    //         }}
    //       >
    //         Edit
    //       </Btn>
    //     </Media>
    //   ),
    // },
    // {
    //   id: 2,
    //   name: "Service",
    //   scheduledDate: "2023/04/27",
    //   maintenanceBy: "CP Computer",
    //   completionDate: "2023/04/27",
    //   cost: (
    //     <span>
    //       <i className="font-info icofont icofont-cur-dollar"></i> 3.60
    //     </span>
    //   ),
    //   notes:
    //     "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    //   status: <span className="badge badge-light-info">In Progress</span>,
    //   action: (
    //     <Media>
    //       <Btn
    //         attrBtn={{
    //           color: "info",
    //           size: "sm",
    //           disabled: false,
    //           outline: true,
    //           onClick: () => setTriggerSaveModel(true),
    //         }}
    //       >
    //         Edit
    //       </Btn>
    //     </Media>
    //   ),
    // },
    // {
    //   id: 3,
    //   name: "Service",
    //   scheduledDate: "2023/04/27",
    //   maintenanceBy: "CP Computer",
    //   completionDate: "2023/04/27",
    //   cost: (
    //     <span>
    //       <i className="font-info icofont icofont-cur-dollar"></i> 3.60
    //     </span>
    //   ),
    //   notes:
    //     "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    //   status: <span className="badge badge-light-danger">Cancelled</span>,
    //   action: (
    //     <Media>
    //       <Btn
    //         attrBtn={{
    //           color: "info",
    //           size: "sm",
    //           disabled: false,
    //           outline: true,
    //           onClick: () => setTriggerSaveModel(true),
    //         }}
    //       >
    //         Edit
    //       </Btn>
    //     </Media>
    //   ),
    // },
    // {
    //   id: 4,
    //   name: "Service",
    //   scheduledDate: "2023/04/27",
    //   maintenanceBy: "CP Computer",
    //   completionDate: "2023/04/27",
    //   cost: (
    //     <span>
    //       <i className="font-info icofont icofont-cur-dollar"></i> 3.60
    //     </span>
    //   ),
    //   notes:
    //     "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    //   status: <span className="badge badge-light-warning">On Hold</span>,
    //   action: (
    //     <Media>
    //       <Btn
    //         attrBtn={{
    //           color: "info",
    //           size: "sm",
    //           disabled: false,
    //           outline: true,
    //           onClick: () => setTriggerSaveModel(true),
    //         }}
    //       >
    //         Edit
    //       </Btn>
    //     </Media>
    //   ),
    // },
  ]);
  const handleRowClick = (row_data) => {
    setTriggerSaveModel(true);
    setModelData(row_data);
  };
  const handleEditBtnClick = () => {
    setTriggerSaveModel(true);
    setModelData("");
  };

  /**Page Onload Section */
  useEffect(() => {
    setTableData(assetData.current);
    setSearchingProgress(COMPLETED);
  }, []);

  return (
    <Fragment>
      <Container>
        <div className="d-flex justify-content-end">
          <Btn
            attrBtn={{
              color: "info",
              size: "sm",
              disabled: false,
              outline: true,
              className: "m-b-15",
              onClick: () => handleEditBtnClick(),
            }}
          >
            <span>
              <i className="icofont icofont-plus m-r-10"></i>
            </span>
            Add Record
          </Btn>
        </div>
        <ReactTable
          columns={tableColumns.current}
          data={tableData}
          progressPending={searchingProgress === INPROGRESS}
          progressComponent={
            <div className="">
              <div className="loader-box">
                <Spinner attrSpinner={{ className: "loader-26" }} />
              </div>
              <small>Searching...</small>
            </div>
          }
          onRowClicked={(row, event) => handleRowClick(row)}
          pointerOnHover
        />
        <AssetViewManageMaintenance
          is_open={triggerSaveModel}
          trigger_model={setTriggerSaveModel}
          model_data={modelData}
        />
      </Container>
    </Fragment>
  );
};

export default AssetViewMaintenance;
