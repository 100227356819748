import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Collapse,
  Label,
  Button,
  Form,
} from "reactstrap";
import { Breadcrumbs, Spinner } from "../../../AbstractElements";
import HeaderCard from "../../Common/Component/HeaderCard";
import ReactTable from "react-data-table-component";
import user from "../../../assets/images/user/4.jpg";
import asset_default from "../../../assets/images/asset-item/asset_default.png";
import { Image, Btn, H5 } from "../../../AbstractElements";

import { Media } from "reactstrap";
// import AssetMinView from "./AssetList/AssetMinView";
import { Link, useNavigate } from "react-router-dom";
import SvgIcon from "../../Common/Component/SvgIcon";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import axios from "axios";
import { backendURL, imageFileURL } from "../../../Config/Config";
import { ConvertDateToDate, ConvertMillisToDate } from "../../../Data/DateUtil";
import AssetItemContext from "../../../_helper/AssetItem";
import UserContext from "../../../_helper/User";
import * as feather from "feather-icons";
import { CurrencyFormatter } from "../../../Data/StringFormatter";

const AssetVerification = () => {
  const assetContext = useContext(AssetItemContext);
  const userContext = useContext(UserContext);
  const loggedUser = userContext.userData;
  const history = useNavigate();

  // Set Form Data
  const [assetVerification, setAssetVerification] = useState([]);
  const [assetCondition, setAssetCondition] = useState([]);
  const [searchType, setSearchType] = useState([
    { searchTypeId: -1, name: "- Select -" },
    { searchTypeId: 1, name: "Name" },
    { searchTypeId: 2, name: "Asset Id" },
    { searchTypeId: 3, name: "Purchased From" },
    { searchTypeId: 4, name: "Reference No" },
    { searchTypeId: 5, name: "Brand" },
    { searchTypeId: 6, name: "Model" },
    { searchTypeId: 7, name: "Serial No" },
  ]);
  const [dpVerficationCodeValue, setDpVerficationCodeValue] = useState(-1);
  const [dpConditionValue, setDpConditionValue] = useState(-1);
  const [dpFloorValue, setDpFloorValue] = useState(-1);
  const [dpLocationValue, setDpLocationValue] = useState(-1);
  const [dpMainCategoryValue, setDpMainCategoryValue] = useState(-1);
  const [dpSubCategoryValue, setDpSubCategoryValue] = useState(-1);
  const [dpDepartmentValue, setDpDepartmentValue] = useState(-1);
  const [dpSearchTypeValue, setDpSearchTypeValue] = useState(-1);
  const [currency, setCurrency] = useState("");

  /**Datatable Section */
  const costSort = (rowA, rowB) => {
    const a = parseFloat(rowA.cost.props.children[1].trim());
    const b = parseFloat(rowB.cost.props.children[1].trim());

    if (a > b) {
      return 1;
    }

    if (b > a) {
      return -1;
    }

    return 0;
  };
  const statusSort = (rowA, rowB) => {
    console.log(rowA);
    const a = rowA.cost.props.children;
    const b = rowB.cost.props.children;

    if (a > b) {
      return 1;
    }

    if (b > a) {
      return -1;
    }

    return 0;
  };
  const setAssetItemInContext = (select_asset_item) => {
    const reqBody = {
      clientId: loggedUser.clientId,
      orgId: loggedUser.orgId,
      userId: loggedUser.userId,
      moduleId: loggedUser.loggedModuleId,
      assetItemId: select_asset_item.assetItemId,
    };
    const reqInstance = axios.create({
      baseURL: backendURL,
    });
    reqInstance
      .post("/getAssetItem", reqBody)
      .then((response_data) => {
        if (response_data) {
          const result = response_data.data;
          if (!result.hasError) {
            assetContext.addAssetItem(result.assetItem);
            history("/asset/asset-list/asset-view");
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const tableColumns = useRef([
    {
      name: "Asset",
      selector: (row) => row.name,
      sortable: true,
      download: true,
      grow: 2,
    },
    {
      name: "Asset Id",
      selector: (row) => row.searchKey,
      sortable: true,
      download: true,
      grow: 2,
    },
    {
      name: "Reference No.",
      selector: (row) => (row.referenceNo ? row.referenceNo : ""),
      sortable: true,
      download: true,
      grow: 2,
    },
    // {
    //   name: "Condition",
    //   selector: (row) =>
    //     row.assetCondition ? row.assetCondition.name : "Not Selected",
    //   sortable: true,
    //   download: true,
    // },
    // {
    //   name: "Purchase Date",
    //   selector: (row) => ConvertMillisToDate(row.purchaseDate),
    //   sortable: true,
    //   download: true,
    //   grow: 2,
    // },
    // {
    //   name: "Capitalization Date",
    //   selector: (row) => ConvertMillisToDate(row.capitalizationDate),
    //   sortable: true,
    //   download: true,
    //   grow: 2,
    // },
    // {
    //   name: "Fixed Asset",
    //   selector: (row) => row["fixedAsset"],
    //   sortable: true,
    //   download: true,
    //   center: true,
    // },
    // {
    //   name: "Cost (LKR)",
    //   selector: (row) => CurrencyFormatter(row.costPerItem),
    //   sortable: true,
    //   // sortFunction: costSort,
    //   download: true,
    //   grow: 2,
    // },
    // {
    //   name: "Status",
    //   selector: (row) => row["status"],
    //   sortable: true,
    //   sortFunction: statusSort,
    //   download: true,
    // },
  ]);
  const [triggerModel, setTriggerModel] = useState(false);
  const [modelData, setModelData] = useState([]);
  const handleRowClick = (row_data) => {
    setTriggerModel(true);
    setModelData(row_data);
  };
  const [tableData, setTableData] = useState();
  const [fetchingTableData, setFetchingTableData] = useState(true);
  const [searchingData, setSearchingData] = useState(false);
  const assetData = useRef([]);
  // Load Table Data
  useEffect(() => {
    if (fetchingTableData && Object.keys(loggedUser).length > 0) {
      const reqBody = {
        clientId: loggedUser.clientId,
        orgId: loggedUser.orgId,
        userId: loggedUser.userId,
        moduleId: loggedUser.loggedModuleId,
      };
      const reqInstance = axios.create({
        baseURL: backendURL,
      });
      reqInstance
        .post("/viewGetVerificationReport", reqBody)
        .then((response_data) => {
          if (response_data) {
            const result = response_data.data;
            if (!result.hasError) {
              setAssetVerification([
                { assetVerificationId: -1, searchKey: "- Select -" },
                ...result.assetVerificationList,
              ]);
              setAssetCondition([
                { assetConditionId: -1, name: "- Select -" },
                ...result.assetConditionList,
              ]);
              setFetchingTableData(false);
            } else {
              setTableData([]);
              setFetchingTableData(false);
              toast.error("Request Failed", {
                isLoading: false,
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [loggedUser, fetchingTableData]);
  //Search Functions
  const handleAdvancedSearch = () => {
    const form = new FormData(document.querySelector(".form-1"));
    setSearchingData(true);
    const reqInstance = axios.create({
      baseURL: backendURL,
    });
    const searchBody = {
      clientId: loggedUser.clientId,
      orgId: loggedUser.orgId,
      userId: loggedUser.userId,
      // searchKeyword: form.get("fm_1_search_keyword"),
      searchKeyword: "",
      searchType: dpSearchTypeValue,
      verificationId: dpVerficationCodeValue ? dpVerficationCodeValue : -1,
      conditionId: dpConditionValue ? dpConditionValue : -1,
      startDate: form.get("fm_1_start_date"),
      endDate: form.get("fm_1_end_date"),
      status: -1,
      // resultsToDisplay: form.get("fm_1_no_of_display_results"),
    };
    reqInstance
      .post("/getVerificationReport", searchBody)
      .then((response_data) => {
        const searchResult = response_data.data;
        if (!response_data.hasError) {
          let assetItemData = [];
          searchResult.forEach((asset_item) => {
            let imageList =
              asset_item?.assetImageList !== null
                ? asset_item?.assetImageList
                : [];
            let defaultImage = "";
            if (imageList.length) {
              defaultImage = imageList.filter(
                (image_item) => image_item.isPrimaryImage === true
              )[0];
              console.log(defaultImage);
            }
            assetItemData.push({
              ...asset_item,
              fixedAsset: asset_item?.isBulk ? (
                <span className="f-w-700 font-danger">No</span>
              ) : (
                <span className="font-info">Yes</span>
              ),
              status: asset_item?.isActive ? (
                <span className="badge badge-light-info">Active</span>
              ) : (
                <span className="badge badge-light-danger">Not Active</span>
              ),
            });
          });
          setTableData(assetItemData);
          assetData.current = assetItemData;
          setSearchingData(false);
        }
      });
  };
  /**Page  */
  const handleBasicSearch = (evt) => {
    let inputValue = evt.target.value.toLocaleLowerCase();
    if (inputValue.length > 0) {
      let searchResults = assetData.current;
      searchResults = searchResults.filter((search_row) => {
        let possibleValues = Object.values(search_row).filter(
          (search_prop) =>
            typeof search_prop === "string" || typeof search_prop === "number"
        );
        // console.log(possibleValues);
        return (
          possibleValues.filter(
            (target_value) =>
              target_value
                .toString()
                .toLocaleLowerCase()
                .indexOf(inputValue) !== -1
          )?.length > 0
        );
      });
      setTableData(searchResults);
    } else {
      setTableData(assetData.current);
    }
  };

  const [isOpen, setIsOpen] = useState(0);
  const toggle = (id) => (isOpen === id ? setIsOpen(null) : setIsOpen(id));

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const convertArrayOfObjectsToCSV = (header_list, table_data) => {
    let result;

    const columnHeaders = [];
    header_list.current.forEach((header_item) => {
      if (header_item.download) columnHeaders.push(header_item.name);
    });
    const columnDelimiter = ",";
    const lineDelimiter = "\n";
    const keys = columnHeaders;

    result = "";
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    table_data.forEach((item) => {
      console.log(item);
      let ctr = 0;

      //extracting required data
      let rowData = [
        item.name,
        item.searchKey,
        item.referenceNo,
        item.assetCondition.name,
        ConvertMillisToDate(item.purchaseDate),
        ConvertMillisToDate(item.capitalizationDate),
        item.fixedAsset.props.children,
        item.costPerItem,
        item.status.props.children,
      ];

      rowData.forEach((row_item) => {
        if (ctr > 0) result += columnDelimiter;

        result += row_item;

        ctr++;
      });
      result += lineDelimiter;
    });

    return result;
  };
  const downloadCSV = (header_list, table_data) => {
    const link = document.createElement("a");
    let csv = convertArrayOfObjectsToCSV(header_list, table_data);
    if (csv == null) return;

    const currentTimestamp = new Date();
    const filename = `Asset Items ${currentTimestamp}.csv`;

    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }

    link.setAttribute("href", encodeURI(csv));
    link.setAttribute("download", filename);
    link.click();
  };

  return (
    <Fragment>
      <Breadcrumbs
        mainTitle="Reports"
        parent="Reports"
        title="Asset Verifications"
      />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <HeaderCard
                title="Asset Verifications"
                span1="Purpose of this interface is to retrieve reports on asset verifications."
              />
              <CardBody>
                {/* Start of Search Of Criteria */}
                <Card className="shadow-none">
                  <CardHeader className="py-2 px-0">
                    <H5 attrH5={{ className: "mb-0" }}>
                      <Btn
                        attrBtn={{
                          as: Card.Header,
                          className: "btn d-flex align-items-center f-w-600",
                          color: "default",
                          onClick: () => toggle(1),
                        }}
                      >
                        <span className="header-search">
                          <SvgIcon
                            className="stroke-icon m-r-10"
                            iconId={`search`}
                            height="18px"
                            width="18px"
                            style={{
                              stroke: "rgba(47, 47, 59, 0.9)",
                              fill: "none",
                            }}
                          />
                        </span>{" "}
                        Advanced Search Criteria
                      </Btn>
                    </H5>
                  </CardHeader>
                  <Collapse isOpen={isOpen}>
                    <CardBody className="b-b-light">
                      <Form className="form-1">
                        {/* <Row>
                          <Col md="6" className="m-b-10">
                            <Label htmlFor="input_fm_1_asset_id">Search</Label>
                            <input
                              id="input_fm_1_search_keyword"
                              className="form-control"
                              name="fm_1_search_keyword"
                              type="text"
                              placeholder="Enter Keywords"
                              {...register("fm_1_search_keyword")}
                            />
                          </Col>
                          <Col md="6" className="m-b-10">
                            <Label htmlFor="validationCustom01">
                              Search Fields
                            </Label>
                            <select
                              id="input_fm_1_a_search_type_id"
                              name="fm_1_a_search_type_id"
                              className={`form-select`}
                              {...register("fm_1_a_search_type_id")}
                              onChange={(evt) =>
                                setDpSearchTypeValue(evt.target.value)
                              }
                              value={dpSearchTypeValue}
                            >
                              {searchType.length
                                ? searchType.map((list_item, index) => (
                                    <option
                                      value={list_item?.searchTypeId}
                                      key={index}
                                    >
                                      {list_item?.name}
                                    </option>
                                  ))
                                : null}
                            </select>
                          </Col>
                        </Row> */}
                        <Row>
                          <Col md="6" className="m-b-10">
                            <Label htmlFor="input_fm_1_no_of_display_results">
                              Start Date
                            </Label>
                            <input
                              id="input_fm_1_no_of_display_results"
                              className="form-control"
                              name="fm_1_start_date"
                              type="date"
                              {...register("fm_1_start_date")}
                            />
                          </Col>
                          <Col md="6" className="m-b-10">
                            <Label htmlFor="input_fm_1_no_of_display_results">
                              End Date
                            </Label>
                            <input
                              id="input_fm_1_no_of_display_results"
                              className="form-control"
                              name="fm_1_end_date"
                              type="date"
                              {...register("fm_1_end_date")}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col md="6" className="m-b-10">
                            <Label htmlFor="input_fm_1_a_asset_verification_id">
                              Verification Code
                            </Label>
                            <select
                              id="input_fm_1_a_asset_verification_id"
                              name="fm_1_a_asset_verification_id"
                              className={`form-select ${
                                assetVerification.length ? "" : "disabled"
                              }`}
                              {...register("fm_1_a_asset_verification_id")}
                              onChange={(evt) =>
                                setDpVerficationCodeValue(evt.target.value)
                              }
                              value={dpVerficationCodeValue}
                            >
                              {assetVerification.length
                                ? assetVerification.map((list_item, index) => (
                                    <option
                                      value={list_item?.assetVerificationId}
                                      key={index}
                                    >
                                      {list_item?.searchKey}
                                    </option>
                                  ))
                                : null}
                            </select>
                            <span className="txt-danger f-12">
                              {errors?.fm_1_a_asset_verification_id &&
                                errors?.fm_1_a_asset_verification_id?.message}
                            </span>
                          </Col>
                          <Col md="6" className="m-b-10">
                            <Label htmlFor="input_fm_1_condition_id">
                              Condition
                            </Label>
                            <select
                              id="input_fm_1_condition_id"
                              name="fm_1_condition_id"
                              className={`form-select ${
                                assetCondition.length ? "" : "disabled"
                              }`}
                              {...register("fm_1_condition_id")}
                              onChange={(evt) =>
                                setDpConditionValue(evt.target.value)
                              }
                              value={dpConditionValue}
                            >
                              {assetCondition.length
                                ? assetCondition.map((list_item, index) => (
                                    <option
                                      value={list_item?.assetConditionId}
                                      key={index}
                                    >
                                      {list_item?.name}
                                    </option>
                                  ))
                                : null}
                            </select>
                            <span className="txt-danger f-12">
                              {errors?.fm_1_condition_id &&
                                errors?.fm_1_condition_id?.message}
                            </span>
                          </Col>
                        </Row>
                        {/* <Row>
                          <Col className="m-b-10">
                            <Label htmlFor="validationCustom01">
                              {"Status"}
                            </Label>
                            <select name="" className="form-select"></select>
                            <span className="txt-danger f-12">
                              {errors.firstName && "First name is required"}
                            </span>
                            <div className="valid-feedback">
                              {"Looks good!"}
                            </div>
                          </Col>
                        </Row> */}
                        {/* <Row>
                          <Col md="3" className="m-b-10">
                            <Label htmlFor="input_fm_1_no_of_display_results">
                              No. of Display Results
                            </Label>
                            <input
                              id="input_fm_1_no_of_display_results"
                              className="form-control"
                              name="fm_1_no_of_display_results"
                              type="number"
                              defaultValue={100}
                              {...register("fm_1_no_of_display_results")}
                            />
                          </Col>
                        </Row> */}
                        <div className="d-flex justify-content-end m-t-20">
                          <Button
                            type="button"
                            color="primary"
                            onClick={() => handleAdvancedSearch()}
                          >
                            {"Search"}
                          </Button>
                        </div>
                      </Form>
                    </CardBody>
                  </Collapse>
                </Card>
                {/* End of Search Of Criteria */}

                <Row className="m-b-5 justify-content-between align-items-center">
                  <Col md="4 p-l-10">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Search..."
                      onKeyDown={(evt) => handleBasicSearch(evt)}
                    />
                  </Col>
                  <Col
                    md="3"
                    className="d-flex justify-content-end align-items-center"
                  >
                    <div
                      className="d-flex"
                      onClick={() => downloadCSV(tableColumns, tableData)}
                    >
                      <div
                        dangerouslySetInnerHTML={{
                          __html: feather.icons["download"].toSvg({
                            height: "16px",
                            width: "16px",
                          }),
                        }}
                        className={`txt-info m-r-10 d-flex align-items-center`}
                      />
                      Export CSV
                    </div>
                  </Col>
                </Row>
                <ReactTable
                  columns={tableColumns.current}
                  data={tableData}
                  progressComponent={
                    <div className="">
                      <div className="loader-box">
                        <Spinner attrSpinner={{ className: "loader-26" }} />
                      </div>
                      <small>Fetching...</small>
                    </div>
                  }
                  progressPending={fetchingTableData || searchingData}
                  pointerOnHover
                  pagination
                />
                {/* <AssetMinView
                  is_open={triggerModel}
                  trigger_model={setTriggerModel}
                  model_data={modelData}
                /> */}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default AssetVerification;
