import React, { Fragment, useContext, useEffect, useState } from "react";
import { Breadcrumbs, H5, H6, P, Image } from "../../../AbstractElements";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import LightCardBox from "./LightCardBox";
import ReactApexChart from "react-apexcharts";
import { ArrowDown, ArrowUp, TrendingUp } from "react-feather";
import Chart from "react-apexcharts";
import RadialProgressChart from "./RadialProgressChart";
import RadialChart from "./RadialChart";
import asset_default from "../../../assets/images/asset-item/asset_default.png";
import axios from "axios";
import UserContext from "../../../_helper/User";
import { backendURL } from "../../../Config/Config";
import {
  CurrencyFormatter,
  ThousandSplitter,
} from "../../../Data/StringFormatter";

const SamplePage = () => {
  const userContext = useContext(UserContext);
  const loggedUser = userContext.userData;
  //
  const [assetValueCard, setAssetValueCard] = useState({
    title: "Asset Items",
    gros: "LKR 0000",
    total: "LKR 0000",
    subTitle: "Total Assets Value",
    status: "success",
    chart: {
      color: ["#FFA941"],
      series: [0],
    },
  });
  const [assetAmountCard, setAssetAmountCard] = useState({
    title: "Total Asset Items",
    average: "00000",
    gros: 0,
    color: "primary",
    subTitle: "0000 Asset Items has disposed.",
    chart: {
      series: [0],
      color: "var(--theme-deafult)",
    },
  });
  const [assetCategoriesBarChart, setAssetCategoriesBarChart] = useState({
    series: [
      {
        name: "No. of Assets",
        data: [],
      },
    ],
    options: {
      chart: {
        height: 300,
        type: "bar",
        toolbar: {
          show: false,
        },
        dropShadow: {
          enabled: true,
          // enabledOnSeries: undefined,
          top: 10,
          left: 0,
          blur: 5,
          color: "#7064F5",
          opacity: 0.35,
        },
      },
      plotOptions: {
        bar: {
          borderRadius: 6,
          columnWidth: "30%",
        },
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        categories: [
          "Category 01",
          "Category 02",
          "Category 03",
          "Category 04",
        ],
        labels: {
          formatter: function (val) {
            let abbreviatedString = val.split(" ").map((item) => item[0]);
            return abbreviatedString.join("");
          },
          style: {
            fontSize: "12px",
            fontFamily: "Poppins, sans-serif",
            colors: "var(--chart-text-color)",
          },
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        tooltip: {
          enabled: false,
        },
        title: {
          text: "Asset Categories",
        },
      },
      yaxis: {
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          formatter: function (val) {
            return val;
          },
          style: {
            fontSize: "12px",
            fontFamily: "Poppins, sans-serif",
            colors: "var(--chart-text-color)",
          },
        },
        title: {
          text: "No.of Asset Items",
        },
      },
      grid: {
        borderColor: "var(--chart-dashed-border)",
        strokeDashArray: 5,
      },
      colors: ["#7064F5", "#8D83FF"],
      fill: {
        type: "gradient",
        gradient: {
          shade: "light",
          type: "vertical",
          gradientToColors: ["#7064F5", "#8D83FF"],
          opacityFrom: 0.98,
          opacityTo: 0.85,
          stops: [0, 100],
        },
      },
      responsive: [
        {
          breakpoint: 1200,
          options: {
            chart: {
              height: 200,
            },
          },
        },
      ],
    },
  });
  const [buildingBardChart, setBuildingBardChart] = useState({
    series: [
      {
        name: "Active",
        data: [],
      },
      {
        name: "Disposed",
        data: [],
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 270,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "50%",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 6,
        colors: ["transparent"],
      },
      grid: {
        show: true,
        borderColor: "var(--chart-border)",
        xaxis: {
          lines: {
            show: true,
          },
        },
      },
      colors: ["var(--theme-deafult)", "#FFA941"],
      xaxis: {
        categories: [],
        tickAmount: 4,
        tickPlacement: "between",
        labels: {
          style: {
            fontFamily: "Rubik, sans-serif",
          },
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        title: {
          text: "Building",
        },
      },
      yaxis: {
        min: 0,
        tickAmount: 5,
        tickPlacement: "between",
        labels: {
          style: {
            fontFamily: "Rubik, sans-serif",
          },
        },
        title: {
          text: "No.of Asset Items",
        },
      },
      fill: {
        opacity: 1,
      },
      legend: {
        position: "top",
        horizontalAlign: "left",
        fontFamily: "Rubik, sans-serif",
        fontSize: "14px",
        fontWeight: 500,
        labels: {
          colors: "var(--chart-text-color)",
        },
        markers: {
          width: 6,
          height: 6,
          radius: 12,
        },
        itemMargin: {
          horizontal: 10,
        },
      },
      responsive: [
        {
          breakpoint: 1366,
          options: {
            plotOptions: {
              bar: {
                columnWidth: "80%",
              },
            },
            grid: {
              padding: {
                right: 0,
              },
            },
          },
        },
        {
          breakpoint: 992,
          options: {
            plotOptions: {
              bar: {
                columnWidth: "70%",
              },
            },
          },
        },
        {
          breakpoint: 576,
          options: {
            plotOptions: {
              bar: {
                columnWidth: "60%",
              },
            },
            grid: {
              padding: {
                right: 5,
              },
            },
          },
        },
      ],
    },
  });
  const [assetCategorySummary, setAssetCategorySummary] = useState([
    {
      title: "Total Asset Categories",
      total: 45,
      badge: 86,
      color: "success",
    },
    {
      title: "Used Asset Categories",
      total: 12,
      badge: 120,
      color: "warning",
    },
  ]);
  const [assetConditionPieChart, setAssetConditionPieChart] = useState({
    series: [30, 55, 35],
    options: {
      labels: ["Shoes", "Grocery", "other"],
      chart: {
        type: "donut",
        height: 200,
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        position: "bottom",
        fontSize: "14px",
        fontFamily: "Rubik, sans-serif",
        fontWeight: 500,
        labels: {
          colors: ["var(--chart-text-color)"],
        },
        markers: {
          width: 6,
          height: 6,
        },
        itemMargin: {
          horizontal: 7,
          vertical: 0,
        },
      },
      stroke: {
        width: 10,
        colors: ["var(--light2)"],
      },
      plotOptions: {
        pie: {
          expandOnClick: false,
          donut: {
            size: "83%",
            labels: {
              show: true,
              name: {
                offsetY: 4,
              },
              total: {
                show: true,
                fontSize: "20px",
                fontFamily: "Rubik, sans-serif",
                fontWeight: 500,
                // label: "$ 34,098",
                // formatter: () => "Total Profit",
              },
            },
          },
        },
      },
      states: {
        normal: {
          filter: {
            type: "none",
          },
        },
        hover: {
          filter: {
            type: "none",
          },
        },
        active: {
          allowMultipleDataPointsSelection: false,
          filter: {
            type: "none",
          },
        },
      },
      colors: ["#54BA4A", "var(--theme-deafult)", "#FFA941"],
      responsive: [
        {
          breakpoint: 1630,
          options: {
            chart: {
              height: 360,
            },
          },
        },
        {
          breakpoint: 1584,
          options: {
            chart: {
              height: 400,
            },
          },
        },
        {
          breakpoint: 1473,
          options: {
            chart: {
              height: 250,
            },
          },
        },
        {
          breakpoint: 1425,
          options: {
            chart: {
              height: 270,
            },
          },
        },
        {
          breakpoint: 1400,
          options: {
            chart: {
              height: 320,
            },
          },
        },
        {
          breakpoint: 480,
          options: {
            chart: {
              height: 250,
            },
          },
        },
      ],
    },
  });
  // getting the request
  useEffect(() => {
    if (Object.keys(loggedUser).length > 0) {
      const reqBody = {
        clientId: loggedUser.clientId,
        orgId: loggedUser.orgId,
        userId: loggedUser.userId,
        moduleId: loggedUser.loggedModuleId,
      };
      const reqInstance = axios.create({
        baseURL: backendURL,
      });
      reqInstance
        .post("/viewDashboard", reqBody)
        .then((response_data) => {
          if (response_data) {
            const result = response_data.data;
            if (!result.hasError) {
              // set asset value card
              let totalAssetValuePercentage =
                100 -
                (result.totalDisposedAssetValue / result.totalAssetValue) * 100;
              setAssetValueCard({
                ...assetValueCard,
                gros: `LKR ${CurrencyFormatter(
                  result.totalDisposedAssetValue
                )}`,
                total: `LKR ${CurrencyFormatter(result.totalAssetValue)}`,
                subTitle: "Total Assets Value",
                status: "success",
                chart: {
                  ...assetValueCard.chart,
                  series: [Math.floor(totalAssetValuePercentage)],
                },
              });
              // set asset amout card
              let disposedAssetItemPercentage =
                (result.totalDisposedAssetItems / result.totalAssetItems) * 100;
              setAssetAmountCard({
                ...assetAmountCard,
                average: ThousandSplitter(result.totalAssetItems),
                gros: disposedAssetItemPercentage.toFixed(2),
                chart: {
                  ...assetAmountCard.chart,
                  series: [Math.floor(100 - disposedAssetItemPercentage)],
                },
                subTitle: `${ThousandSplitter(
                  result.totalDisposedAssetItems
                )} Asset Items has disposed.`,
              });
              // set building card
              let buildingList = [];
              let activeBuildingAmounts = [];
              let disposedBuildingAmounts = [];
              result.assetItemBuildingGraphDataAll.forEach((building_item) => {
                buildingList.push(building_item.buildingName);
                activeBuildingAmounts.push(building_item.quantity);
                disposedBuildingAmounts.push(0);
              });
              result.assetItemBuildingGraphDataDisposed.forEach(
                (building_item) => {
                  if (buildingList.includes(building_item.buildingName)) {
                    let indexOfBuilding = buildingList.indexOf(
                      building_item.buildingName
                    );
                    disposedBuildingAmounts[indexOfBuilding] =
                      building_item.quantity;
                  } else {
                    buildingList.push(building_item.buildingName);
                    disposedBuildingAmounts.push(building_item.quantity);
                  }
                }
              );
              setBuildingBardChart({
                ...buildingBardChart,
                series: [
                  {
                    name: "Active",
                    data: activeBuildingAmounts,
                  },
                  {
                    name: "Disposed",
                    data: disposedBuildingAmounts,
                  },
                ],
                options: {
                  ...buildingBardChart.options,
                  xaxis: {
                    ...buildingBardChart.options.xaxis,
                    categories: buildingList,
                  },
                },
              });
              //
              let assetCategoryList = [];
              let assetItemAmounts = [];
              result.assetItemCategoryGraphData.forEach((asset_item) => {
                assetCategoryList.push(asset_item.assetCategoryName);
                assetItemAmounts.push(asset_item.quantity);
              });
              setAssetCategoriesBarChart({
                ...assetCategoriesBarChart,
                series: [
                  {
                    name: "No. of Assets",
                    data: assetItemAmounts,
                  },
                ],
                options: {
                  ...assetCategoriesBarChart.options,
                  xaxis: {
                    ...assetCategoriesBarChart.options.xaxis,
                    categories: assetCategoryList,
                  },
                },
              });
              setAssetCategorySummary([
                {
                  ...assetCategorySummary[0],
                  total: result.assetCategoryList.length,
                  badge: 100,
                },
                {
                  ...assetCategorySummary[1],
                  total: assetCategoryList.length,
                  badge: (
                    (assetCategoryList.length /
                      result.assetCategoryList.length) *
                    100
                  ).toFixed(2),
                },
              ]);
              //
              let assetConditionList = [];
              let assetConditionWiseAssetItemAmounts = [];
              result.assetItemAssetConditionGraphData.forEach((asset_item) => {
                assetConditionList.push(asset_item.conditionName);
                assetConditionWiseAssetItemAmounts.push(asset_item.quantity);
              });
              setAssetConditionPieChart({
                ...assetConditionPieChart,
                series: assetConditionWiseAssetItemAmounts,
                options: {
                  ...assetConditionPieChart.options,
                  labels: assetConditionList,
                },
              });
              //
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [loggedUser]);

  return (
    <Fragment>
      <Breadcrumbs mainTitle="Dashboard" parent="Pages" title="Dashboard" />
      <Container fluid={true}>
        <Row>
          <Col lg="6">
            <Card className="social-widget widget-hover">
              <CardBody className="p-0">
                <div className="p-t-20 p-b-20 p-l-20 p-r-30">
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center gap-2">
                      <div className="social-icons">
                        <Image
                          attrImage={{
                            className: "img-30",
                            src: `${asset_default}`,
                            alt: "asset icon",
                          }}
                        />
                      </div>
                      <span>{assetValueCard.title}</span>
                    </div>
                    <div className="d-flex flex-column align-items-end">
                      <span className="muted f-12 d-xxl-block d-xl-none">
                        {assetValueCard.gros}
                      </span>
                      <span className="f-light">Total Disposed Asset Value</span>
                    </div>
                  </div>
                  <div className="social-content">
                    <div>
                      <H5 attrH5={{ className: "mb-1" }}>
                        {assetValueCard.total}
                      </H5>
                      <span className="f-light">{assetValueCard.subTitle}</span>
                    </div>
                    <div className="social-chart">
                      <RadialChart chartData={assetValueCard} />
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
            <Card className="widget-hover">
              <CardBody className="radial-progress-card">
                <div>
                  <H6 attrH6={{ className: "mb-0" }}>
                    {assetAmountCard.title}
                  </H6>
                  <div className="sale-details">
                    <H5
                      attrH5={{
                        className: `font-${assetAmountCard.color} mb-0`,
                      }}
                    >
                      {assetAmountCard.average}
                    </H5>
                    <span className="f-12 f-light f-w-500">
                      {<ArrowDown />}
                      {"-"}
                      {assetAmountCard.gros}%
                    </span>
                  </div>
                  <P attrPara={{ className: "f-light" }}>
                    {" "}
                    {assetAmountCard.subTitle}
                  </P>
                </div>
                <div className="radial-chart-wrap">
                  <RadialProgressChart
                    chartHeight={130}
                    chartOption={assetAmountCard}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col lg="6">
            <Card className="visitor-card">
              <CardHeader className="card-no-border">
                <div className="header-top">
                  <H5 attrH5={{ className: "m-0" }}>
                    Building Wise Asset Items
                    {/* <span className="f-14 font-primary f-w-500 ms-1">
                          <SvgIcon
                            iconId="user-visitor"
                            className="svg-fill me-1"
                          />
                          (+2.8)
                        </span> */}
                  </H5>
                </div>
              </CardHeader>
              <CardBody className="pt-0">
                <div className="visitors-container">
                  <ReactApexChart
                    height={270}
                    type="bar"
                    options={buildingBardChart.options}
                    series={buildingBardChart.series}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col lg="8">
            <Card>
              <CardHeader className="card-no-border">
                <div className="header-top">
                  <H5>Asset Category Wise Asset Items</H5>
                </div>
              </CardHeader>
              <CardBody className="pt-0">
                <Row className="m-0 overall-card">
                  <Col xl="8" className="col-xl-8">
                    <div className="chart-right">
                      <Row>
                        <Col xl="12" className="col-xl-12">
                          <CardBody className="p-0">
                            <div className="activity-wrap">
                              <ReactApexChart
                                type="bar"
                                height={300}
                                options={assetCategoriesBarChart.options}
                                series={assetCategoriesBarChart.series}
                              />
                            </div>
                          </CardBody>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                  <Col xl="4" className="p-0">
                    <Row className="g-sm-3 g-2 mt-0">
                      {assetCategorySummary.map((item, i) => (
                        <Col key={i} xl="12" md="6" sm="4">
                          <LightCardBox data={item} key={i} />
                        </Col>
                      ))}
                    </Row>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col lg="4">
            <Card>
              <CardHeader className="card-no-border">
                <H5>Asset Condition</H5>
                <span className="f-light f-w-500 f-14">
                  {/* ({MonthlyProfitsGrowth}) */}
                </span>
              </CardHeader>
              <CardBody className="pt-0">
                <div className="monthly-profit">
                  <ReactApexChart
                    type="donut"
                    height={300}
                    series={assetConditionPieChart.series}
                    options={assetConditionPieChart.options}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default SamplePage;
