import React, { useRef } from "react";
import { Trash } from "react-feather";
import Swal from "sweetalert2";
import { imageFileURL, MAX_ASSET_IMAGE_SIZE } from "../../../Config/Config";
import * as feather from "feather-icons";

const ImageUploader = ({
  attachment_list,
  preview_list,
  set_attachment_list,
  set_preview_list,
  max_file_limit,
  max_file_size,
}) => {
  // input field data
  const fileValidationMessage = useRef("");
  const fileComponent = useRef("");

  const handleRemove = (preview_item, index) => {
    let removingItem = undefined;
    if (fileComponent.current.classList.contains("is-invalid"))
      fileComponent.current.classList.remove("is-invalid");
    if (preview_item.id === 0) {
      removingItem = attachment_list.find(
        (item) => item.attachment === preview_item.source
      );
      if (removingItem !== undefined) {
        let tempAttachments = attachment_list;
        tempAttachments.splice(attachment_list.indexOf(removingItem), 1);
        let tempPreviews = preview_list;
        tempPreviews.splice(index, 1);
        set_attachment_list([...tempAttachments]);
        set_preview_list([...tempPreviews]);
      }
    } else {
      Swal.fire({
        icon: "warning",
        title: "Are you sure?",
        text: "This will permanently deletes this file from the server.",
        showDenyButton: true,
        confirmButtonText: "Delete",
        denyButtonText: "Cancel",
        denyButtonColor: "#2a3142",
      }).then((result) => {
        if (result.isConfirmed) {
          removingItem = attachment_list.find(
            (item) => item.documentId === preview_item.id
          );
          if (removingItem !== undefined) {
            let tempAttachments = attachment_list;
            tempAttachments.splice(attachment_list.indexOf(removingItem), 1);
            let tempPreviews = preview_list;
            tempPreviews.splice(index, 1);
            set_attachment_list([...tempAttachments]);
            set_preview_list([...tempPreviews]);
          }
        }
      });
    }
  };

  const handleFileUpload = (event) => {
    if (event.target.files[0].size < parseInt(MAX_ASSET_IMAGE_SIZE)) {
      set_preview_list((prevList) => [
        ...prevList,
        {
          id: 0,
          type: event.target.files[0].type,
          source: event.target.files[0],
        },
      ]);
      set_attachment_list((prev_list_items) => [
        ...prev_list_items,
        {
          documentId: 0,
          isModified: true,
          attachmentExtension: `${event.target.files[0].type.split("/")[1]}`,
          attachment: event.target.files[0], //multipart form data
        },
      ]);
    }
  };

  return (
    <div className="form-group mb-4">
      <label>Image{max_file_limit > 1 ? "s" : ""}</label>
      <div ref={fileComponent} className="border rounded p-4 form-control">
        {/* previewer */}
        <div className="form-row">
          {preview_list.map((list_item, index) => (
            <div
              className={`col-12 col-sm-5 m-2 py-2 px-2 d-flex align-items-center justify-content-between bg-light rounded`}
              key={index}
            >
              <img
                width="200px"
                alt=""
                src={
                  list_item.id
                    ? `${imageFileURL}${list_item.source}`
                    : URL.createObjectURL(list_item.source)
                }
                onError={(event) => {
                  event.target.src = "";
                  event.onerror = null;
                }}
                className="rounded"
              />
              <div
                dangerouslySetInnerHTML={{
                  __html: feather.icons["trash-2"].toSvg({
                    height: "14px",
                    width: "14px",
                  }),
                }}
                className="txt-danger bg-light-danger px-2 py-2 b-r-4 d-flex align-items-center m-r-10"
                onClick={() => handleRemove(list_item, index)}
              />
            </div>
          ))}
        </div>
        {/* input handler */}
        <div className="btn btn-outline-primary position-relative">
          Add Image{max_file_limit > 1 ? "s" : ""}
          <input
            type="file"
            accept="image/*"
            className="form-control"
            onChange={(e) => handleFileUpload(e)}
          />
        </div>
      </div>
      <div ref={fileValidationMessage} className="invalid-feedback"></div>
      <small className="txt-warning">
        <ul className="d-sm-flex">
          <li>1. Maximum File size is {max_file_size / 1024 ** 2}MB.</li>
          <li className="mx-sm-3">
            2. You can upload upto {max_file_limit} files.
          </li>
          <li className="">3. Only Images are allowed.</li>
        </ul>
      </small>
    </div>
  );
};

export default ImageUploader;
