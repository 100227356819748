import React, { useEffect, useState } from "react";
import CommonModal from "../../../Common/Component/CommonModal";
import { Container, Row, Col, Form, Label, Input } from "reactstrap";
import { useForm } from "react-hook-form";
import { Btn } from "../../../../AbstractElements";
import axios from "axios";
import { toast } from "react-toastify";
import { adminBackendURL, backendURL } from "../../../../Config/Config";
import { useContext } from "react";
import UserContext from "../../../../_helper/User";

const SaveEmployeeLocationModel = ({
  is_open,
  trigger_model,
  model_data,
  form_data,
  reload_table,
}) => {
  const userContext = useContext(UserContext)
  const loggedUser = userContext.userData;
  const toggle = () => trigger_model(!is_open);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });
  const onSubmit = (data) => {
    const form = new FormData(document.querySelector(".form-1"));
    let assignLocationModelList = [];
    const selectedFunctions = form.getAll("fm_1_location");
    selectedFunctions.forEach((item) =>
      assignLocationModelList.push({
        locationId: item,
        searchKey: "",
        name: "",
        description: "",
      })
    );
    if (data !== "") {
      const id = toast.loading("Please wait...");
      const role = {
        clientId: loggedUser.clientId,
        orgId: loggedUser.orgId,
        userId: loggedUser.userId,
        moduleId: loggedUser.loggedModuleId,
        bpartnerId: form.get("fm_1_sa_bpartner_id"),
        assignLocationModelList: assignLocationModelList,
      };
      const reqInstance = axios.create({
        baseURL: adminBackendURL,
      });
      reqInstance
        .post("/saveBpartnerLocation", role)
        .then((response_data) => {
          const result = response_data.data;
          if (result.hasError) {
            toast.update(id, {
              render: "Failed",
              type: "error",
              isLoading: false,
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          } else {
            let successMessage = "Employee Location List Succesfully Updated.";
            toast.update(id, {
              render: successMessage,
              type: "success",
              isLoading: false,
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            reload_table(true);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      errors.showMessages();
    }
  };
  const [locationList, setLocationList] = useState([]);
  const [bpartnerList, setBpartnerList] = useState([]);
  const [bpartnerValue, setBpartnerValue] = useState(0);

  useEffect(() => {
    if (form_data !== undefined) {
      setBpartnerList([
        { bpartnerId: 0, name: "- Select -" },
        ...form_data.employeeList,
      ]);
      setLocationList([...form_data.locationList]);
    } else {
      setBpartnerValue(0);
    }
  }, [model_data]);

  useEffect(() => {
    if (bpartnerValue > 0) {
      const reqBody = {
        clientId: 38,
        orgId: 41,
        bpartnerId: bpartnerValue,
        moduleId: 250,
      };
      const reqInstance = axios.create({
        baseURL: adminBackendURL,
      });
      reqInstance
        .post("/getBpartnerLocation", reqBody)
        .then((response_data) => {
          if (response_data) {
            const result = response_data.data;
            // setIterfaceFunctionList(result);
            result.forEach((location_item) => {
              document.querySelector(
                `#input_fm_1_active_location_id_${location_item.locationId}`
              ).checked = true;
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [bpartnerValue]);

  return (
    <CommonModal
      isOpen={is_open}
      toggler={toggle}
      bodyClass="grid-showcase"
      size="xl"
    >
      <Container fluid={true}>
        <Row>
          <Col sm="12" className="p-b-15">
            <Form onSubmit={handleSubmit(onSubmit)} className="form-1">
              <Row>
                <Col lg="2">
                  <Label
                    htmlFor="id_input_fm_1_sa_bpartner_id"
                    className="required-input-mark"
                  >
                    Employee
                  </Label>
                </Col>
                <Col lg="10" className="m-b-10">
                  <select
                    id="id_input_fm_1_sa_bpartner_id"
                    name="fm_1_sa_bpartner_id"
                    className="form-select"
                    {...register("fm_1_sa_bpartner_id", {
                      required: "Please select Employee",
                    })}
                    onChange={(evt) => setBpartnerValue(evt.target.value)}
                    value={bpartnerValue}
                  >
                    {bpartnerList.length
                      ? bpartnerList.map((list_item, index) => (
                          <option value={list_item?.bpartnerId} key={index}>
                            {list_item?.name}
                          </option>
                        ))
                      : null}
                  </select>
                  <span className="txt-danger f-12">
                    {errors?.fm_1_sa_bpartner_id &&
                      errors?.fm_1_sa_bpartner_id?.message}
                  </span>
                </Col>
              </Row>
              <Row className="m-b-10">
                <Col lg="2">
                  <Label
                    htmlFor="id_input_fm_1_sa_bpartner_location"
                    className="required-input-mark"
                  >
                    Locations
                  </Label>
                </Col>
                <Col lg="10">
                  <Row className="m-b-20">
                    {locationList?.length > 0
                      ? locationList.map((location_item, index) => (
                          <Col lg="6" key={index}>
                            <Label
                              className="d-block"
                              htmlFor="input_fm_1_active"
                              key={index}
                            >
                              <Input
                                className="checkbox_animated"
                                id={`input_fm_1_active_location_id_${location_item.locationId}`}
                                name={`fm_1_location`}
                                value={`${location_item.locationId}`}
                                type="checkbox"
                              />
                              {location_item.name}
                            </Label>
                          </Col>
                        ))
                      : null}
                  </Row>
                </Col>
              </Row>
              <div className="d-flex justify-content-end">
                <Btn
                  attrBtn={{
                    color: "primary",
                    size: "sm",
                    disabled: false,
                    outline: true,
                    type: "submit",
                    className: "m-r-10 d-flex align-items-center",
                  }}
                >
                  {model_data === "" ? "Submit" : "Update"}
                </Btn>
                {model_data !== "" ? (
                  <Btn
                    attrBtn={{
                      color: "danger",
                      size: "sm",
                      disabled: false,
                      outline: true,
                      className: "d-flex align-items-center",
                    }}
                  >
                    Delete
                  </Btn>
                ) : null}
              </div>
            </Form>
          </Col>
        </Row>
      </Container>
    </CommonModal>
  );
};

export default SaveEmployeeLocationModel;
