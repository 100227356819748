import React, { useContext, useState } from "react";
import { Container, Row, Col, Form, Label, Input } from "reactstrap";
import CommonModal from "../../../Common/Component/CommonModal";
import { useForm } from "react-hook-form";
import axios from "axios";
import { Btn, H5 } from "../../../../AbstractElements";
import { toast } from "react-toastify";
import { backendURL } from "../../../../Config/Config";
import UserContext from "../../../../_helper/User";
import Swal from "sweetalert2";
import AssetItemContext from "../../../../_helper/AssetItem/index.jsx"
import FileUploader from "./FileUploader";


const NewSaveDocumentGaleryModel = ({
  is_open,
  trigger_model,
  model_data,
  form_data,
  reload_table,
}) => {
  const userContext = useContext(UserContext);
  const loggedUser = userContext.userData;
  const toggle = () => trigger_model(!is_open);

  const { register, handleSubmit, formState: { errors } } = useForm({
    mode: "onChange",
  });

  const [previewFileList, setPreviewFileList] = useState([]);

  const [attachmentList, setAttachmentList] = useState([]);
  const assetContext = useContext(AssetItemContext);
  const assetItemData = assetContext.assetItem;



  const onSubmit = (data) => {
    const form = new FormData(document.querySelector(".form-1"));
    if (data !== "") {
      // const id = toast.loading("Please wait...");
      const reqInstance = axios.create({
        baseURL: backendURL,
      });

      // const assetDocument = {
      //   assetDocumentId: data.assetDocumentId ? data.assetDocumentId : 0,
      //   clientId: loggedUser.clientId,
      //   orgId: loggedUser.orgId,
      //   userId: loggedUser.userId,
      //   moduleId: loggedUser.loggedModuleId,
      //   categoryId: 12,
      //   searchKey: form.get("fm_1_asset_id"),
      //   name: form.get("fm_1_name"),
      //   isActive: form.get("fm_1_active") ? true : false,
      //   remark: form.get("fm_1_remark"),
      // };

      // reqInstance
      //   .post("/saveAssetDocument", assetDocument)
      //   .then((response_data) => {
      //     const result = response_data.data;
      //     if (result.hasError) {
      //       toast.update(id, {
      //         render: "Failed",
      //         type: "error",
      //         isLoading: false,
      //         position: "top-right",
      //         autoClose: 5000,
      //         hideProgressBar: true,
      //         closeOnClick: true,
      //         pauseOnHover: true,
      //         draggable: true,
      //         progress: undefined,
      //         theme: "light",
      //       });
      //     } else {
      //       let successMessage = "Asset Item Successfully Added";
      //       toast.update(id, {
      //         render: successMessage,
      //         type: "success",
      //         isLoading: false,
      //         position: "top-right",
      //         autoClose: 5000,
      //         hideProgressBar: true,
      //         closeOnClick: true,
      //         pauseOnHover: true,
      //         draggable: true,
      //         progress: undefined,
      //         theme: "light",
      //       });


      // add images
      let modifiedAttachmentListSize = [];
      if (attachmentList.length > 0) {
        modifiedAttachmentListSize = attachmentList.filter(
          (file_item) => file_item.isModified
        );
        if (modifiedAttachmentListSize.length > 0) {
          const statusToast = toast.loading(
            `Uploading ${attachmentList.length} Attachment(s).`
          );
          attachmentList.forEach((attachment_item) => {
            if (attachment_item.isModified) {
              let fileData = new FormData();
              fileData.append(
                "assetDocumentId",
                parseInt(attachment_item.documentId)
              );
              fileData.append("clientId", loggedUser.clientId);
              fileData.append("orgId", loggedUser.orgId);
              fileData.append("userId", loggedUser.userId);
              fileData.append("moduleId", loggedUser.loggedModuleId);
              fileData.append(
                "isAttachmentModified",
                attachment_item.isModified
              );
              fileData.append("documentTypeId", "1");
              fileData.append(
                "attachmentExtension",
                attachment_item.attachmentExtension
              );
              fileData.append(
                "attachment",
                attachment_item.attachment
              );
              fileData.append("isActive", "true");
              fileData.append("name", data.fm_1_document_title);
              fileData.append("remark", data.fm_1_remark);
              reqInstance
                .post("/saveAssetDocument", fileData, {
                  headers: {
                    "Content-Type": "multipart/form-data",
                  },
                })
                .then((file_response_data) => {
                  const uploadResult = file_response_data.data;
                  console.log(file_response_data);
                  if (!uploadResult.hasError) {
                    toast.update(statusToast, {
                      render: "Attachments Uploaded Failed.",
                      type: "success",
                      isLoading: false,
                      position: "top-right",
                      autoClose: 5000,
                      hideProgressBar: true,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                      theme: "light",
                    });
                  } else {
                    toast.update(statusToast, {
                      render: "Attachments Uploaded.",
                      type: "success",
                      isLoading: false,
                      position: "top-right",
                      autoClose: 5000,
                      hideProgressBar: true,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                      theme: "light",
                    });
                  }
                });
            }
          });
        }
      }
      // refresh item
      assetContext.refreshAssetItem();
      // }
      // })
      // .catch((error) => {
      //   console.error(error);
      // });

    };
  };







  const deleteAssetCategory = () => {
    Swal.fire({
      icon: "warning",
      title: "Are you sure?",
      text: "This will permanently delete this Document.",
      showDenyButton: true,
      confirmButtonText: "Delete",
      denyButtonText: "Cancel",
      denyButtonColor: "#2a3142",
    }).then((confirmation) => {
      if (confirmation.isConfirmed) {
        const id = toast.loading("Please wait...");
        const reqInstance = axios.create({
          baseURL: backendURL,
        });
        const assetCategory = {
          assetCategoryId: model_data === "" ? 0 : model_data.assetCategoryId,
          clientId: loggedUser.clientId,
          orgId: loggedUser.orgId,
          userId: loggedUser.userId,
        };
        reqInstance
          .post("/deleteAssetCategory", assetCategory)
          .then((response_data) => {
            const result = response_data.data;
            if (result.hasError) {
              toast.update(id, {
                render: result.errorMsg,
                type: "error",
                isLoading: false,
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
            } else {
              let successMessage = "Document Successfully Deleted.";
              toast.update(id, {
                render: successMessage,
                type: "success",
                isLoading: false,
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
              reload_table(true);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    });
  };

  return (
    <CommonModal
      isOpen={is_open}
      toggler={toggle}
      bodyClass="grid-showcase"
      size="xl"
    >
      <Container fluid={true}>
        <Row>
          <Col sm="12" className="p-b-15">
            <Form onSubmit={handleSubmit(onSubmit)} className="form-1">
              <Row>
                <Col lg="2">
                  <Label htmlFor="id_input_fm_1_upload_document" className="required-input-mark">
                    Document Title
                  </Label>
                </Col>
                <Col lg="10" className="m-b-10">
                  <input
                    id="id_input_fm_1_document_title"
                    name="fm_1_document_title"
                    type="text"
                    className="form-control"
                    placeholder="Enter Document Title"
                    {...register("fm_1_document_title", {
                      required: "Please enter Document Title",
                      value: model_data.name,
                      maxLength: {
                        value: 150,
                        message: "Please enter less than 150 characters.",
                      },
                    })}
                  />
                  <span className="txt-danger f-12">
                    {errors?.fm_1_document_title && errors?.fm_1_document_title?.message}
                  </span>
                </Col>
              </Row>
              {/* <Row>
                <Col lg="2" className="m-b-10">
                  <Label htmlFor="fileInput" className="required-input-mark">
                    Select File
                  </Label>
                </Col>
                <Col lg="4">
                  <div className="d-flex">
                    <input
                      id="fileInput"
                      name="fm_1_file"
                      type="file"
                      onChange={fileChange}
                      className="form-control"
                      required // This attribute makes the file input field mandatory
                    />
                    <label htmlFor="fileInput" className="btn btn-primary ml-2">
                      Choose File
                    </label>
                  </div>
                  <span className="txt-danger f-12">
                    {errors?.fileInput && errors?.fileInput?.message}
                  </span>
                </Col>
              </Row> */}
             
              <FileUploader
                attachment_list={attachmentList}
                preview_list={previewFileList}
                set_attachment_list={setAttachmentList}
                set_preview_list={setPreviewFileList}
                max_file_limit={1}
                max_file_size={5242880}
              />
              
              <Row>
                <Col lg="2">
                  <Label htmlFor="id_input_fm_1_remark">Remark</Label>
                </Col>
                <Col lg="10" className="m-b-10">
                  <textarea
                    id="id_input_fm_1_remark"
                    name="fm_1_remark"
                    rows="3"
                    className="form-control"
                    placeholder="Enter Remarks"
                    {...register("fm_1_remark", {
                      maxLength: {
                        value: 250,
                        message: "Maximum allowed character length is 250.",
                      },
                    })}
                  ></textarea>
                </Col>
              </Row>
              <Row>
                <Col lg="6" className="offset-lg-2 m-b-10">
                  <Label className="d-block" htmlFor="input_fm_1_active">
                    <Input
                      className="checkbox_animated"
                      id="input_fm_1_active"
                      name="fm_1_active"
                      type="checkbox"
                      defaultChecked={model_data !== "" ? model_data.isActive : true}
                      {...register("fm_1_active")}
                    />
                    Active
                  </Label>
                </Col>
              </Row>
              <div className="d-flex justify-content-end">
                {model_data !== "" ? (
                  <Btn
                    attrBtn={{
                      color: "danger",
                      size: "sm",
                      disabled: false,
                      outline: true,
                      className: "m-r-10 d-flex align-items-center",
                      type: "button",
                      onClick: () => deleteAssetCategory(),
                    }}
                  >
                    Delete
                  </Btn>
                ) : null}
                <Btn
                  attrBtn={{
                    color: "primary",
                    size: "sm",
                    disabled: false,
                    outline: true,
                    type: "submit",
                    className: "d-flex align-items-center",
                  }}
                >
                  {model_data === "" ? "Submit" : "Update"}
                </Btn>
              </div>
            </Form>
          </Col>
        </Row>
      </Container>
    </CommonModal>
  );
};
export default NewSaveDocumentGaleryModel;
