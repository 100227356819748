import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Media,
  Button,
  Label,
  Form,
} from "reactstrap";
import { Breadcrumbs, Btn, Spinner } from "../../../AbstractElements";
import HeaderCard from "../../Common/Component/HeaderCard";
import ReactTable from "react-data-table-component";
import { useForm } from "react-hook-form";
import AssetItemContext from "../../../_helper/AssetItem";
import axios from "axios";
import { backendURL } from "../../../Config/Config";
import { toast } from "react-toastify";
import UserContext from "../../../_helper/User";
import { ConvertDateToDate, ConvertMillisToDate } from "../../../Data/DateUtil";
import * as feather from "feather-icons";
import SearchAssetItemForTransfer from "./Model/SearchAssetItemForTransfer";

const Transfer = () => {
  const userContext = useContext(UserContext);
  const loggedUser = userContext.userData;
  /**Page Status Section */
  const [fetchingTableData, setFetchingTableData] = useState(true);
  const [triggerSearchModel, setTriggerSearchModel] = useState(false);
  const [assetItemIdList, setAssetItemIdList] = useState([]);
  const [modelFormData, setModelFormData] = useState();
  const [modelData, setModelData] = useState();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const assetContext = useContext(AssetItemContext);
  const assetItemData = assetContext.assetItem;
  // Set Form Data
  const [dpAssetSiteList, setDpAssetSiteList] = useState([]);
  const [dpBuildingList, setDpAssetBuildingList] = useState([]);
  const [dpFloorList, setDpFloorList] = useState([]);
  const [dpLocationList, setDpLocationList] = useState([]);
  const [dpDepartmentList, setDpDepartmentList] = useState([]);
  const [dpSiteValue, setDpSiteValue] = useState(0);
  const [dpBuildingValue, setDpBuildingValue] = useState(0);
  const [dpFloorValue, setDpFloorValue] = useState(0);

  useEffect(() => {
    if (dpSiteValue > 0) {
      const reqBody = {
        clientId: loggedUser.clientId,
        orgId: loggedUser.orgId,
        userId: loggedUser.userId,
        siteId: dpSiteValue,
      };
      const reqInstance = axios.create({
        baseURL: backendURL,
      });
      reqInstance
        .post("/getAllSiteBuilding", reqBody)
        .then((response_data) => {
          if (response_data) {
            const result = response_data.data;
            setDpAssetBuildingList([
              { buildingId: 0, name: "- Select -" },
              ...result,
            ]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [dpSiteValue]);

  useEffect(() => {
    if (dpBuildingValue > 0) {
      const reqBody = {
        clientId: loggedUser.clientId,
        orgId: loggedUser.orgId,
        userId: loggedUser.userId,
        moduleId: loggedUser.loggedModuleId,
        buildingId: dpBuildingValue,
      };
      const reqInstance = axios.create({
        baseURL: backendURL,
      });
      reqInstance
        .post("/getAllBuildingFloor", reqBody)
        .then((response_data) => {
          if (response_data) {
            const result = response_data.data;
            setDpFloorList([{ floorId: 0, name: "- Select -" }, ...result]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [dpBuildingValue]);

  useEffect(() => {
    if (dpFloorValue > 0) {
      const reqBody = {
        clientId: loggedUser.clientId,
        orgId: loggedUser.orgId,
        userId: loggedUser.userId,
        moduleId: loggedUser.loggedModuleId,
        siteId: dpSiteValue,
        buildingId: dpBuildingValue,
        floorId: dpFloorValue,
      };
      const reqInstance = axios.create({
        baseURL: backendURL,
      });
      reqInstance
        .post("/getAllSiteBuildingFloorLocation", reqBody)
        .then((response_data) => {
          if (response_data) {
            const result = response_data.data;
            setDpLocationList([
              { locationId: 0, name: "- Select -" },
              ...result,
            ]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [dpFloorValue]);

  // const onSubmit = (data) => {
  //   const form = new FormData(document.querySelector(".form-1"));
  //   if (data !== "") {
  //     const id = toast.loading("Please wait...");
  //     const reqInstance = axios.create({
  //       baseURL: backendURL,
  //     });
  //     const assetTransfer = {
  //       transferId: 0,
  //       clientId: loggedUser.clientId,
  //       orgId: loggedUser.orgId,
  //       userId: loggedUser.userId,
  //       moduleId: loggedUser.loggedModuleId,
  //       isActive: true,
  //       searchKey: "",
  //       remark: "",
  //       assetSiteTo: form.get("fm_1_site_id"),
  //       buildingTo: form.get("fm_1_building_id"),
  //       floorTo: form.get("fm_1_floor_id"),
  //       locationTo: form.get("fm_1_location_id"),
  //       departmentTo: form.get("fm_1_department_id"),
  //       transferredOn: ConvertDateToDate(new Date()),
  //       financialYear: new Date().getFullYear(),
  //       assetItemMasterId: assetItemIdList,
  //     };
  //     reqInstance
  //       .post("/saveTransfer", assetTransfer)
  //       .then((response_data) => {
  //         const result = response_data.data;
  //         setFetchingTableData(true);
  //         if (result.hasError) {
  //           toast.update(id, {
  //             render: "Failed",
  //             type: "error",
  //             isLoading: false,
  //             position: "top-right",
  //             autoClose: 5000,
  //             hideProgressBar: true,
  //             closeOnClick: true,
  //             pauseOnHover: true,
  //             draggable: true,
  //             progress: undefined,
  //             theme: "light",
  //           });
  //         } else {
  //           let successMessage = "";
  //           successMessage = "Asset Transfer Successfully Added.";

  //           // }
  //           toast.update(id, {
  //             render: successMessage,
  //             type: "success",
  //             isLoading: false,
  //             position: "top-right",
  //             autoClose: 5000,
  //             hideProgressBar: true,
  //             closeOnClick: true,
  //             pauseOnHover: true,
  //             draggable: true,
  //             progress: undefined,
  //             theme: "light",
  //           });
  //         }
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //       });
  //   }
  //   //     }
  //   //   });
  //   // }

  //   // }
  //   else {
  //     errors.showMessages();
  //   }
  // };

  /**Datatable Section */
  const statusSort = (rowA, rowB) => {
    console.log(rowA);
    const a = rowA.cost.props.children;
    const b = rowB.cost.props.children;

    if (a > b) {
      return 1;
    }

    if (b > a) {
      return -1;
    }

    return 0;
  };
  const tableColumns = useRef([
    {
      name: "Asset Item",
      selector: (row) => `${row.assetItem.name}`,
      sortable: true,
      center: false,
      download: true,
    },
    {
      name: "Site",
      selector: (row) => `${row.siteTo.name}`,
      sortable: true,
      center: true,
      download: true,
    },
    {
      name: "Building",
      selector: (row) => `${row.buildingTo.name}`,
      sortable: true,
      center: true,
      download: true,
    },
    {
      name: "Floor",
      selector: (row) => `${row.floorTo.name}`,
      sortable: true,
      center: true,
      download: true,
    },
    {
      name: "Location",
      selector: (row) => `${row.locationTo.name}`,
      sortable: true,
      center: true,
      download: true,
    },
    {
      name: "Department",
      selector: (row) => `${row.departmentTo.name}`,
      sortable: true,
      center: true,
      download: true,
    },
    {
      name: "Transferred On",
      selector: (row) => `${ConvertMillisToDate(row.transferredOn)}`,
      sortable: true,
      center: true,
      download: true,
      omit: true
    },
    // {
    //   name: "Action",
    //   selector: (row) => row["action"],
    //   sortable: true,
    //   center: true,
    //   ignoreRowClick: true,
    //   download: false,
    // },
  ]);

  const detailsTableColumns = useRef([
    {
      name: "Asset Item",
      selector: (row) => row["name"],
      sortable: true,
      center: false,
      download: true,
    },
    {
      name: "Site",
      selector: (row) => `${row.assetSite.name}`,
      sortable: true,
      center: true,
      download: true,
    },
    {
      name: "Building",
      selector: (row) => `${row.building.name}`,
      sortable: true,
      center: true,
      download: true,
    },
    {
      name: "Floor",
      selector: (row) => `${row.floor.name}`,
      sortable: true,
      center: true,
      download: true,
    },
    {
      name: "Location",
      selector: (row) => `${row.location.name}`,
      sortable: true,
      center: true,
      download: true,
    },
    {
      name: "Action",
      selector: (row) => row["action"],
      sortable: true,
      center: true,
      ignoreRowClick: true,
      download: false,
    },
  ]);

  const [tableData, setTableData] = useState();
  const [detailsTableData, setDetailsTableData] = useState();
  const [showMoveForm, setShowMoveForm] = useState(false);
  const assetData = useRef([]);
  const [dpLocationValue, setDpLocationValue] = useState(0);
  const [dpDepartmentValue, setDpDepartmentValue] = useState(0);

  const onSubmit = () => {
    const assetItemIds = detailsTableData.map((item) => item.assetItemId);
    console.log(assetItemIds);
    const selectedSiteId = dpSiteValue;
    const selectedBuildingId = dpBuildingValue;
    const selectedFloorId = dpFloorValue;
    const selectedLocationId = dpLocationValue;
    const selectedDepartmentId = dpDepartmentValue;

    const reqBody = {
      transferId: 0,
      clientId: loggedUser.clientId,
      orgId: loggedUser.orgId,
      isActive: true,
      searchKey: "",
      remark: "",
      moduleId: loggedUser.loggedModuleId,
      floorTo: selectedFloorId,
      buildingTo: selectedBuildingId,
      userId: loggedUser.userId,
      assetSiteTo: selectedSiteId,
      locationTo: selectedLocationId,
      departmentTo: selectedDepartmentId,
      financialYear: "",
      assetItemMasterId: assetItemIds,
    };
    const reqInstance = axios.create({
      baseURL: backendURL,
    });
    reqInstance
      .post("/saveBulkAssetTransfer", reqBody)
      .then((response_data) => {
        console.log("response_data is ", response_data);
      });

    setDpSiteValue(0);
    setDpBuildingValue(0);
    setDpFloorValue(0);
    setDpLocationValue(0);
    setDpDepartmentValue(0);
    setDetailsTableData([]);
  };

  // Load Table Data
  useEffect(() => {
    if (fetchingTableData && Object.keys(loggedUser).length > 0) {
      const reqBody = {
        clientId: loggedUser.clientId,
        orgId: loggedUser.orgId,
        userId: loggedUser.userId,
        moduleId: loggedUser.loggedModuleId,
      };
      const reqInstance = axios.create({
        baseURL: backendURL,
      });
      reqInstance
        .post("/viewAssetTransfer", reqBody)
        .then((response_data) => {
          if (response_data) {
            const result = response_data.data;
            console.log("result is ", result);
            if (!result.hasError) {
              // console.log("result is ", result);
              let assetTransferData = [];
              setDpAssetSiteList([
                { assetSiteId: 0, name: "- Select -" },
                ...result.activeAssetSiteList,
              ]);
              setDpDepartmentList([
                { departmentId: 0, name: "- Select -" },
                ...result.activeDepartmentList,
              ]);
              result.allTransferList.forEach((transfer_item) => {
                console.log("transfer_item ", transfer_item);
                assetTransferData.push({
                  ...transfer_item,
                  status: transfer_item?.isActive ? (
                    <span className="badge badge-light-info">Available</span>
                  ) : (
                    <span className="badge badge-light-danger">
                      Not Available
                    </span>
                  ),
                });
              });
              setTableData(assetTransferData);
              assetData.current = assetTransferData;
              setFetchingTableData(false);
            } else {
              setTableData([]);
              setFetchingTableData(false);
              toast.error("Request Failed", {
                isLoading: false,
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
    setTableData(assetData.current);
  }, [loggedUser, fetchingTableData]);

  const handleBasicSearch = (evt) => {
    console.log(assetData.current);
    let inputValue = evt.target.value.toLocaleLowerCase();
    if (inputValue.length > 0) {
      let searchResults = assetData.current;
      searchResults = searchResults.filter((search_row) => {
        let possibleValues = Object.values(search_row.assetItem).filter(
          (search_prop) =>
            typeof search_prop === "string" || typeof search_prop === "number"
        );
        // console.log(possibleValues);
        return (
          possibleValues.filter(
            (target_value) =>
              target_value
                .toString()
                .toLocaleLowerCase()
                .indexOf(inputValue) !== -1
          )?.length > 0
        );
      });
      console.log(searchResults);
      setTableData(searchResults);
    } else {
      setTableData(assetData.current);
    }
  };

  /**Page Onload Section */
  useEffect(() => {
    setTableData(assetData.current);
    setFetchingTableData(true);
  }, []);

  const removeSelectedItem = (item) => {
    setDetailsTableData(prevData => prevData.filter(exist_item => item.assetItemId !== exist_item.assetItemId))
  }

  const handleSelectedData = (data) => {
    setDetailsTableData((prevData) => {
      if (!Array.isArray(prevData)) {
        prevData = [];
      }

      // Ensure data is always in an array
      if (Array.isArray(data)) {
        return [
          // ...prevData,
          ...data.map((item) => ({
            ...item,
            fixedAsset: item.isBulk ? (
              <span className="f-w-700 font-info">Yes</span>
            ) : (
              <span className="font-danger">No</span>
            ),
            action: (
              <Media>
                <Btn
                  attrBtn={{
                    color: "warning",
                    size: "sm",
                    disabled: false,
                    outline: true,
                    className: "m-r-10",
                    onClick: () => removeSelectedItem(item),
                  }}
                >
                  Remove
                </Btn>
              </Media>
            ),
          })),
        ];
      } else if (data) {
        return [
          // ...prevData,
          {
            ...data,
            fixedAsset: data.isBulk ? (
              <span className="f-w-700 font-info">Yes</span>
            ) : (
              <span className="font-danger">No</span>
            ),
          },
        ];
      }

      return prevData;
    });
  };

  const handleAddAsset = () => {
    setShowMoveForm(true);
    setTriggerSearchModel(true);
  };

  const handleCloseAddAsset = () => {
    setShowMoveForm(false);
    setDetailsTableData([]);
  };

  const convertArrayOfObjectsToCSV = (header_list, table_data) => {
    let result;

    const columnHeaders = [];
    header_list.current.forEach((header_item) => {
      if (header_item.download) columnHeaders.push(header_item.name);
    });
    const columnDelimiter = ",";
    const lineDelimiter = "\n";
    const keys = columnHeaders;

    result = "";
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    table_data.forEach((item) => {
      let ctr = 0;

      //extracting required data
      let rowData = [
        item.assetItem.name,
        item.floorTo.name,
        item.buildingTo.name,
        item.siteTo.name,
        item.locationTo.name,
        item.departmentTo.name,
        ConvertMillisToDate(item.transferredOn),
        // item.status.props.children,
      ];

      rowData.forEach((row_item) => {
        if (ctr > 0) result += columnDelimiter;

        result += row_item;

        ctr++;
      });
      result += lineDelimiter;
    });

    return result;
  };
  const downloadCSV = (header_list, table_data) => {
    const link = document.createElement("a");
    let csv = convertArrayOfObjectsToCSV(header_list, table_data);
    if (csv == null) return;

    const currentTimestamp = new Date();
    const filename = `Trasnferred Assets ${currentTimestamp}.csv`;

    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }

    link.setAttribute("href", encodeURI(csv));
    link.setAttribute("download", filename);
    link.click();
  };

  return (
    <Fragment>
      <Breadcrumbs mainTitle="Asset" parent="Asset" title="Transfer" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <HeaderCard
                title="Transfer/Move"
                span1="Asset transfer or asset movement refers to the process of relocating or transferring assets from one location or entity to another.This process allows for the transfer of one or more items at once."
              />
              <CardBody>
                <Row className="m-b-20">
                  <Col md="4 p-l-10">
                    <Button
                      type="button"
                      color="primary"
                      onClick={() => handleAddAsset()}
                    >
                      Transfer Asset(s)
                    </Button>
                  </Col>
                </Row>
                <Row className="m-b-5 justify-content-between align-items-center">
                  <Col md="4 p-l-10">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Search..."
                      onKeyDown={(evt) => handleBasicSearch(evt)}
                    />
                  </Col>
                  <Col md="3" className="d-flex justify-content-end">
                    <div
                      className="d-flex"
                      onClick={() => downloadCSV(tableColumns, tableData)}
                    >
                      <div
                        dangerouslySetInnerHTML={{
                          __html: feather.icons["download"].toSvg({
                            height: "16px",
                            width: "16px",
                          }),
                        }}
                        className={`txt-info m-r-10 d-flex align-items-center`}
                      />
                      Export CSV
                    </div>
                  </Col>
                </Row>
                <ReactTable
                  columns={tableColumns.current}
                  data={tableData}
                  progressPending={fetchingTableData}
                  progressComponent={
                    <div className="">
                      <div className="loader-box">
                        <Spinner attrSpinner={{ className: "loader-26" }} />
                      </div>
                      <small>Searching...</small>
                    </div>
                  }
                  pointerOnHover
                  pagination
                />
              </CardBody>
            </Card>
            {showMoveForm ? (
              <Card>
                <CardBody>
                  <ReactTable
                    columns={detailsTableColumns.current}
                    data={detailsTableData || []} // Ensure detailsTableData is displayed
                    progressComponent={
                      <div className="">
                        <div className="loader-box">
                          <Spinner attrSpinner={{ className: "loader-26" }} />
                        </div>
                        <small>Fetching...</small>
                      </div>
                    }
                    pointerOnHover
                    pagination
                  />
                  {/* Start of Location Details */}
                  <Form
                    className="form-1 m-t-10"
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <Row className="m-b-10">
                      <Col md="2">
                        <Label
                          htmlFor="input_fm_1_site_id"
                          className="required-input-mark"
                        >
                          Asset Site
                        </Label>
                      </Col>
                      <Col md="4" className="m-b-10">
                        <select
                          id="input_fm_1_site_id"
                          name="fm_1_site_id"
                          className={`form-select ${
                            dpAssetSiteList.length > 0 ? "" : "disabled"
                          }`}
                          {...register("fm_1_site_id", {
                            required: "Please select Site",
                          })}
                          onChange={(evt) => setDpSiteValue(evt.target.value)}
                          value={dpSiteValue}
                        >
                          {dpAssetSiteList.length
                            ? dpAssetSiteList.map((list_item, index) => (
                                <option
                                  value={list_item?.assetSiteId}
                                  key={index}
                                >
                                  {list_item?.name}
                                </option>
                              ))
                            : null}
                        </select>
                        <span className="valid-feedback">
                          {errors?.fm_1_site_id &&
                            errors?.fm_1_site_id?.message}
                        </span>
                      </Col>
                      <Col md="2">
                        <Label
                          htmlFor="input_fm_1_department_id"
                          className="required-input-mark"
                        >
                          Department
                        </Label>
                      </Col>
                      <Col md="4" className="m-b-10">
                        <select
                          id="input_fm_1_department_id"
                          name="fm_1_department_id"
                          className={`form-select ${
                            dpDepartmentList.length > 0 ? "" : "disabled"
                          }`}
                          {...register("fm_1_department_id", {
                            required: "Please select Department",
                          })}
                          onChange={(evt) =>
                            setDpDepartmentValue(evt.target.value)
                          }
                          value={dpDepartmentValue}
                        >
                          {dpDepartmentList.length
                            ? dpDepartmentList.map((list_item, index) => (
                                <option
                                  value={list_item?.departmentId}
                                  key={index}
                                >
                                  {list_item?.name}
                                </option>
                              ))
                            : null}
                        </select>{" "}
                        <span className="valid-feedback">
                          {errors?.fm_1_department_id &&
                            errors?.fm_1_department_id?.message}
                        </span>
                      </Col>
                    </Row>
                    <Row className="m-b-10">
                      <Col md="2">
                        <Label
                          htmlFor="input_fm_1_building_id"
                          className="required-input-mark"
                        >
                          Building
                        </Label>
                      </Col>
                      <Col md="4" className="m-b-10">
                        <select
                          id="input_fm_1_building_id"
                          name="fm_1_building_id"
                          className={`form-select ${
                            dpBuildingList.length ? "" : "disabled"
                          }`}
                          {...register("fm_1_building_id", {
                            required: "Please select Building",
                          })}
                          onChange={(evt) =>
                            setDpBuildingValue(evt.target.value)
                          }
                          value={dpBuildingValue}
                        >
                          {dpBuildingList.length
                            ? dpBuildingList.map((list_item, index) => (
                                <option
                                  value={list_item?.buildingId}
                                  key={index}
                                >
                                  {list_item?.name}
                                </option>
                              ))
                            : null}
                        </select>
                        <span className="valid-feedback">
                          {errors?.fm_1_building_id &&
                            errors?.fm_1_building_id?.message}
                        </span>
                      </Col>
                    </Row>
                    <Row className="m-b-10">
                      <Col md="2">
                        <Label
                          htmlFor="input_fm_1_floor_id"
                          className="required-input-mark"
                        >
                          Floor
                        </Label>
                      </Col>
                      <Col md="4" className="m-b-10">
                        <select
                          id="input_fm_1_floor_id"
                          name="fm_1_floor_id"
                          className={`form-select ${
                            dpFloorList.length > 0 ? "" : "disabled"
                          }`}
                          {...register("fm_1_floor_id", {
                            required: "Please select Floor",
                          })}
                          onChange={(evt) => setDpFloorValue(evt.target.value)}
                          value={dpFloorValue}
                        >
                          {dpFloorList.length
                            ? dpFloorList.map((list_item, index) => (
                                <option value={list_item?.floorId} key={index}>
                                  {list_item?.name}
                                </option>
                              ))
                            : null}
                        </select>{" "}
                        <span className="valid-feedback">
                          {errors?.fm_1_floor_id &&
                            errors?.fm_1_floor_id?.message}
                        </span>
                      </Col>
                    </Row>
                    <Row className="m-b-10">
                      <Col md="2">
                        <Label
                          htmlFor="input_fm_1_location_id"
                          className="required-input-mark"
                        >
                          Location
                        </Label>
                      </Col>
                      <Col md="4" className="m-b-10">
                        <select
                          id="input_fm_1_location_id"
                          name="fm_1_location_id"
                          className={`form-select ${
                            dpLocationList.length > 0 ? "" : "disabled"
                          }`}
                          {...register("fm_1_location_id", {
                            required: "Please select Location",
                          })}
                          onChange={(evt) =>
                            setDpLocationValue(evt.target.value)
                          }
                          value={dpLocationValue}
                        >
                          {dpLocationList.length
                            ? dpLocationList.map((list_item, index) => (
                                <option
                                  value={list_item?.locationId}
                                  key={index}
                                >
                                  {list_item?.name}
                                </option>
                              ))
                            : null}
                        </select>{" "}
                        <span className="valid-feedback">
                          {errors?.fm_1_location_id &&
                            errors?.fm_1_location_id?.message}
                        </span>
                      </Col>
                    </Row>
                    <div className="d-flex justify-content-end">
                      <Button
                        type="submit"
                        color="primary"
                        className="m-r-10"
                        // onClick={() => onHadelMove()}
                      >
                        {"Move"}
                      </Button>
                      <Btn
                        attrBtn={{
                          color: "primary",
                          size: "sm",
                          disabled: false,
                          outline: true,
                          onClick: handleCloseAddAsset,
                        }}
                      >
                        Cancel
                      </Btn>
                    </div>
                  </Form>
                  {/* End of Location Details */}
                </CardBody>
              </Card>
            ) : null}
            <SearchAssetItemForTransfer
              is_open={triggerSearchModel}
              trigger_model={setTriggerSearchModel}
              onItemSelected={handleSelectedData}
              model_data={modelData}
              form_data={modelFormData}
              reload_table={setFetchingTableData}
              set_items={setAssetItemIdList}
            />
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Transfer;
