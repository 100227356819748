import React, { useState } from "react";
import { Button, Col, Container, Form, Input, Label, Row } from "reactstrap";
import { useForm } from "react-hook-form";
import DatePicker from "react-datepicker";
import CommonModal from "../../../../Common/Component/CommonModal";

const AssetViewManageMaintenance = ({ is_open, trigger_model, model_data }) => {
  const weekDays = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const toggle = () => trigger_model(!is_open);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    data.prevetDefault();
    if (data !== "") {
      alert("You submitted the form and stuff!");
    } else {
      errors.showMessages();
    }
  };
  const [startDate, setstartDate] = useState(new Date());
  const [frequency, setFrequency] = useState(0);

  const handleChange = (date) => {
    setstartDate(date);
  };

  return (
    <CommonModal
      isOpen={is_open}
      toggler={toggle}
      bodyClass="grid-showcase"
      size="xl"
    >
      <Container fluid={true}>
        <Row>
          <Col sm="12" className="p-b-15">
            <Form
              className="needs-validation"
              noValidate=""
              onSubmit={handleSubmit(onSubmit)}
            >
              <Row className="justify-content-center">
                <Col lg="2">
                  <Label
                    htmlFor="input_fm_1_asset_list"
                    className="required-input-mark"
                  >
                    {"Name"}
                  </Label>
                </Col>
                <Col lg="10" className="m-b-10">
                  <input
                    id="input_fm_1_maintenance_by"
                    className="form-control"
                    name="fm_1_asset_list"
                    type="text"
                    placeholder="Name"
                    {...register("firstName", { required: true })}
                  />
                  <span className="txt-danger f-12">
                    {errors.firstName && "First name is required"}
                  </span>
                  <div className="valid-feedback">{"Looks good!"}</div>
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col lg="2">
                  <Label htmlFor="input_fm_1_asset_list">{"Description"}</Label>
                </Col>
                <Col lg="10" className="m-b-10">
                  <Input
                    type="textarea"
                    className="form-control"
                    placeholder="Type a Description here"
                    {...register("firstName", { required: true })}
                    rows="3"
                  />
                  <span className="txt-danger f-12">
                    {errors.firstName && "First name is required"}
                  </span>
                  <div className="valid-feedback">{"Looks good!"}</div>
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col lg="2">
                  <Label htmlFor="input_fm_1_date_acquired">{"Due Date"}</Label>
                </Col>
                <Col lg="10" className="m-b-10">
                  <DatePicker
                    id="input_fm_1_due_date"
                    className="form-control digits"
                    selected={startDate}
                    onChange={handleChange}
                  />
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col lg="2">
                  <Label htmlFor="input_fm_1_asset_list">
                    {"Maintenance By"}
                  </Label>
                </Col>
                <Col lg="10" className="m-b-10">
                  <input
                    id="input_fm_1_maintenance_by"
                    className="form-control"
                    name="fm_1_asset_list"
                    type="text"
                    placeholder="Maintenance By"
                    {...register("firstName", { required: true })}
                  />
                  <span className="txt-danger f-12">
                    {errors.firstName && "First name is required"}
                  </span>
                  <div className="valid-feedback">{"Looks good!"}</div>
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col lg="2">
                  <Label htmlFor="input_fm_1_status">{"Status"}</Label>
                </Col>
                <Col lg="10" className="m-b-10">
                  <select
                    id="input_fm_1_status"
                    name="fm_1_depreciation_method"
                    className="form-select"
                  ></select>
                  <span className="txt-danger f-12">
                    {errors.lastName && "Last name is required"}
                  </span>
                  <div className="valid-feedback">{"Looks good!"}</div>
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col lg="2">
                  <Label htmlFor="input_fm_1_date_acquired">
                    {"Date Completed"}
                  </Label>
                </Col>
                <Col lg="10" className="m-b-10">
                  <DatePicker
                    id="input_fm_1_date_completed"
                    className="form-control digits"
                    selected={startDate}
                    onChange={handleChange}
                  />
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col md="2">
                  <Label htmlFor="input_fm_1_cost">Maintenance Cost</Label>
                </Col>
                <Col md="10" className="m-b-10">
                  <div class="input-group mb-0">
                    <span class="input-group-text" id="basic-addon1">
                      $
                    </span>
                    <input
                      id="input_fm_1_cost"
                      type="text"
                      className="form-control"
                      name="fm_1_cost"
                      placeholder="00.00"
                      aria-label="Cost"
                      aria-describedby="basic-addon1"
                      {...register("fm_1_cost", {
                        pattern: {
                          value: /^\d+(\.\d+)?$/,
                          message: "Invalid Phone Number",
                        },
                      })}
                    />
                  </div>
                  <span className="txt-danger f-12">
                    {errors?.fm_1_cost && errors?.fm_1_cost?.message}
                  </span>
                </Col>{" "}
              </Row>
              <Row className="m-b-10">
                <Col sm="12" md="2">
                  <Label htmlFor="input_fm_1_repeating">Repeating</Label>
                </Col>
                <Col xs="12" sm="12" md="4" className="d-flex m-b-10">
                  <Col xs="6" sm="6">
                    <Label
                      className="d-flex align-items-center mb-0"
                      htmlFor="input_fm_1_repeating_yes"
                    >
                      <input
                        className="radio_animated"
                        id="input_fm_1_repeating_yes"
                        type="radio"
                        name="fm_1_repeating"
                        value={1}
                        {...register("fm_1_repeating")}
                      />
                      Yes
                    </Label>
                  </Col>
                  <Col xs="6" sm="6">
                    <Label
                      className="d-flex align-items-center mb-0"
                      htmlFor="input_fm_1_repeating_no"
                    >
                      <input
                        className="radio_animated m-l-5"
                        id="input_fm_1_repeating_no"
                        type="radio"
                        name="fm_1_repeating"
                        value={0}
                        {...register("fm_1_repeating")}
                      />
                      No
                    </Label>
                  </Col>
                </Col>
              </Row>
              <Row className="m-b-10">
                <Col sm="12" md="2">
                  <Label htmlFor="input_fm_1_frequency">Frequency</Label>
                </Col>
                <Col xs="12" sm="12" md="4" className="d-flex m-b-10">
                  <Col xs="6" sm="6">
                    <Label
                      className="d-flex align-items-center mb-0"
                      htmlFor="input_fm_1_frequency_daily"
                    >
                      <input
                        className="radio_animated"
                        id="input_fm_1_frequency_daily"
                        type="radio"
                        name="fm_1_frequency"
                        value={0}
                        onClick={() => setFrequency(0)}
                        {...register("fm_1_frequency")}
                      />
                      Daily
                    </Label>
                  </Col>
                  <Col xs="6" sm="6">
                    <Label
                      className="d-flex align-items-center mb-0"
                      htmlFor="input_fm_1_frequency_weekly"
                    >
                      <input
                        className="radio_animated m-l-5"
                        id="input_fm_1_frequency_weekly"
                        type="radio"
                        name="fm_1_frequency"
                        value={1}
                        onClick={() => setFrequency(1)}
                        {...register("fm_1_frequency")}
                      />
                      Weekly
                    </Label>
                  </Col>
                  <Col xs="6" sm="6">
                    <Label
                      className="d-flex align-items-center mb-0"
                      htmlFor="input_fm_1_frequency_monthly"
                    >
                      <input
                        className="radio_animated"
                        id="input_fm_1_frequency_monthly"
                        type="radio"
                        name="fm_1_frequency"
                        value={2}
                        onClick={() => setFrequency(2)}
                        {...register("fm_1_frequency")}
                      />
                      Monthly
                    </Label>
                  </Col>
                  <Col xs="6" sm="6">
                    <Label
                      className="d-flex align-items-center mb-0"
                      htmlFor="input_fm_1_frequency_yearly"
                    >
                      <input
                        className="radio_animated m-l-5"
                        id="input_fm_1_frequency_yearly"
                        type="radio"
                        name="fm_1_frequency"
                        value={3}
                        onClick={() => setFrequency(3)}
                        {...register("fm_1_frequency")}
                      />
                      Yearly
                    </Label>
                  </Col>
                </Col>
              </Row>
              {frequency > 0 ? (
                <Row className="m-b-10">
                  <Col md="2">
                    <Label htmlFor="">Recur On</Label>
                  </Col>
                  <Col md="10">
                    {frequency === 1 ? (
                      <Row>
                        <Col md="2">
                          <Label htmlFor="input_fm_1_weekly">Every</Label>
                        </Col>
                        <Col md="4">
                          <input
                            id="input_fm_1_weekly"
                            className="form-control"
                            name="fm_1_weekly"
                            type="text"
                            placeholder="1"
                            {...register("fm_1_weekly", {
                              pattern: {
                                value: /\b[0-9]\b/,
                                message: "Invalid Input",
                              },
                            })}
                          />
                        </Col>
                        <Col md="2">
                          <Label htmlFor="input_fm_1_weekly_day">
                            Week(s) On
                          </Label>
                        </Col>
                        <Col md="4">
                          <select
                            id="input_fm_1_weekly_day"
                            name="fm_1_weekly_day"
                            className="form-select"
                            {...register("fm_1_weekly_day")}
                          >
                            {weekDays.map((week_item, index) => (
                              <option key={index} value={index}>
                                {week_item}
                              </option>
                            ))}
                          </select>
                        </Col>
                      </Row>
                    ) : null}
                    {frequency === 2 ? (
                      <Row>
                        <Col md="2">
                          <Label htmlFor="input_fm_1_monthly">Every</Label>
                        </Col>
                        <Col md="4">
                          <input
                            id="input_fm_1_monthly"
                            className="form-control"
                            name="fm_1_monthly"
                            type="text"
                            placeholder="1"
                            {...register("fm_1_monthly", {
                              pattern: {
                                value: /\b[0-9]\b/,
                                message: "Invalid Input",
                              },
                            })}
                          />
                        </Col>
                        <Col md="2">
                          <Label htmlFor="input_fm_1_monthly_month">
                            Month(s) On
                          </Label>
                        </Col>
                        <Col md="4">
                          <select
                            id="input_fm_1_monthly_month"
                            name="fm_1_monthly_month"
                            className="form-select"
                            {...register("fm_1_monthly_month")}
                          >
                            {[...Array(30).keys()].map((month_item, index) =>
                              index ? (
                                <option key={index} value={index}>
                                  {month_item}
                                </option>
                              ) : null
                            )}
                          </select>
                        </Col>
                      </Row>
                    ) : null}
                    {frequency === 3 ? (
                      <Row>
                        <Col md="2">
                          <Label htmlFor="input_fm_1_yearly">Every</Label>
                        </Col>
                        <Col md="4">
                          <select
                            id="input_fm_1_yearly"
                            name="fm_1_yearly"
                            className="form-select"
                            {...register("fm_1_yearly")}
                          >
                            {months.map((month_item, index) => (
                              <option key={index} value={index}>
                                {month_item}
                              </option>
                            ))}
                          </select>
                        </Col>
                        <Col md="2">
                          <Label htmlFor="input_fm_1_yearly_month">On</Label>
                        </Col>
                        <Col md="4">
                          <select
                            id="input_fm_1_monthly_month"
                            name="fm_1_monthly_month"
                            className="form-select"
                            {...register("fm_1_monthly_month")}
                          >
                            {[...Array(30).keys()].map((month_item, index) =>
                              index ? (
                                <option key={index} value={index}>
                                  {month_item}
                                </option>
                              ) : null
                            )}
                          </select>
                        </Col>
                      </Row>
                    ) : null}
                  </Col>
                </Row>
              ) : null}
              <div className="d-flex justify-content-end">
                <Button type="submit" color="primary" className="m-r-10">
                  {model_data === "" ? "Submit" : "Update"}
                </Button>
                {model_data !== "" ? (
                  <Button type="button" color="danger" outline={true}>
                    Delete
                  </Button>
                ) : null}
              </div>
            </Form>
          </Col>
        </Row>
      </Container>
    </CommonModal>
  );
};
export default AssetViewManageMaintenance;
