import React, { useEffect, useState } from "react";
import CommonModal from "../../../Common/Component/CommonModal";
import { Container, Row, Col, Form, Label, Input } from "reactstrap";
import { useForm } from "react-hook-form";
import { Btn, H5 } from "../../../../AbstractElements";
import axios from "axios";
import { toast } from "react-toastify";
import { adminBackendURL, backendURL } from "../../../../Config/Config";
import { useContext } from "react";
import UserContext from "../../../../_helper/User";

const SaveDesignationModel = ({
  is_open,
  trigger_model,
  model_data,
  form_data,
  reload_table,
}) => {
  const userContext = useContext(UserContext);
  const loggedUser = userContext.userData;
  const toggle = () => trigger_model(!is_open);
  const {
    setValue,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    const form = new FormData(document.querySelector(".form-1"));
    if (data !== "") {
      const id = toast.loading("Please wait...");
      const designation = {
        designationId: model_data === "" ? 0 : model_data.designationId,
        clientId: loggedUser.clientId,
        orgId: loggedUser.orgId,
        userId: loggedUser.userId,
        moduleId: loggedUser.loggedModuleId,
        departmentId: parseInt(form.get("fm_1_sa_department_id")),
        isActive: form.get("fm_1_active") ? true : false,
        name: form.get("fm_1_name"),
        searchKey: form.get("fm_1_search_key"),
        remark: form.get("fm_1_remark"),
      };
      const reqInstance = axios.create({
        baseURL: adminBackendURL,
      });
      reqInstance
        .post("/saveDesignation", designation)
        .then((response_data) => {
          const result = response_data.data;
          if (result.hasError) {
            toast.update(id, {
              render: "Failed",
              type: "error",
              isLoading: false,
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          } else {
            let successMessage = "";
            if (designation.designationId > 0) {
              successMessage = "Designation Successfully Updated.";
            } else {
              successMessage = "Designation Successfully Added.";
            }
            toast.update(id, {
              render: successMessage,
              type: "success",
              isLoading: false,
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            reload_table(true);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      errors.showMessages();
    }
  };

  const [departmentList, setDepartmentList] = useState([]);

  useEffect(() => {
    if (form_data !== undefined) {
      setDepartmentList([
        { departmentId: "", name: "- Select -" },
        ...form_data.departmentList,
      ]);
    }
    if (model_data !== "") {
      console.log(model_data);
      setValue("fm_1_name", model_data?.name);
      setValue("fm_1_search_key", model_data?.searchKey);
      setValue("fm_1_remark", model_data?.remark);
      setValue("fm_1_sa_department_id", model_data?.department?.departmentId);
      setValue("fm_1_active", model_data?.isActive);
    } else {
      setValue("fm_1_name", "");
      setValue("fm_1_search_key", "");
      setValue("fm_1_remark", "");
      setValue("fm_1_sa_department_id", "");
      setValue("fm_1_active", true);
    }
  }, [model_data]);

  return (
    <CommonModal
      isOpen={is_open}
      toggler={toggle}
      bodyClass="grid-showcase"
      size="xl"
    >
      <Container fluid={true}>
        <Row>
          <Col sm="12" className="p-b-15">
            <Form onSubmit={handleSubmit(onSubmit)} className="form-1">
              <H5 attrH5={{ className: "m-b-30" }}>
                {model_data === "" ? "Add " : "Update "} Designation
              </H5>
              <Row>
                <Col lg="2">
                  <Label
                    htmlFor="id_input_fm_1_search_key"
                    className="required-input-mark"
                  >
                    Designation Code
                  </Label>
                </Col>
                <Col lg="4" className="m-b-10">
                  <input
                    id="id_input_fm_1_search_key"
                    name="fm_1_search_key"
                    type="text"
                    className="form-control"
                    placeholder="Enter Designation Code"
                    {...register("fm_1_search_key", {
                      required: "Please enter  Designation Code",
                      maxLength: {
                        value: 250,
                        message: "Please enter less than characters 250.",
                      },
                    })}
                  />
                  <span className="txt-danger f-12">
                    {errors?.fm_1_search_key &&
                      errors?.fm_1_search_key?.message}
                  </span>
                </Col>
                <Col lg="2">
                  <Label
                    htmlFor="id_input_fm_1_name"
                    className="required-input-mark"
                  >
                    Designation
                  </Label>
                </Col>
                <Col lg="4" className="m-b-10">
                  <input
                    id="id_input_fm_1_name"
                    name="fm_1_name"
                    type="text"
                    className="form-control"
                    placeholder="Enter Designation Name"
                    {...register("fm_1_name", {
                      required: "Please enter Designation Name",
                      maxLength: {
                        value: 250,
                        message: "Please enter less than characters 250.",
                      },
                    })}
                  />
                  <span className="txt-danger f-12">
                    {errors?.fm_1_name && errors?.fm_1_name?.message}
                  </span>
                </Col>
              </Row>
              <Row>
                <Col lg="2">
                  <Label
                    htmlFor="id_input_fm_1_sa_department_id"
                    className="required-input-mark"
                  >
                    Department
                  </Label>
                </Col>
                <Col lg="10" className="m-b-10">
                  <select
                    id="id_input_fm_1_sa_department_id"
                    name="fm_1_sa_department_id"
                    className="form-select"
                    {...register("fm_1_sa_department_id", {
                      required: "Please select Department",
                    })}
                  >
                    {departmentList.length
                      ? departmentList.map((list_item, index) => (
                          <option value={list_item?.departmentId} key={index}>
                            {list_item?.name}
                          </option>
                        ))
                      : null}
                  </select>
                  <span className="txt-danger f-12">
                    {errors?.fm_1_sa_department_id &&
                      errors?.fm_1_sa_department_id?.message}
                  </span>
                </Col>
              </Row>
              <Row>
                <Col lg="2">
                  <Label htmlFor="id_input_fm_1_remark">Remark</Label>
                </Col>
                <Col lg="10" className="m-b-10">
                  <textarea
                    id="id_input_fm_1_remark"
                    name="fm_1_remark"
                    rows="3"
                    className="form-control"
                    placeholder="Enter Remarks"
                    {...register("fm_1_remark", {
                      maxLength: {
                        value: 250,
                        message: "Maximum allowed character length is 250.",
                      },
                    })}
                  ></textarea>
                </Col>
              </Row>
              <Row>
                <Col lg="6" className="offset-lg-2 m-b-10">
                  <Label className="d-block" htmlFor="input_fm_1_active">
                    <input
                      className="checkbox_animated"
                      id="input_fm_1_active"
                      name="fm_1_active"
                      type="checkbox"
                      defaultChecked={
                        model_data !== "" ? model_data.isActive : true
                      }
                      {...register("fm_1_active")}
                    />
                    Active
                  </Label>
                </Col>
              </Row>
              <div className="d-flex justify-content-end">
                <Btn
                  attrBtn={{
                    color: "primary",
                    size: "sm",
                    disabled: false,
                    outline: true,
                    type: "submit",
                    className: "m-r-10 d-flex align-items-center",
                  }}
                >
                  {model_data === "" ? "Submit" : "Update"}
                </Btn>
                {/* {model_data !== "" ? (
                  <Btn
                    attrBtn={{
                      color: "danger",
                      size: "sm",
                      disabled: false,
                      outline: true,
                      className: "d-flex align-items-center",
                    }}
                  >
                    Delete
                  </Btn>
                ) : null} */}
              </div>
            </Form>
          </Col>
        </Row>
      </Container>
    </CommonModal>
  );
};

export default SaveDesignationModel;
