import axios from "axios";
import React, { Fragment, useContext, useEffect, useState } from "react";
import {
  Col,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  Row,
} from "reactstrap";
import { LI, H6, P, UL, Btn } from "../../../../../AbstractElements";
import { assetDocumentFileURL, backendURL } from "../../../../../Config/Config";
import { ConvertMillisToDate } from "../../../../../Data/DateUtil";
import AssetItemContext from "../../../../../_helper/AssetItem";
import UserContext from "../../../../../_helper/User";
import AssetViewAddImage from "./AssetViewAddImage";

const AssetViewDocuments = ({ asset_data }) => {
  const userContext = useContext(UserContext);
  const loggedUser = userContext.userData;
  const assetContext = useContext(AssetItemContext);
  const assetItemData = assetContext.assetItem;

  const [triggerModel, setTriggerModel] = useState(false);
  const [modelData, setModelData] = useState([]);
  const [documentList, setDocumentList] = useState([
    {
      id: 2,
      name: "C_Card.pdf",
      uploadedOn: "2nd October, 2023",
      uploaded: "EshaniJ",
      icon: "fa fa-file-pdf-o txt-secondary",
      folderclass: "fa fa-folder f-36 txt-warning",
      title: "Endless Admin",
      foldersize: "101 files, 10mb",
      description: "Warranty Card",
    },
    {
      id: 2,
      name: "C_Card.pdf",
      uploadedOn: "2nd October, 2023",
      uploaded: "EshaniJ",
      icon: "fa fa-file-pdf-o txt-secondary",
      folderclass: "fa fa-folder f-36 txt-warning",
      title: "Endless Admin",
      foldersize: "101 files, 10mb",
      description: "Warranty Card",
    },
  ]);

  useEffect(() => {
    const reqBody = {
      clientId: loggedUser.clientId,
      orgId: loggedUser.orgId,
      assetItemId: assetItemData.assetItemId,
    };
    const reqInstance = axios.create({
      baseURL: backendURL,
    });
    reqInstance
      .post("/getAllItemDocuments", reqBody)
      .then((response_data) => {
        if (response_data) {
          const result = response_data.data;
          let assetDocumentList = [];
          result.forEach((document_item) => {
            assetDocumentList.push({
              id: document_item.assetDocumentId,
              name: document_item.documentPath.replace(/^.*[\\\/]/, ""),
              uploadedOn: ConvertMillisToDate(document_item.created),
              // uploaded: "EshaniJ",
              icon: "fa fa-file-pdf-o txt-secondary",
              folderclass: "fa fa-folder f-36 txt-warning",
              title: document_item.name,
              foldersize: "101 files, 10mb",
              description: document_item.remark,
              documentPath: document_item.documentPath,
            });
          });
          setDocumentList(assetDocumentList);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [assetItemData]);

  const downloadDocument = (asset_document) => {
    // console.log(moreDModelData);
    const filename = `${asset_document.name}`;
    const documentURL = assetDocumentFileURL + asset_document?.documentPath;
    console.log(`${assetDocumentFileURL}${asset_document?.documentPath}`);
    //
    const link = document.createElement("a");
    link.setAttribute("href", documentURL);
    link.setAttribute("download", filename);
    link.setAttribute("target", "_blank");
    link.click();
  };

  return (
    <Fragment>
      <Container fluid={true}>
        <div className="d-flex justify-content-end">
          {/* <Btn
            attrBtn={{
              color: "info",
              size: "sm",
              disabled: false,
              outline: true,
              className: "m-b-15",
              onClick: () => setTriggerModel(true),
            }}
          >
            <span>
              <i className="icofont icofont-plus m-r-10"></i>
            </span>
            Add Document
          </Btn> */}
        </div>
        <UL attrUL={{ className: "simple-list files" }}>
          <Row>
            {documentList.map((document_item, index) => (
              <Col key={index} sm="4" className="p-b-15" onClick={() => downloadDocument(document_item)}>
                <LI
                  key={index}
                  attrLI={{
                    className: "file-box w-100 bg-light b-r-8 px-3 py-3",
                  }}
                >
                  <div className="p-relative bg-white file-top f-36 p-5 m-b-10 d-flex justify-content-center align-items-center b-r-8">
                    <i className={document_item.icon}></i>
                    <div className="dropdown-basic p-absolute top-0 end-0 d-flex">
                      <Dropdown className="dropdown m-b-0">
                        <div className="dropbtn p-3 d-flex">
                          <i className="txt-dark fa fa-ellipsis-v f-14 ellips"></i>
                        </div>
                        <DropdownMenu className="dropdown-content">
                          {/* <DropdownItem> */}
                            {/* <div onClick={() => downloadDocument(document_item)}>Download</div> */}
                          {/* </DropdownItem> */}
                          {/* <DropdownItem href="#">Delete</DropdownItem> */}
                        </DropdownMenu>
                      </Dropdown>
                    </div>
                  </div>
                  <div className="file-bottom">
                    <H6>
                      <b>{document_item.name}</b>
                    </H6>
                    <P attrPara={{ className: "mb-0 mb-1 text-secondary" }}>
                      {document_item.uploadedOn}
                    </P>
                    <P>{document_item.description}</P>
                    <P>
                      {/* <span className="f-w-500">{"uploaded"} : </span> */}
                      {/* {document_item.uploaded} */}
                    </P>
                  </div>
                </LI>
              </Col>
            ))}
          </Row>
        </UL>
        <AssetViewAddImage
          is_open={triggerModel}
          trigger_model={setTriggerModel}
          model_data={modelData}
        />
      </Container>
    </Fragment>
  );
};

export default AssetViewDocuments;
