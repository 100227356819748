export class ConfigDB {
  static data = {
    settings: {
      layout_type: "ltr",
      sidebar: { type: "compact-wrapper modern-type", iconType: "fill-svg" },
      sidebar_setting: "default-sidebar",
    },
    color: {
      primary_color: "#268a16",
      secondary_color: "#2e9de4",
      mix_background_layout: "light-only",
    },
    router_animation: "fadeIn",
  };
}
export default ConfigDB;
