import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Label,
  Input,
  Button,
  Media,
} from "reactstrap";
import { H5, Btn, Image, Spinner } from "../../../../AbstractElements";
import CommonModal from "../../../Common/Component/CommonModal";
import { useForm } from "react-hook-form";
import { backendURL, imageFileURL } from "../../../../Config/Config";
import axios from "axios";
import UserContext from "../../../../_helper/User";
import asset_default from "../../../../assets/images/asset-item/asset_default.png";
import SvgIcon from "../../../Common/Component/SvgIcon";
import { Link, useHistory } from "react-router-dom";
import ReactTable from "react-data-table-component";
import { Checkbox } from "@mobiscroll/react-lite";
import { SquareCheckbox } from "../../../../Constant";
import { ConvertMillisToDate } from "../../../../Data/DateUtil.jsx";
import { CurrencyFormatter } from "../../../../Data/StringFormatter";
// import PopupPage from './PopupPage';

const NewLinkAssetItem = ({
  is_open,
  trigger_model,
  model_data,
  form_data,
  reload_table,
  set_items,
  onSelectionChange,
}) => {
  const userContext = useContext(UserContext);
  const loggedUser = userContext.userData;
  const toggle = () => trigger_model(!is_open);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });

  const statusSort = (rowA, rowB) => {
    console.log(rowA);
    const a = rowA.cost.props.children;
    const b = rowB.cost.props.children;

    if (a > b) {
      return 1;
    }

    if (b > a) {
      return -1;
    }

    return 0;
  };

  // Set Form Data
  const [assetSite, setAssetSite] = useState([]);
  const [assetBuilding, setAssetBuilding] = useState([]);
  const [assetFloor, setAssetFloor] = useState([]);
  const [assetLocation, setAssetLocation] = useState([]);
  const [assetMainCategory, setAssetMainCategory] = useState([]);
  const [assetSubCategory, setAssetSubCategory] = useState([]);
  const [assetDepartment, setAssetDepartment] = useState([]);
  const [searchType, setSearchType] = useState([
    { searchTypeId: -1, name: "- Select -" },
    { searchTypeId: 1, name: "Name" },
    { searchTypeId: 2, name: "Asset Id" },
    { searchTypeId: 3, name: "Purchased From" },
    { searchTypeId: 4, name: "Reference No" },
    { searchTypeId: 5, name: "Brand" },
    { searchTypeId: 6, name: "Model" },
    { searchTypeId: 7, name: "Serial No" },
  ]);
  const [dpSiteValue, setDpSiteValue] = useState(-1);
  const [dpBuildingValue, setDpBuildingValue] = useState(-1);
  const [dpFloorValue, setDpFloorValue] = useState(-1);
  const [dpLocationValue, setDpLocationValue] = useState(-1);
  const [dpMainCategoryValue, setDpMainCategoryValue] = useState(-1);
  const [dpSubCategoryValue, setDpSubCategoryValue] = useState(-1);
  const [dpDepartmentValue, setDpDepartmentValue] = useState(-1);
  const [dpSearchTypeValue, setDpSearchTypeValue] = useState(-1);
  const [fetchingTableData, setFetchingTableData] = useState(true);
  const [tableData, setTableData] = useState();
  const tableColumns = useRef([
    {
      name: "Asset",
      selector: (row) => row["name"],
      sortable: true,
      download: true,
      grow: 2,
    },
    {
      name: "Asset Id",
      selector: (row) => row.searchKey,
      sortable: true,
      download: true,
    },
    {
      name: "Purchase Date",
      selector: (row) => ConvertMillisToDate(row.purchaseDate),
      sortable: true,
      download: true,
    },
    {
      name: "Fixed Asset",
      selector: (row) => row["fixedAsset"],
      sortable: true,
      download: true,
    },
    {
      name: "Cost (LKR)",
      selector: (row) => CurrencyFormatter(row.costPerItem),
      sortable: true,
      // sortFunction: costSort,
      right: true,
      download: true,
    },
  ]);

  // Load Table Data
  useEffect(() => {
    if (fetchingTableData) {
      const reqBody = {
        clientId: loggedUser.clientId,
        orgId: loggedUser.orgId,
        userId: loggedUser.userId,
        moduleId: loggedUser.loggedModuleId,
      };
      const reqInstance = axios.create({
        baseURL: backendURL,
      });
      reqInstance
        .post("/viewSearchAssetItem", reqBody)
        .then((response_data) => {
          if (response_data) {
            const result = response_data.data;
            if (!result.hasError) {
              let assetData = [];
              result.allAssetItemList.forEach((asset_item) => {
                let imageList =
                  asset_item?.assetImageList !== null
                    ? asset_item?.assetImageList
                    : [];
                let defaultImage = "";
                if (imageList.length) {
                  defaultImage = imageList.filter(
                    (image_item) => image_item.isPrimaryImage === true
                  )[0];
                  console.log(defaultImage);
                }
                assetData.push({
                  ...asset_item,
                  name: (
                    <div>
                      <Media className="d-flex">
                        <Image
                          attrImage={{
                            className: "img-30 me-3",
                            src: `${
                              defaultImage
                                ? imageFileURL + defaultImage.imagePath
                                : asset_default
                            }`,
                            alt: "Generic placeholder image",
                          }}
                        />
                        <Media body className="align-self-center">
                          <div>{asset_item.name}</div>
                        </Media>
                      </Media>
                    </div>
                  ),
                  fixedAsset: asset_item?.isBulk ? (
                    <span className="f-w-700 font-info">Yes</span>
                  ) : (
                    <span className="font-danger">No</span>
                  ),
                  action: (
                    <Media>
                      <Btn
                        attrBtn={{
                          color: "info",
                          size: "sm",
                          disabled: false,
                          outline: true,
                          onClick: () => set_items(asset_item.assetItemId),
                        }}
                      >
                        Select
                      </Btn>
                    </Media>
                  ),
                });
              });
              setAssetSite([
                { assetSiteId: 0, name: "- Select -" },
                ...result.activeAssetSiteList,
              ]);
              setAssetMainCategory([
                { categoryId: 0, name: "- Select -" },
                ...result.activeMainCategoryList,
              ]);
              setAssetDepartment([
                { departmentId: 0, name: "- Select -" },
                ...result.activeDepartmentList,
              ]);
              setTableData(assetData);
              // assetData.current = assetData;
              setFetchingTableData(false);
            } else {
              //   setTableData([]);
              //   setFetchingTableData(false);
              //   toast.error("Request Failed", {
              //     isLoading: false,
              //     position: "top-right",
              //     autoClose: 5000,
              //     hideProgressBar: true,
              //     closeOnClick: true,
              //     pauseOnHover: true,
              //     draggable: true,
              //     progress: undefined,
              //     theme: "light",
              //   });
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [fetchingTableData]);

  /**Search Section */
  useEffect(() => {
    if (dpSiteValue > 0) {
      const reqBody = {
        clientId: loggedUser.clientId,
        orgId: loggedUser.orgId,
        userId: loggedUser.userId,
        siteId: dpSiteValue,
      };
      const reqInstance = axios.create({
        baseURL: backendURL,
      });
      reqInstance
        .post("/getAllSiteBuilding", reqBody)
        .then((response_data) => {
          if (response_data) {
            const result = response_data.data;
            setAssetBuilding([
              { buildingId: 0, name: "- Select -" },
              ...result,
            ]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [dpSiteValue]);
  useEffect(() => {
    if (dpBuildingValue > 0) {
      const reqBody = {
        clientId: loggedUser.clientId,
        orgId: loggedUser.orgId,
        userId: loggedUser.userId,
        moduleId: loggedUser.loggedModuleId,
        buildingId: dpBuildingValue,
      };
      const reqInstance = axios.create({
        baseURL: backendURL,
      });
      reqInstance
        .post("/getAllBuildingFloor", reqBody)
        .then((response_data) => {
          if (response_data) {
            const result = response_data.data;
            setAssetFloor([{ floorId: 0, name: "- Select -" }, ...result]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [dpBuildingValue]);
  useEffect(() => {
    if (dpFloorValue > 0) {
      const reqBody = {
        clientId: loggedUser.clientId,
        orgId: loggedUser.orgId,
        userId: loggedUser.userId,
        moduleId: loggedUser.loggedModuleId,
        siteId: dpSiteValue,
        buildingId: dpBuildingValue,
        floorId: dpFloorValue,
      };
      const reqInstance = axios.create({
        baseURL: backendURL,
      });
      reqInstance
        .post("/getAllSiteBuildingFloorLocation", reqBody)
        .then((response_data) => {
          if (response_data) {
            const result = response_data.data;
            setAssetLocation([
              { locationId: 0, name: "- Select -" },
              ...result,
            ]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [dpFloorValue]);
  useEffect(() => {
    if (dpMainCategoryValue > 0) {
      const reqBody = {
        clientId: loggedUser.clientId,
        orgId: loggedUser.orgId,
        userId: loggedUser.userId,
        moduleId: loggedUser.loggedModuleId,
        mainCategoryId: dpMainCategoryValue,
      };
      const reqInstance = axios.create({
        baseURL: backendURL,
      });
      reqInstance
        .post("/getAllAssetCategorySubCategory", reqBody)
        .then((response_data) => {
          if (response_data) {
            const result = response_data.data;
            setAssetSubCategory([
              { categoryId: 0, name: "- Select -" },
              ...result,
            ]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [dpMainCategoryValue]);

  //Search Functions
  const handleAdvancedSearch = () => {
    const form = new FormData(document.querySelector(".form-1"));
    // setSearchingData(true);
    const reqInstance = axios.create({
      baseURL: backendURL,
    });
    const searchBody = {
      clientId: loggedUser.clientId,
      orgId: loggedUser.orgId,
      userId: loggedUser.userId,
      searchKeyword: form.get("fm_1_search_keyword"),
      searchType: dpSearchTypeValue,
      assetSiteId: dpSiteValue ? dpSiteValue : -1,
      buildingId: dpBuildingValue ? dpBuildingValue : -1,
      floorId: dpFloorValue ? dpFloorValue : -1,
      locationId: dpLocationValue ? dpLocationValue : -1,
      mainCategoryId: dpMainCategoryValue ? dpMainCategoryValue : -1,
      subCategoryId: dpSubCategoryValue ? dpSubCategoryValue : -1,
      departmentId: dpDepartmentValue ? dpDepartmentValue : -1,
      status: -1,
      resultsToDisplay: form.get("fm_1_no_of_display_results"),
    };
    reqInstance.post("/searchAssetItem", searchBody).then((response_data) => {
      const searchResult = response_data.data;
      if (!response_data.hasError) {
        let assetData = [];
        searchResult.resultedAssetItemList.forEach((asset_item) => {
          let imageList = asset_item?.assetImageList;
          let defaultImage = "";
          if (imageList.length) {
            defaultImage = imageList.filter(
              (image_item) => image_item.isPrimaryImage === true
            )[0];
            console.log(defaultImage);
          }
          assetData.push({
            ...asset_item,
            name: (
              <div>
                <Media className="d-flex">
                  <Image
                    attrImage={{
                      className: "img-30 me-3",
                      src: `${
                        defaultImage
                          ? imageFileURL + defaultImage.imagePath
                          : asset_default
                      }`,
                      alt: "Generic placeholder image",
                    }}
                  />
                  <Media body className="align-self-center">
                    <div>{asset_item.name}</div>
                  </Media>
                </Media>
              </div>
            ),
            fixedAsset: asset_item?.isBulk ? (
              <span className="f-w-700 font-info">Yes</span>
            ) : (
              <span className="font-danger">No</span>
            ),
            action: (
              <Media>
                <Btn
                  attrBtn={{
                    color: "info",
                    size: "sm",
                    disabled: false,
                    outline: true,
                    className: "m-r-10",
                    // onClick: () => handleEditBtnClick(building_item),
                  }}
                >
                  View
                </Btn>
              </Media>
            ),
          });
        });
        // setTableData(assetData);
        // assetData.current = assetData;
        // setSearchingData(false);
      }
    });
  };

  // Getting selected data
  const [selectedRows, setSelectedRows] = useState([]);

  const handleRowSelected = (state) => {
    setSelectedRows(state.selectedRows);
  };

  const linkAssetDocumentsWithAssetItems = () => {
    // Connecting asset to document when rows are selected
    const selectedAssetIds = selectedRows.map((row) => row.assetItemId);
    console.log(model_data);
    const reqBody = {
      clientId: loggedUser.clientId,
      orgId: loggedUser.orgId,
      userId: loggedUser.userId,
      moduleId: loggedUser.loggedModuleId,
      assetDocumentId: model_data[0].assetDocumentId,
      searchKey: "",
      remark: "",
      isActive: true,
      itemMasterId: selectedAssetIds,
    };

    const reqInstance = axios.create({
      baseURL: backendURL,
    });

    reqInstance
      .post("/linkDocumentWithAssets", reqBody)
      .then((response_data) => {
        if (response_data) {
          const result = response_data.data;
          console.log("Linking successful");
          setSelectedRows([]); // Clear selected rows after linking
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <CommonModal
      isOpen={is_open}
      toggler={toggle}
      bodyClass="grid-showcase"
      size="xl"
    >
      <Container fluid={true}>
        <Row>
          <Col sm="12" className="p-b-15">
            <Form className="form-1">
              <H5 attrH5={{ className: "m-b-30" }}>
                <span className="header-search">
                  <SvgIcon
                    className="stroke-icon m-r-10"
                    iconId={`search`}
                    height="18px"
                    width="18px"
                    style={{
                      stroke: "rgba(47, 47, 59, 0.9)",
                      fill: "none",
                    }}
                  />
                </span>{" "}
                Link Asset(s)
              </H5>
              <Row>
                <Col md="6" className="m-b-10">
                  <Label htmlFor="input_fm_1_asset_id">Search</Label>
                  <input
                    id="input_fm_1_search_keyword"
                    className="form-control"
                    name="fm_1_search_keyword"
                    type="text"
                    placeholder="Enter Keywords"
                    {...register("fm_1_search_keyword")}
                  />
                </Col>
                <Col md="6" className="m-b-10">
                  <Label htmlFor="validationCustom01">Search Fields</Label>
                  <select
                    id="input_fm_1_a_search_type_id"
                    name="fm_1_a_search_type_id"
                    className={`form-select`}
                    {...register("fm_1_a_search_type_id")}
                    onChange={(evt) => setDpSearchTypeValue(evt.target.value)}
                    value={dpSearchTypeValue}
                  >
                    {searchType.length
                      ? searchType.map((list_item, index) => (
                          <option value={list_item?.searchTypeId} key={index}>
                            {list_item?.name}
                          </option>
                        ))
                      : null}
                  </select>
                </Col>
              </Row>
              <Row>
                <Col md="3" className="m-b-10">
                  <Label htmlFor="input_fm_1_a_asset_site_id">{"Site"}</Label>
                  <select
                    id="input_fm_1_a_asset_site_id"
                    name="fm_1_a_asset_site_id"
                    className={`form-select ${
                      assetSite.length ? "" : "disabled"
                    }`}
                    {...register("fm_1_a_asset_site_id", {
                      required: "Please select Site",
                    })}
                    onChange={(evt) => setDpSiteValue(evt.target.value)}
                    value={dpSiteValue}
                  >
                    {assetSite.length
                      ? assetSite.map((list_item, index) => (
                          <option value={list_item?.assetSiteId} key={index}>
                            {list_item?.name}
                          </option>
                        ))
                      : null}
                  </select>
                  <span className="txt-danger f-12">
                    {errors?.fm_1_a_asset_site_id &&
                      errors?.fm_1_a_asset_site_id?.message}
                  </span>
                </Col>
                <Col md="3" className="m-b-10">
                  <Label htmlFor="input_fm_1_building_id">{"Building"}</Label>
                  <select
                    id="id_input_fm_1_sa_fo_building_id"
                    name="fm_1_sa_fo_building_id"
                    className={`form-select ${
                      assetBuilding.length ? "" : "disabled"
                    }`}
                    {...register("fm_1_sa_fo_building_id", {
                      required: "Please select Building",
                    })}
                    onChange={(evt) => setDpBuildingValue(evt.target.value)}
                    value={dpBuildingValue}
                  >
                    {assetBuilding.length
                      ? assetBuilding.map((list_item, index) => (
                          <option value={list_item?.buildingId} key={index}>
                            {list_item?.name}
                          </option>
                        ))
                      : null}
                  </select>
                  <span className="txt-danger f-12">
                    {errors?.fm_1_sa_fo_building_id &&
                      errors?.fm_1_sa_fo_building_id?.message}
                  </span>
                </Col>
                <Col md="3" className="m-b-10">
                  <Label htmlFor="input_fm_1_sa_fo_floor_id">{"Floor"}</Label>
                  <select
                    id="input_fm_1_sa_fo_floor_id"
                    name="fm_1_sa_fo_floor_id"
                    className={`form-select ${
                      assetFloor.length ? "" : "disabled"
                    }`}
                    {...register("fm_1_sa_fo_floor_id", {
                      required: "Please select Floor",
                    })}
                    onChange={(evt) => setDpFloorValue(evt.target.value)}
                    value={dpFloorValue}
                  >
                    {assetFloor.length
                      ? assetFloor.map((list_item, index) => (
                          <option value={list_item?.floorId} key={index}>
                            {list_item?.name}
                          </option>
                        ))
                      : null}
                  </select>
                  <span className="txt-danger f-12">
                    {errors?.fm_1_sa_fo_floor_id &&
                      errors?.fm_1_sa_fo_floor_id?.message}
                  </span>
                </Col>
                <Col md="3" className="m-b-10">
                  <Label htmlFor="input_fm_1_sa_location_id">
                    {"Location"}
                  </Label>
                  <select
                    id="input_fm_1_sa_location_id"
                    name="fm_1_sa_location_id"
                    className={`form-select ${
                      assetLocation.length ? "" : "disabled"
                    }`}
                    {...register("fm_1_sa_location_id", {
                      required: "Please select Location",
                    })}
                    onChange={(evt) => setDpLocationValue(evt.target.value)}
                    value={dpLocationValue}
                  >
                    {assetLocation.length
                      ? assetLocation.map((list_item, index) => (
                          <option value={list_item?.locationId} key={index}>
                            {list_item?.name}
                          </option>
                        ))
                      : null}
                  </select>
                  <span className="txt-danger f-12">
                    {errors?.fm_1_sa_location_id &&
                      errors?.fm_1_sa_location_id?.message}
                  </span>
                </Col>
              </Row>
              <Row>
                <Col md="4" className="m-b-10">
                  <Label htmlFor="id_input_fm_1_sa_main_category_id">
                    {"Category"}
                  </Label>
                  <select
                    id="id_input_fm_1_sa_main_category_id"
                    name="fm_1_sa_main_category_id"
                    className={`form-select ${
                      assetMainCategory.length ? "" : "disabled"
                    }`}
                    {...register("fm_1_sa_main_category_id", {
                      required: "Please select Category",
                    })}
                    onChange={(evt) => setDpMainCategoryValue(evt.target.value)}
                    value={dpMainCategoryValue}
                  >
                    {assetMainCategory.length
                      ? assetMainCategory.map((list_item, index) => (
                          <option value={list_item?.categoryId} key={index}>
                            {list_item?.name}
                          </option>
                        ))
                      : null}
                  </select>
                  <span className="txt-danger f-12">
                    {errors?.fm_1_sa_main_category_id &&
                      errors?.fm_1_sa_main_category_id?.message}
                  </span>
                </Col>
                <Col md="4" className="m-b-10">
                  <Label htmlFor="id_input_fm_1_sa_category_id">
                    {"Sub Category"}
                  </Label>
                  <select
                    id="id_input_fm_1_sa_category_id"
                    name="fm_1_sa_category_id"
                    className={`form-select ${
                      assetSubCategory.length ? "" : "disabled"
                    }`}
                    {...register("fm_1_sa_category_id")}
                    onChange={(evt) => setDpSubCategoryValue(evt.target.value)}
                    value={dpSubCategoryValue}
                  >
                    {assetSubCategory.length
                      ? assetSubCategory.map((list_item, index) => (
                          <option value={list_item?.categoryId} key={index}>
                            {list_item?.name}
                          </option>
                        ))
                      : null}
                  </select>
                  <span className="txt-danger f-12">
                    {errors?.fm_1_sa_category_id &&
                      errors?.fm_1_sa_category_id?.message}
                  </span>
                </Col>
                <Col md="4" className="m-b-10">
                  <Label htmlFor="id_input_fm_1_sa_exist_department_id">
                    Department
                  </Label>
                  <select
                    id="id_input_fm_1_department_id"
                    name="fm_1_department_id"
                    className={`form-select ${
                      assetDepartment.length ? "" : "disabled"
                    }`}
                    {...register("fm_1_department_id", {
                      required: "Please select Exist Department",
                    })}
                    onChange={(evt) => setDpDepartmentValue(evt.target.value)}
                    value={dpDepartmentValue}
                  >
                    {assetDepartment.length
                      ? assetDepartment.map((list_item, index) => (
                          <option value={list_item?.departmentId} key={index}>
                            {list_item?.name}
                          </option>
                        ))
                      : null}
                  </select>
                  <span className="txt-danger f-12">
                    {errors?.fm_1_sa_exist_department_id &&
                      errors?.fm_1_sa_exist_department_id?.message}
                  </span>
                </Col>
              </Row>
              <Row>
                <Col className="m-b-10">
                  <Label htmlFor="validationCustom01">{"Status"}</Label>
                  <select name="" className="form-select"></select>
                  <span className="txt-danger f-12">
                    {errors.firstName && "First name is required"}
                  </span>
                  <div className="valid-feedback">{"Looks good!"}</div>
                </Col>
              </Row>
              <Row>
                <Col md="3" className="m-b-10">
                  <Label htmlFor="input_fm_1_no_of_display_results">
                    No. of Display Results
                  </Label>
                  <input
                    id="input_fm_1_no_of_display_results"
                    className="form-control"
                    name="fm_1_no_of_display_results"
                    type="number"
                    {...register("fm_1_no_of_display_results")}
                  />
                </Col>
              </Row>
              <div className="d-flex justify-content-end m-t-20">
                <Button
                  type="button"
                  color="primary"
                  onClick={() => handleAdvancedSearch()}
                >
                  {"Search"}
                </Button>
              </div>
            </Form>
            <ReactTable
              columns={tableColumns.current}
              data={tableData}
              progressComponent={
                <div className="">
                  <div className="loader-box">
                    <Spinner attrSpinner={{ className: "loader-26" }} />
                  </div>
                  <small>Fetching...</small>
                </div>
              }
              progressPending={fetchingTableData}
              pointerOnHover
              selectableRows
              pagination
              onSelectedRowsChange={handleRowSelected}
            />
            <button
              //   onClick={() => onSelectionChange(selectedRows)}
              onClick={linkAssetDocumentsWithAssetItems}
              type="button"
              class="btn btn-outline-primary"
            >
              Move Selected
            </button>
          </Col>
        </Row>
      </Container>
    </CommonModal>
  );
};

export default NewLinkAssetItem;
