import React, { Fragment, useEffect, useRef, useState } from "react";
import { Container } from "reactstrap";
import ReactTable from "react-data-table-component";
import { Spinner } from "../../../../../AbstractElements";

const AssetViewHistory = () => {
  /**Page Status Section */
  const INPROGRESS = 10;
  const COMPLETED = 11;
  const [searchingProgress, setSearchingProgress] = useState(INPROGRESS);

  /**Datatable Section */
  const tableColumns = useRef([
    {
      name: "Date",
      selector: (row) => `${row.date}`,
      sortable: true,
      center: false,
    },
    {
      name: "Action",
      selector: (row) => `${row.action}`,
      sortable: true,
      center: true,
    },
    {
      name: "Field",
      selector: (row) => `${row.field}`,
      sortable: true,
      center: true,
    },
    {
      name: "Change From",
      selector: (row) => row["changedFrom"],
      sortable: true,
      center: true,
    },
    {
      name: "Change To",
      selector: (row) => row["changedTo"],
      sortable: true,
      center: true,
    },
    {
      name: "Action By",
      selector: (row) => row["actionBy"],
      sortable: true,
      center: true,
    },
  ]);
  const [tableData, setTableData] = useState();
  const [triggerSaveModel, setTriggerSaveModel] = useState(false);
  const [modelData, setModelData] = useState([]);
  const assetData = useRef([
    {
      id: 1,
      date: "2023-05-23 12:23:12",
      action: "Asset Edit",
      field: "Depreciable Cost",
      changedFrom: 5.6,
      changedTo: 20.6,
      actionBy: (
        <span className="txt-success">
          <i className="font-success icofont icofont-user-alt-7"></i> EshaniJ
        </span>
      ),
    },
    {
      id: 2,
      date: "2023-05-23 12:23:12",
      action: "Asset Edit",
      field: "Asset Life (Months)",
      changedFrom: 5.6,
      changedTo: 3.6,
      actionBy: (
        <span className="txt-success">
          <i className="font-success icofont icofont-user-alt-7"></i> EshaniJ
        </span>
      ),
    },
  ]);

  /**Page Onload Section */
  useEffect(() => {
    setTableData(assetData.current);
    setSearchingProgress(COMPLETED);
  }, []);

  const CustomRow = ({ row, columns }) => {
    return (
      <tr>
        {columns.map((column, columnIndex) => {
          console.log(row);
          if (columnIndex === 1) {
            return (
              <td rowSpan={2} key={columnIndex}>
                {row.name}
              </td>
            );
          } else {
            return <td key={columnIndex}>{row[column.selector]}</td>;
          }
        })}
      </tr>
    );
  };

  return (
    <Fragment>
      <Container>
        <ReactTable
          columns={tableColumns.current}
          data={tableData}
          progressPending={searchingProgress === INPROGRESS}
          progressComponent={
            <div className="">
              <div className="loader-box">
                <Spinner attrSpinner={{ className: "loader-26" }} />
              </div>
              <small>Searching...</small>
            </div>
          }
          pointerOnHover
          customRowComponent={CustomRow}
        />
      </Container>
    </Fragment>
  );
};

export default AssetViewHistory;
