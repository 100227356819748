import React, { useState } from "react";
import { Button, Col, Container, Form, Input, Label, Row } from "reactstrap";
import CommonModal from "../../../../Common/Component/CommonModal";
import { useForm } from "react-hook-form";
import DatePicker from "react-datepicker";
import SvgIcon from "../../../../Common/Component/SvgIcon";
import { P } from "../../../../../AbstractElements";

const AssetViewSaveWarranty = ({ is_open, trigger_model, model_data }) => {
  const toggle = () => trigger_model(!is_open);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    data.prevetDefault();
    if (data !== "") {
      alert("You submitted the form and stuff!");
    } else {
      errors.showMessages();
    }
  };
  const [startDate, setstartDate] = useState(new Date());

  const handleChange = (date) => {
    setstartDate(date);
  };

  return (
    <CommonModal
      isOpen={is_open}
      toggler={toggle}
      bodyClass="grid-showcase"
      size="lg"
    >
      <Container fluid={true}>
        <Row>
          <Col sm="12" className="p-b-15">
            <Form
              className="needs-validation"
              noValidate=""
              onSubmit={handleSubmit(onSubmit)}
            >
              <Row className="justify-content-center">
                <Col lg="4">
                  <Label htmlFor="input_fm_1_period">{"Period"}</Label>
                </Col>
                <Col lg="6" className="m-b-10">
                  <div className="input-group mb-0">
                    <span className="input-group-text" id="basic-addon1">
                      Month/s
                    </span>
                    <input
                      id="input_fm_1_period"
                      name="fm_1_period"
                      type="text"
                      className="form-control"
                      placeholder="00"
                      aria-label="Salvage Value"
                      aria-describedby="basic-addon1"
                      {...register("period")}
                    />
                  </div>
                  <div className="valid-feedback">{"Looks good!"}</div>
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col lg="4">
                  <Label
                    htmlFor="input_fm_1_date_acquired"
                    className="required-input-mark"
                  >
                    {"Expiration Date"}
                  </Label>
                </Col>
                <Col lg="6" className="m-b-10">
                  <DatePicker
                    id="input_fm_1_date_acquired"
                    className="form-control digits"
                    selected={startDate}
                    onChange={handleChange}
                    required
                  />
                </Col>
              </Row>
              <Row className="justify-content-center m-b-30">
                <Col lg="4">
                  <Label htmlFor="input_fm_1_asset_list">{"Notes"}</Label>
                </Col>
                <Col lg="6" className="m-b-10">
                  <Input
                    type="textarea"
                    className="form-control"
                    placeholder="Type a Note here"
                    {...register("notes")}
                    rows="3"
                  />
                  <div className="valid-feedback">{"Looks good!"}</div>
                </Col>
              </Row>
              <div className="d-flex justify-content-end">
                <Button type="submit" color="primary" className="m-r-10">
                  {model_data === "" ? "Submit" : "Update"}
                </Button>
                {model_data !== "" ? (
                  <Button type="button" color="danger" outline={true}>
                    Delete
                  </Button>
                ) : null}
              </div>
              {model_data !== "" ? (
                <div className="d-flex">
                  <div className="d-flex p-t-10 p-b-10">
                    <SvgIcon
                      className="fill-icon m-r-10"
                      iconId={`fill-users`}
                      height="18px"
                      width="18px"
                    />
                    <P>
                      <strong>Created By</strong>
                    </P>
                  </div>
                  <P
                    attrPara={{
                      className: "text-muted  p-t-10 p-b-10 p-l-15",
                    }}
                  >
                    EshaniJ
                  </P>
                </div>
              ) : null}
            </Form>
          </Col>
        </Row>
      </Container>
    </CommonModal>
  );
};

export default AssetViewSaveWarranty;
