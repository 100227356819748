import React, { useContext, useEffect, useState, useRef } from "react";
import CommonModal from "../../../Common/Component/CommonModal";
import { Container, Row, Col, Form, Label, Input, Media } from "reactstrap";
import { useForm, useFormContext } from "react-hook-form";
import { Btn, H5, H6, Spinner } from "../../../../AbstractElements";
import ReactTable from "react-data-table-component";
import axios from "axios";
import { toast } from "react-toastify";
import { assetDocumentFileURL, backendURL } from "../../../../Config/Config";
import UserContext from "../../../../_helper/User";
import Swal from "sweetalert2";
import { Checkbox } from "@mobiscroll/react-lite";
import NewLinkAssetModel from "./NewLinkAssetModel";
import { ConvertMillisToDate } from "../../../../Data/DateUtil";
import { CurrencyFormatter } from "../../../../Data/StringFormatter";

const MoreDocumentGaleryModel = ({
  is_open,
  trigger_model,
  reload_table,
  moreDModelData,
}) => {
  // console.log("Received More Model Data:", moreDModelData);
  const userContext = useContext(UserContext);
  const loggedUser = userContext.userData;
  const toggle = () => trigger_model(!is_open);
  const {
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });

  // Table Functions
  const LinkAsset = (row_data) => {
    setLinkModelData(row_data);
    setTriggerLinkAssetModel(true);
  };
  // more table data
  const moretableColumns = useRef([
    {
      name: "Document Id",
      selector: (row) => `${row.assetDocumentId}`,
      sortable: true,
      center: false,
      download: false,
      omit:true
    },
    {
      name: "File Name",
      selector: (row) => `${row.documentPath.replace(/^.*[\\\/]/, "")}`,
      sortable: true,
      download: true,
    },
    {
      name: "Document title",
      selector: (row) => `${row.name}`,
      sortable: true,
      download: true,
    },
    {
      name: "File type",
      selector: (row) => row.assetDocumentType.name,
      sortable: true,
      download: true,
    },
    {
      name: "Uploaded by",
      selector: (row) => row.createdBy,
      sortable: true,
      download: true,
    },
    {
      name: "Uploaded",
      selector: (row) => ConvertMillisToDate(row.created),
      sortable: true,
      download: true,
    },
    {
      name: "Remark",
      selector: (row) => `${row.remark}`,
      sortable: true,
      download: true,
    },
  ]);
  const tableColumns = useRef([
    {
      name: "Asset",
      selector: (row) => row["name"],
      sortable: true,
      download: true,
      grow: 2,
    },
    {
      name: "Asset Id",
      selector: (row) => row.searchKey,
      sortable: true,
      download: true,
    },
    {
      name: "Purchase Date",
      selector: (row) => ConvertMillisToDate(row.purchaseDate),
      sortable: true,
      download: true,
    },
    {
      name: "Cost (LKR)",
      selector: (row) => CurrencyFormatter(row.costPerItem),
      sortable: true,
      // sortFunction: costSort,
      right: true,
      download: true,
    },
  ]);
  const [fetchingTableData, setFetchingTableData] = useState(false);
  /**Model */
  const [triggerLinkAssetModel, setTriggerLinkAssetModel] = useState(false);
  const [linkAssetData, setLinkAssetData] = useState();
  const [linkModelData, setLinkModelData] = useState([]);
  /**Page  */
  const downloadDocument = () => {
    // console.log(moreDModelData);
    const filename = `${moreDModelData[0].name}`;
    const documentURL = assetDocumentFileURL + moreDModelData[0]?.documentPath;
    console.log(`${assetDocumentFileURL}${moreDModelData[0]?.documentPath}`);
    //
    const link = document.createElement("a");
    link.setAttribute("href", documentURL);
    link.setAttribute("download", "sdsdfsdf.pdf");
    link.setAttribute("target", "_blank");
    link.click();
  };

  // new try
  const [selectedData, setSelectedData] = useState([]);

  const handleSelectionChange = (selectedRows) => {
    setSelectedData(selectedRows);
  };

  //delete function
  const deleteAssetDocument = () => {
    Swal.fire({
      icon: "warning",
      title: "Are you sure?",
      text: "This will permanently deletes this Asset document.",
      showDenyButton: true,
      confirmButtonText: "Delete",
      denyButtonText: "Cancel",
      denyButtonColor: "#2a3142",
    }).then((confirmation) => {
      if (confirmation.isConfirmed) {
        const id = toast.loading("Please wait...");
        const reqInstance = axios.create({
          baseURL: backendURL,
        });
        const assetdocument = {
          //   assetDocumentId: model_data ? model_data.assetDocumentId : 0,
          assetDocumentId: moreDModelData[0].assetDocumentId,
          clientId: loggedUser.clientId,
          orgId: loggedUser.orgId,
          userId: loggedUser.userId,
          moduleId: loggedUser.loggedModuleId,
        };
        reqInstance
          .post("/deleteAssetDocument", assetdocument)
          .then((response_data) => {
            const result = response_data.data;
            // console.log("moreDModelData:", moreDModelData[0].assetDocumentId);
            if (result.hasError) {
              toast.update(id, {
                render: result.errorMsg,
                type: "error",
                isLoading: false,
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
            } else {
              let successMessage = "Assetdocument Successfully Deleted.";
              toast.update(id, {
                render: successMessage,
                type: "success",
                isLoading: false,
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
              reload_table(true);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    });
  };

  return (
    <CommonModal
      isOpen={is_open}
      toggler={toggle}
      bodyClass="grid-showcase"
      size="xl"
    >
      <Container fluid={true}>
        <Row>
          <Col sm="12" className="p-b-15">
            <Form className="form-1">
              <H5 attrH5={{ className: "m-b-30" }}>More Document Details</H5>
              <ReactTable
                columns={moretableColumns.current}
                data={moreDModelData}
                progressPending={fetchingTableData}
                pointerOnHover
                pagination
              />
              <div>
                <Btn
                  attrBtn={{
                    color: "primary",
                    size: "sm",
                    disabled: false,
                    outline: false,
                    className: "m-r-10 d-flex align-items-center",
                    type: "button",
                    onClick: () => LinkAsset(),
                  }}
                >
                  Link Asset
                </Btn>
              </div>
              <div className="d-flex justify-content-end">
                <Btn
                  attrBtn={{
                    color: "primary",
                    size: "sm",
                    disabled: false,
                    outline: true,
                    className: "m-r-10 d-flex align-items-center",
                    type: "button",
                    onClick: () => downloadDocument(),
                  }}
                >
                  View
                </Btn>
                <Btn
                  attrBtn={{
                    color: "danger",
                    size: "sm",
                    disabled: false,
                    outline: true,
                    type: "button",
                    className: "d-flex align-items-center",
                    onClick: () => deleteAssetDocument(),
                  }}
                >
                  Delete
                </Btn>
              </div>
              <ReactTable
                columns={tableColumns.current}
                data={selectedData}
                progressPending={fetchingTableData}
                pointerOnHover
                pagination
              />
              <NewLinkAssetModel
                is_open={triggerLinkAssetModel}
                trigger_model={setTriggerLinkAssetModel}
                model_data={moreDModelData}
                form_data={linkAssetData}
                reload_table={setFetchingTableData}
                onSelectionChange={handleSelectionChange}
              />
            </Form>
          </Col>
        </Row>
      </Container>
    </CommonModal>
  );
};

export default MoreDocumentGaleryModel;
