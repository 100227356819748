import React, { Fragment, useContext, useEffect, useState } from "react";

import Language from "./Language";
import Searchbar from "./Searchbar";
import Notificationbar from "./Notificationbar";
import MoonLight from "./MoonLight";
import CartHeader from "./CartHeader";
import BookmarkHeader from "./BookmarkHeader";
import UserHeader from "./UserHeader";
import { UL, H6 } from "../../../AbstractElements";
import { Col } from "reactstrap";
import UserContext from "../../../_helper/User";
import SvgIcon from "../../../Components/Common/Component/SvgIcon";

const RightHeader = () => {
  const userContext = useContext(UserContext);
  const loggedUser = userContext.userData;
  //
  const [orgName, setOrgName] = useState("");
  useEffect(() => {
    if (Object.keys(loggedUser).length > 0) {
      setOrgName(loggedUser.orgList[0].name);
    }
  }, [loggedUser]);

  return (
    <Fragment>
      <Col
        xxl="7"
        xl="6"
        md="7"
        className="nav-right pull-right right-header col-8 p-0 ms-auto"
      >
        {/* <Col md="8"> */}
        <UL attrUL={{ className: "simple-list nav-menus flex-row" }}>
          <SvgIcon
            className="fill-icon m-r-10"
            iconId={`fill-building`}
            height="18px"
            width="18px"
          />
          <H6 attrH6={{ className: "f-bold m-r-30 m-b-0" }}>{orgName}</H6>
          <Language />
          <Searchbar />
          {/* <BookmarkHeader /> */}
          <MoonLight />
          {/* <CartHeader /> */}
          {/* <Notificationbar /> */}
          <UserHeader />
        </UL>
        {/* </Col> */}
      </Col>
    </Fragment>
  );
};

export default RightHeader;
