import React, { Fragment, useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Form,
  Label,
  Input,
} from "reactstrap";
import { Breadcrumbs, Btn } from "../../../AbstractElements";
import HeaderCard from "../../Common/Component/HeaderCard";
import { toast } from "react-toastify";
import axios from "axios";
import { adminBackendURL } from "../../../Config/Config";
import UserContext from "../../../_helper/User";
import { useContext } from "react";
import { useForm } from "react-hook-form";

const RoleLine = () => {
  const userContext = useContext(UserContext);
  const loggedUser = userContext.userData;
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });
  //
  const [fetchingTableData, setFetchingTableData] = useState(true);
  // Load Table Data
  useEffect(() => {
    if (fetchingTableData && Object.keys(loggedUser).length > 0) {
      const reqBody = {
        clientId: loggedUser.clientId,
        orgId: loggedUser.orgId,
        userId: loggedUser.userId,
        moduleId: loggedUser.loggedModuleId,
      };
      const reqInstance = axios.create({
        baseURL: adminBackendURL,
      });
      reqInstance
        .post("/viewRoleLine", reqBody)
        .then((response_data) => {
          if (response_data) {
            const result = response_data.data;
            if (!result.hasError) {
              setRoleList([
                { roleId: 0, name: "- Select -" },
                ...result.activeRoleList,
              ]);
              setModuleList([
                { moduleId: 0, name: "- Select -" },
                ...result.activeModuleList,
              ]);
              setIterfaceFunctionList(result.roleAssignInterfaceModelList);
              setModelFormData({
                clientList: result.activeClientList,
                orgList: result.activeOrgList,
                moduleList: result.activeModuleList,
                roleList: result.activeRoleList,
                interfaceFunctionList: result.roleAssignInterfaceModelList,
              });
              setFetchingTableData(false);
            } else {
              setFetchingTableData(false);
              toast.error("Request Failed", {
                isLoading: false,
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [loggedUser, fetchingTableData]);
  /**Model */
  const [modelFormData, setModelFormData] = useState();
  const [modelData, setModelData] = useState([]);
  /**Common */
  const onSubmit = (data) => {
    const form = new FormData(document.querySelector(".form-1"));
    let assignedInterfaceFunctionList = [];
    const selectedFunctions = form.getAll("fm_1_interface_functions");
    console.log(selectedFunctions);
    let detectedInterfaces = [];
    selectedFunctions.forEach((item) => {
      const interfaceId = item.split("__")[0];
      const functionId = item.split("__")[1];
      console.log(interfaceId, functionId);
      //
      console.log("Detected Interface List =>");
      console.log(detectedInterfaces);
      if (detectedInterfaces.includes(interfaceId)) {
        let interfaceObj = assignedInterfaceFunctionList.find(
          (item) => item.interfaceId === interfaceId
        );
        console.log("Detected Interface Object => ");
        console.log(interfaceObj);
        interfaceObj?.functionList?.push({
          functionId: functionId,
          searchKey: "",
          name: "",
          description: "",
          isActive: "true",
        });
      } else {
        detectedInterfaces.push(interfaceId);
        assignedInterfaceFunctionList.push({
          interfaceId: interfaceId,
          searchKey: "",
          name: "",
          description: "",
          functionList: [
            {
              functionId: functionId,
              searchKey: "",
              name: "",
              description: "",
              isActive: "true",
            },
          ],
        });
      }
    });
    if (data !== "") {
      const id = toast.loading("Please wait...");
      const roleLine = {
        clientId: loggedUser.clientId,
        orgId: loggedUser.orgId,
        userId: loggedUser.userId,
        moduleId: loggedUser.loggedModuleId,
        roleId: parseInt(form.get("fm_1_sa_role_id")),
        interfaceList: assignedInterfaceFunctionList,
      };
      const reqInstance = axios.create({
        baseURL: adminBackendURL,
      });
      reqInstance
        .post("/saveRoleLine", roleLine)
        .then((response_data) => {
          const result = response_data.data;
          if (result.hasError) {
            toast.update(id, {
              render: "Failed",
              type: "error",
              isLoading: false,
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          } else {
            let successMessage = "Permission List Succesfully Updated.";
            toast.update(id, {
              render: successMessage,
              type: "success",
              isLoading: false,
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            setFetchingTableData(true);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      errors.showMessages();
    }
  };
  const [roleList, setRoleList] = useState([]);
  const [moduleList, setModuleList] = useState([]);
  const [roleValue, setRoleValue] = useState(0);
  const [moduleValue, setModuleValue] = useState(0);
  const [interfaceFunctionList, setIterfaceFunctionList] = useState([]);

  useEffect(() => {
    if (modelFormData !== undefined) {
      setRoleList([
        { roleId: 0, name: "- Select -" },
        ...modelFormData.roleList,
      ]);
      setModuleList([
        { moduleId: 0, name: "- Select -" },
        ...modelFormData.moduleList,
      ]);
      setIterfaceFunctionList(modelFormData.interfaceFunctionList);
    }
  }, [modelData]);

  useEffect(() => {
    if (moduleValue > 0 && roleValue > 0) {
      const reqBody = {
        clientId: 38,
        orgId: 41,
        roleId: roleValue,
        moduleId: moduleValue,
      };
      const reqInstance = axios.create({
        baseURL: adminBackendURL,
      });
      reqInstance
        .post("/getRoleLine", reqBody)
        .then((response_data) => {
          if (response_data) {
            const result = response_data.data;
            result.forEach((interface_item) => {
              interface_item.functionList.forEach((function_item) => {
                document.querySelector(
                  `#input_fm_1_active_location_id_${function_item.functionId}`
                ).checked = true;
              });
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [roleValue]);

  useEffect(() => {
    if (moduleValue > 0) {
      const reqBody = {
        clientId: loggedUser.clientId,
        orgId: loggedUser.orgId,
        roleId: roleValue,
        moduleId: moduleValue,
      };
      const reqInstance = axios.create({
        baseURL: adminBackendURL,
      });
      reqInstance
        .post("/getModuleInterfaceFunctionList", reqBody)
        .then((response_data) => {
          if (response_data) {
            setIterfaceFunctionList(response_data.data);
          //   const result = response_data.data;
          //   result.forEach((interface_item) => {
          //     interface_item.functionList.forEach((function_item) => {
          //       document.querySelector(
          //         `#input_fm_1_active_location_id_${function_item.functionId}`
          //       ).checked = true;
          //     });
          //   });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [moduleValue]);

  return (
    <Fragment>
      <Breadcrumbs mainTitle="Settings" parent="Settings" title="Role Lines" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <HeaderCard
                title="Role Line"
                span1="Role Line defines the scope of a Role. This contains what modules, interfaces and functions are accessible for a certain role when using the application."
              />
              <CardBody>
                <Row>
                  <Col sm="12" className="p-b-15">
                    <Form onSubmit={handleSubmit(onSubmit)} className="form-1">
                      <Row>
                        <Col lg="2">
                          <Label
                            htmlFor="id_input_fm_1_sa_module_id"
                            className="required-input-mark"
                          >
                            Module
                          </Label>
                        </Col>
                        <Col lg="10" className="m-b-10">
                          <select
                            id="id_input_fm_1_sa_module_id"
                            name="fm_1_sa_module_id"
                            className="form-select"
                            {...register("fm_1_sa_module_id", {
                              required: "Please select Module",
                            })}
                            onChange={(evt) => setModuleValue(evt.target.value)}
                            value={moduleValue}
                          >
                            {moduleList.length
                              ? moduleList.map((list_item, index) => (
                                  <option
                                    value={list_item?.moduleId}
                                    key={index}
                                  >
                                    {list_item?.name}
                                  </option>
                                ))
                              : null}
                          </select>
                          <span className="txt-danger f-12">
                            {errors?.fm_1_sa_module_id &&
                              errors?.fm_1_sa_module_id?.message}
                          </span>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="2">
                          <Label
                            htmlFor="id_input_fm_1_sa_role_id"
                            className="required-input-mark"
                          >
                            Role
                          </Label>
                        </Col>
                        <Col lg="10" className="m-b-10">
                          <select
                            id="id_input_fm_1_sa_role_id"
                            name="fm_1_sa_role_id"
                            className="form-select"
                            {...register("fm_1_sa_role_id", {
                              required: "Please select Role",
                            })}
                            onChange={(evt) => setRoleValue(evt.target.value)}
                            value={roleValue}
                          >
                            {roleList.length
                              ? roleList.map((list_item, index) => (
                                  <option value={list_item?.roleId} key={index}>
                                    {list_item?.name}
                                  </option>
                                ))
                              : null}
                          </select>
                          <span className="txt-danger f-12">
                            {errors?.fm_1_sa_role_id &&
                              errors?.fm_1_sa_role_id?.message}
                          </span>
                        </Col>
                      </Row>
                      <Row className="m-b-10">
                        <Col lg="2">
                          <Label
                            htmlFor="id_input_fm_1_sa_role_line"
                            className="required-input-mark"
                          >
                            Permissions
                          </Label>
                        </Col>
                        <Col lg="10">
                          <Row className="m-b-20">
                            {interfaceFunctionList?.length > 0
                              ? interfaceFunctionList.map(
                                  (interface_item, index) => (
                                    <Col lg="6" key={index}>
                                      <div className="fw-bold m-b-10">
                                        {interface_item.name}
                                      </div>
                                      {interface_item.functionList.map(
                                        (function_item, index2) => (
                                          <Label
                                            className="d-block"
                                            htmlFor="input_fm_1_active"
                                            key={index2}
                                          >
                                            <Input
                                              className="checkbox_animated"
                                              id={`input_fm_1_active_location_id_${function_item.functionId}`}
                                              value={`${interface_item.interfaceId}__${function_item.functionId}`}
                                              name="fm_1_interface_functions"
                                              type="checkbox"
                                            />
                                            {function_item.name}
                                          </Label>
                                        )
                                      )}
                                    </Col>
                                  )
                                )
                              : null}
                          </Row>
                        </Col>
                      </Row>
                      <div className="d-flex justify-content-end">
                        <Btn
                          attrBtn={{
                            color: "primary",
                            size: "sm",
                            disabled: false,
                            outline: true,
                            type: "submit",
                            className: "m-r-10 d-flex align-items-center",
                          }}
                        >
                          Set Permission(s)
                        </Btn>
                        {/* {model_data !== "" ? (
                  <Btn
                    attrBtn={{
                      color: "danger",
                      size: "sm",
                      disabled: false,
                      outline: true,
                      className: "d-flex align-items-center",
                    }}
                  >
                    Delete
                  </Btn>
                ) : null} */}
                      </div>
                    </Form>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default RoleLine;
