import React, { Fragment, useState, useEffect, useContext } from "react";
import { Form, FormGroup, Input, Label } from "reactstrap";
import { Btn, H4, P, Image } from "../../../AbstractElements";
import {
  EmailAddress,
  ForgotPassword,
  LoginWithJWT,
  Password,
  RememberPassword,
  SignIn,
} from "../../../Constant";
import axios from "axios";
import { toast } from "react-toastify";

import { useNavigate } from "react-router-dom";
import { adminBackendURL, hostedURL, Jwt_token } from "../../../Config/Config";
import man from "../../../assets/images/dashboard/profile.png";
import { handleResponse } from "../../../Services/fack.backend";

import CustomizerContext from "../../../_helper/Customizer";
import OtherWay from "./OtherWay";
import UserContext from "../../../_helper/User";

const LoginTab = ({ selected }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [clientId, setClientId] = useState("");
  const loading = useState(false)[0];
  const [togglePassword, setTogglePassword] = useState(false);
  const history = useNavigate();
  const { layoutURL } = useContext(CustomizerContext);
  const userContext = useContext(UserContext);

  const [value, setValue] = useState(localStorage.getItem("profileURL" || man));
  const [name, setName] = useState(localStorage.getItem("Name"));

  useEffect(() => {
    localStorage.setItem("profileURL", man);
    localStorage.setItem("Name", name);
  }, [value, name]);

  const loginAuth = async (e) => {
    e.preventDefault();
    // setLoading(true);
    setValue(man);
    setName("Emay Walter");
    if (email !== "" && password !== "") {
      localStorage.setItem("login", JSON.stringify(true));
      history(`/pages/dashboard`);
    }
  };

  const loginWithJwt = (e) => {
    const id = toast.loading("Please Wait...");
    const reqBody = {
      clientCode: clientId,
      username: email,
      password: password,
    };
    const reqInstance = axios.create({
      baseURL: adminBackendURL,
    });
    reqInstance
      .post("/checkLogin", reqBody)
      .then((response_data) => {
        if (response_data) {
          const result = response_data.data;
          if (!result.hasError) {
            if (result.islogInSuccess) {
              toast.update(id, {
                render: "Login Success",
                type: "success",
                isLoading: false,
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
              setValue(man);
              setName(result.username);
              localStorage.setItem("token", Jwt_token);
              userContext.addUserData({
                ...result,
                orgId: result.orgList.length > 1 ? 0 : result.orgList[0].orgId,
              });
              window.location.href = `${hostedURL}/pages/dashboard`;
            } else {
              toast.update(id, {
                render: "Login Failed",
                type: "error",
                isLoading: false,
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
            }
          } else {
            toast.update(id, {
              render: result.errorMsg,
              type: "error",
              isLoading: false,
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });

    // return fetch("/users/authenticate", requestOptions)
    //   .then(handleResponse)
    //   .then((user) => {
    //     // store user details and jwt token in local storage to keep user logged in between page refreshes
    //     setValue(man);
    //     setName("Emay Walter");
    //     localStorage.setItem("token", Jwt_token);
    //     window.location.href = `${hostedURL}/pages/sample-page`;
    //     return user;
    //   });
  };

  return (
    <Fragment>
      <Form className="theme-form">
        <div className="d-flex justify-content-center align-items-center m-b-20">
          <Image
            attrImage={{
              className: "img-fluid for-light",
              style: { height: "80px" },
              src: `${require("../../../assets/images/logo/sapro_logo/logo.png")}`,
              alt: "",
            }}
          />
          <Image
            attrImage={{
              className: "img-fluid for-light",
              style: { height: "80px" },
              src: `${require("../../../assets/images/logo/sapro_logo/logo-name.png")}`,
              alt: "",
            }}
          />
        </div>
        <H4>
          {selected === "simpleLogin" ? (
            "Sign In With Simple Login"
          ) : (
            <span>
              Sign In To
              <br />
              Asset Management System
            </span>
          )}
        </H4>
        <P>{"Enter your username, password & client code to login"}</P>
        <FormGroup>
          <Label className="col-form-label">Username</Label>
          <Input
            className="form-control"
            type="email"
            name="fm_1_email"
            defaultValue={""}
            required=""
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter Username"
            autoComplete="off"
          />
        </FormGroup>
        <FormGroup className="position-relative">
          <Label className="col-form-label">{Password}</Label>
          <div className="position-relative">
            <Input
              className="form-control"
              type={togglePassword ? "text" : "password"}
              name="fm_1_password"
              onChange={(e) => setPassword(e.target.value)}
              autoComplete="off"
              placeholder="Enter Password"
              required=""
              defaultValue={""}
            />
            <div
              className="show-hide"
              onClick={() => setTogglePassword(!togglePassword)}
            >
              <span className={togglePassword ? "" : "show"}></span>
            </div>
          </div>
        </FormGroup>
        <FormGroup>
          <Label className="col-form-label">Client Code</Label>
          <Input
            className="form-control"
            type="test"
            name="fm_1_client_code"
            defaultValue={""}
            required=""
            onChange={(e) => setClientId(e.target.value)}
            placeholder="Enter Client Code"
            autoComplete="off"
          />
        </FormGroup>
        <div className="position-relative form-group mb-0">
          {/* <div className="checkbox">
            <Input id="checkbox1" type="checkbox" />
            <Label className="text-muted" for="checkbox1">
              {RememberPassword}
            </Label>
          </div>
          <a className="link txt-primary" href="#javascript">
            {ForgotPassword}
          </a> */}
          {selected === "simpleLogin" ? (
            <Btn
              attrBtn={{
                color: "primary",
                className: "d-block w-100 mt-2",
                disabled: loading ? loading : loading,
                onClick: (e) => loginAuth(e),
              }}
            >
              {loading ? "LOADING..." : SignIn}
            </Btn>
          ) : (
            <Btn
              attrBtn={{
                color: "primary",
                className: "d-block w-100 mt-2",
                disabled: loading ? loading : loading,
                onClick: (e) => loginWithJwt(e),
              }}
            >
              {loading ? "LOADING..." : "Sign In"}
            </Btn>
          )}
        </div>
        {/* <OtherWay /> */}
      </Form>
    </Fragment>
  );
};

export default LoginTab;
