import React from "react";
import ReactApexChart from "react-apexcharts";
import { RadialProgressChart } from "../../../Data/Social/Chart";

const RadialChart = ({ chartData }) => {
  const updatedOption = {
    ...RadialProgressChart,
    series: chartData.chart.series,
    options: {
      ...RadialProgressChart.options,
      chart: {
        fontFamily: "Poppins, sans-serif",
        dropShadow: {
          ...RadialProgressChart.options.chart.dropShadow,
          color: [...chartData.chart.color],
        },
      },
      colors: chartData.chart.color,
    },
  };
  return (
    <ReactApexChart
      type="radialBar"
      height={130}
      options={updatedOption.options}
      series={updatedOption.series}
    />
  );
};

export default RadialChart;
