export const MENUITEMS = [
  {
    menutitle: "General",
    menucontent: "",
    Items: [
      {
        title: "Dashboard",
        icon: "home",
        type: "link",
        active: false,
        path: `/pages/dashboard`,
      },
      // {
      //   title: "Inventory",
      //   icon: "ecommerce",
      //   type: "sub",
      //   children: [
      //     {
      //       active: false,
      //       path: `/pages/sample-page`,
      //       title: "Sample-Page",
      //       type: "link",
      //     },
      //   ],
      // },
      {
        title: "Asset",
        icon: "board",
        type: "sub",
        children: [
          {
            active: false,
            path: `/asset/add-asset`,
            title: "Add-Asset",
            type: "link",
          },
        ],
      },
    ],
  },
  {
    menutitle: "Miscellaneous",
    menucontent: "",
    Items: [
      {
        title: "List",
        icon: "sample-page",
        type: "sub",
        children: [
          {
            active: false,
            path: `/asset/list-of-assets`,
            title: "List-Of-Asset",
            type: "link",
          },
          {
            active: false,
            path: `/asset/list-of-transferred-asset`,
            title: "List-Of-Transferred-Asset",
            type: "link",
          },
          {
            active: false,
            path: `/asset/list-of-disposed-assets`,
            title: "List-Of-Disposed-Assets",
            type: "link",
          },
          // {
          //   active: false,
          //   path: `/asset/maintenance`,
          //   title: "Maintenance",
          //   type: "link",
          // },
          // {
          //   active: false,
          //   path: `/asset/list-of-warranties`,
          //   title: "List-of-Warranties",
          //   type: "link",
          // },
        ],
      },
      {
        title: "Transaction Files",
        icon: "others",
        type: "sub",
        children: [
          {
            active: false,
            path: `/transactionfiles/transferred-asset`,
            title: "Transferred-Assets",
            type: "link",
          },
          {
            active: false,
            path: `/transactionfiles/disposed-assets`,
            title: "Disposed-Assets",
            type: "link",
          },
          {
            active: false,
            path: `/transactionfiles/verification`,
            title: "Verification",
            type: "link",
          },
        ],
      },
      {
        title: "Reports",
        icon: "charts",
        type: "sub",
        children: [
          {
            active: false,
            path: `/reports/asset-items`,
            title: "Asset-Items",
            type: "link",
          },
          {
            active: false,
            path: `/reports/asset-verification`,
            title: "Asset-Verification",
            type: "link",
          },
        ],
      },
      {
        title: "Tools",
        icon: "tools",
        type: "sub",
        children: [
          {
            active: false,
            path: `/tools/export-data`,
            title: "Export-Data",
            type: "link",
          },
          {
            active: false,
            path: `/tools/DocumentGalery`,
            title: "Documents Gallery",
            type: "link",
          },
        ],
      },
    ],
  },
  {
    menutitle: "System",
    menucontent: "",
    Items: [
      {
        title: "Master Files",
        icon: "others",
        type: "sub",
        children: [
          {
            active: false,
            path: `/masterfiles/site`,
            title: "Site",
            type: "link",
          },
          {
            active: false,
            path: `/masterfiles/building`,
            title: "Building",
            type: "link",
          },
          {
            active: false,
            path: `/masterfiles/floor`,
            title: "Floor",
            type: "link",
          },
          {
            active: false,
            path: `/masterfiles/location`,
            title: "Location",
            type: "link",
          },
          {
            active: false,
            path: `/masterfiles/asset-category`,
            title: "Asset-Category",
            type: "link",
          },
          {
            active: false,
            path: `/masterfiles/common-category`,
            title: "Common-Category",
            type: "link",
          },
          {
            active: false,
            path: `/masterfiles/disposal-type`,
            title: "Disposal-Type",
            type: "link",
          },
          {
            active: false,
            path: `/masterfiles/condition`,
            title: "Condition",
            type: "link",
          },
          {
            active: false,
            path: `/masterfiles/verification`,
            title: "Verification",
            type: "link",
          },
        ],
      },
      {
        title: "User Management",
        icon: "user",
        type: "sub",
        children: [
          {
            active: false,
            path: `/pages/employees`,
            title: "Employees",
            type: "link",
          },
          {
            active: false,
            path: `/pages/users`,
            title: "Users",
            type: "link",
          },
        ],
      },
      {
        title: "Settings",
        icon: "settings",
        type: "sub",
        children: [
          {
            active: false,
            path: `/pages/departments`,
            title: "Departments",
            type: "link",
          },
          {
            active: false,
            path: `/pages/designations`,
            title: "Designations",
            type: "link",
          },
          {
            active: false,
            path: `/pages/roles`,
            title: "Roles",
            type: "link",
          },
          {
            active: false,
            path: `/pages/role-lines`,
            title: "Role-Lines",
            type: "link",
          },
          {
            active: false,
            path: `/pages/modules`,
            title: "Modules",
            type: "link",
          },
          {
            active: false,
            path: `/pages/interfaces`,
            title: "Interfaces",
            type: "link",
          },
          {
            active: false,
            path: `/pages/functions`,
            title: "Functions",
            type: "link",
          },
          // {
          //   active: false,
          //   path: `/pages/employee-location`,
          //   title: "Employee-Location",
          //   type: "link",
          // },
        ],
      },
      {
        title: "Help / Support",
        icon: "support-tickets",
        type: "sub",
        children: [
          {
            active: false,
            path: `/help-support/user-manual`,
            title: "User-Manual",
            type: "link",
          },
        ],
      },
    ],
  },
];
