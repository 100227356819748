import React, { useContext, useEffect, useState } from "react";
import CommonModal from "../../../Common/Component/CommonModal";
import { Container, Row, Col, Form, Label, Input } from "reactstrap";
import { useForm, useFormContext } from "react-hook-form";
import { Btn, H5 } from "../../../../AbstractElements";
import axios from "axios";
import { toast } from "react-toastify";
import { backendURL } from "../../../../Config/Config";
import UserContext from "../../../../_helper/User";
import Swal from "sweetalert2";

const SaveCategoryModel = ({
  is_open,
  trigger_model,
  model_data,
  form_data,
  reload_table,
}) => {
  const userContext = useContext(UserContext);
  const loggedUser = userContext.userData;
  const toggle = () => trigger_model(!is_open);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });
  const onSubmit = (data) => {
    const form = new FormData(document.querySelector(".form-1"));
    if (data !== "") {
      const id = toast.loading("Please wait...");
      const reqInstance = axios.create({
        baseURL: backendURL,
      });
      const checkDuplicateReq = {
        categoryId: model_data === "" ? 0 : model_data.categoryId,
        clientId: loggedUser.clientId,
        orgId: loggedUser.orgId,
        userId: loggedUser.userId,
        moduleId: loggedUser.loggedModuleId,
        name: form.get("fm_1_name"),
        searchKey: form.get("fm_1_search_key"),
      };
      reqInstance
        .post("/checkCategoryExist", checkDuplicateReq)
        .then((check_response) => {
          const checkResponseData = check_response.data;
          if (!checkResponseData.hasError) {
            if (
              checkResponseData.existType === 0 ||
              checkResponseData.existType === -1 ||
              checkResponseData.existType === 2
            ) {
              const category = {
                categoryId: model_data === "" ? 0 : model_data.categoryId,
                clientId: loggedUser.clientId,
                orgId: loggedUser.orgId,
                userId: loggedUser.userId,
                moduleId: loggedUser.loggedModuleId,
                isActive: form.get("fm_1_active") ? true : false,
                assetCategoryId: form.get("fm_1_a_asset_category_id"),
                parentId: parseInt(form.get("fm_1_sa_category_id_parent_id"))
                  ? form.get("fm_1_sa_category_id_parent_id")
                  : -1,
                name: form.get("fm_1_name"),
                searchKey: form.get("fm_1_search_key"),
                remark: form.get("fm_1_remark"),
              };
              reqInstance
                .post("/saveCategory", category)
                .then((response_data) => {
                  const result = response_data.data;
                  if (result.hasError) {
                    toast.update(id, {
                      render: "Failed",
                      type: "error",
                      isLoading: false,
                      position: "top-right",
                      autoClose: 5000,
                      hideProgressBar: true,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                      theme: "light",
                    });
                  } else {
                    let successMessage = "";
                    if (category.categoryId > 0) {
                      successMessage = "Cateogry Successfully Updated.";
                    } else {
                      successMessage = "Cateogry Successfully Added.";
                    }
                    toast.update(id, {
                      render: successMessage,
                      type: "success",
                      isLoading: false,
                      position: "top-right",
                      autoClose: 5000,
                      hideProgressBar: true,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                      theme: "light",
                    });
                    reload_table(true);
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
            } else {
              console.log(check_response);
              if (checkResponseData.existType === 1) {
                toast.update(id, {
                  render: "Site Search Key Exists",
                  type: "warning",
                  isLoading: false,
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                });
              } else if (checkResponseData.existType === 2) {
                toast.update(id, {
                  render: "Site Name Exists",
                  type: "warning",
                  isLoading: false,
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                });
              } else if (checkResponseData.existType === 3) {
                toast.update(id, {
                  render: "Site Search Key and Name Exists",
                  type: "warning",
                  isLoading: false,
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                });
              }
            }
          }
        });
    } else {
      errors.showMessages();
    }
  };

  const [assetCategoryList, setAssetCategoryList] = useState([]);
  const [parentCategoryList, setParentCategoryList] = useState([]);

  useEffect(() => {
    if (form_data) {
      setAssetCategoryList([
        { assetCategoryId: 0, name: "- Select -" },
        ...form_data.assetCategoryList,
      ]);
      setParentCategoryList([
        { categoryId: 0, name: "- Select -" },
        ...form_data.parentList,
      ]);
    }
    if (model_data !== "") {
      console.log(model_data);
      setValue("fm_1_name", model_data?.name);
      setValue("fm_1_search_key", model_data?.searchKey);
      setValue("fm_1_remark", model_data?.remark);
      setValue("fm_1_sa_category_id_parent_id", model_data?.parentId);
      setValue("fm_1_a_asset_category_id", model_data?.assetCategoryId);
    } else {
      setValue("fm_1_name", "");
      setValue("fm_1_search_key", "");
      setValue("fm_1_remark", "");
      setValue("fm_1_sa_category_id_parent_id", 0);
      setValue("fm_1_a_asset_category_id", 0);
    }
  }, [model_data]);

  const deleteCategory = () => {
    Swal.fire({
      icon: "warning",
      title: "Are you sure?",
      text: "This will permanently deletes this Category.",
      showDenyButton: true,
      confirmButtonText: "Delete",
      denyButtonText: "Cancel",
      denyButtonColor: "#2a3142",
    }).then((confirmation) => {
      if (confirmation.isConfirmed) {
        const id = toast.loading("Please wait...");
        const reqInstance = axios.create({
          baseURL: backendURL,
        });
        const category = {
          categoryId: model_data === "" ? 0 : model_data.categoryId,
          clientId: loggedUser.clientId,
          orgId: loggedUser.orgId,
          userId: loggedUser.userId,
        };
        reqInstance
          .post("/deleteCategory", category)
          .then((response_data) => {
            const result = response_data.data;
            if (result.hasError) {
              toast.update(id, {
                render: result.errorMsg,
                type: "error",
                isLoading: false,
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
            } else {
              let successMessage = "Category Successfully Deleted.";
              toast.update(id, {
                render: successMessage,
                type: "success",
                isLoading: false,
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
              reload_table(true);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    });
  };

  return (
    <CommonModal
      isOpen={is_open}
      toggler={toggle}
      bodyClass="grid-showcase"
      size="xl"
    >
      <Container fluid={true}>
        <Row>
          <Col sm="12" className="p-b-15">
            <Form onSubmit={handleSubmit(onSubmit)} className="form-1">
              <H5 attrH5={{ className: "m-b-30" }}>
                {model_data === "" ? "Add " : "Update "} Category
              </H5>
              <Row>
                <Col lg="2">
                  <Label
                    htmlFor="id_input_fm_1_search_key"
                    className="required-input-mark"
                  >
                    Search Key
                  </Label>
                </Col>
                <Col lg="4" className="m-b-10">
                  <input
                    id="id_input_fm_1_search_key"
                    name="fm_1_search_key"
                    type="text"
                    className="form-control"
                    placeholder="Enter Search Key"
                    {...register("fm_1_search_key", {
                      required: "Please enter Search Key",
                      maxLength: {
                        value: 250,
                        message: "Please enter less than characters 250.",
                      },
                    })}
                  />
                  <span className="txt-danger f-12">
                    {errors?.fm_1_search_key &&
                      errors?.fm_1_search_key?.message}
                  </span>
                </Col>
                <Col lg="2">
                  <Label
                    htmlFor="id_input_fm_1_name"
                    className="required-input-mark"
                  >
                    Category Name
                  </Label>
                </Col>
                <Col lg="4" className="m-b-10">
                  <input
                    id="id_input_fm_1_name"
                    name="fm_1_name"
                    type="text"
                    className="form-control"
                    placeholder="Enter Asset Category Name"
                    {...register("fm_1_name", {
                      required: "Please enter Asset Category Name",
                      maxLength: {
                        value: 250,
                        message: "Please enter less than characters 250.",
                      },
                    })}
                  />
                  <span className="txt-danger f-12">
                    {errors?.fm_1_name && errors?.fm_1_name?.message}
                  </span>
                </Col>
              </Row>
              <Row>
                <Col lg="2">
                  <Label htmlFor="id_input_fm_1_sa_category_id_parent_id">
                    Parent Category
                  </Label>
                </Col>
                <Col lg="4" className="m-b-10">
                  <select
                    id="id_input_fm_1_sa_category_id_parent_id"
                    name="fm_1_sa_category_id_parent_id"
                    className="form-select"
                    {...register("fm_1_sa_category_id_parent_id")}
                  >
                    {parentCategoryList.length
                      ? parentCategoryList.map((list_item, index) => (
                          <option value={list_item?.categoryId} key={index}>
                            {list_item?.name}
                          </option>
                        ))
                      : null}
                  </select>
                </Col>
                <Col lg="2">
                  <Label htmlFor="id_input_fm_1_a_asset_category_id">
                    Asset Category
                  </Label>
                </Col>
                <Col lg="4" className="m-b-10">
                  <select
                    id="id_input_fm_1_a_asset_category_id"
                    name="fm_1_a_asset_category_id"
                    className="form-select"
                    {...register("fm_1_a_asset_category_id")}
                  >
                    {assetCategoryList.length
                      ? assetCategoryList.map((list_item, index) => (
                          <option
                            value={list_item?.assetCategoryId}
                            key={index}
                          >
                            {list_item?.name}
                          </option>
                        ))
                      : null}
                  </select>
                </Col>
              </Row>
              <Row>
                <Col lg="2">
                  <Label htmlFor="id_input_fm_1_remark">Remark</Label>
                </Col>
                <Col lg="10" className="m-b-10">
                  <textarea
                    id="id_input_fm_1_remark"
                    name="fm_1_remark"
                    rows="3"
                    className="form-control"
                    placeholder="Enter Remarks"
                    {...register("fm_1_remark", {
                      maxLength: {
                        value: 250,
                        message: "Maximum allowed character length is 250.",
                      },
                    })}
                  ></textarea>
                </Col>
              </Row>
              <Row>
                <Col lg="6" className="offset-lg-2 m-b-10">
                  <Label className="d-block" htmlFor="input_fm_1_active">
                    <Input
                      className="checkbox_animated"
                      id="input_fm_1_active"
                      name="fm_1_active"
                      type="checkbox"
                      defaultChecked={
                        model_data !== "" ? model_data.isActive : true
                      }
                      {...register("fm_1_active")}
                    />
                    Active
                  </Label>
                </Col>
              </Row>
              <div className="d-flex justify-content-end">
                {model_data !== "" ? (
                  <Btn
                    attrBtn={{
                      color: "danger",
                      size: "sm",
                      disabled: false,
                      outline: true,
                      className: "m-r-10 d-flex align-items-center",
                      type: "button",
                      onClick: () => deleteCategory(),
                    }}
                  >
                    Delete
                  </Btn>
                ) : null}
                <Btn
                  attrBtn={{
                    color: "primary",
                    size: "sm",
                    disabled: false,
                    outline: true,
                    type: "submit",
                    className: "d-flex align-items-center",
                  }}
                >
                  {model_data === "" ? "Submit" : "Update"}
                </Btn>
              </div>
            </Form>
          </Col>
        </Row>
      </Container>
    </CommonModal>
  );
};

export default SaveCategoryModel;
