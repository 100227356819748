const config = require("./AppConfig.json");

// Auth0
export const auth0 = {
  domain: config.auth0.domain,
  clientId: config.auth0.clientID,
  redirectUri: config.auth0.redirectUri,
};

// Jwt
export const Jwt_token = config.jwt_token;
//
export const backendURL = config.backendURL;
export const adminBackendURL = config.adminBackendURL;
export const hostedURL = config.hostedURL;
export const imageFileURL = config.fileServerURL;
export const assetDocumentFileURL = config.assetDocumentURL;
export const adminModuleImageURL = config.fileServer.adminModuleImageFileURL;
// Image Max Size
export const MAX_ASSET_IMAGE_SIZE = config.imageMaxSize.assetImage;
export const MAX_ASSET_FILE_SIZE = config.fileMaxSize.assetFile;

// "backendURL": "https://assetbe.saprosolutions.com/AssetManagementBE",
// "adminBackendURL": "https://assetadminbe.saprosolutions.com/AssetAdminBE",
// "hostedURL": "https://pms.saprosolutions.com/AssetManagement/#",
// "fileServerURL": "http://192.168.1.200/asset/image",
// "backendURL": "http://192.168.1.200:8080/AssetManagementModule-0.0.1-SNAPSHOT",
// "adminBackendURL": "http://192.168.1.200:8080/UserManagementModule-0.0.1-SNAPSHOT",
// "backendURL": "http://assetbe.saprosolutions.com:8087/AssetManagementBE",
// "adminBackendURL": "http://assetadminbe.saprosolutions.com:8087/AssetAdminBE",

// "backendURL": "http://192.168.1.200:9000",
// "adminBackendURL": "http://192.168.1.200:8080/UserManagementModule-0.0.1-SNAPSHOT",
// "hostedURL": "http://localhost:3000/#",
// "fileServer": {
//   "adminModuleImageFileURL": "http://192.168.1.200/image"
// },
// "fileServerURL": "http://192.168.1.200/asset/image",
// "assetDocumentURL": "http://192.168.1.200/asset/document",

// "backendURL": "https://assetbe.saprosolutions.com/AssetManagementBE",
// "adminBackendURL": "https://assetadminbe.saprosolutions.com/AssetAdminBE",
// "hostedURL": "https://asset.saprosolutions.com/",