import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Media,
  Button,
  Label,
  Form,
} from "reactstrap";
import { Breadcrumbs, Btn, Spinner } from "../../../AbstractElements";
import HeaderCard from "../../Common/Component/HeaderCard";
import ReactTable from "react-data-table-component";
import { useForm } from "react-hook-form";
import axios from "axios";
import { backendURL } from "../../../Config/Config";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import UserContext from "../../../_helper/User";
import SearchAssetItem from "./Model/SearchAssetItem";
import { ConvertDateToDate, ConvertMillisToDate } from "../../../Data/DateUtil";
import * as feather from "feather-icons";

const Dispose = () => {
  const userContext = useContext(UserContext);
  const loggedUser = userContext.userData;
  /**Page Status Section */
  const INPROGRESS = 10;
  const COMPLETED = 11;
  const [searchingProgress, setSearchingProgress] = useState(INPROGRESS);
  const [fetchingTableData, setFetchingTableData] = useState(true);
  const [triggerSearchModel, setTriggerSearchModel] = useState(false);
  const [assetItemIdList, setAssetItemIdList] = useState([]);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  /**Datatable Section */
  const statusSort = (rowA, rowB) => {
    console.log(rowA);
    const a = rowA.cost.props.children;
    const b = rowB.cost.props.children;

    if (a > b) {
      return 1;
    }

    if (b > a) {
      return -1;
    }

    return 0;
  };
  const tableColumns = useRef([
    {
      name: "Asset Item",
      selector: (row) => `${row.assetItem.name}`,
      sortable: true,
      center: false,
      sortFunction: statusSort,
      download: true,
    },
    {
      name: "Dispose Type",
      selector: (row) => `${row.disposalType.name}`,
      sortable: true,
      center: false,
      sortFunction: statusSort,
      download: true,
    },
    {
      name: "Dispose To",
      selector: (row) => `${row.disposeTo}`,
      sortable: true,
      center: false,
      download: true,
    },
    {
      name: "Disposed On",
      selector: (row) => `${ConvertMillisToDate(row.disposedOn)}`,
      sortable: true,
      center: true,
      download: true,
    },
    {
      name: "Status",
      selector: (row) => row["status"],
      sortable: true,
      center: false,
      sortFunction: statusSort,
      download: true,
    },
    {
      name: "Action",
      selector: (row) => row["action"],
      sortable: true,
      center: true,
      ignoreRowClick: true,
      download: false,
      omit: true,
    },
  ]);
  const [tableData, setTableData] = useState();
  const [showMoveForm, setShowMoveForm] = useState(false);
  const assetData = useRef([]);
  const [disposalTypeList, setDisposalTypeList] = useState([]);

  const handleBasicSearch = (evt) => {
    let inputValue = evt.target.value.toLocaleLowerCase();
    if (inputValue.length > 0) {
      let searchResults = assetData.current;
      console.log(searchResults);
      searchResults = searchResults.filter((search_row) => {
        let possibleValues = Object.values(search_row.assetItem).filter(
          (search_prop) =>
            typeof search_prop === "string" || typeof search_prop === "number"
        );
        // console.log(possibleValues);
        return (
          possibleValues.filter(
            (target_value) =>
              target_value
                .toString()
                .toLocaleLowerCase()
                .indexOf(inputValue) !== -1
          )?.length > 0
        );
      });
      setTableData(searchResults);
    } else {
      setTableData(assetData.current);
    }
  };
  const handleRowClick = (row_data) => {};

  /**Page Onload Section */
  useEffect(() => {
    if (fetchingTableData && Object.keys(loggedUser).length > 0) {
      const reqBody = {
        clientId: loggedUser.clientId,
        orgId: loggedUser.orgId,
        userId: loggedUser.userId,
        moduleId: loggedUser.loggedModuleId,
      };
      const reqInstance = axios.create({
        baseURL: backendURL,
      });
      reqInstance
        .post("/viewDisposal", reqBody)
        .then((res) => {
          console.log("result is", res);
          const result = res.data;
          console.log("result data is ", result);
          console.log("result disposalTypeList is ", disposalTypeList);
          if (!result.hasError) {
            console.log("no hasError");
            // setDisposalTypeList(result.activeDisposalTypeList)
            setDisposalTypeList([
              { disposalTypeId: 0, name: "- Select -" },
              ...result.activeDisposalTypeList,
            ]);
            let disposalData = [];
            setModelFormData({
              clientList: result.activeClientList,
              orgList: result.activeOrgList,
            });
            result.allDisposalList.forEach((disposal_item) => {
              disposalData.push({
                ...disposal_item,
                status: disposal_item?.isActive ? (
                  <span className="badge badge-light-info">Available</span>
                ) : (
                  <span className="badge badge-light-danger">
                    Not Available
                  </span>
                ),
                action: (
                  <Media>
                    <Btn
                      attrBtn={{
                        color: "success",
                        size: "sm",
                        disabled: false,
                        outline: true,
                        onClick: () => handleEditAddAsset(disposal_item),
                      }}
                    >
                      Edit
                    </Btn>
                  </Media>
                ),
              });
            });
            setTableData(disposalData);
            assetData.current = disposalData;
            setFetchingTableData(false);
            setSearchingProgress(COMPLETED);
          } else {
            setTableData([]);
            setFetchingTableData(false);
            toast.error("Request Failed", {
              isLoading: false,
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
    setTableData(assetData.current);
  }, [loggedUser, fetchingTableData]);

  const hadalTableData = () => {
    if (fetchingTableData) {
      const reqBody = {
        clientId: 38,
        orgId: 41,
        userId: 213,
        moduleId: 250,
      };
      axios
        .post(backendURL + "/viewDisposal", reqBody)
        .then((res) => {
          console.log("result is", res);
          const result = res.data;
          console.log("result data is ", result);
          console.log("result disposalTypeList is ", disposalTypeList);
          if (!result.hasError) {
            console.log("no hasError");
            // setDisposalTypeList(result.activeDisposalTypeList)
            setDisposalTypeList([
              { disposalTypeId: 0, name: "- Select -" },
              ...result.activeDisposalTypeList,
            ]);
            let disposalData = [];
            setModelFormData({
              clientList: result.activeClientList,
              orgList: result.activeOrgList,
            });
            result.allDisposalList.forEach((disposal_item) => {
              disposalData.push({
                ...disposal_item,
                status: disposal_item?.isActive ? (
                  <span className="badge badge-light-info">Available</span>
                ) : (
                  <span className="badge badge-light-danger">
                    Not Available
                  </span>
                ),
                disposed: disposal_item?.disposalType?.name,
                action: (
                  <Media>
                    <Btn
                      attrBtn={{
                        color: "success",
                        size: "sm",
                        disabled: false,
                        outline: true,
                        onClick: () => handleEditAddAsset(disposal_item),
                      }}
                    >
                      Edit
                    </Btn>
                  </Media>
                ),
              });
            });
            setTableData(disposalData);
            assetData.current = disposalData;
            setFetchingTableData(false);
            setSearchingProgress(COMPLETED);
          } else {
            setTableData([]);
            setFetchingTableData(false);
            toast.error("Request Failed", {
              isLoading: false,
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const [modelFormData, setModelFormData] = useState();
  const [modelData, setModelData] = useState();
  const [formData, setFormData] = useState({});
  const [formUpdate, setFormUpdate] = useState(false);
  const [detailsTableData, setDetailsTableData] = useState([]);

  const onSubmit = (data) => {
    const form = new FormData(document.querySelector(".form-1"));
    if (data !== "") {
      const id = toast.loading("Please wait...");
      const reqInstance = axios.create({
        baseURL: backendURL,
      });
      const disposeData = {
        disposedOn: ConvertDateToDate(form.get("fm_1_dispose_date")),
        sellPrice: form.get("fm_1_sell_price"),
        disposalTypeId: form.get("fm_1_dispose_type"),
        disposeTo: form.get("fm_1_dispose_to"),
        remark: form.get("fm_1_remark"),
        disposeId: 0,
        assetItemMasterId: detailsTableData.map((item) => item.assetItemId),
        clientId: loggedUser.clientId,
        orgId: loggedUser.orgId,
        isActive: true,
        searchKey: "",
        userId: loggedUser.userId,
        moduleId: loggedUser.loggedModuleId,
      };
      reqInstance
        .post("/saveDisposal", disposeData)
        .then((response_data) => {
          setSearchingProgress(INPROGRESS);
          setFetchingTableData(true);
          const result = response_data.data;
          if (result.hasError) {
            toast.update(id, {
              render: "Failed",
              type: "error",
              isLoading: false,
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          } else {
            let successMessage = "Item Successfully Disposed,";
            toast.update(id, {
              render: successMessage,
              type: "success",
              isLoading: false,
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            setFetchingTableData(true);
          }
        })
    } else {
      errors.showMessages();
    }
  };

  const handleSelectedData = (data) => {
    setDetailsTableData((prevData) => {
      if (!Array.isArray(prevData)) {
        prevData = [];
      }

      // Ensure data is always in an array
      if (Array.isArray(data)) {
        return [
          // ...prevData,
          ...data.map((item) => ({
            ...item,
            fixedAsset: item.isBulk ? (
              <span className="f-w-700 font-info">Yes</span>
            ) : (
              <span className="font-danger">No</span>
            ),
            action: (
              <Media>
                <Btn
                  attrBtn={{
                    color: "warning",
                    size: "sm",
                    disabled: false,
                    outline: true,
                    className: "m-r-10",
                    onClick: () => removeSelectedItem(item),
                  }}
                >
                  Remove
                </Btn>
              </Media>
            ),
          })),
        ];
      } else if (data) {
        return [
          // ...prevData,
          {
            ...data,
            fixedAsset: data.isBulk ? (
              <span className="f-w-700 font-info">Yes</span>
            ) : (
              <span className="font-danger">No</span>
            ),
          },
        ];
      }

      return prevData;
    });
  };

  const handleAddAsset = () => {
    setShowMoveForm(true);
    setTriggerSearchModel(true);
  };
  const handleEditAddAsset = (model_data) => {
    setShowMoveForm(true);
    setModelData(model_data);
    console.log("modelData", modelData);
    if (model_data !== "") {
      setFormUpdate(true);
      console.log("model data is : ", model_data);

      try {
        const disposData = new Date(model_data?.disposedOn);
        const formattedDate = disposData.toISOString().split("T")[0];
        setValue("fm_1_dispose_date", formattedDate);
      } catch (error) {
        console.error("Invalid disposalData format:", error);
        setValue("fm_1_dispose_date", "");
      }

      setValue("fm_1_sell_price", model_data?.sellPrice);
      setValue("fm_1_dispose_type", model_data?.disposalType?.disposalTypeId);
      setValue("fm_1_dispose_to", model_data?.disposeTo);
      setValue("fm_1_remark", model_data?.remark);
    } else {
      setValue("fm_1_dispose_date", "");
      setValue("fm_1_sell_price", "");
      setValue("fm_1_dispose_type", "");
      setValue("fm_1_dispose_to", "");
      setValue("fm_1_remark", "");
    }
  };

  const handleCloseAddAsset = () => {
    setValue("fm_1_dispose_date", "");
    setValue("fm_1_sell_price", "");
    setValue("fm_1_dispose_type", "");
    setValue("fm_1_dispose_to", "");
    setValue("fm_1_remark", "");
    setFormUpdate(false);
    setShowMoveForm(false);
    setDetailsTableData([]);
  };

  const handleUpdateDisposeClick = () => {
    const disposeDate = document.getElementById(
      "id_input_fm_1_dispose_date"
    ).value;
    const sellPrice = document.getElementById("input_fm_1_sell_price").value;
    const disposeType = document.getElementById(
      "id_input_fm_1_dispose_type"
    ).value;
    const disposeTo = document.getElementById("input_fm_1_dispose_to").value;
    const remark = document.getElementById("id_input_fm_1_remark").value;

    const data = {
      disposedOn: disposeDate,
      sellPrice: sellPrice,
      disposalTypeId: disposeType,
      disposeTo: disposeTo,
      remark: remark,
      disposeId: modelData.disposeId,
      clientId: modelData.clientId,
      orgId: modelData.orgId,
      isActive: true,
      createdBy: modelData.createdBy,
      updatedBy: modelData.updatedBy,
      searchKey: modelData.searchKey,
      moduleId: 250,
      userId: 213,
    };

    console.log("update data : ", data);
    axios
      .post(backendURL + "/saveDisposal", data)
      .then((res) => {
        setSearchingProgress(INPROGRESS);
        setFetchingTableData(true);
        console.log("Sucsess ", res);
        Swal.fire({
          icon: "success",
          title: "Disposal Success Update..!",
          showDenyButton: false,
        });
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const convertArrayOfObjectsToCSV = (header_list, table_data) => {
    let result;

    const columnHeaders = [];
    header_list.current.forEach((header_item) => {
      if (header_item.download) columnHeaders.push(header_item.name);
    });
    const columnDelimiter = ",";
    const lineDelimiter = "\n";
    const keys = columnHeaders;

    result = "";
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    table_data.forEach((item) => {
      let ctr = 0;

      //extracting required data
      let rowData = [
        item.assetItem.name,
        item.disposalType.name,
        item.disposeTo,
        ConvertMillisToDate(item.disposedOn),
        item.status.props.children,
      ];

      rowData.forEach((row_item) => {
        if (ctr > 0) result += columnDelimiter;

        result += row_item;

        ctr++;
      });
      result += lineDelimiter;
    });

    return result;
  };
  const downloadCSV = (header_list, table_data) => {
    const link = document.createElement("a");
    let csv = convertArrayOfObjectsToCSV(header_list, table_data);
    if (csv == null) return;

    const currentTimestamp = new Date();
    const filename = `Diposed Assets ${currentTimestamp}.csv`;

    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }

    link.setAttribute("href", encodeURI(csv));
    link.setAttribute("download", filename);
    link.click();
  };
  const tableSelectedColumns = useRef([
    {
      name: "Asset",
      selector: (row) => row["name"],
      sortable: true,
      center: false,
    },
    {
      name: "Asset Id",
      selector: (row) => `${row.searchKey}`,
      sortable: true,
      center: true,
    },
    {
      name: "Fixed Asset",
      selector: (row) => row["fixedAsset"],
      sortable: true,
      center: true,
    },
    {
      name: "Action",
      selector: (row) => row["action"],
      sortable: true,
      center: true,
      ignoreRowClick: true,
      download: false,
    },
  ]);

  const removeSelectedItem = (item) => {
    setDetailsTableData((prevData) =>
      prevData.filter(
        (exist_item) => item.assetItemId !== exist_item.assetItemId
      )
    );
  };
  return (
    <Fragment>
      <Breadcrumbs mainTitle="Asset" parent="Asset" title="Dispose" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <HeaderCard
                title="Dispose"
                span1="In here you can dispose individual/bulk asset."
              />
              <CardBody>
                <Row className="m-b-20">
                  <Col md="4 p-l-10">
                    <Button
                      type="button"
                      color="primary"
                      onClick={() => handleAddAsset()}
                    >
                      Dispose Asset(s)
                    </Button>
                  </Col>
                </Row>
                <Row className="m-b-5 justify-content-between align-items-center">
                  <Col md="4 p-l-10">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Search..."
                      onKeyDown={(evt) => handleBasicSearch(evt)}
                    />
                  </Col>
                  <Col md="3" className="d-flex justify-content-end">
                    <div
                      className="d-flex"
                      onClick={() => downloadCSV(tableColumns, tableData)}
                    >
                      <div
                        dangerouslySetInnerHTML={{
                          __html: feather.icons["download"].toSvg({
                            height: "16px",
                            width: "16px",
                          }),
                        }}
                        className={`txt-info m-r-10 d-flex align-items-center`}
                      />
                      Export CSV
                    </div>
                  </Col>
                </Row>
                <ReactTable
                  columns={tableColumns.current}
                  data={tableData}
                  progressPending={searchingProgress === INPROGRESS}
                  progressComponent={
                    <div className="">
                      <div className="loader-box">
                        <Spinner attrSpinner={{ className: "loader-26" }} />
                      </div>
                      <small>Searching...</small>
                    </div>
                  }
                  onRowClicked={(row, event) => handleRowClick(row)}
                  pointerOnHover
                  pagination
                />
              </CardBody>
            </Card>
            {showMoveForm ? (
              <Card>
                <CardBody>
                  <ReactTable
                    columns={tableSelectedColumns.current}
                    data={detailsTableData || []} // Ensure detailsTableData is displayed
                    progressComponent={
                      <div className="">
                        <div className="loader-box">
                          <Spinner attrSpinner={{ className: "loader-26" }} />
                        </div>
                        <small>Fetching...</small>
                      </div>
                    }
                    pointerOnHover
                    pagination
                  />
                  <Form onSubmit={handleSubmit(onSubmit)} className="form-1">
                    {/* Start of Dispose Details */}
                    <Row className="m-b-10">
                      <Col lg="2">
                        <Label
                          htmlFor="id_input_fm_1_dispose_date"
                          className="required-input-mark"
                        >
                          Dispose Date
                        </Label>
                      </Col>
                      <Col lg="4" className="m-b-10">
                        <input
                          type="date"
                          className="form-control"
                          id="id_input_fm_1_dispose_date"
                          name="fm_1_dispose_date"
                          {...register("fm_1_dispose_date", {
                            required: "Please select Dispose Date",
                          })}
                        />
                        <span className="txt-danger f-12">
                          {errors?.fm_1_dispose_date &&
                            errors?.fm_1_dispose_date?.message}
                        </span>
                      </Col>
                      <Col md="2">
                        <Label htmlFor="input_fm_1_sell_price">
                          Sell Price
                        </Label>
                      </Col>
                      <Col md="4" className="m-b-10">
                        <div class="input-group mb-0">
                          <span class="input-group-text" id="basic-addon1">
                            LKR
                          </span>
                          <input
                            id="input_fm_1_sell_price"
                            type="text"
                            className="form-control"
                            name="fm_1_sell_price"
                            placeholder="00.00"
                            aria-label="Cost"
                            aria-describedby="basic-addon1"
                            {...register("fm_1_sell_price", {
                              pattern: {
                                value: /^\d+(\.\d+)?$/,
                                message: "Invalid Price",
                              },
                            })}
                          />
                        </div>
                        <span className="txt-danger f-12">
                          {errors?.fm_1_sell_price &&
                            errors?.fm_1_sell_price?.message}
                        </span>
                      </Col>
                    </Row>
                    <Row className="m-b-10">
                      <Col lg="2">
                        <Label
                          htmlFor="id_input_fm_1_dispose_type"
                          className="required-input-mark"
                        >
                          Dispose Type
                        </Label>
                      </Col>
                      <Col md="4" className="m-b-10">
                        <select
                          id="id_input_fm_1_dispose_type"
                          name="fm_1_dispose_type"
                          className="form-select"
                          {...register("fm_1_dispose_type", {
                            required: "Please Select Dispose Type",
                          })}
                        >
                          {disposalTypeList.length
                            ? disposalTypeList.map((list_item, index) => (
                                <option
                                  value={list_item?.disposalTypeId}
                                  key={index}
                                >
                                  {list_item?.name}
                                </option>
                              ))
                            : null}
                        </select>
                        <span className="txt-danger f-12">
                          {errors?.fm_1_dispose_type &&
                            errors?.fm_1_dispose_type?.message}
                        </span>
                      </Col>
                      <Col md="2">
                        <Label
                          htmlFor="input_fm_1_dispose_to"
                          className="required-input-mark"
                        >
                          Dispose To
                        </Label>
                      </Col>
                      <Col md="4" className="m-b-10">
                        <input
                          id="input_fm_1_dispose_to"
                          className="form-control"
                          name="fm_1_dispose_to"
                          type="text"
                          placeholder="Dispose To"
                          {...register("fm_1_dispose_to", {
                            required: "Please enter Dispose To",
                            maxLength: {
                              value: 250,
                              message: "Please enter less than characters 250.",
                            },
                          })}
                        />
                        <span className="txt-danger f-12">
                          {errors?.fm_1_dispose_to &&
                            errors?.fm_1_dispose_to?.message}
                        </span>
                      </Col>
                    </Row>
                    <Row className="m-b-10">
                      <Col lg="2">
                        <Label htmlFor="id_input_fm_1_remark">Remark</Label>
                      </Col>
                      <Col lg="10" className="m-b-10">
                        <textarea
                          id="id_input_fm_1_remark"
                          name="fm_1_remark"
                          rows="3"
                          className="form-control"
                          placeholder="Enter Remarks"
                          {...register("fm_1_remark", {
                            maxLength: {
                              value: 250,
                              message:
                                "Maximum allowed character length is 250.",
                            },
                          })}
                        ></textarea>
                      </Col>
                    </Row>
                    <div className="d-flex justify-content-end">
                      <Button
                        color="primary"
                        className="m-r-10"
                      >
                        Dispose
                      </Button>

                      <Btn
                        attrBtn={{
                          color: "primary",
                          size: "sm",
                          disabled: false,
                          outline: true,
                          onClick: handleCloseAddAsset,
                        }}
                      >
                        Cancel
                      </Btn>
                    </div>
                  </Form>
                  {/* End of Dispose Details */}
                </CardBody>
              </Card>
            ) : null}
            <SearchAssetItem
              is_open={triggerSearchModel}
              trigger_model={setTriggerSearchModel}
              model_data={modelData}
              onItemSelected={handleSelectedData}
              form_data={modelFormData}
              reload_table={setFetchingTableData}
              set_items={setAssetItemIdList}
            />
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Dispose;
