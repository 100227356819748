import React, { Fragment, useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Dropdown,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import {
  Breadcrumbs,
  P,
  H5,
  Image,
  Btn,
  ToolTip,
} from "../../../../AbstractElements";
import { ConvertMillisToDate } from "../../../../Data/DateUtil";
import AssetItemContext from "../../../../_helper/AssetItem";
import SvgIcon from "../../../Common/Component/SvgIcon";
import AssetViewDepreciation from "./AssetView/AssetViewDepreciation";
import AssetViewDetails from "./AssetView/AssetViewDetails";
import AssetViewDocuments from "./AssetView/AssetViewDocument";
import AssetViewHistory from "./AssetView/AssetViewHistory";
import AssetViewImages from "./AssetView/AssetViewImages";
import AssetViewMaintenance from "./AssetView/AssetViewMaintenance";
import AssetViewWarranty from "./AssetView/AssetViewWarranty";
import asset_default from "../../../../assets/images/asset-item/asset_default.png";
import { imageFileURL } from "../../../../Config/Config";
import { CurrencyFormatter } from "../../../../Data/StringFormatter";

const AssetView = () => {
  const assetContext = useContext(AssetItemContext);
  const assetItemData = assetContext.assetItem;
  const [basictooltip, setbasictooltip] = useState(false);
  const toggle = () => setbasictooltip(!basictooltip);
  const [pillWithIconTab, setpillWithIconTab] = useState("1");
  const [defaultImage, setDefaultImage] = useState("");

  const mainData = [
    ["Asset Id", assetItemData.searchKey],
    ["Purchase Date", ConvertMillisToDate(assetItemData?.purchaseDate)],
    ["Cost", `LKR ${CurrencyFormatter(assetItemData?.costPerItem)}`],
    ["Brand", assetItemData?.brand],
    ["Model", assetItemData?.model],
    ["Asset Category", assetItemData?.assetCategory?.name],
    ["Category", assetItemData?.category?.name],
    ["Sub Category", assetItemData?.category?.name],
  ];
  const locationData = [
    ["Site", assetItemData?.assetSite?.name],
    ["Building", assetItemData?.building?.name],
    ["Floor", assetItemData?.floor?.name],
    ["Location", assetItemData?.location?.name],
  ];

  useEffect(() => {
    let imageList = assetItemData.assetImageList;
    if (imageList?.length > 0) {
      let selectedImage = imageList.filter(
        (image_item) => image_item.isPrimaryImage === true
      )[0];
      if (selectedImage) {
        setDefaultImage(selectedImage);
      }
    }
  }, [assetItemData]);

  return (
    <Fragment>
      <Breadcrumbs mainTitle="Asset" parent="Asset List" title="Asset View" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <Row className="align-items-stretch">
                  <Col
                    lg="4"
                    style={{ flex: "0 0 auto" }}
                    className="d-flex flex-column justify-content-between"
                  >
                    <div className="">
                      <div className="m-b-20">
                        <div className="d-flex justify-content-between align-items-end">
                          <H5>{assetItemData.name}</H5>
                          <Link to={`/asset/edit-asset`}>
                            <SvgIcon
                              id="TooltipExample"
                              className="stroke-icon"
                              iconId={`stroke-edit`}
                              height="18px"
                              width="18px"
                            />
                          </Link>
                          <ToolTip
                            attrToolTip={{
                              placement: "top",
                              isOpen: basictooltip,
                              target: "TooltipExample",
                              toggle: toggle,
                            }}
                          >
                            <div
                              className="f-w-500"
                              style={{ fontFamily: "poppins" }}
                            >
                              Click to Edit this Asset
                            </div>
                          </ToolTip>
                        </div>
                        <P
                          attrPara={{
                            className: `badge ${
                              assetItemData?.isActive
                                ? "badge-light-info"
                                : "badge-light-danger"
                            }`,
                          }}
                        >
                          {assetItemData?.isActive ? "Active" : "Not Active"}
                        </P>
                      </div>
                      <Image
                        attrImage={{
                          className: "rounded w-100 m-b-10",
                          src: `${
                            defaultImage
                              ? imageFileURL + defaultImage.imagePath
                              : asset_default
                          }`,
                          alt: "Generic placeholder image",
                          style: { maxHeight: "300px", objectFit: "contain" },
                        }}
                      />
                    </div>
                    <div className="p-b-10 d-flex justify-content-end mb-3 mb-lg-0">
                      <div className="dropdown-basic">
                        <Dropdown className="dropdown m-b-0">
                          <Btn
                            attrBtn={{
                              color: "primary",
                              className: "dropbtn",
                            }}
                          >
                            More Actions
                            <span>
                              <i className="icofont icofont-arrow-down"></i>
                            </span>
                          </Btn>
                          <DropdownMenu className="dropdown-content">
                            {/* <DropdownItem href="#">Maintenance</DropdownItem> */}
                            <DropdownItem><Link to={`/asset/list-of-disposed-assets`}>Dispose</Link></DropdownItem>
                            <DropdownItem><Link to={`/asset/list-of-transferred-asset`}>Transfer</Link></DropdownItem>
                            <DropdownItem divider />
                            <DropdownItem>Delete</DropdownItem>
                          </DropdownMenu>
                        </Dropdown>
                      </div>
                    </div>
                  </Col>
                  <Col lg="8">
                    <div className="h-100 rounded p-t-20 p-l-15 p-r-15 p-b-20 bg-light">
                      <Card className="shadow-none rounded m-b-15">
                        <CardBody className="d-flex justify-content-between p-0">
                          <div className="d-flex p-t-10 p-b-10 p-l-15">
                            <SvgIcon
                              className="fill-icon m-r-10"
                              iconId={`fill-building`}
                              height="18px"
                              width="18px"
                            />
                            <P>
                              <strong>Department</strong>
                            </P>
                          </div>
                          <P
                            attrPara={{
                              className: "text-muted  p-t-10 p-b-10 p-r-15",
                            }}
                          >
                            {assetItemData?.existDepartment?.name}
                          </P>
                        </CardBody>
                      </Card>
                      <Row className="m-0">
                        <Col sm="12" md="6">
                          {mainData.map((data_object, index) => (
                            <Row key={index} className="m-b-15">
                              <Col md="5">
                                <P attrPara={{ className: "f-w-500" }}>
                                  {data_object[0]}
                                </P>
                              </Col>
                              <Col md="7">
                                <P attrPara={{ className: "text-muted" }}>
                                  {data_object[1]}
                                </P>
                              </Col>
                            </Row>
                          ))}
                        </Col>
                        <Col sm="12" md="6">
                          {locationData.map((data_object, index) => (
                            <Row key={index} className="m-b-15">
                              <Col md="5">
                                <P attrPara={{ className: "f-w-500" }}>
                                  {data_object[0]}
                                </P>
                              </Col>
                              <Col md="7">
                                <P attrPara={{ className: "text-muted" }}>
                                  {data_object[1]}
                                </P>
                              </Col>
                            </Row>
                          ))}
                        </Col>
                      </Row>
                      <Card className="shadow-none rounded m-0">
                        <CardBody className="d-flex justify-content-between p-0">
                          <div className="d-flex p-t-10 p-b-10 p-l-15">
                            <SvgIcon
                              className="fill-icon m-r-10"
                              iconId={`fill-users`}
                              height="18px"
                              width="18px"
                            />
                            <P>
                              <strong>Assigned To</strong>
                            </P>
                          </div>
                          <P
                            attrPara={{
                              className: "text-muted  p-t-10 p-b-10 p-r-15",
                            }}
                          >
                            Not Mentioned
                          </P>
                        </CardBody>
                      </Card>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <Nav className="nav-primary nav-pills">
                  <NavItem>
                    <NavLink
                      className={pillWithIconTab === "1" ? "active" : ""}
                      onClick={() => setpillWithIconTab("1")}
                    >
                      Details
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={pillWithIconTab === "2" ? "active" : ""}
                      onClick={() => setpillWithIconTab("2")}
                    >
                      Images
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={pillWithIconTab === "3" ? "active" : ""}
                      onClick={() => setpillWithIconTab("3")}
                    >
                      Documents
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={pillWithIconTab === "4" ? "active" : ""}
                      onClick={() => setpillWithIconTab("4")}
                    >
                      Depreciation
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={pillWithIconTab === "5" ? "active" : ""}
                      onClick={() => setpillWithIconTab("5")}
                    >
                      Warranty
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={pillWithIconTab === "6" ? "active" : ""}
                      onClick={() => setpillWithIconTab("6")}
                    >
                      Linking
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={pillWithIconTab === "7" ? "active" : ""}
                      onClick={() => setpillWithIconTab("7")}
                    >
                      Maintenance
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={pillWithIconTab === "8" ? "active" : ""}
                      onClick={() => setpillWithIconTab("8")}
                    >
                      Reserve{" "}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={pillWithIconTab === "9" ? "active" : ""}
                      onClick={() => setpillWithIconTab("9")}
                    >
                      Audit
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={pillWithIconTab === "10" ? "active" : ""}
                      onClick={() => setpillWithIconTab("10")}
                    >
                      History
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent activeTab={pillWithIconTab}>
                  <TabPane className="fade show" tabId="1">
                    <P attrPara={{ className: "mb-0 m-t-30" }}>
                      <AssetViewDetails asset_data={assetItemData} />
                    </P>
                  </TabPane>
                  <TabPane tabId="2">
                    <P attrPara={{ className: "mb-0 m-t-30" }}>
                      {pillWithIconTab === "2" ? (
                        <AssetViewImages
                          asset_item_data_context={assetItemData}
                          item_context={assetContext}
                        />
                      ) : null}
                    </P>
                  </TabPane>
                  <TabPane tabId="3">
                    <P attrPara={{ className: "mb-0 m-t-30" }}>
                      <AssetViewDocuments asset_data={assetItemData} />
                    </P>
                  </TabPane>
                  <TabPane tabId="4">
                    <P attrPara={{ className: "mb-0 m-t-30" }}>
                      <AssetViewDepreciation />
                    </P>
                  </TabPane>
                  <TabPane tabId="5">
                    <P attrPara={{ className: "mb-0 m-t-30" }}>
                      <AssetViewWarranty />
                    </P>
                  </TabPane>
                  <TabPane tabId="6">
                    <P attrPara={{ className: "mb-0 m-t-30" }}>
                      <AssetViewWarranty />
                    </P>
                  </TabPane>
                  <TabPane tabId="7">
                    <P attrPara={{ className: "mb-0 m-t-30" }}>
                      <AssetViewMaintenance />
                    </P>
                  </TabPane>
                  <TabPane tabId="10">
                    <P attrPara={{ className: "mb-0 m-t-30" }}>
                      <AssetViewHistory />
                    </P>
                  </TabPane>
                </TabContent>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default AssetView;
