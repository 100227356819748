import React, { Fragment, useEffect, useRef, useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Media,
  Button,
  Label,
} from "reactstrap";
import { Breadcrumbs, Btn, Spinner } from "../../../AbstractElements";
import HeaderCard from "../../Common/Component/HeaderCard";
import ReactTable from "react-data-table-component";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";

const Maintenance = () => {
  /**Page Status Section */
  const INPROGRESS = 10;
  const COMPLETED = 11;
  const [searchingProgress, setSearchingProgress] = useState(INPROGRESS);
  const weekDays = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  /**Datatable Section */
  const statusSort = (rowA, rowB) => {
    console.log(rowA);
    const a = rowA.cost.props.children;
    const b = rowB.cost.props.children;

    if (a > b) {
      return 1;
    }

    if (b > a) {
      return -1;
    }

    return 0;
  };
  const tableColumns = useRef([
    {
      name: "Asset Id",
      selector: (row) => `${row.assetId}`,
      sortable: true,
      center: true,
    },
    {
      name: "Description",
      selector: (row) => `${row.description}`,
      sortable: true,
      center: true,
    },
    {
      name: "Status",
      selector: (row) => row["status"],
      sortable: true,
      center: true,
      sortFunction: statusSort,
    },
    {
      name: "Site",
      selector: (row) => `${row.site}`,
      sortable: true,
      center: true,
    },
    {
      name: "Building",
      selector: (row) => `${row.building}`,
      sortable: true,
      center: true,
    },
    {
      name: "Floor",
      selector: (row) => `${row.floor}`,
      sortable: true,
      center: true,
    },
    {
      name: "Location",
      selector: (row) => `${row.location}`,
      sortable: true,
      center: true,
    },
    {
      name: "Action",
      selector: (row) => row["action"],
      sortable: true,
      center: true,
      ignoreRowClick: true,
    },
  ]);
  const [tableData, setTableData] = useState();
  const [showMoveForm, setShowMoveForm] = useState(false);
  const [frequency, setFrequency] = useState(0);
  const assetData = useRef([
    {
      id: 1,
      assetId: "#PX0101",
      description: "HP ProBook 12050",
      status: <span className="badge badge-light-info">Available</span>,
      site: "Site A",
      building: "Wing A",
      floor: "Ground Floor",
      location: "Meeting Room",
      action: (
        <Media>
          <Link to={`${process.env.PUBLIC_URL}/asset/asset-list/asset-view/2`}>
            <Btn
              attrBtn={{
                color: "info",
                size: "sm",
                disabled: false,
                outline: true,
              }}
            >
              More
            </Btn>
          </Link>
        </Media>
      ),
    },
    {
      id: 2,
      assetId: "#PX0101",
      description: "Chair HB",
      status: <span className="badge badge-light-info">Available</span>,
      site: "Site A",
      building: "Wing A",
      floor: "1st Floor",
      location: "Meeting Room",
      action: (
        <Media>
          <Link to={`${process.env.PUBLIC_URL}/asset/asset-list/asset-view/2`}>
            <Btn
              attrBtn={{
                color: "info",
                size: "sm",
                disabled: false,
                outline: true,
              }}
            >
              More
            </Btn>
          </Link>
        </Media>
      ),
    },
    {
      id: 3,
      assetId: "#PX0101",
      description: "Table Set 6 Seat",
      status: <span className="badge badge-light-info">Available</span>,
      site: "Site C",
      building: "Wing B",
      floor: "Ground Floor",
      location: "Lobby",
      action: (
        <Media>
          <Link to={`${process.env.PUBLIC_URL}/asset/asset-list/asset-view/2`}>
            <Btn
              attrBtn={{
                color: "info",
                size: "sm",
                disabled: false,
                outline: true,
              }}
            >
              More
            </Btn>
          </Link>
        </Media>
      ),
    },
  ]);
  const handleBasicSearch = (evt) => {
    let inputValue = evt.target.value.toLocaleLowerCase();
    if (inputValue.length > 0) {
      let searchResults = assetData.current;
      searchResults = searchResults.filter((search_row) => {
        let possibleValues = Object.values(search_row).filter(
          (search_prop) =>
            typeof search_prop === "string" || typeof search_prop === "number"
        );
        // console.log(possibleValues);
        return (
          possibleValues.filter(
            (target_value) =>
              target_value
                .toString()
                .toLocaleLowerCase()
                .indexOf(inputValue) !== -1
          )?.length > 0
        );
      });
      setTableData(searchResults);
    } else {
      setTableData(assetData.current);
    }
  };
  const handleRowClick = (row_data) => {};

  /**Page Onload Section */
  useEffect(() => {
    setTableData(assetData.current);
    setSearchingProgress(COMPLETED);
  }, []);

  const handleAddAsset = () => {
    setShowMoveForm(true);
  };
  const handleCloseAddAsset = () => {
    setShowMoveForm(false);
  };

  return (
    <Fragment>
      <Breadcrumbs mainTitle="Asset" parent="Asset" title="Maintenance" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <HeaderCard
                title="Maintenance"
                span1="In here you can use to individual/bulk asset maintenance define."
              />
              <CardBody>
                <Row className="m-b-5 justify-content-between">
                  <Col md="4 p-l-10">
                    <Button
                      type="button"
                      color="primary"
                      onClick={() => handleAddAsset()}
                    >
                      Add Asset
                    </Button>
                  </Col>
                  <Col md="4 p-l-10">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Search..."
                      onKeyDown={(evt) => handleBasicSearch(evt)}
                    />
                  </Col>
                </Row>
                <ReactTable
                  columns={tableColumns.current}
                  data={tableData}
                  progressPending={searchingProgress === INPROGRESS}
                  progressComponent={
                    <div className="">
                      <div className="loader-box">
                        <Spinner attrSpinner={{ className: "loader-26" }} />
                      </div>
                      <small>Searching...</small>
                    </div>
                  }
                  onRowClicked={(row, event) => handleRowClick(row)}
                  pointerOnHover
                  selectableRows
                  pagination
                />
              </CardBody>
            </Card>
            {showMoveForm ? (
              <Card>
                <CardBody>
                  {/* Start of Maintenance Details */}
                  <Row className="m-b-10">
                    <Col md="2">
                      <Label
                        htmlFor="input_fm_1_title"
                        className="required-input-mark"
                      >
                        Title
                      </Label>
                    </Col>
                    <Col md="4" className="m-b-10">
                      <input
                        id="input_fm_1_title"
                        className="form-control"
                        name="fm_1_title"
                        type="text"
                        placeholder="Title"
                        {...register("fm_1_title", {
                          required: "Please enter Title",
                          maxLength: {
                            value: 250,
                            message: "Please enter less than characters 250.",
                          },
                        })}
                      />
                      <span className="txt-danger f-12">
                        {errors?.fm_1_title && errors?.fm_1_title?.message}
                      </span>
                    </Col>
                    <Col md="2">
                      <Label htmlFor="input_fm_1_status">Status</Label>
                    </Col>
                    <Col md="4" className="m-b-10">
                      <select
                        id="input_fm_1_status"
                        name="fm_1_status"
                        className="form-select"
                        {...register("fm_1_status")}
                      ></select>
                    </Col>
                  </Row>
                  <Row className="m-b-10">
                    <Col lg="2">
                      <Label htmlFor="id_input_fm_1_description">
                        Description
                      </Label>
                    </Col>
                    <Col lg="4" className="m-b-10">
                      <textarea
                        id="id_input_fm_1_description"
                        name="fm_1_description"
                        className="form-control"
                        rows="3"
                        placeholder="Enter Description"
                        {...register("fm_1_description", {
                          maxLength: {
                            value: 250,
                            message: "Maximum allowed character length is 250.",
                          },
                        })}
                      ></textarea>
                    </Col>
                    <Col lg="2">
                      <Label htmlFor="id_input_fm_1_date_completed">
                        Date Completed
                      </Label>
                    </Col>
                    <Col lg="4" className="m-b-10">
                      <input
                        type="date"
                        className="form-control"
                        id="id_input_fm_1_date_completed"
                        name="fm_1_date_completed"
                        {...register("fm_1_date_completed")}
                      />
                    </Col>
                  </Row>
                  <Row className="m-b-10">
                    <Col lg="2">
                      <Label htmlFor="id_input_fm_1_due_date">Due Date</Label>
                    </Col>
                    <Col lg="4" className="m-b-10">
                      <input
                        type="date"
                        className="form-control"
                        id="id_input_fm_1_due_date"
                        name="fm_1_due_date"
                        {...register("fm_1_due_date")}
                      />
                    </Col>
                    <Col md="2">
                      <Label htmlFor="input_fm_1_cost">Maintenance Cost</Label>
                    </Col>
                    <Col md="4" className="m-b-10">
                      <div class="input-group mb-0">
                        <span class="input-group-text" id="basic-addon1">
                          $
                        </span>
                        <input
                          id="input_fm_1_cost"
                          type="text"
                          className="form-control"
                          name="fm_1_cost"
                          placeholder="00.00"
                          aria-label="Cost"
                          aria-describedby="basic-addon1"
                          {...register("fm_1_cost", {
                            pattern: {
                              value: /^\d+(\.\d+)?$/,
                              message: "Invalid Phone Number",
                            },
                          })}
                        />
                      </div>
                      <span className="txt-danger f-12">
                        {errors?.fm_1_cost && errors?.fm_1_cost?.message}
                      </span>
                    </Col>{" "}
                  </Row>
                  <Row className="m-b-10">
                    <Col md="2">
                      <Label htmlFor="input_fm_1_maitnenance_by">
                        Maintenance By
                      </Label>
                    </Col>
                    <Col md="4" className="m-b-10">
                      <input
                        id="input_fm_1_maitnenance_by"
                        className="form-control"
                        name="fm_1_maitnenance_by"
                        type="text"
                        placeholder="Title"
                        {...register("fm_1_maitnenance_by", {
                          maxLength: {
                            value: 250,
                            message: "Please enter less than characters 250.",
                          },
                        })}
                      />
                      <span className="txt-danger f-12">
                        {errors?.fm_1_maitnenance_by &&
                          errors?.fm_1_maitnenance_by?.message}
                      </span>
                    </Col>
                  </Row>
                  <Row className="m-b-10">
                    <Col sm="12" md="2">
                      <Label htmlFor="input_fm_1_repeating">Repeating</Label>
                    </Col>
                    <Col xs="12" sm="12" md="4" className="d-flex m-b-10">
                      <Col xs="6" sm="6">
                        <Label
                          className="d-flex align-items-center mb-0"
                          htmlFor="input_fm_1_repeating_yes"
                        >
                          <input
                            className="radio_animated"
                            id="input_fm_1_repeating_yes"
                            type="radio"
                            name="fm_1_repeating"
                            value={1}
                            {...register("fm_1_repeating")}
                          />
                          Yes
                        </Label>
                      </Col>
                      <Col xs="6" sm="6">
                        <Label
                          className="d-flex align-items-center mb-0"
                          htmlFor="input_fm_1_repeating_no"
                        >
                          <input
                            className="radio_animated m-l-5"
                            id="input_fm_1_repeating_no"
                            type="radio"
                            name="fm_1_repeating"
                            value={0}
                            {...register("fm_1_repeating")}
                          />
                          No
                        </Label>
                      </Col>
                    </Col>
                  </Row>
                  <Row className="m-b-10">
                    <Col sm="12" md="2">
                      <Label htmlFor="input_fm_1_frequency">Frequency</Label>
                    </Col>
                    <Col xs="12" sm="12" md="4" className="d-flex m-b-10">
                      <Col xs="6" sm="6">
                        <Label
                          className="d-flex align-items-center mb-0"
                          htmlFor="input_fm_1_frequency_daily"
                        >
                          <input
                            className="radio_animated"
                            id="input_fm_1_frequency_daily"
                            type="radio"
                            name="fm_1_frequency"
                            value={0}
                            onClick={() => setFrequency(0)}
                            {...register("fm_1_frequency")}
                          />
                          Daily
                        </Label>
                      </Col>
                      <Col xs="6" sm="6">
                        <Label
                          className="d-flex align-items-center mb-0"
                          htmlFor="input_fm_1_frequency_weekly"
                        >
                          <input
                            className="radio_animated m-l-5"
                            id="input_fm_1_frequency_weekly"
                            type="radio"
                            name="fm_1_frequency"
                            value={1}
                            onClick={() => setFrequency(1)}
                            {...register("fm_1_frequency")}
                          />
                          Weekly
                        </Label>
                      </Col>
                      <Col xs="6" sm="6">
                        <Label
                          className="d-flex align-items-center mb-0"
                          htmlFor="input_fm_1_frequency_monthly"
                        >
                          <input
                            className="radio_animated"
                            id="input_fm_1_frequency_monthly"
                            type="radio"
                            name="fm_1_frequency"
                            value={2}
                            onClick={() => setFrequency(2)}
                            {...register("fm_1_frequency")}
                          />
                          Monthly
                        </Label>
                      </Col>
                      <Col xs="6" sm="6">
                        <Label
                          className="d-flex align-items-center mb-0"
                          htmlFor="input_fm_1_frequency_yearly"
                        >
                          <input
                            className="radio_animated m-l-5"
                            id="input_fm_1_frequency_yearly"
                            type="radio"
                            name="fm_1_frequency"
                            value={3}
                            onClick={() => setFrequency(3)}
                            {...register("fm_1_frequency")}
                          />
                          Yearly
                        </Label>
                      </Col>
                    </Col>
                  </Row>
                  {frequency > 0 ? (
                    <Row className="m-b-10">
                      <Col md="2">
                        <Label htmlFor="">Recur On</Label>
                      </Col>
                      <Col md="10">
                        {frequency === 1 ? (
                          <Row>
                            <Col md="2">
                              <Label htmlFor="input_fm_1_weekly">Every</Label>
                            </Col>
                            <Col md="4">
                              <input
                                id="input_fm_1_weekly"
                                className="form-control"
                                name="fm_1_weekly"
                                type="text"
                                placeholder="1"
                                {...register("fm_1_weekly", {
                                  pattern: {
                                    value: /\b[0-9]\b/,
                                    message: "Invalid Input",
                                  },
                                })}
                              />
                            </Col>
                            <Col md="2">
                              <Label htmlFor="input_fm_1_weekly_day">
                                Week(s) On
                              </Label>
                            </Col>
                            <Col md="4">
                              <select
                                id="input_fm_1_weekly_day"
                                name="fm_1_weekly_day"
                                className="form-select"
                                {...register("fm_1_weekly_day")}
                              >
                                {weekDays.map((week_item, index) => (
                                  <option key={index} value={index}>
                                    {week_item}
                                  </option>
                                ))}
                              </select>
                            </Col>
                          </Row>
                        ) : null}
                        {frequency === 2 ? (
                          <Row>
                            <Col md="2">
                              <Label htmlFor="input_fm_1_monthly">Every</Label>
                            </Col>
                            <Col md="4">
                              <input
                                id="input_fm_1_monthly"
                                className="form-control"
                                name="fm_1_monthly"
                                type="text"
                                placeholder="1"
                                {...register("fm_1_monthly", {
                                  pattern: {
                                    value: /\b[0-9]\b/,
                                    message: "Invalid Input",
                                  },
                                })}
                              />
                            </Col>
                            <Col md="2">
                              <Label htmlFor="input_fm_1_monthly_month">
                                Month(s) On
                              </Label>
                            </Col>
                            <Col md="4">
                              <select
                                id="input_fm_1_monthly_month"
                                name="fm_1_monthly_month"
                                className="form-select"
                                {...register("fm_1_monthly_month")}
                              >
                                {[...Array(30).keys()].map(
                                  (month_item, index) =>
                                    index ? (
                                      <option key={index} value={index}>
                                        {month_item}
                                      </option>
                                    ) : null
                                )}
                              </select>
                            </Col>
                          </Row>
                        ) : null}
                        {frequency === 3 ? (
                          <Row>
                            <Col md="2">
                              <Label htmlFor="input_fm_1_yearly">Every</Label>
                            </Col>
                            <Col md="4">
                              <select
                                id="input_fm_1_yearly"
                                name="fm_1_yearly"
                                className="form-select"
                                {...register("fm_1_yearly")}
                              >
                                {months.map((month_item, index) => (
                                  <option key={index} value={index}>
                                    {month_item}
                                  </option>
                                ))}
                              </select>
                            </Col>
                            <Col md="2">
                              <Label htmlFor="input_fm_1_yearly_month">
                                On
                              </Label>
                            </Col>
                            <Col md="4">
                              <select
                                id="input_fm_1_monthly_month"
                                name="fm_1_monthly_month"
                                className="form-select"
                                {...register("fm_1_monthly_month")}
                              >
                                {[...Array(30).keys()].map(
                                  (month_item, index) =>
                                    index ? (
                                      <option key={index} value={index}>
                                        {month_item}
                                      </option>
                                    ) : null
                                )}
                              </select>
                            </Col>
                          </Row>
                        ) : null}
                      </Col>
                    </Row>
                  ) : null}
                  <div className="d-flex justify-content-end">
                    <Button type="button" color="primary" className="m-r-10">
                      Save
                    </Button>
                    <Btn
                      attrBtn={{
                        color: "primary",
                        size: "sm",
                        disabled: false,
                        outline: true,
                        onClick: handleCloseAddAsset,
                      }}
                    >
                      Cancel
                    </Btn>
                  </div>
                  {/* End of Maintenance Details */}
                </CardBody>
              </Card>
            ) : null}
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Maintenance;
