import React from "react";
import { Col, Container, Row } from "reactstrap";
import { H6, P } from "../../../../../AbstractElements";
import * as feather from "feather-icons";
import {
  ConvertMillisToDate,
  ConvertMillisToTime,
} from "../../../../../Data/DateUtil";

const AssetViewDetails = ({ asset_data }) => {
  const mainData = [
    ["Serial No.", asset_data.serialNo],
    ["Purchase From", asset_data.purchaseFrom],
    ["Reference No.", asset_data.referenceNo],
  ];
  const depreciationData = [
    ["Depreciation Cost", "$ 20"],
    ["Asset Life (months)", "60"],
    ["Salvage Value", "$ 2"],
    ["Depreciation Method", "Straight Line"],
    ["Date Acquired", "2023-03-23"],
  ];
  const creationData = [
    [
      "Created On",
      `${ConvertMillisToDate(asset_data?.created)} ${ConvertMillisToTime(
        asset_data?.created
      )}`,
    ],
    ["Created By", asset_data?.updatedBy?.username],
  ];

  return (
    <Container fluid={true}>
      <H6
        attrH6={{
          className: "m-b-20 p-b-10 f-w-600 d-flex b-b-dark",
        }}
      >
        <div
          dangerouslySetInnerHTML={{
            __html: feather.icons["codepen"].toSvg({
              height: "14px",
              width: "14px",
            }),
          }}
          className="txt-success"
        />
        <div className="m-l-10">Basic</div>
      </H6>
      <Row>
        {mainData.map((data_object, index) => (
          <Col key={index} sm="12" md="6">
            <Row key={index} className="m-b-15">
              <Col md="5">
                <P attrPara={{ className: "f-w-500" }}>{data_object[0]}</P>
              </Col>
              <Col md="7">
                <P attrPara={{ className: "text-muted" }}>{data_object[1]}</P>
              </Col>
            </Row>
          </Col>
        ))}
      </Row>
      {/* <H6
        attrH6={{
          className: "m-b-20 m-t-30 p-b-10 f-w-600 d-flex b-b-dark",
        }}
      >
        <div
          dangerouslySetInnerHTML={{
            __html: feather.icons["trending-down"].toSvg({
              height: "14px",
              width: "14px",
            }),
          }}
          className="txt-danger"
        />
        <div className="m-l-10">Depreciation</div>
      </H6>
      <Row>
        {depreciationData.map((data_object, index) => (
          <Col key={index} sm="12" md="6">
            <Row key={index} className="m-b-15">
              <Col md="5">
                <P attrPara={{ className: "f-w-500" }}>{data_object[0]}</P>
              </Col>
              <Col md="7">
                <P attrPara={{ className: "text-muted" }}>{data_object[1]}</P>
              </Col>
            </Row>
          </Col>
        ))}{" "}
      </Row> */}
      <H6
        attrH6={{
          className: "m-b-20 m-t-30 p-b-10 f-w-600 d-flex b-b-dark",
        }}
      >
        <div
          dangerouslySetInnerHTML={{
            __html: feather.icons["feather"].toSvg({
              height: "14px",
              width: "14px",
            }),
          }}
          className="txt-info"
        />
        <div className="m-l-10">Creation</div>
      </H6>
      <Row>
        {creationData.map((data_object, index) => (
          <Col key={index} sm="12" md="6">
            <Row key={index} className="m-b-15">
              <Col md="5">
                <P attrPara={{ className: "f-w-500" }}>{data_object[0]}</P>
              </Col>
              <Col md="7">
                <P attrPara={{ className: "text-muted" }}>{data_object[1]}</P>
              </Col>
            </Row>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default AssetViewDetails;
